import { string } from 'zod';
import { defineMessages } from 'react-intl';

import {
  isConsecutiveDigitsNegative,
  isConsecutiveDigitsPositive,
  isOnlyDigits,
  isSameDigits,
} from '../../../lib/test-utils/validations';

export const messages = defineMessages({
  requiredSize: {
    id: 'pinValidation:size',
    defaultMessage: 'Incorrect pin length',
  },
  required: {
    id: 'pinValidation:required',
    defaultMessage: 'Pin is required',
  },
  onlyDigits: {
    id: 'pinValidation:onlyDigits',
    defaultMessage: 'Your PIN must contains only digits',
  },
  sameDigits: {
    id: 'pinValidation:sameDigits',
    defaultMessage: 'Your PIN cannot be the same digits',
  },
  consecutiveDigits: {
    id: 'pinValidation:consecutiveDigits',
    defaultMessage: 'Your PIN cannot contain consecutive digits',
  },
  incorrectConfirmation: {
    id: 'pinValidation:incorrectConfirmation',
    defaultMessage: 'Incorrect confirmation pin',
  },
});

export const DEFAULT_PIN_SIZE = 4;

type GetPinValidationOptions = {
  size?: number;
  errorMessages?: {
    incorrectSize?: string;
    required?: string;
    sameDigits?: string;
    consecutiveDigits?: string;
    onlyDigits?: string;
  };
};
export const getPinValidation = (options: GetPinValidationOptions = {}) => {
  const { size = DEFAULT_PIN_SIZE, errorMessages = {} } = options;

  return string({ message: errorMessages.required || messages.required.defaultMessage })
    .min(size, errorMessages.incorrectSize || messages.requiredSize.defaultMessage)
    .max(size, errorMessages.incorrectSize || messages.requiredSize.defaultMessage)
    .refine((value) => isOnlyDigits(value), errorMessages.onlyDigits || messages.onlyDigits.defaultMessage)
    .refine((value) => isSameDigits(value), errorMessages.sameDigits || messages.sameDigits.defaultMessage)
    .refine(
      (value) => isConsecutiveDigitsPositive(value),
      errorMessages.consecutiveDigits || messages.consecutiveDigits.defaultMessage,
    )
    .refine(
      (value) => isConsecutiveDigitsNegative(value),
      errorMessages.consecutiveDigits || messages.consecutiveDigits.defaultMessage,
    );
};
