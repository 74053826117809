import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useMemo } from 'react';

import { SmsOptInPopupController } from './sms-opt-in-popup.controller';

const SmsOptInPopupModal = NiceModal.create(SmsOptInPopupController);
export const useSmsOptInPopup = () => {
  const { show } = useModal(SmsOptInPopupModal);
  return useMemo(() => ({ open: show }), [show]);
};
