import React, { ReactNode } from 'react';
import { defineMessage } from 'react-intl';

import { PoweredByOpenCommerce, NavigationBar, Page, PageContent } from '@ocx/ui';
import { BackButton } from '../../components/Layout/NavigationBar/BackButton/BackButton';
import { HtmlTitle } from '../../components/router/HtmlTitle';
import { VendorLogo } from '../../components/Layout/NavigationBar/VendorLogo/VendorLogo';

export type TransactionReceiptLayoutProps = {
  children: ReactNode;
};

export const TransactionReceiptLayout: React.FC<TransactionReceiptLayoutProps> = ({ children }) => {
  return (
    <Page>
      <HtmlTitle
        title={defineMessage({
          id: 'transactionReceipt:htmlTitle',
          defaultMessage: 'Receipt',
          description: 'HTML title tag for Transaction Receipt Page',
        })}
      />
      <NavigationBar left={<BackButton />} center={<VendorLogo />} />
      <PageContent bgcolor="background.paper">
        {children}
        <PoweredByOpenCommerce />
      </PageContent>
    </Page>
  );
};
