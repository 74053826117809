import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useDeepLinkNavigate, useGetIsInternalUrl } from '../../../modules/deep-linking/deep-linking.utils';

const navigateExternal = (url: string, options?: { isOpenInNewTab?: boolean }) => {
  const a = document.createElement('a');
  a.href = url;
  if (options?.isOpenInNewTab) {
    a.target = '_blank';
  }
  a.click();
};

export const useOfferNavigateTo = () => {
  const navigate = useNavigate();
  const navigateDeepLink = useDeepLinkNavigate();
  const isInternalUrl = useGetIsInternalUrl();

  return useCallback(
    (params: { url: string; isInAppLink: boolean }) => {
      if (params.isInAppLink) {
        navigate(params.url);
      }
      if (isInternalUrl(params.url)) {
        navigateDeepLink(params.url);
        return;
      }
      navigateExternal(params.url, { isOpenInNewTab: true });
    },
    [isInternalUrl, navigate, navigateDeepLink],
  );
};
