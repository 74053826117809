import React from 'react';

export const CloseIcon = (props: React.SVGProps<SVGSVGElement>) => {
  const { color = 'currentColor', ...rest } = props;

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill={color} {...rest}>
      <path d="M13.4158 12.0041L19.7158 5.71409C19.9041 5.52579 20.0099 5.27039 20.0099 5.00409C20.0099 4.73779 19.9041 4.4824 19.7158 4.29409C19.5275 4.10579 19.2721 4 19.0058 4C18.7395 4 18.4841 4.10579 18.2958 4.29409L12.0058 10.5941L5.7158 4.29409C5.52749 4.10579 5.2721 4 5.0058 4C4.7395 4 4.4841 4.10579 4.2958 4.29409C4.10749 4.4824 4.00171 4.73779 4.00171 5.00409C4.00171 5.27039 4.10749 5.52579 4.2958 5.71409L10.5958 12.0041L4.2958 18.2941C4.20207 18.3871 4.12768 18.4977 4.07691 18.6195C4.02614 18.7414 4 18.8721 4 19.0041C4 19.1361 4.02614 19.2668 4.07691 19.3887C4.12768 19.5105 4.20207 19.6211 4.2958 19.7141C4.38876 19.8078 4.49936 19.8822 4.62122 19.933C4.74308 19.9838 4.87379 20.0099 5.0058 20.0099C5.13781 20.0099 5.26852 19.9838 5.39038 19.933C5.51223 19.8822 5.62284 19.8078 5.7158 19.7141L12.0058 13.4141L18.2958 19.7141C18.3888 19.8078 18.4994 19.8822 18.6212 19.933C18.7431 19.9838 18.8738 20.0099 19.0058 20.0099C19.1378 20.0099 19.2685 19.9838 19.3904 19.933C19.5122 19.8822 19.6228 19.8078 19.7158 19.7141C19.8095 19.6211 19.8839 19.5105 19.9347 19.3887C19.9855 19.2668 20.0116 19.1361 20.0116 19.0041C20.0116 18.8721 19.9855 18.7414 19.9347 18.6195C19.8839 18.4977 19.8095 18.3871 19.7158 18.2941L13.4158 12.0041Z" />
    </svg>
  );
};
