import { MessageDescriptor } from '../../../lib/translation/types';
import { primaryStatusMessages, secondaryFuelingMessage } from './messages';
import { PrimaryInternalFuelingStatus } from '../../../modules/transaction/types';

export type IInternalStatusToMessages = {
  [key in PrimaryInternalFuelingStatus]: {
    primary: MessageDescriptor;
    secondary?: MessageDescriptor;
  };
};

export const statusToMessageMap: IInternalStatusToMessages = {
  [PrimaryInternalFuelingStatus.Authorizing]: {
    primary: primaryStatusMessages[PrimaryInternalFuelingStatus.Authorizing],
  },
  [PrimaryInternalFuelingStatus.BeginFueling]: {
    primary: primaryStatusMessages[PrimaryInternalFuelingStatus.BeginFueling],
    secondary: secondaryFuelingMessage,
  },
  [PrimaryInternalFuelingStatus.Fueling]: {
    primary: primaryStatusMessages[PrimaryInternalFuelingStatus.Fueling],
  },
  [PrimaryInternalFuelingStatus.CompletedSuccessfully]: {
    primary: primaryStatusMessages[PrimaryInternalFuelingStatus.CompletedSuccessfully],
  },
  [PrimaryInternalFuelingStatus.CompletedUnsuccessfully]: {
    primary: primaryStatusMessages[PrimaryInternalFuelingStatus.CompletedUnsuccessfully],
  },
  [PrimaryInternalFuelingStatus.Cancelling]: {
    primary: primaryStatusMessages[PrimaryInternalFuelingStatus.Cancelling],
  },
  [PrimaryInternalFuelingStatus.CompletedCancelled]: {
    primary: primaryStatusMessages[PrimaryInternalFuelingStatus.CompletedCancelled],
  },
  [PrimaryInternalFuelingStatus.Unknown]: {
    primary: primaryStatusMessages[PrimaryInternalFuelingStatus.Unknown],
  },
};
