import React from 'react';
import { Box, Divider, Paper, Stack, styled } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@ocx/ui';

import { PointsStrategy } from '@ocx/graphql';
import { BuyEntriesButton } from './components/BuyEntriesButton';
import { BuyAllEntriesButton } from './components/BuyAllEntriesButton';
import { EntriesCounter } from './components/EntriesCounter';
import { SizingMultiplier } from '../../../theme/constants';
import { OfferDetailsTermsAndDisclaimer } from '../../../components/OfferDetails/OfferDetailsTermsAndDisclaimer';
import { messages } from '../messages';
import { EntriesTicket } from '../components/EntriesTicket/EntriesTicket';
import { MarketingContent } from '../../../modules/offers/offer.types';
import { genericMessages } from '../../../modules/localization/genericMessages';

const CONTENT_Z_INDEX = 1;

const PaperStyled = styled(Paper)(({ theme }) => ({
  borderBottomRightRadius: 0,
  borderBottomLeftRadius: 0,
  padding: theme.spacing(SizingMultiplier.lg),
  paddingBottom: `calc(${theme.spacing(SizingMultiplier.lg)} + var(--ion-safe-area-bottom, 0px))`,
  zIndex: CONTENT_Z_INDEX,
}));

export interface IPointsSweepsProps {
  memberEntries: number;
  entriesAmountToBuy: number;
  entriesAmountToBuyPrice: number;
  onEntriesAmountToBuyChange: (value: number) => void;
  maxEntriesMemberCanBuy: number;
  maxEntriesMemberCanBuyPrice: number;
  onBuyEntriesClick: () => void;
  onBuyAllEntriesClick: () => void;
  canAddEntries: boolean;
  canRemoveEntries: boolean;
  marketingContent: MarketingContent;
  loading: boolean;
  pointsStrategy: Exclude<PointsStrategy, PointsStrategy.NotUsed>;
}

export const PointsSweeps: React.FC<IPointsSweepsProps> = ({
  memberEntries,
  entriesAmountToBuy,
  entriesAmountToBuyPrice,
  onEntriesAmountToBuyChange,
  maxEntriesMemberCanBuy,
  maxEntriesMemberCanBuyPrice,
  onBuyEntriesClick,
  onBuyAllEntriesClick,
  canAddEntries,
  canRemoveEntries,
  marketingContent,
  loading,
  pointsStrategy,
}) => {
  const { disclaimer, disclaimerUrl, terms, termsUrl } = marketingContent;
  const hasTermsOrDisclaimer = disclaimer || disclaimerUrl || terms || termsUrl;
  const canMemberBuyEntries = !!maxEntriesMemberCanBuy;

  return (
    <Stack direction="column" minHeight="100%" overflow="auto">
      <Stack position="relative" minHeight={200} justifyContent="center" m="auto">
        <EntriesTicket entries={memberEntries} />
      </Stack>
      <PaperStyled>
        <Typography variant="h6" align={canMemberBuyEntries ? 'center' : 'left'} gutterBottom>
          <FormattedMessage {...messages.title} />
        </Typography>
        {canMemberBuyEntries ? (
          <Stack
            alignItems="center"
            py={SizingMultiplier.md}
            divider={
              <Divider sx={{ width: 130, my: SizingMultiplier.lg }}>
                <Typography variant="subtitle2" textTransform="uppercase">
                  <FormattedMessage {...genericMessages['generic:vocabulary:or']} />
                </Typography>
              </Divider>
            }>
            <BuyAllEntriesButton
              entries={maxEntriesMemberCanBuy}
              price={maxEntriesMemberCanBuyPrice}
              onClick={onBuyAllEntriesClick}
              pointsStrategy={pointsStrategy}
            />
            <EntriesCounter
              value={entriesAmountToBuy}
              onChange={onEntriesAmountToBuyChange}
              minusButtonEnabled={canRemoveEntries}
              plusButtonEnabled={canAddEntries}
            />
          </Stack>
        ) : (
          <Typography variant="body2" paragraph>
            {pointsStrategy === PointsStrategy.Points && <FormattedMessage {...messages.notEnoughPointsText} />}
            {pointsStrategy === PointsStrategy.Cents && <FormattedMessage {...messages.notEnoughCashText} />}
          </Typography>
        )}
        <BuyEntriesButton
          entries={entriesAmountToBuy}
          price={entriesAmountToBuyPrice}
          onClick={onBuyEntriesClick}
          loading={loading}
          disabled={!canMemberBuyEntries}
          pointsStrategy={pointsStrategy}
        />
        {hasTermsOrDisclaimer && (
          <Box mt={SizingMultiplier.md}>
            <OfferDetailsTermsAndDisclaimer
              disclaimer={disclaimer}
              disclaimerUrl={disclaimerUrl}
              terms={terms}
              termsUrl={termsUrl}
            />
          </Box>
        )}
      </PaperStyled>
    </Stack>
  );
};
