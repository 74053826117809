import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  'contact-support-page:title': {
    id: 'contact-support-page:title',
    defaultMessage: 'Need help?',
    description: 'Contact support page title',
  },
  'contact-support-page:description': {
    id: 'contact-support-page:description',
    defaultMessage: 'Reach out to our support team.',
    description: 'Contact support page description',
  },
  'contact-support-page:chatButtonText': {
    id: 'support:buttonText',
    defaultMessage: 'Chat with support',
    description: 'Support button text',
  },
});
