import { useCallback, useEffect, useState } from 'react';

import { InMemoryStorage } from './in-memory-storage';

export const useInMemoryStorage = <ValueType>(storageKey: string) => {
  const [value, setValue] = useState<ValueType | null>(() => InMemoryStorage.getValue<ValueType>(storageKey));

  const setStorageValue = useCallback(
    (value: ValueType, ttlMs?: number) => {
      InMemoryStorage.setValue(storageKey, value, { ttlMs });
      setValue(value);
    },
    [storageKey],
  );

  const eraseStorageValue = useCallback(() => {
    InMemoryStorage.eraseValue(storageKey);
    setValue(null);
  }, [storageKey]);

  useEffect(() => {
    const listenerId = InMemoryStorage.addOnSetListener<ValueType | null>(storageKey, (value) => setValue(value));
    return () => InMemoryStorage.removeOnSetListener(storageKey, listenerId);
  }, [storageKey]);

  return { value, setStorageValue, eraseStorageValue };
};
