import { Box, Stack } from '@mui/material';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useModal } from '@ebay/nice-modal-react';
import { Typography } from '@ocx/ui';

import { CompleteEmailActionSheetProps } from './complete-email-action-sheet.types';
import { ModalDrawer } from '../components';
import { SizingMultiplier } from '../../../theme/constants';
import { useCompleteEmailActionSheetController } from './complete-email-action-sheet.controller.hook';
import { CompleteEmailActionSheetView } from './complete-email-action-sheet.view';
import { messages } from './complete-email-action-sheet.messages';

export const CompleteEmailActionSheetController = (props: CompleteEmailActionSheetProps) => {
  const { visible, hide, remove } = useModal();

  const { onCompleted } = props;
  const handleCompleted = useCallback(() => {
    hide();
    onCompleted?.();
  }, [hide, onCompleted]);
  const { handleUpdateEmail } = useCompleteEmailActionSheetController({ onCompleted: handleCompleted });

  return (
    <ModalDrawer open={visible} onClose={hide} onExited={remove}>
      <Box p={SizingMultiplier.lg}>
        <Stack mb={SizingMultiplier.md}>
          <Typography align="center" variant="h5" gutterBottom>
            <FormattedMessage {...messages['complete-email-action-sheet:title']} />
          </Typography>
          <Typography align="center" variant="body2" color="textSecondary">
            <FormattedMessage {...messages['complete-email-action-sheet:description']} />
          </Typography>
        </Stack>
        <CompleteEmailActionSheetView onSubmit={handleUpdateEmail} />
      </Box>
    </ModalDrawer>
  );
};
