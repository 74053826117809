import React from 'react';
import { Switch, FormControlLabel, FormControlLabelProps } from '@mui/material';
import { Field } from 'react-final-form';

export type SwitchFieldProps = {
  name: string;
  value?: boolean;
  disabled?: boolean;
  label?: React.ReactNode;
  formControlLabelProps?: Partial<FormControlLabelProps>;
};

export const SwitchField = (props: SwitchFieldProps) => {
  const { name, value, label, disabled, formControlLabelProps } = props;

  return (
    <Field
      name={name}
      type="checkbox"
      value={value}
      render={({ input }) => (
        <FormControlLabel
          control={<Switch {...input} />}
          label={label}
          disabled={disabled}
          {...formControlLabelProps}
        />
      )}
    />
  );
};
