import React, { ReactNode } from 'react';
import { styled } from '@mui/material';

import { Indicator, Phase } from './Indicator';
import { TRIGGER_THRESHOLD, OUTER_DIAMETER, RADIUS, START_THRESHOLD, TRIGGERED_EASING } from './constants';
import { linearScale } from './linearScale';

export type PullToRefreshProps = {
  scrollRef?: React.MutableRefObject<HTMLElement | null>;
  onRefresh: () => Promise<any>;
  children: ReactNode;
};

const scale = {
  // Move content downward so that the indicator is fully visible by the time the trigger threshold is reached, then add
  // a little 'overscroll' effect beyond.
  surfaceTranslation: linearScale({
    domain: [0, TRIGGER_THRESHOLD, 100],
    range: [0, OUTER_DIAMETER, OUTER_DIAMETER * 1.1],
  }),

  // Start the indicator slightly above it's normal position and move downward once indicator has started.
  surfaceIndicatorTranslation: linearScale({
    domain: [START_THRESHOLD, TRIGGER_THRESHOLD],
    range: [-RADIUS, 0],
  }),

  // Applies a linear scale up to the activation threshold, then an exponential easing function.
  drag: (dY: number) => {
    const { a, b, c, friction } = TRIGGERED_EASING;
    const x = dY * friction;

    if (x <= TRIGGER_THRESHOLD) {
      return x;
    }

    return a / (b - x) + c;
  },
};

const IndicatorContainer = styled('div', {
  shouldForwardProp: (propName) => !['pullDistance'].includes(propName as string),
})<{ pullDistance: number }>(({ pullDistance, theme }) => ({
  width: '100%',
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  transition: theme.transitions.create('transform'),

  // region - Handle phase
  '&.idle': {
    transform: `translateY(${scale.surfaceIndicatorTranslation(0)}px)`,
  },
  '&.active': {
    transform: `translateY(${scale.surfaceIndicatorTranslation(100)}px)`,
  },
  '&.pulling': {
    transition: 'none',
    transform: `translateY(${scale.surfaceIndicatorTranslation(pullDistance)}px)`,
  },
  // endregion
}));

export const ContentContainer = styled('div')<{ pullDistance: number }>(({ pullDistance, theme }) => ({
  transition: theme.transitions.create('transform'),

  // usage same layout styles as PageContent
  flex: '1 1 0',
  display: 'flex',
  flexDirection: 'column',

  // region - Handle phase
  '&.idle': {
    transform: `translateY(${scale.surfaceTranslation(0)}px)`,
  },
  '&.active': {
    transform: `translateY(${scale.surfaceTranslation(TRIGGER_THRESHOLD)}px)`,
  },
  '&.pulling': {
    transition: 'none',
    transform: `translateY(${scale.surfaceTranslation(pullDistance)}px) !important`,
  },
  // endregion
}));

export const PullToRefresh: React.FC<PullToRefreshProps> = ({ children, onRefresh, scrollRef }) => {
  const { phase, pullDistance, onTouchMove, onTouchEnd } = useController({ onRefresh, scrollRef });

  return (
    <>
      <IndicatorContainer pullDistance={pullDistance} className={phase}>
        <Indicator phase={phase} pullDistance={pullDistance} />
      </IndicatorContainer>
      <ContentContainer pullDistance={pullDistance} className={phase} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}>
        {children}
      </ContentContainer>
    </>
  );
};

export type IUseControllerOptions = Pick<PullToRefreshProps, 'onRefresh' | 'scrollRef'>;

export const useController = ({ onRefresh, scrollRef }: IUseControllerOptions) => {
  // The initial Y position of the pull gesture. Stored as a ref to prevent re-renders during user interaction.
  const startY = React.useRef<number | null>(null);

  // The logical distance that the pull gesture has been moved, after passing the physical input through an easing
  // function.
  const [pullDistance, setPullDistance] = React.useState(0);

  // The phase of the pull gesture.
  // idle - the user is not pulling; indicator should be hidden.
  // pulling - the user is mid-pull; indicator should be visible and animated according to `pullDistance`.
  // active - the user released after crossing the trigger threshold and a refresh is currently in progress.
  const [phase, setPhase] = React.useState<Phase>('idle');

  // (dis)allows scrolling the scrollable element or viewport
  // This is performed by manipulating style props rather than through `event.preventDefault()`, since the latter would
  // required non-passive touch event listeners, which could impact perceived scroll performance.
  const allowScroll = React.useCallback(
    (canScroll: boolean) => {
      const element = scrollRef?.current ?? window.document.body;
      if (canScroll) {
        element.style.removeProperty('overflow-y');
      } else {
        element.style.setProperty('overflow-y', 'hidden');
      }
    },
    [scrollRef],
  );

  // (dis)allows the built-in overscroll behaviour on the viewport.
  const allowOverscroll = React.useCallback((canOverscroll: boolean) => {
    if (canOverscroll) {
      window.document.body.style.removeProperty('overscroll-behavior-y');
    } else {
      window.document.body.style.setProperty('overscroll-behavior-y', 'none');
    }
  }, []);

  // Creates a function that gets the scroll offset of the scrollable element or viewport.
  // This is memoized so we don't have to query which element to measure on every touch event.
  const scrollDistance = React.useMemo(() => {
    if (scrollRef) {
      return () => scrollRef.current?.scrollTop || 0;
    }

    return () => window.scrollY;
  }, [scrollRef]);

  const onTouchMove = React.useCallback<React.TouchEventHandler<HTMLDivElement>>(
    (event) => {
      // If we're not at the 'top' of the scrollable element, or already active, do nothing
      if (scrollDistance() !== 0 || phase === 'active') {
        return;
      }

      // Initialize the start-point of the current gesture if not already done
      const y = event.targetTouches[0].screenY;
      if (startY.current === null) {
        startY.current = y;
      }

      // If we're attempting to scroll upward (ie, dragging down) then ensure we're in the pulling phase and update the
      // pull distance. Otherwise, return to the idle phase (ie, continue scrolling as normal)
      const dy = y - startY.current;
      if (dy > 0) {
        setPhase('pulling');
        setPullDistance(scale.drag(dy));
      } else {
        setPhase('idle');
        setPullDistance(0);
      }
    },
    [scrollDistance, phase],
  );

  const onTouchEnd = React.useCallback<React.TouchEventHandler<HTMLDivElement>>(() => {
    // Reset starting y position for next gesture
    startY.current = null;

    // Don't allow another refresh if when active or idle
    if (phase !== 'pulling') {
      return;
    }

    // If the user released before the trigger threshold is hit, cancel and return to initial state
    if (pullDistance < TRIGGER_THRESHOLD) {
      setPhase('idle');
      return;
    }

    // Successfully triggered, run refresh function then return to initial state regardless of outcome
    setPhase('active');
    onRefresh().finally(() => setPhase('idle'));
  }, [phase, pullDistance, onRefresh]);

  // Prevent scrolling while in the middle of a pull gesture
  React.useEffect(() => {
    allowScroll(phase !== 'pulling');
  }, [allowScroll, phase]);

  // Prevent overscrolling while the component is mounted
  React.useEffect(() => {
    allowOverscroll(false);

    return () => {
      allowOverscroll(true);
    };
  }, [allowOverscroll]);

  return { phase, pullDistance, onTouchMove, onTouchEnd };
};
