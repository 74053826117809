import { useState, useEffect } from 'react';
import * as Sentry from '@sentry/react';

import { useGetGooglePayRequest } from './useGetGooglePayRequests';
import { IPaymentAvailability } from '../../payments.types';
import { GooglePayUniversal } from '../GooglePayUniversal';

export const useIsGooglePayAvailable = (): IPaymentAvailability => {
  const [loading, setLoading] = useState(true);
  const [available, setAvailable] = useState(false);
  const { getGooglePayIsReadyToPayRequestData } = useGetGooglePayRequest();

  useEffect(() => {
    const exec = async () => {
      const isReadyToPayRequestData = getGooglePayIsReadyToPayRequestData();
      if (isReadyToPayRequestData === null) {
        return;
      }

      const { success } = await GooglePayUniversal.isReadyToPay(isReadyToPayRequestData);
      setAvailable(success);
    };
    exec()
      .catch(Sentry.captureException)
      .finally(() => setLoading(false));
  }, [getGooglePayIsReadyToPayRequestData]);

  return { loading, available };
};
