import { FormattedMessage } from 'react-intl';
import React from 'react';
import { Typography } from '@ocx/ui';

import { InfoIcon } from '../Icons/InfoIcon';
import { SizingMultiplier } from '../../theme/constants';
import { messages as walletMessages } from '../../modules/wallet/wallet.messages';

interface IWalletSizeLimitHintProps {
  sizeLimit: number;
}

export const WalletSizeLimitHint = ({ sizeLimit }: IWalletSizeLimitHintProps) => {
  return (
    <Typography variant="caption" color="textSecondary" display="flex" alignContent="center">
      <InfoIcon sx={{ color: 'text.disabled', mr: SizingMultiplier.xs, width: 16, height: 16 }} />
      <FormattedMessage {...walletMessages['wallet:size-limit:title']} values={{ sizeLimit }} />
    </Typography>
  );
};
