import React from 'react';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { TransactionReceipt } from './TransactionReceipt';
import { TransactionReceiptLayout } from './TransactionReceiptLayout';
import { useTransactionReceiptController } from './hooks/useTransactionReceiptController';
import { ScreenLoader } from '../../components/ScreenLoader/ScreenLoader';
import { TRANSACTION_RECEIPT_NUMBER_FORMAT_OPTIONS } from '../../modules/transaction/utils';
import { formatDateTime } from '../../lib/date/date';
import { ReplaceToHome } from '../Home/ReplaceToHome';
import { DateTimeFormat } from '../../lib/date/date.types';

export const TransactionReceiptController: React.FC = () => {
  const intl = useIntl();
  const { transactionId = '' } = useParams<{ transactionId: string }>();
  const { loading, receipt } = useTransactionReceiptController(transactionId);

  if (!receipt && loading) {
    return (
      <TransactionReceiptLayout>
        <ScreenLoader />
      </TransactionReceiptLayout>
    );
  }

  if (!receipt) {
    return <ReplaceToHome />;
  }

  return (
    <TransactionReceiptLayout>
      <TransactionReceipt
        address={receipt.address}
        date={formatDateTime(new Date(receipt.date), DateTimeFormat.DateSlashedTimeFull)}
        lineItems={receipt.lineItems.map((item) => ({
          title: item.name,
          value:
            item.amount !== null ? intl.formatNumber(item.amount, TRANSACTION_RECEIPT_NUMBER_FORMAT_OPTIONS) : null,
          quantity: item.quantity,
        }))}
      />
    </TransactionReceiptLayout>
  );
};

export default TransactionReceiptController;
