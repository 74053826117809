import React from 'react';

import { ListItemButton, ListItemLink } from '@ocx/ui';
import { useSupportAction } from '../use-support-action';

export interface SupportListItemProps {
  children?: React.ReactNode;
}

export const SupportListItem: React.FC<SupportListItemProps> = (props) => {
  const supportAction = useSupportAction();
  const { children } = props;
  if (supportAction.type === 'external-url') {
    return (
      <ListItemLink onClick={supportAction.onClick} href={supportAction.href} target="_blank">
        {children}
      </ListItemLink>
    );
  }
  return <ListItemButton onClick={supportAction.onClick}>{children}</ListItemButton>;
};
