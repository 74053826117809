import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useEventId } from '../../modules/events/useEventId';
import { HtmlTitle } from '../../components/router/HtmlTitle';
import { ReplaceToHome } from '../Home/ReplaceToHome';

export const EventController: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [, setEventId] = useEventId();
  useEffect(() => {
    setEventId(id || null);
  }, [id, setEventId]);

  return (
    <>
      <HtmlTitle title="LoyaltyEventHandler" />
      <ReplaceToHome />
    </>
  );
};
