import React from 'react';
import Payment from 'payment';

import { TextField, TextFieldProps } from './TextField';

export const CardCVVField: React.FC<TextFieldProps> = (props) => {
  const attachFormatter = React.useCallback((input?: HTMLInputElement) => {
    if (!input) {
      return;
    }
    Payment.formatCardCVC(input);
  }, []);

  return (
    <TextField
      fullWidth
      type="password"
      autoComplete="on"
      inputProps={{
        inputMode: 'numeric',
        autoComplete: 'on',
        autocompletetype: 'cc-csc',
        pattern: '\\d*',
      }}
      label="CVV"
      inputRef={attachFormatter}
      {...props}
    />
  );
};
