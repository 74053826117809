import { useMemo } from 'react';
import { SpinWheelGame } from '@ocx/spin-wheel';

import { SizingMultiplier } from '../../../../theme/constants';
import { SpinWheelGameOffer } from '../../../../modules/offers/offer.types';
import { mapSpinWheelGameCustomDataToProps, mapSegmentsToSectors } from '../spin-wheel-game.utils';
import { SpinWheelSectorContent } from '../components';
import { useSpinWheelFreeGameController } from './use-spin-wheel-free-game-controller.hook';

type SpinWheelFreeGameControllerProps = {
  offer: SpinWheelGameOffer;
  onLastGameplayEnd(): void;
};
export const SpinWheelFreeGameController = (props: SpinWheelFreeGameControllerProps) => {
  const { offer, onLastGameplayEnd } = props;
  const {
    customData,
    earningMechanism: { prizePool },
    marketingContent: { terms, termsUrl, disclaimer, disclaimerUrl },
  } = offer;

  const {
    //
    gameplayCount,
    handlePlayClick,
    handleSpinningEnd,
    isSpinning,
    winningSectorIndex,
    isActionButtonDisabled,
    isGameLimitReached,
  } = useSpinWheelFreeGameController({ offer, onLastGameplayEnd });

  const { segments: sectorsConfiguration } = customData?.spinWheelGame ?? {};
  const sectors = useMemo(
    () => mapSegmentsToSectors(prizePool, sectorsConfiguration),
    [prizePool, sectorsConfiguration],
  );

  return (
    <SpinWheelGame
      // play button configuration
      numberOfGamePlays={gameplayCount}
      onPlayClick={handlePlayClick}
      isPlayButtonDisabled={isActionButtonDisabled || isGameLimitReached}
      isPlayButtonVisible
      // animation
      onSpinningEnd={handleSpinningEnd}
      isSpinning={isSpinning}
      winningSectorIndex={winningSectorIndex}
      // configuration
      sectors={sectors}
      sectorContentRender={SpinWheelSectorContent}
      sizingMultiplier={SizingMultiplier}
      // disclaimer
      terms={terms || undefined}
      termsUrl={termsUrl || undefined}
      disclaimer={disclaimer || undefined}
      disclaimerUrl={disclaimerUrl || undefined}
      // customisation
      {...mapSpinWheelGameCustomDataToProps(customData)}
      // purchase button disabled for free games
      isPurchaseButtonVisible={false}
      isPurchaseButtonLoading={false}
      isPurchaseButtonDisabled
    />
  );
};
