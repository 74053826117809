import React from 'react';
import { FallbackRender } from '@sentry/react';

import criticalErrorIcon from './assets/critical-error-icon.svg';

const handleButtonClick = () => {
  window.location.reload();
};

type ErrorBoundaryFallbackProps = {
  // error: Error;
  // componentStack: string;
  eventId?: string;
  reason?: 'exception' | 'app-config-not-loaded';
};

export const renderErrorFallback: FallbackRender = ({ eventId }) => {
  return <ErrorBoundaryFallback eventId={eventId} />;
};

export const ErrorBoundaryFallback: React.FC<ErrorBoundaryFallbackProps> = ({ eventId, reason }) => {
  const reasonExplanation =
    reason === 'app-config-not-loaded'
      ? 'Unable to load application configuration due to poor internet or issue on our end. Please check your connectivity and try again.'
      : 'Something went wrong. Please press the try again button.';

  return (
    <div style={{ textAlign: 'center', margin: '5vh auto 0', padding: 8, color: '#565656', maxWidth: 400 }}>
      <img src={criticalErrorIcon} alt="Robot" />
      <h2 data-sentry-unmask>OOPS...</h2>
      <p data-sentry-unmask style={{ fontSize: 16, textAlign: 'left' }}>
        {reasonExplanation}
        {eventId ? ' If the issue persists, provide the following Event ID to our support team. Thank you!' : null}
      </p>
      {eventId && (
        <p
          data-sentry-unmask
          style={{
            color: '#212121',
            fontSize: 18,
            fontWeight: 'bold',
            wordWrap: 'break-word',
          }}>
          {eventId}
        </p>
      )}
      <button
        data-sentry-unmask
        type="button"
        onClick={handleButtonClick}
        style={{
          border: 'none',
          width: 140,
          height: 48,
          backgroundColor: '#002D5D',
          color: '#fff',
          borderRadius: 8,
          font: 'inherit',
        }}>
        TRY AGAIN
      </button>
    </div>
  );
};
