import { useCallback } from 'react';

import { useAlerts } from './useAlerts';
import { useIsOnline } from '../../hooks/useIsOnline';
import { useGetNavigationActions } from '../../hooks/useGetNavigationActions';
import { useRudderStack } from '../../lib/rudderStack/useRudderStack';

export interface IUseStartPayInsideParams {
  isEmailCompleted: boolean;
}

export const useStartPayInside = (params: IUseStartPayInsideParams) => {
  const { triggerEvent } = useRudderStack();

  const alerts = useAlerts();
  const isOnline = useIsOnline();
  const { pushToPayInside } = useGetNavigationActions();
  const { isEmailCompleted } = params;

  return useCallback(async () => {
    triggerEvent('scan_for_pay_inside_clicked');

    if (!isOnline) {
      alerts.showNoConnectionAlert();
      return;
    }

    if (!isEmailCompleted) {
      alerts.showCompleteEmailAlert({ onCompleted: () => pushToPayInside() });
      return;
    }

    pushToPayInside();
  }, [isEmailCompleted, isOnline, pushToPayInside, alerts, triggerEvent]);
};
