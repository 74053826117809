import { useEffect } from 'react';
import { isApolloError } from '@apollo/client';
import { addBreadcrumb, captureException } from '@sentry/react';

import { useGetLoyaltyEventOffer, useShowLoyaltyEventAward } from './useHandleUserGotLoyaltyEventOffer';
import { useCreateLoyaltyEvent } from '../../../hooks/useCreateLoyaltyEvent';
import { useEventId } from '../useEventId';
import { useShowGenericErrorSnackbar } from '../../../hooks/useShowGenericErrorSnackbar';

export type CreateLoyaltyEventWithStatusModalParams = {
  membershipId: string;
};

export const useCreateLoyaltyEventWithStatusModal = ({ membershipId }: CreateLoyaltyEventWithStatusModalParams) => {
  const showGenericErrorSnackbar = useShowGenericErrorSnackbar();
  const [eventId, setEventId] = useEventId();
  const [createLoyaltyEvent, { loading: isCreatingALoyaltyEvent }] = useCreateLoyaltyEvent({ membershipId });

  const [getEventOffers, { loading: isGettingAnAward }] = useGetLoyaltyEventOffer();
  const showAward = useShowLoyaltyEventAward();

  useEffect(() => {
    // IMPORTANT: The `createLoyaltyEvent` function should only be called here if the `membershipId` is not null.
    if (!eventId || !membershipId) {
      return;
    }
    const createEvent = async () => {
      setEventId(null);
      let award = null;
      try {
        award = await getEventOffers(eventId);
        addBreadcrumb({
          category: 'loyalty',
          message: `useCreateLoyaltyEventWithStatusModal received an award, ${award?.id}`,
        });
      } catch (e) {
        addBreadcrumb({
          category: 'loyalty',
          message: 'useCreateLoyaltyEventWithStatusModal failed to fetch an award',
        });
      }
      await createLoyaltyEvent(eventId);
      showAward(award);
    };
    createEvent().catch((e) => {
      showGenericErrorSnackbar();
      if (!isApolloError(e)) {
        // TODO: consider skipping network errors
        captureException(e);
      }
    });
  }, [eventId, createLoyaltyEvent, setEventId, membershipId, getEventOffers, showAward, showGenericErrorSnackbar]);
  return { loading: isCreatingALoyaltyEvent || isGettingAnAward };
};
