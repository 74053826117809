import React from 'react';

import { OfferTitle } from './OfferTitle';

export type ExternalOfferTitleProps = {
  title: string;
  titleMiddle?: string | null;
  title2?: string | null;
};

export const ExternalOfferTitle: React.FC<ExternalOfferTitleProps> = ({ title, titleMiddle, title2 }) => {
  return (
    <OfferTitle variant="carousel">
      {title} {titleMiddle} {title2}
    </OfferTitle>
  );
};
