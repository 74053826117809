import { styled, TabScrollButtonProps, alpha } from '@mui/material';

/* To reach the fade effect we need to display block with gradient on top of the tab text.
Block with gradient works as scroll arrow button - scroll tabs to left or to right. */
const CarouselScrollButton = styled('div')<TabScrollButtonProps>(({ disabled, theme }) => {
  const gradientColorStart = theme.palette.background.paper;
  const gradientColorCenter = alpha(gradientColorStart, 0.5);
  const gradientColorEnd = alpha(gradientColorStart, 0.0);

  return {
    width: 24,
    height: '100%',
    position: 'absolute',
    zIndex: 1,
    transition: '0.1s ease-in',

    '&:first-of-type': {
      background: `linear-gradient(to right, ${gradientColorStart}, ${gradientColorCenter}, ${gradientColorEnd});`,
      left: 0,
    },
    '&:last-of-type': {
      background: `linear-gradient(to left, ${gradientColorStart}, ${gradientColorCenter}, ${gradientColorEnd});`,
      right: 0,
    },

    ...(disabled
      ? {
          transition: '0.1s ease-out',
          width: 0,
        }
      : undefined),
  };
});

export default CarouselScrollButton;
