import { useMemo } from 'react';

// This hook is primarily used fot the Lottie player
// When Lottie player finishes animation - this hook resolves the promise so that we can `await` the animation
export const useAnimationHandler = () => {
  return useMemo(() => {
    let handleAnimationComplete: (value: void | PromiseLike<void>) => void = () => {};

    const handleAnimation = new Promise<void>((res) => {
      handleAnimationComplete = res;
    });

    return { handleAnimationComplete, handleAnimation };
  }, []);
};
