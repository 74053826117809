import { defineMessages, MessageDescriptor } from 'react-intl';

import { PinCodeAction } from './wallet-pin-code-popup.types';

export const actionTitles: { [key in PinCodeAction]: MessageDescriptor } = defineMessages({
  [PinCodeAction.SET]: {
    id: 'pinPopup:setTitle',
    description: 'Pin popup set title',
    defaultMessage: 'Set payment pin',
  },
  [PinCodeAction.SET_CONFIRM]: {
    id: 'pinPopup:setConfirmTitle',
    description: 'Pin popup set confirm title',
    defaultMessage: 'Confirm payment pin',
  },
  [PinCodeAction.VERIFY]: {
    id: 'pinPopup:verifyTitle',
    description: 'Verify payment pin title',
    defaultMessage: 'Enter pin',
  },
});

export const messages = defineMessages({
  title: {
    id: 'resetPinCaution:title',
    defaultMessage: 'Caution',
    description: 'Rest pin caution popup title',
  },
  text: {
    id: 'resetPinCaution:text',
    defaultMessage: 'For security, resetting your pin will remove your payment methods',
    description: 'Rest pin caution popup text',
  },
  confirmButtonTitle: {
    id: 'resetPinCaution:confirmButtonTitle',
    defaultMessage: 'Continue',
    description: 'Rest pin caution popup confirm button title',
  },
});
