import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  'force-upgrade-popup:title': {
    id: 'force-upgrade-popup:title',
    defaultMessage: 'New Version Available',
    description: 'Title of the "Force Upgrade" popup',
  },
  'force-upgrade-popup:description': {
    id: 'force-upgrade-popup:description',
    defaultMessage:
      'This version of the app is no longer supported. Please upgrade to the latest version in the app store.',
    description: 'Description of the "Force Upgrade" popup',
  },
});
