import { useCallback, useMemo, useState } from 'react';

const STORE_KEY = 'OC_EVENT_ID';
const TTL_MS = 5 * 60 * 1000;

type PersistedEventId = {
  eventId: string;
  expiresAt: number;
};

export const useEventId = () => {
  const [value, setValue] = useState<PersistedEventId | null>(() => {
    const stored = localStorage.getItem(STORE_KEY);
    if (!stored) {
      return null;
    }
    try {
      return JSON.parse(stored);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn('Unable to parse event ID in local storage; discarded.', e);
    }
    return null;
  });

  const updateValue = useCallback((eventId: string | null) => {
    if (!eventId) {
      setValue(null);
      localStorage.removeItem(STORE_KEY);
      return;
    }

    const newValue = { eventId, expiresAt: Date.now() + TTL_MS };
    setValue(newValue);
    localStorage.setItem(STORE_KEY, JSON.stringify(newValue));
  }, []);

  return useMemo(
    () => [value && value.expiresAt > Date.now() ? value.eventId : null, updateValue] as const,
    [updateValue, value],
  );
};
