import React from 'react';
import { Box, Stack } from '@mui/material';
import noDataIcon from '@active-configuration/assets/no-data.svg';
import { Typography } from '@ocx/ui';

import { SizingMultiplier } from '../../theme/constants';

export type NoDataViewProps = {
  title: string;
  description?: string;
};

export const NoDataView = ({ title, description }: NoDataViewProps) => {
  return (
    <Stack px={SizingMultiplier.md} alignItems="center" justifyContent="center" flexGrow={1} bgcolor="background.paper">
      <Box component="img" src={noDataIcon} alt={title} mb={SizingMultiplier.lg} width={120} height={120} />
      <Typography variant="h5" mb={SizingMultiplier.xs} color="textPrimary">
        {title}
      </Typography>
      <Typography variant="body2" textAlign="center" color="textSecondary">
        {description}
      </Typography>
    </Stack>
  );
};
