import { setContext } from '@apollo/client/link/context';
import { App } from '@capacitor/app';
import { v4 as uuidv4 } from 'uuid';

export type DevicePlatform = 'ios' | 'android' | 'web';

export type TracingHeaders = {
  'x-app-version': string;
  'x-ocx-release': string;
  'x-ocx-git-revision': string;
  'x-ocx-platform': DevicePlatform;
  'x-ocx-native-build'?: string;
  'x-ocx-native-version'?: string;
  'x-client-trace-id': string;
  'x-device-id': string;
};

export type CreateTracingMiddlewareParams = {
  release: string;
  gitRevision: string;
  getDeviceId: () => Promise<string>;
  getDevicePlatform: () => DevicePlatform;
};

export const createTracingMiddleware = (params: CreateTracingMiddlewareParams) => {
  return setContext(async () => {
    const devicePlatform = params.getDevicePlatform();
    const deviceId = await params.getDeviceId();

    const tracingHeaders: TracingHeaders = {
      'x-app-version': params.release,
      'x-ocx-release': params.release,
      'x-ocx-git-revision': params.gitRevision,
      'x-ocx-platform': devicePlatform,
      'x-client-trace-id': uuidv4(),
      'x-device-id': deviceId,
    };

    if (devicePlatform !== 'web') {
      const appInfo = await App.getInfo();
      tracingHeaders['x-ocx-native-build'] = appInfo.build;
      tracingHeaders['x-ocx-native-version'] = appInfo.version;
    }

    return {
      headers: tracingHeaders,
    };
  });
};
