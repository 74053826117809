export interface IBaseErrorParams<ErrorName> {
  name: ErrorName;
  message: string;
  cause?: any;
  stack?: any;
}

export class BaseError<ErrorName extends string> extends Error {
  name: ErrorName;
  cause?: any;
  stack?: any;

  constructor({ name, message, cause, stack }: IBaseErrorParams<ErrorName>) {
    super(message);
    this.name = name;
    this.cause = cause;
    this.stack = stack;

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, BaseError.prototype);
  }
}
