import React, { useCallback } from 'react';
import { Button } from '@mui/material';
import { useIntl, FormattedMessage } from 'react-intl';

import { useSnackbar } from '../../../../lib/snackbar/snackbar';
import { useGetNavigationActions } from '../../../../hooks/useGetNavigationActions';
import { messages as walletMessages } from '../../../../modules/wallet/wallet.messages';
import { useAddPaymentInstrumentPopup } from '../../../../components/modals/AddPaymentInstrumentPopup/add-payment-instrument-popup.hook';
import { usePaymentMethodSelectorPopup } from '../../../../components/modals';

const snackbarKey = 'patAtPump:paymentMethodLimitReached:snackbarKey';

export interface IUseHandleAddPaymentMethodClickParams {
  isWalletSizeLimitReached: boolean;
  isGoToWalletButtonShown?: boolean;
}

export const useHandleAddPaymentMethodClick = ({
  isWalletSizeLimitReached,
  isGoToWalletButtonShown = false,
}: IUseHandleAddPaymentMethodClickParams) => {
  const intl = useIntl();
  const snackbar = useSnackbar();
  const { open: openAddPaymentInstrumentPopup } = useAddPaymentInstrumentPopup();
  const { pushToWallet } = useGetNavigationActions();
  const { remove: removePaymentMethodSelectorPopup } = usePaymentMethodSelectorPopup();

  const handleGoToWalletClick = useCallback(() => {
    pushToWallet();
    snackbar.close(snackbarKey);

    // The following code is necessary for the proper functioning of the PayAtPump Payment Selector.
    // Modal does not remove on route change
    // Ticket: https://stuzo.atlassian.net/browse/OCST-7488
    // GitHub Issue: https://github.com/eBay/nice-modal-react/issues/143
    removePaymentMethodSelectorPopup();
  }, [snackbar, pushToWallet, removePaymentMethodSelectorPopup]);

  const showLimitReachedPopup = useCallback(() => {
    snackbar.open(intl.formatMessage(walletMessages['wallet:size-limit-reached:snackbar:title']), {
      action: isGoToWalletButtonShown ? (
        // TODO: think how to improve or remove. Secondary text button does not exist in DS
        <Button color="secondary" onClick={handleGoToWalletClick}>
          <FormattedMessage {...walletMessages['wallet:navigation-action:title']} />
        </Button>
      ) : null,
      key: snackbarKey,
    });
  }, [handleGoToWalletClick, intl, isGoToWalletButtonShown, snackbar]);

  return isWalletSizeLimitReached ? showLimitReachedPopup : openAddPaymentInstrumentPopup;
};
