import { TypePolicies } from '@apollo/client';

export const membershipTypePolicy: TypePolicies = {
  Membership: {
    fields: {
      // LocalSchema.graphqls
      isCompleted: {
        read(existing, _) {
          const firstName = _.readField('firstName');
          const lastName = _.readField('lastName');
          const birthdate = _.readField('birthdate');
          const email = _.readField('email');
          return Boolean(birthdate && firstName && lastName && email);
        },
      },
    },
  },
};
