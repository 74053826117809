import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { useSnackbar } from '../../lib/snackbar/snackbar';
import { globalErrorHandler } from './global-error-handler';
import { messages } from './global-error-handler.messages';
import { ForceUpgradePopup } from '../../components/modals/ForceUpgradePopup';

type GlobalErrorHandlerProviderProps = {
  children: React.ReactNode;
};
export const GlobalErrorHandlerProvider = (props: GlobalErrorHandlerProviderProps) => {
  const { children } = props;
  const intl = useIntl();
  const snackbar = useSnackbar();

  const [isReady, setIsReady] = useState(false);
  const [isForceUpgradeRequired, setIsForceUpgradeRequired] = useState(false);

  const throttlingErrorHandler = useCallback(() => {
    snackbar.open(intl.formatMessage(messages['error-messages:api-call-rate-limited']), { variant: 'info' });
  }, [intl, snackbar]);

  useEffect(() => {
    globalErrorHandler.THROTTLING_ERROR = throttlingErrorHandler;
    globalErrorHandler.UPDATE_REQUIRED_ERROR = () => setIsForceUpgradeRequired(true);
    setIsReady(true);
    return () => {
      globalErrorHandler.THROTTLING_ERROR = () => undefined;
      globalErrorHandler.UPDATE_REQUIRED_ERROR = () => undefined;
    };
  }, [throttlingErrorHandler]);

  // We need to be sure that no requests are sent until error handlers are added
  // Example: app config and membership queries
  if (!isReady) {
    return null;
  }

  if (isForceUpgradeRequired) {
    return <ForceUpgradePopup />;
  }

  return children;
};
