import { isToday as dateFNSIsToday } from 'date-fns/isToday';
import { min as dateFNSMin } from 'date-fns/min';
import { parse as dateFNSParse } from 'date-fns/parse';
import { parseISO as dateFNSParseISO } from 'date-fns/parseISO';
import { formatISO } from 'date-fns/formatISO';
import { format as dateFNSFormat } from 'date-fns/format';
import { formatDistanceStrict as dateFNSFormatDistance } from 'date-fns/formatDistanceStrict';
import { getDaysInMonth as dateFNSGetDaysInMonth } from 'date-fns/getDaysInMonth';
// region add
import { addMinutes as dateFNSAddMinutes } from 'date-fns/addMinutes';
import { addHours as dateFNSAddHours } from 'date-fns/addHours';
import { addDays as dateFNSAddDays } from 'date-fns/addDays';
import { addMonths as dateFNSAddMonths } from 'date-fns/addMonths';
import { addYears as dateFNSAddYears } from 'date-fns/addYears';
// endregion
// region sub
import { subMinutes as dateFNSSubMinutes } from 'date-fns/subMinutes';
import { subHours as dateFNSSubHours } from 'date-fns/subHours';
import { subDays as dateFNSSubDays } from 'date-fns/subDays';
import { subMonths as dateFNSSubMonths } from 'date-fns/subMonths';
import { subYears as dateFNSSubYears } from 'date-fns/subYears';
// endregion
// region diff
import { differenceInMinutes as dateFNSDifferenceInMinutes } from 'date-fns/differenceInMinutes';
import { differenceInHours as dateFNSDifferenceInHours } from 'date-fns/differenceInHours';
import { differenceInDays as dateFNSDifferenceInDays } from 'date-fns/differenceInDays';
import { differenceInMonths as dateFNSDifferenceInMonths } from 'date-fns/differenceInMonths';
import { differenceInYears as dateFNSDifferenceInYears } from 'date-fns/differenceInYears';
// endregion
// region IS
import { isAfter as dateFNSIsAfter } from 'date-fns/isAfter';
import { isBefore as dateFNSIsBefore } from 'date-fns/isBefore';

import { DateTimeFormat } from './date.types';
// endregion

export type DateInput = number | string | Date | null;
export type DurationUnit = 'minute' | 'hour' | 'day' | 'month' | 'year';

export const isToday = (date: Date) => dateFNSIsToday(date);

export const getMinDate = (date: Date[]) => dateFNSMin(date);

export const getIsoDate = (date: Date) => formatISO(date);

export const parseDate = (date: DateInput, format?: DateTimeFormat) => {
  if (format && typeof date === 'string') {
    return dateFNSParse(date, format, new Date());
  }
  if (typeof date === 'number') {
    return new Date(date);
  }
  if (typeof date === 'string') {
    return dateFNSParseISO(date);
  }
  if (date instanceof Date) {
    return date;
  }
  return new Date();
};

export const formatDateTime = (date: Date, format: DateTimeFormat) => {
  return dateFNSFormat(date, format);
};

// region - Date/Time add/sub
const mapAdd: Record<DurationUnit, (date: Date, duration: number) => Date> = {
  minute: dateFNSAddMinutes,
  hour: dateFNSAddHours,
  day: dateFNSAddDays,
  month: dateFNSAddMonths,
  year: dateFNSAddYears,
};

const mapSub: Record<DurationUnit, (date: Date, duration: number) => Date> = {
  minute: dateFNSSubMinutes,
  hour: dateFNSSubHours,
  day: dateFNSSubDays,
  month: dateFNSSubMonths,
  year: dateFNSSubYears,
};

export const calcDateTime = (date: Date, action: 'add' | 'sub', duration: number, unit: DurationUnit) => {
  if (action === 'add') {
    return mapAdd[unit](date, duration);
  }
  return mapSub[unit](date, duration);
};
// endregion

// region - Date/Time difference
const mapDiff: Record<DurationUnit, (left: Date, right: Date) => number> = {
  minute: dateFNSDifferenceInMinutes,
  hour: dateFNSDifferenceInHours,
  day: dateFNSDifferenceInDays,
  month: dateFNSDifferenceInMonths,
  year: dateFNSDifferenceInYears,
};

// dateLeft: the later date
// dateRight: the earlier date
export const diffDateTime = (dateLeft: Date, dateRight: Date, unit: DurationUnit) => {
  return mapDiff[unit](dateLeft, dateRight);
};
// endregion

export const isAfterDateTime = (date: Date, compareWithDate: Date) => {
  return dateFNSIsAfter(date, compareWithDate);
};

export const isBeforeDateTime = (date: Date, compareWithDate: Date) => {
  return dateFNSIsBefore(date, compareWithDate);
};

export const getDaysInMonth = (date: Date): number => {
  return dateFNSGetDaysInMonth(date);
};

export const durationDateTime = (duration: number, unit: DurationUnit) => {
  // date-fns.formatDuration  does not work in proper way
  // it returns values like '1000 minutes' and so on.
  // so date-fns.formatDistance is exactly what we need
  // but it works only with two dates objects - > so we need convert duration into date objects
  const dateFrom = new Date();
  const dateTo = calcDateTime(dateFrom, 'add', duration, unit);
  return dateFNSFormatDistance(dateFrom, dateTo);
};

export const getIsoWeekday = (date: Date = new Date()) => {
  const weekDay = date.getDay() - 1;
  if (weekDay < 0) {
    return 6;
  }
  return weekDay;
};
