import React from 'react';

import { SurveysContext } from './survey.context';

export const useSurveys = () => {
  const contextValue = React.useContext(SurveysContext);
  if (!contextValue) {
    throw new Error('useSurveys must be used within a SurveysContextProvider');
  }

  return contextValue;
};
