import { useCallback } from 'react';
import { Capacitor } from '@capacitor/core';

import { sharedStorage } from '../shared-storage/shared.storage';
import { APP_RATE_LAST_DATE_STORAGE_KEY } from './app-rate.constants';
import { useConfiguration } from '../configuration/useConfiguration';
import { useAppRateUtils } from './app-rate.utils';

export const useAppRateDialogController = () => {
  const appRatingEnabled = useConfiguration('appRating.enabled');
  const { getIsAppRateDelayPassed, getLastAppRateDate } = useAppRateUtils();

  const setLastAppRateDate = useCallback(async (date: Date): Promise<void> => {
    await sharedStorage.setItem(APP_RATE_LAST_DATE_STORAGE_KEY, date.toISOString());
  }, []);

  const getIsAppRateAvailable = useCallback(async () => {
    if (!Capacitor.isNativePlatform() || !appRatingEnabled) {
      return false;
    }

    const lastAppRateDate = await getLastAppRateDate();
    if (!lastAppRateDate) {
      return true;
    }
    return getIsAppRateDelayPassed(lastAppRateDate);
  }, [appRatingEnabled, getIsAppRateDelayPassed, getLastAppRateDate]);

  return { setLastAppRateDate, getIsAppRateAvailable };
};
