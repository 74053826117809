import { defineMessages } from 'react-intl';

export const genericMessages = defineMessages({
  'generic:errorMessageText': {
    defaultMessage: 'Something went wrong. Please try again later.',
    description: 'Generic error message.',
    id: 'generic:errorMessageText',
  },
  'generic:vocabulary:or': {
    id: 'generic:vocabulary:or',
    defaultMessage: 'Or',
    description: 'Generic vocabulary: "Or"',
  },
  'generic:vocabulary:oops': {
    id: 'generic:vocabulary:oops',
    defaultMessage: 'Oops',
    description: 'Generic vocabulary: "Oops"',
  },
});
