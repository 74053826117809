import React, { ReactNode } from 'react';

import { ModalDrawer } from '..';

type SurveyPopupLayoutProps = {
  children: ReactNode;
  isOpen: boolean;
  onClose(): void;
  onExited(): void;
};

export const SurveyPopupLayout: React.FC<SurveyPopupLayoutProps> = (props) => {
  const { children, isOpen, onClose, onExited } = props;
  return (
    <ModalDrawer minPaperHeight={300} open={isOpen} onClose={onClose} onExited={onExited}>
      {children}
    </ModalDrawer>
  );
};
