import React from 'react';
import { Grid } from '@mui/material';
import { Typography } from '@ocx/ui';

import { SizingMultiplier } from '../../../theme/constants';

export interface IReceiptOptionQuantity {
  title: string | null;
  value: string | null;
  quantity: number | null;
}

export interface IOptionQuantityItemProps {
  option: IReceiptOptionQuantity;
}

export const OptionQuantityItem: React.FC<IOptionQuantityItemProps> = (props) => {
  const { option } = props;

  return (
    <Grid container>
      <Grid item xs={6}>
        <Typography variant="body1">{option.title}</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="body1" color="textSecondary" align="right">
          {option.quantity}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="body1" color="textSecondary" align="right">
          {option.value}
        </Typography>
      </Grid>
    </Grid>
  );
};

export interface IOptionListProps {
  options: IReceiptOptionQuantity[];
}

export const OptionQuantityList: React.FC<IOptionListProps> = (props) => {
  const { options } = props;

  return (
    <div>
      <Grid container mb={SizingMultiplier.xs}>
        <Grid item xs={6}>
          <Typography color="textSecondary" variant="caption">
            Item
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography color="textSecondary" component="p" variant="caption" align="right">
            Amount
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography color="textSecondary" component="p" variant="caption" align="right">
            Total
          </Typography>
        </Grid>
      </Grid>
      {options.map((option) => (
        <OptionQuantityItem key={option.title} option={option} />
      ))}
    </div>
  );
};
