/* eslint-disable no-undef */
import {
  CardAuthMethod,
  DisplayItem,
  IGetIsReadyToPayRequestDataParams,
  IGetPaymentRequestDataParams,
  IIsReadyToPayRequestData,
  IPaymentRequestData,
  DisplayItemStatus,
} from './types';
import { IPaymentItem, PAYMENT_STATUS } from '../payments.types';

export const paymentStatusMap: { [key in PAYMENT_STATUS]: DisplayItemStatus } = {
  [PAYMENT_STATUS.FINAL]: 'FINAL',
  [PAYMENT_STATUS.PENDING]: 'PENDING',
};

export const GPAY_SCRIPT_URL = 'https://pay.google.com/gp/p/js/pay.js';

export const baseRequest = {
  apiVersion: 2,
  apiVersionMinor: 0,
};

export const allowedAuthMethods: CardAuthMethod[] = ['PAN_ONLY', 'CRYPTOGRAM_3DS'];

export const getIsReadyToPayRequestData = (params: IGetIsReadyToPayRequestDataParams): IIsReadyToPayRequestData => {
  return {
    environment: params.environment,
    isReadyToPayRequest: {
      ...baseRequest,
      existingPaymentMethodRequired: true,
      allowedPaymentMethods: [
        {
          type: 'CARD',
          parameters: {
            allowedAuthMethods,
            allowedCardNetworks: params.allowedCardNetworks,
          },
        },
      ],
    },
  };
};

export const mapPaymentItemsToDisplayItems = (paymentItems: IPaymentItem[]): DisplayItem[] =>
  paymentItems.map((paymentItem) => ({
    label: paymentItem.label,
    price: paymentItem.price,
    type: 'LINE_ITEM',
    status: paymentStatusMap[paymentItem.status],
  }));

export const getPaymentRequestData = (params: IGetPaymentRequestDataParams): IPaymentRequestData => {
  const displayItems = mapPaymentItemsToDisplayItems(params.paymentItems);
  const merchantInfo: google.payments.api.MerchantInfo = {
    merchantId: params.merchantId,
    merchantName: params.merchantName,
  };

  // totalPrice should not be required for totalPriceStatus: 'NOT_CURRENTLY_KNOWN',
  const transactionInfo: Omit<google.payments.api.TransactionInfo, 'totalPrice'> = {
    countryCode: 'US',
    currencyCode: 'USD',
    totalPriceStatus: 'NOT_CURRENTLY_KNOWN',
    // 'totalPriceLabel' is required for 'displayItems'
    totalPriceLabel: 'Total',
    displayItems,
  };

  const tokenizationSpecification: google.payments.api.PaymentMethodTokenizationSpecification = {
    type: 'PAYMENT_GATEWAY',
    parameters: params.gatewayParameters,
  };

  const allowedPaymentMethods: google.payments.api.PaymentMethodSpecification[] = [
    {
      type: 'CARD',
      parameters: {
        allowedAuthMethods,
        allowedCardNetworks: params.allowedCardNetworks,
      },
      tokenizationSpecification,
    },
  ];

  return {
    environment: params.environment,
    paymentRequest: {
      ...baseRequest,
      allowedPaymentMethods,
      // TODO: clarify types mapping
      transactionInfo: transactionInfo as any,
      merchantInfo,
    },
  };
};

export const DEFAULT_ANDROID_BUTTON_COLOR = 'black';
