import React from 'react';

export const Amex: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg width="32" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 4.471A3.765 3.765 0 013.765.707h24.47A3.765 3.765 0 0132 4.47v15.06a3.765 3.765 0 01-3.765 3.764H3.765A3.765 3.765 0 010 19.53V4.471z"
      fill="#1F72CD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.66 8.547l-2.993 6.901H6.25l.444-1.1h1.016l.444 1.1h3.946v-.84l.351.84h2.04l.352-.857v.857h8.205l.998-1.071.934 1.071 4.214.01-3.003-3.441 3.003-3.47h-4.148l-.972 1.052-.904-1.052h-8.926l-.767 1.781-.784-1.781H9.117v.811l-.398-.811H5.661zm.694.98h1.747l1.986 4.68v-4.68h1.914l1.534 3.356 1.414-3.356h1.904v4.952h-1.159l-.01-3.88-1.688 3.88h-1.037L11.26 10.6v3.88H8.876l-.452-1.11H5.983l-.451 1.11H4.255l2.1-4.952zm16.27 0h-4.71v4.95h4.638l1.495-1.64 1.441 1.64h1.507l-2.19-2.461 2.19-2.49h-1.442l-1.487 1.622-1.441-1.621zm-15.42.838L6.4 12.343h1.608l-.804-1.978zm11.873 1.091v-.904h2.94l1.282 1.445-1.34 1.454h-2.882v-.987h2.57v-1.008h-2.57z"
      fill="#fff"
    />
  </svg>
);
