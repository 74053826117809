import React from 'react';
import { useModal } from '@ebay/nice-modal-react';
import { Box, Stack } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@ocx/ui';

import { ModalDrawer } from '../components';
import { ExternalBannerDetailsPopupProps } from './external-banner-details-popup.types';
import { SizingMultiplier } from '../../../theme/constants';
import { SurgeonWarning } from '../../SurgeonWarning/SurgeonWarning';
import { OfferDetailsMedia } from '../../OfferDetails/OfferDetailsMedia';
import { Button } from '../../Button';
import { buttonTextMessages } from '../../../modules/localization/button-text.messages';

export const ExternalBannerDetailsPopupController = (props: ExternalBannerDetailsPopupProps) => {
  const { visible, hide, remove } = useModal();
  const {
    banner: {
      content: { title, titleMiddle, title2, imageUrl, surgeonGeneralWarning },
    },
  } = props;

  return (
    <ModalDrawer open={visible} onClose={hide} onExited={remove}>
      {surgeonGeneralWarning && (
        <Box m={SizingMultiplier.md}>
          <SurgeonWarning message={surgeonGeneralWarning} size="large" square />
        </Box>
      )}
      {imageUrl && <OfferDetailsMedia variant="big-image" src={imageUrl} alt={title} />}
      <Stack spacing={SizingMultiplier.md} pt={SizingMultiplier.lg} px={SizingMultiplier.lg} pb={SizingMultiplier.xl}>
        <Typography variant="h6">
          {title} {titleMiddle} {title2}
        </Typography>
        <Button variant="text" fullWidth onClick={hide}>
          <FormattedMessage {...buttonTextMessages['button-text:close']} />
        </Button>
      </Stack>
    </ModalDrawer>
  );
};
