import { useCallback } from 'react';

import { useGetGuestSurveyLazyQuery } from '@ocx/graphql';
import { SanitizedSurvey } from '../survey.types';
import { isSurveyValid } from '../survey.validation';

export type UseFetchGuestSurveyReturns = {
  isLoading: boolean;
  fetch: (id: string) => Promise<SanitizedSurvey | null>;
};

export const useFetchGuestSurvey = (): UseFetchGuestSurveyReturns => {
  const [fetchGuestSurvey, { loading }] = useGetGuestSurveyLazyQuery({
    fetchPolicy: 'network-only',
  });

  const fetch: UseFetchGuestSurveyReturns['fetch'] = useCallback(
    async (guestSurveyId: string) => {
      const { data } = await fetchGuestSurvey({ variables: { guestSurveyId } });

      if (!data?.guestSurvey || !isSurveyValid(data.guestSurvey)) {
        return null;
      }

      return {
        ...data.guestSurvey,
        type: 'guest',
        guestSurveyId,
      };
    },
    [fetchGuestSurvey],
  );

  return {
    fetch,
    isLoading: loading,
  };
};
