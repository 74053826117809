import palette from '@active-configuration/palette.json';
import React, { CSSProperties } from 'react';

import styles from './pure-circular-progress.module.css';

const spinnerStyle: CSSProperties = {
  borderColor: `${palette?.primary?.main || '#000'} transparent transparent transparent`,
};

export const PureCircularProgress = () => (
  <div className={styles.spinner}>
    <div style={spinnerStyle} />
    <div style={spinnerStyle} />
    <div style={spinnerStyle} />
    <div style={spinnerStyle} />
  </div>
);
