import React, { ReactNode, useCallback } from 'react';
import {
  Slider as MuiSlider,
  SliderProps as MuiSliderProps,
  FormControlProps,
  FormControlLabelProps,
  FormGroupProps,
  FormHelperTextProps,
  FormLabelProps,
  InputLabel,
  FormHelperText,
  SliderProps,
} from '@mui/material';
import { Field, FieldProps, FieldMetaState, useField } from 'react-final-form';

import { isAppleWithTouchScreen } from '../../../modules/device/utils/info';

export interface ErrorMessageProps {
  showError: boolean;
  meta: FieldMetaState<any>;
  formHelperTextProps?: Partial<FormHelperTextProps>;
  helperText?: string;
}

const ErrorMessage = ({ showError, meta, formHelperTextProps, helperText }: ErrorMessageProps) => {
  if (showError) {
    return <FormHelperText {...formHelperTextProps}>{meta.error || meta.submitError}</FormHelperText>;
  }
  if (helperText) {
    return <FormHelperText {...formHelperTextProps}>{helperText}</FormHelperText>;
  }

  return null;
};

export interface showErrorProps {
  meta: FieldMetaState<any>;
}

const config = {
  subscription: {
    error: true,
    submitError: true,
    dirtySinceLastSubmit: true,
    touched: true,
    modified: true,
  },
};

export function useFieldForErrors(name: string) {
  return useField(name, config);
}

export function showError({ meta: { submitError, dirtySinceLastSubmit, error, touched, modified } }: showErrorProps) {
  return !!(((submitError && !dirtySinceLastSubmit) || error) && (touched || modified));
}

export interface SliderFieldProps extends Partial<Omit<MuiSliderProps, 'onChange'>> {
  name: string;
  label?: ReactNode;
  onChangeMiddleware?: (currentValue: number | number[], newValue: number | number[]) => number | number[];
  disabled?: boolean;
  required?: boolean;
  helperText?: string;
  fieldProps?: Partial<FieldProps<any, any>>;
  formControlProps?: Partial<FormControlProps>;
  formGroupProps?: Partial<FormGroupProps>;
  formLabelProps?: Partial<FormLabelProps>;
  formControlLabelProps?: Partial<FormControlLabelProps>;
  formHelperTextProps?: Partial<FormHelperTextProps>;
  onChange?(value: number | number[]): void;
}

export const SliderField = (props: SliderFieldProps) => {
  const {
    name,
    label,
    onChangeMiddleware,
    disabled,
    required,
    helperText,
    fieldProps,
    formControlProps,
    formGroupProps,
    formLabelProps,
    formControlLabelProps,
    formHelperTextProps,
    onChange: _onChange,
    ...restSlider
  } = props;

  const field = useFieldForErrors(name);
  const isError = showError(field);

  const handleOnChange = useCallback(
    (onChange: (value: number | number[]) => void, currentValue: number | number[]): SliderProps['onChange'] => {
      return (_event, value) => {
        // https://github.com/mui/material-ui/issues/32737#issuecomment-1347356610
        if (isAppleWithTouchScreen() && _event.type === 'mousedown') {
          return;
        }

        _onChange?.(value);
        if (onChangeMiddleware) {
          onChange(onChangeMiddleware(currentValue, value));
        } else {
          onChange(value);
        }
      };
    },
    [_onChange, onChangeMiddleware],
  );

  return (
    <div>
      {label && <InputLabel>{label}</InputLabel>}
      <Field
        name={name}
        render={({ input: { name, value, onChange, checked, ...restInput } }) => (
          <MuiSlider
            name={name}
            value={value}
            onChange={handleOnChange(onChange, value)}
            disabled={disabled}
            {...restSlider}
            {...restInput}
          />
        )}
        {...fieldProps}
      />
      <ErrorMessage
        showError={isError}
        meta={field.meta}
        formHelperTextProps={formHelperTextProps}
        helperText={helperText}
      />
    </div>
  );
};
