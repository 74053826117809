import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const PlusIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="m17 11h-4v-4c0-.26522-.1054-.51957-.2929-.70711-.1875-.18753-.4419-.29289-.7071-.29289s-.5196.10536-.7071.29289c-.1875.18754-.2929.44189-.2929.70711v4h-4c-.26522 0-.51957.1054-.70711.2929-.18753.1875-.29289.4419-.29289.7071s.10536.5196.29289.7071c.18754.1875.44189.2929.70711.2929h4v4c0 .2652.1054.5196.2929.7071s.4419.2929.7071.2929.5196-.1054.7071-.2929.2929-.4419.2929-.7071v-4h4c.2652 0 .5196-.1054.7071-.2929s.2929-.4419.2929-.7071-.1054-.5196-.2929-.7071-.4419-.2929-.7071-.2929z" />
    </SvgIcon>
  );
};
