import React, { useState, useContext, useMemo } from 'react';

import { ScreenLoaderBackdrop } from '../../components/ScreenLoader/ScreenLoader';

export interface ILoaderProviderState {
  turnLoader(state: boolean): void;
}

const initialState: ILoaderProviderState = {
  turnLoader: () => {
    throw new Error('Not implemented');
  },
};

export const LoaderContext = React.createContext(initialState);
export const useLoader = () => useContext(LoaderContext);

export interface ILoaderProviderProps {
  children: React.ReactNode;
}

export const LoaderProvider = ({ children }: ILoaderProviderProps) => {
  const [open, setOpen] = useState(false);

  const contextValue = useMemo(() => {
    return {
      turnLoader: (state: boolean) => setOpen(state),
    };
  }, []);

  return (
    <LoaderContext.Provider value={contextValue}>
      {children}
      <ScreenLoaderBackdrop open={open} />
    </LoaderContext.Provider>
  );
};
