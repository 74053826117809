import { useCallback } from 'react';

import { PointsStrategy } from '@ocx/graphql';
import { Offer, CustomDataWithAction } from '../../../modules/offers/offer.types';
import { hasOfferDetailedMarketingContent } from '../../../modules/offers/marketing-content.utils';
import { IneligibilityHandlingStrategy } from '../../../modules/offers/eligibility.types';
import { ActionType } from '../../../modules/offers/custom-data/actions/types';
import { useOfferNavigateTo } from '../hooks/useOfferNavigateTo';
import { useShowExplanationSnackbarForIneligibleOffer } from './useShowExplanationSnackbarForIneligibleOffer';
import {
  isOfferSweepstakesPoints,
  isOfferSweepstakesProduct,
  isCustomDataWithAction,
  isOfferWithExternalUrl,
  isSpinWheelGameOffer,
} from '../../../modules/offers/offer.type-guards';
import { useEditProfilePopup } from '../../../components/modals';
import { useGetNavigationActions } from '../../../hooks/useGetNavigationActions';
import { useRudderStack } from '../../../lib/rudderStack/useRudderStack';

const useHandleCustomDataAction = () => {
  const navigateTo = useOfferNavigateTo();
  const { open: openEditProfilePopup } = useEditProfilePopup();

  return (customData: CustomDataWithAction) => {
    const { action } = customData;
    if (action.type === ActionType.NavigateTo) {
      navigateTo({ url: action.payload.url, isInAppLink: !action.payload.openURLInWebView });
    }

    if (action.type === ActionType.RequestCompleteProfile) {
      openEditProfilePopup({ returnUrl: action?.payload?.return_url });
    }
  };
};

export type OfferOnClick = (() => unknown) | undefined;
export type GetOfferOnClick = (offer: Offer) => OfferOnClick;

export type UseGetOfferOnClick = (params: {
  pointsStrategy: PointsStrategy;
  membershipPoints: number;
}) => GetOfferOnClick;
export const useGetOfferOnClick: UseGetOfferOnClick = ({ pointsStrategy, membershipPoints }) => {
  const showIneligibilityExplanationSnackbar = useShowExplanationSnackbarForIneligibleOffer();
  const handleOfferCustomDataAction = useHandleCustomDataAction();
  const navigateTo = useOfferNavigateTo();
  const { pushToOfferDetails } = useGetNavigationActions();

  const { triggerEvent } = useRudderStack();

  const trackOfferClickedEvent = useCallback(
    (offer: Offer) => {
      triggerEvent('offer_clicked', { offerId: offer.id });
    },
    [triggerEvent],
  );

  return useCallback(
    (offer: Offer) => {
      const hasDetails = hasOfferDetailedMarketingContent(offer.marketingContent);

      if (isOfferWithExternalUrl(offer)) {
        return () => {
          navigateTo({ url: offer.marketingContent.externalUrl, isInAppLink: false });
          trackOfferClickedEvent(offer);
        };
      }

      const { customData } = offer;
      if (isCustomDataWithAction(customData)) {
        return () => {
          handleOfferCustomDataAction(customData);
          trackOfferClickedEvent(offer);
        };
      }

      if (isOfferSweepstakesPoints(offer) && pointsStrategy !== PointsStrategy.NotUsed) {
        return () => {
          pushToOfferDetails(offer.id, { shouldHistoryPopOnClose: true });
          trackOfferClickedEvent(offer);
        };
      }

      if (isOfferSweepstakesProduct(offer) && hasDetails) {
        return () => {
          pushToOfferDetails(offer.id, { shouldHistoryPopOnClose: true });
          trackOfferClickedEvent(offer);
        };
      }

      const [isEligible, ineligibilityHandlingStrategy, ineligibilityReasons] = offer.eligible;
      if (!isEligible && ineligibilityHandlingStrategy === IneligibilityHandlingStrategy.DisableInteractions) {
        return () => {
          showIneligibilityExplanationSnackbar(ineligibilityReasons[0]);
          trackOfferClickedEvent(offer);
        };
      }

      if (hasDetails || isSpinWheelGameOffer(offer)) {
        return () => {
          pushToOfferDetails(offer.id, { shouldHistoryPopOnClose: true });
          trackOfferClickedEvent(offer);
        };
      }

      return undefined;
    },
    [
      pointsStrategy,
      navigateTo,
      pushToOfferDetails,
      handleOfferCustomDataAction,
      showIneligibilityExplanationSnackbar,
      trackOfferClickedEvent,
    ],
  );
};
