import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  'app-rate:dialog-title': {
    id: 'app-rate:dialog-title',
    defaultMessage: 'Help Us Serve You Better',
    description: 'App rating dialog title',
  },
  'app-rate:dialog-description': {
    id: 'app-rate:dialog-description',
    defaultMessage: 'Did you have a good experience?',
    description: 'App rating dialog message',
  },
});
