// eslint-disable-next-line max-classes-per-file
import { DetailedHTMLProps, HTMLAttributes } from 'react';

const debounceCallback = (callback: (...args: unknown[]) => void, time = 0): ((...args: unknown[]) => void) => {
  // run callback one time after an interval
  let debounceTimer: ReturnType<typeof setTimeout>;
  return (...args: unknown[]) => {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      callback(...args);
    }, time);
  };
};

const convertToInt = (v: number | string): number => {
  if (typeof v !== 'string') {
    return v;
  }
  return parseInt(v || '0', 10);
};

const convertToArray = (v: string[] | string): string[] => {
  if (Array.isArray(v)) {
    return v;
  }
  return v.split(/\s*[;|]+\s*/).filter(Boolean);
};

class Spinner {
  #cubicBezierBase = 0.25;
  #stopAnimation: Animation | null = null;
  #infinityAnimation: Animation | null = null;

  el: HTMLElement | null = null;
  duration = 0;
  onStopFinish?: () => void = undefined;

  constructor(element: HTMLElement, onStopFinish?: () => void, duration: number = 300) {
    this.el = element;
    this.duration = duration;
    this.onStopFinish = onStopFinish;
  }

  get isSpinning(): boolean {
    return this.#infinityAnimation?.playState === 'running' || this.#stopAnimation?.playState === 'running';
  }

  get isInfinitySpinning(): boolean {
    return this.#infinityAnimation?.playState === 'running';
  }

  getActualRotation(): number {
    if (!this.el) {
      return 0;
    }
    // eslint-disable-next-line no-undef
    const matrix = new WebKitCSSMatrix(window.getComputedStyle(this.el).transform);
    const angle = Math.atan2(matrix.m21, matrix.m11) * (180 / Math.PI);
    // the atan2 returns the angle from -180 to 180, let's convert it to 0 - 360
    return angle > 0 ? 360 - angle : Math.abs(angle);
  }

  play(revers = false) {
    if (this.#stopAnimation) {
      this.#stopAnimation.pause();
    }

    const currentRotation = this.getActualRotation();

    if (this.#infinityAnimation) {
      this.#infinityAnimation.cancel();
      this.#infinityAnimation = null;
    }

    this.#infinityAnimation = new Animation(
      new KeyframeEffect(
        this.el,
        [
          { transform: `rotate(${currentRotation}deg)` },
          { transform: `rotate(${currentRotation + (revers ? -360 : 360)}deg)` },
        ],
        {
          duration: this.duration,
          fill: 'forwards',
          iterations: Infinity,
        },
      ),
    );
    this.#infinityAnimation.play();

    if (this.#stopAnimation) {
      this.#stopAnimation.cancel();
      this.#stopAnimation = null;
    }
  }

  stop(angle: number, iterations = 0, withoutAnimation = false, withoutCallback = false) {
    if (!this.el) {
      return;
    }

    if (this.#stopAnimation) {
      this.#stopAnimation.pause();
    }

    if (this.#infinityAnimation) {
      this.#infinityAnimation.pause();
    }

    // Get the current angle
    const currentRotation = this.getActualRotation();

    if (this.#stopAnimation) {
      this.#stopAnimation.cancel();
      this.#stopAnimation = null;
    }

    // Get the speed of rotation (how many degrees per millisecond the element rotates)
    const rotationSpeed = 360 / this.duration;

    // Stop angle (full revolution * N + needed angle)
    const stopRotation = (angle >= 0 ? 360 : -360) * (iterations || 0) + angle;
    const rotation = currentRotation + stopRotation;

    // Stop animation duration
    const stopDuration = Math.abs(stopRotation) / rotationSpeed;

    // Create a new animation for a smooth stop
    this.#stopAnimation = new Animation(
      new KeyframeEffect(
        this.el,
        [{ transform: `rotate(${currentRotation}deg)` }, { transform: `rotate(${rotation}deg)` }],
        {
          // Increase the duration to equalize the impulse from cubicBezier
          duration: withoutAnimation ? 0 : stopDuration * (1 / this.#cubicBezierBase),
          fill: 'forwards',
          easing: `cubic-bezier(${this.#cubicBezierBase}, 1, 0.5, 1)`,
        },
      ),
    );
    if (this.onStopFinish && !withoutCallback) {
      this.#stopAnimation.onfinish = this.onStopFinish;
    }
    this.#stopAnimation.play();

    if (this.#infinityAnimation) {
      this.#infinityAnimation.cancel();
      this.#infinityAnimation = null;
    }
  }
}

const MIN_WHEEL_SIZE = '100px';

class CustomElement extends HTMLElement {
  shadow: ShadowRoot | null = null;

  // eslint-disable-next-line no-useless-constructor, @typescript-eslint/no-useless-constructor
  constructor() {
    super();
  }
  onConnect() {}
  onDisconnect() {}

  emit(eventName: string, eventData: unknown) {
    const detail = { element: this, data: eventData };
    const callback = this[`on${eventName}` as keyof HTMLElement] as unknown;
    const event = new CustomEvent(eventName, {
      detail,
      bubbles: true,
      cancelable: true,
      composed: true, // Allows events to go outside the Shadow DOM
    });

    if (typeof callback === 'function') {
      callback.call(this, event);
    } else {
      const source = this.getAttribute(`on${eventName}`);
      if (source) {
        // eslint-disable-next-line no-new-func
        new Function('e', `var event = e; return ${source}`).call(this, event);
      } else {
        this.dispatchEvent(event);
      }
    }
  }

  connectedCallback() {
    this.shadow = this.attachShadow({ mode: 'open' });
    this.onConnect();
  }

  disconnectedCallback() {
    this.onDisconnect();
  }
}

export class SectoredWheelItemElement extends CustomElement {
  onConnect() {
    super.onConnect();
    const template = document.createElement('template');
    template.innerHTML = `<style>
      :host {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        box-sizing: border-box!important;
        position: absolute;
        color: #000;
        left: 50%;
        font-weight: bold;
        font-family: sans-serif;
        text-transform: uppercase;
        transform-origin: 0 50%;
        z-index: 1;
        width: var(--sector-width);
        height: var(--sector-height);
        padding-right: calc(var(--sector-width) * 0.15);
        font-size: min(calc(var(--sector-height) * 0.2), calc(var(--sector-width) / 8));
      }
   </style><slot></slot>`;

    if (this.shadow) {
      this.shadow.appendChild(template.content);
    }
  }

  static get observedAttributes() {
    return ['clipping', 'color', 'text-color'];
  }

  attributeChangedCallback(attributeName: string) {
    if (['color', 'text-color'].includes(attributeName)) {
      if (this.parentElement instanceof SectoredWheelElement) {
        this.parentElement.realign(true);
      }
    } else {
      this.style.clipPath = this.hasAttribute('clipping') ? 'polygon(0 50%, 100% 0, 100% 100%)' : 'none';
    }
  }
}

export class SectoredWheelElement extends CustomElement {
  #mutationObserver: MutationObserver | null = null;
  #resizeObserver: ResizeObserver | null = null;
  #wheel: HTMLElement | null = null;
  #canvas: HTMLCanvasElement | null = null;
  #ctx: CanvasRenderingContext2D | null = null;
  #spinner: Spinner | null = null;

  #init = true;
  #items: HTMLElement[] = [];

  #toGrad = (angle: string = '0'): number => {
    // 90deg = 100grad = 0.25turn ≈ 1.5708rad
    const value = parseFloat(angle);
    if (angle.indexOf('rad') > 0) {
      return value * (180 / Math.PI);
    }
    if (angle.indexOf('grad') > 0) {
      return (value / 400) * 360;
    }
    if (angle.indexOf('turn') > 0) {
      return value * 360;
    }
    return value;
  };

  #getRotationAngle = (toIndex: number): number => {
    if (!this.#spinner) {
      return 0;
    }
    const to = Math.max(Math.min(toIndex, this.#items.length - 1), 0);
    const sectorAngle = 360 / this.#items.length;
    const actualRotation = this.#spinner.getActualRotation() - this.#toGrad(this.azimuth);

    let newRotation = (this.#direction === 'cw' ? 360 : 0) - to * sectorAngle - actualRotation;
    if ((this.#direction === 'cw' && newRotation < 0) || (this.#direction === 'acw' && newRotation < -360)) {
      newRotation = 360 + newRotation;
    }

    return newRotation;
  };

  #unselectItems = () => {
    this.#items.forEach((el) => el.classList.remove('selected', 'preselected'));
  };

  rotate = (fromIndex: number, toIndex: number, withoutAnimation = false) => {
    if (!this.#wheel || !this.#spinner || !this.#items.length) {
      return;
    }

    this.#unselectItems();
    this.#spinner.stop(this.#getRotationAngle(toIndex), this.#revolutions, withoutAnimation, this.#init);

    if (!withoutAnimation && !this.#init && !this.isSpinning) {
      this.emit('spinningstart', fromIndex);
    }
  };

  spin = () => {
    if (!this.#wheel || !this.#spinner || this.isSpinning) {
      return;
    }

    this.#unselectItems();
    this.#spinner.play(this.direction === 'acw');
    this.emit('spinningstart', this.index);
  };

  #resize = debounceCallback(() => {
    if (this.#wheel) {
      const size = this.#wheel.clientWidth;
      this.#wheel.style.setProperty('--sector-height', `${(Math.PI * size) / this.#items.length}px`);
      this.#wheel.style.setProperty('--sector-width', `${size / 2}px`);
    }
  }, 100);

  realign = debounceCallback((redrawOnly) => {
    if (!redrawOnly) {
      this.#items = Array.from(this.querySelectorAll('sectored-wheel-item'));
    }

    const angle = (Math.PI * 2) / this.#items.length;

    if (this.#canvas && this.#ctx) {
      const width = this.#canvas.clientWidth;
      const height = this.#canvas.clientHeight;
      const x = width / 2;
      const y = height / 2;

      const offset = -angle / 2;
      const radius = Math.max(width, height) / 2;

      const RESOLUTION_COEFFICIENT = 3;
      this.#canvas.width = width * RESOLUTION_COEFFICIENT;
      this.#canvas.height = height * RESOLUTION_COEFFICIENT;
      this.#ctx.scale(RESOLUTION_COEFFICIENT, RESOLUTION_COEFFICIENT);

      for (let i = 0; i < this.#items.length; i += 1) {
        const customColor = this.#items[i].getAttribute('color');
        const customTextColor = this.#items[i].getAttribute('text-color');
        let colorIndex = i % this.#colors.length;
        let color = customColor || this.#colors[colorIndex];
        let textColor = customTextColor || this.#textColors[colorIndex];
        if (!customColor && i === this.#items.length - 1 && color === this.#colors[0]) {
          colorIndex = 1 % this.#colors.length;
          color = this.#colors[colorIndex];
          textColor = this.#textColors[colorIndex];
        }
        this.#ctx.beginPath();
        this.#ctx.moveTo(x, y);
        this.#ctx.arc(
          x,
          y,
          radius - (this.strokeWidth ? this.strokeWidth / 2 : 0),
          offset + angle * i,
          offset + angle * (i + 1),
        );
        this.#ctx.lineTo(x, y);
        this.#ctx.fillStyle = color;
        this.#ctx.fill();
        if (this.strokeWidth) {
          this.#ctx.lineWidth = this.strokeWidth;
          this.#ctx.strokeStyle = this.strokeColor;
          this.#ctx.stroke();
        } else {
          // preventing gaps between sectors
          this.#ctx.lineWidth = 0.7;
          this.#ctx.strokeStyle = color;
          this.#ctx.stroke();
        }
        this.#items[i].style.color = textColor || '';
        this.#items[i].style.transform = `rotate(${angle * i}rad)`;
      }
    }

    if (!this.#items[this.index]) {
      this.index = -1;
      this.#unselectItems();
    }

    if (this.#wheel) {
      this.#wheel.style.setProperty('--border-width', `${this.borderWidth}px`);
      this.#wheel.style.setProperty('--border-color', this.borderColor);
      this.#wheel.style.setProperty('--shadow-width', `${this.shadowWidth}px`);
      this.#wheel.style.setProperty('--shadow-color', this.shadowColor);
      this.#wheel.style.setProperty('--padding', `${this.padding}px`);
      if (!redrawOnly) {
        this.#wheel.style.setProperty('--count', String(this.#items.length));
        this.#wheel.style.setProperty('--sector-angle', `${angle}rad`);
        this.#resize();
        this.rotate(this.index, this.index, true);
      }
    }

    if (this.#init) {
      this.#items.forEach((el) => el.classList.remove('preselected'));
      this.#items[this.index]?.classList.add('preselected');
      this.#init = false;
      this.style.opacity = '1';
    }
  }, 100);

  setIndexAsync = async (index: number, minSpinTimeMs?: number) => {
    this.spin();
    if (minSpinTimeMs) {
      await new Promise<void>((resolve) => {
        setTimeout(() => {
          resolve();
        }, minSpinTimeMs);
      });
    }
    this.index = index;
  };

  static get observedAttributes() {
    return [
      'index',
      'size',
      'colors',
      'padding',
      'azimuth',
      'direction',
      'duration',
      'revolutions',

      'text-colors',
      'textColors',
      'textcolors',

      'stroke-width',
      'strokeWidth',
      'strokewidth',

      'stroke-color',
      'strokeColor',
      'strokecolor',

      'border-width',
      'borderWidth',
      'borderwidth',

      'border-color',
      'borderColor',
      'bordercolor',

      'shadow-width',
      'shadowWidth',
      'shadowwidth',

      'shadow-color',
      'shadowColor',
      'shadowcolor',

      'classname',
    ];
  }

  attributeChangedCallback(attributeName: string, oldValue: string, newValue: string) {
    if (attributeName !== 'index' && oldValue === newValue) {
      return;
    }
    let key = attributeName.replace(/-+[a-z]/g, (v) => v[v.length - 1].toUpperCase());
    key = key.replace(/(.+)(width|colors?|time)$/, (_match, p1, p2) => {
      return `${p1}${p2.charAt(0).toUpperCase() + p2.slice(1)}`;
    });
    (this as Record<string, unknown>)[key] = newValue;
  }

  #index = -1;
  set index(value: number | string) {
    const v = convertToInt(value);
    if (v < -1) {
      return;
    }
    const newIndex = Math.min(Math.max(v, -1), this.#items.length - 1);
    const animationNotNeeded = newIndex === this.#index && newIndex === -1 && !this.#spinner?.isSpinning;
    this.rotate(this.#index, newIndex, animationNotNeeded);
    this.#index = newIndex;
  }

  get index(): number {
    return this.#index;
  }

  #size = MIN_WHEEL_SIZE;
  set size(value: string) {
    this.#size = value || MIN_WHEEL_SIZE;
    this.style.width = this.#size;
    this.style.height = this.#size;
    this.realign();
  }

  get size(): string {
    return this.#size;
  }

  #colors: string[] = [];
  set colors(value: string[] | string) {
    this.#colors = convertToArray(value);
    this.realign(true);
  }

  get colors(): string[] {
    return this.#colors;
  }

  #textColors: string[] = [];
  set textColors(value: string[] | string) {
    this.#textColors = convertToArray(value);
    this.realign(true);
  }

  get textColors(): string[] {
    return this.#textColors;
  }

  #strokeWidth = 0;
  set strokeWidth(value: number | string) {
    const v = convertToInt(value);
    this.#strokeWidth = Math.max(v, 0);
    this.realign(true);
  }

  get strokeWidth(): number {
    return this.#strokeWidth;
  }

  #strokeColor = 'transparent';
  set strokeColor(value: string) {
    this.#strokeColor = value || 'transparent';
    this.realign(true);
  }

  get strokeColor(): string {
    return this.#strokeColor;
  }

  #borderWidth = 0;
  set borderWidth(value: number | string) {
    const v = convertToInt(value);
    this.#borderWidth = Math.max(v, 0);
    this.realign(true);
  }

  get borderWidth(): number {
    return this.#borderWidth;
  }

  #borderColor = 'transparent';
  set borderColor(value: string) {
    this.#borderColor = value || 'transparent';
    this.realign(true);
  }

  get borderColor(): string {
    return this.#borderColor;
  }

  #shadowWidth = 0;
  set shadowWidth(value: number | string) {
    const v = convertToInt(value);
    this.#shadowWidth = Math.max(v, 0);
    this.realign(true);
  }

  get shadowWidth(): number {
    return this.#shadowWidth;
  }

  #shadowColor = 'transparent';
  set shadowColor(value: string) {
    this.#shadowColor = value || 'transparent';
    this.realign(true);
  }

  get shadowColor(): string {
    return this.#shadowColor;
  }

  #padding = 0;
  set padding(value: number | string) {
    const v = convertToInt(value);
    this.#padding = Math.max(v, 0);
    this.realign(true);
  }

  get padding(): number {
    return this.#padding;
  }

  #azimuth: string = '0deg';
  set azimuth(value: string) {
    this.#azimuth = value;
  }

  get azimuth(): string {
    return this.#azimuth;
  }

  #direction = 'acw';
  set direction(value: 'acw' | 'cw') {
    this.#direction = ['acw', 'cw'].includes(value) ? value : 'acw';
  }

  get direction(): string {
    return this.#direction;
  }

  #initialDuration = 0;
  set duration(value: number | string) {
    const v = convertToInt(value) || 0;
    this.#initialDuration = v;
    if (this.#spinner) {
      this.#spinner.duration = v;
    }
  }

  get duration(): number {
    return this.#spinner?.duration || 0;
  }

  #revolutions = 0;
  set revolutions(value: number | string) {
    this.#revolutions = convertToInt(value);
  }

  get revolutions(): number {
    return this.#revolutions;
  }

  #classname: string = '';
  set classname(value: string) {
    if (this.#classname) {
      this.classList.remove(this.#classname);
    }
    this.#classname = value;
    this.classList.add(value);
  }

  get classname(): string {
    return this.#classname;
  }

  get isSpinning(): boolean {
    if (this.#spinner) {
      return this.#spinner.isSpinning;
    }
    return false;
  }

  get isInfinitySpinning(): boolean {
    if (this.#spinner) {
      return this.#spinner.isInfinitySpinning;
    }
    return false;
  }

  onspinningstart: null | ((event: Event) => void) = null;
  onspinningend: null | ((event: Event) => void) = null;

  onConnect() {
    super.onConnect();

    const template = document.createElement('template');
    template.innerHTML = `<style>
      :host {
        position: relative;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        flex-grow: 0;
        flex-shrink: 0;
        user-select: none;
        min-width: ${MIN_WHEEL_SIZE};
        min-height: ${MIN_WHEEL_SIZE};
        border-radius: 50%;
        opacity: 0;
        transition: opacity 0.2s linear;
        will-change: opacity;
        overflow: hidden;
      }
      :host > div {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        aspect-ratio: 1/1;
        border-radius: 50%;
        overflow: hidden;
      }
      :host > div > canvas {
        width: 100%;
        aspect-ratio: 1/1;
        z-index: -2;
        border: var(--padding) solid transparent;
        box-sizing: border-box;
      }
      :host > div::after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        border-radius: 50%;
        margin: calc(var(--padding) - 1px);
        border: var(--border-width) var(--border-color) solid;
        box-shadow: inset var(--shadow-color) 0 0 0 var(--shadow-width);
        pointer-events: none;
      }
    </style><div><canvas></canvas><slot></slot></div>`;

    if (!this.shadow) {
      return;
    }

    this.shadow.appendChild(template.content);
    this.#wheel = this.shadow.querySelector(':host > div');
    if (this.#wheel) {
      this.#canvas = this.#wheel.querySelector('canvas');
      if (this.#canvas) {
        this.#ctx = this.#canvas.getContext('2d');

        this.#mutationObserver = new MutationObserver(() => this.realign());
        this.#mutationObserver.observe(this, { childList: true });

        this.#resizeObserver = new ResizeObserver(() => this.#resize());
        this.#resizeObserver.observe(this);

        this.#spinner = new Spinner(
          this.#wheel,
          () => {
            if (!this.#init && !this.isSpinning) {
              this.emit('spinningend', this.index);
              this.#items[this.index]?.classList.add('selected');
            }
          },
          this.#initialDuration || undefined,
        );
        this.realign();
      }
    }
  }

  onDisconnect() {
    if (this.#mutationObserver) {
      this.#mutationObserver.disconnect();
    }
    if (this.#resizeObserver) {
      this.#resizeObserver.disconnect();
    }
    super.onDisconnect();
  }
}

type JSXElement<T> = DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement> & T;

declare global {
  interface Window {
    SectoredWheelElement: typeof SectoredWheelElement;
    SectoredWheelItemElement: typeof SectoredWheelItemElement;
  }

  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    type SectoredWheelElementAttributes = {
      index?: number;
      size?: string;
      colors?: string;
      textColors?: string;
      strokeWidth?: number;
      strokeColor?: string;
      padding?: number;
      azimuth?: string;
      direction?: 'cw' | 'acw';
      duration?: number;
      revolutions?: number;
      borderWidth?: number;
      borderColor?: string;
      shadowWidth?: number;
      shadowColor?: string;
      classname?: string;
    };

    type SectoredWheelItemElementAttributes = {
      color?: string;
    };

    interface IntrinsicElements {
      'sectored-wheel': JSXElement<SectoredWheelElementAttributes>;
      'sectored-wheel-item': JSXElement<SectoredWheelItemElementAttributes>;
    }
  }
}

export const registerSectoredWheelElement = () => {
  if (!window.customElements.get('sectored-wheel-item')) {
    window.SectoredWheelItemElement = SectoredWheelItemElement;
    window.customElements.define('sectored-wheel-item', SectoredWheelItemElement);
  }

  if (!window.customElements.get('sectored-wheel')) {
    window.SectoredWheelElement = SectoredWheelElement;
    window.customElements.define('sectored-wheel', SectoredWheelElement);
  }
};
