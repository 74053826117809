import { defineMessage, MessageDescriptor } from 'react-intl';

export const messages: { [key: string]: MessageDescriptor } = {
  completeProfileTitle: defineMessage({
    id: 'confirmationPopupTitle:redeemPointsOnOfferNoProfileCompleted',
    defaultMessage: 'Redeem Offer',
    description: 'Title in confirmation popup when ask to redeem points on offers and user profile is not completed',
  }),
  completeProfileDescription: defineMessage({
    id: 'confirmationPopupDescription:redeemPointsOnOfferNoProfileCompleted',
    defaultMessage: 'To use this offer, please complete your profile first.',
    description:
      'Message to inform user in confirmation popup with ask to redeem points on offer and user is not completed',
  }),
  completeProfileConfirmButton: defineMessage({
    id: 'confirmationPopupButton:redeemPointsOnOfferNoProfileCompleted',
    defaultMessage: 'OK',
    description:
      'Confirm button text in confirmation popup with ask to redeem points on offer and user is not completed',
  }),
  redeemPointsTitle: defineMessage({
    id: 'confirmationPopupTitle:redeemPointsOnOffer',
    defaultMessage: 'Redeem Offer',
    description: 'Title in confirmation popup when ask to redeem points on offers',
  }),
  redeemPointsDescription: defineMessage({
    id: 'confirmationPopupDescription:redeemPointsOnOffer',
    defaultMessage:
      'You will have {expiresIn} to redeem this reward in store. Are you sure you would like to redeem it now?',
    description: 'Message to inform user in confirmation popup with ask to redeem points on offer',
  }),
  redeemPointsNoExpirationDaysDescription: defineMessage({
    id: 'confirmationPopupDescription:redeemPointsOnOfferNoExpirationDays',
    defaultMessage: 'Are you sure you would like to redeem this offer now?',
    description:
      'Message to inform user in confirmation popup with ask to redeem points on offer if offer does not have expiration days',
  }),
  redeemPointsConfirmButton: defineMessage({
    id: 'confirmationPopupButton:redeemPointsOnOffer',
    defaultMessage: 'Confirm',
    description: 'Confirm button text in confirmation popup with ask to redeem points on offer',
  }),
};
