import { IntlShape } from 'react-intl/src/types';
import confetti from 'canvas-confetti';
import { isSpinWheelGameSectorIcon, messages, SpinWheelGameProps, SpinWheelGameSectorProps } from '@ocx/spin-wheel';

import { RandomAwardType, SpinWheelGamePrizeFragment } from '@ocx/graphql';
import { EnvConfiguration } from '../../../modules/configuration/EnvConfiguration';
import { SpinWheelGameCustomData } from '../../../modules/offers/custom-data/custom-data.types';
import { InfoActionSheetProps } from '../../../components/modals/InfoActionSheet/info-action-sheet.types';
import { SpinWheelGameEarningMechanism } from '../../../modules/offers/earning-mechanism.types';
import { SpinWheelGamePrize } from './components';
import { buttonTextMessages } from '../../../modules/localization/button-text.messages';

export const mapSegmentToSector = (
  segment: SpinWheelGamePrizeFragment,
  segmentCustomization: { icon?: string | null; color?: string | null } = {},
): SpinWheelGameSectorProps => {
  const { color, icon } = segmentCustomization;
  return {
    color: color || undefined,
    icon: isSpinWheelGameSectorIcon(icon) ? icon : undefined,
    points: segment.award?.__typename === 'AwardPoints' ? segment.award.points : undefined,
    type: segment.awardType,
  };
};

export const mapSpinWheelGameCustomDataToProps = (
  customData: SpinWheelGameCustomData | undefined,
): Partial<SpinWheelGameProps> => {
  if (!customData) {
    return {};
  }
  const {
    foregroundImage,
    backgroundImage,
    backgroundPrimaryColor,
    backgroundSecondaryColor,
    buttonColor,
    buttonTextColor,
    headerText,
    headerTextColor,
    borderColor,
    disclaimerTextColor,
    disclaimerBackgroundColor,
    footerTextColor, // will be deprecated soon, use disclaimerTextColor instead
  } = customData.spinWheelGame || {};

  return {
    foregroundImage: foregroundImage || undefined,
    backgroundImage: backgroundImage || undefined,
    backgroundPrimaryColor: backgroundPrimaryColor || undefined,
    backgroundSecondaryColor: backgroundSecondaryColor || undefined,
    buttonColor: buttonColor || undefined,
    buttonTextColor: buttonTextColor || undefined,
    headerText: headerText || undefined,
    headerTextColor: headerTextColor || undefined,
    borderColor: borderColor || undefined,
    disclaimerTextColor: footerTextColor || disclaimerTextColor || undefined,
    disclaimerBackgroundColor: disclaimerBackgroundColor || undefined,
  };
};

export const getSpinWheelGamePrizePopupParams = (
  prize: SpinWheelGamePrizeFragment,
  intl: IntlShape,
): InfoActionSheetProps => {
  const { awardType, award } = prize;
  let popupId: 'no-awards' | 'try-again' | 'win' = 'no-awards';

  if (awardType === RandomAwardType.TryAgain) {
    popupId = 'try-again';
  } else if (awardType === RandomAwardType.Vouchers || awardType === RandomAwardType.Points) {
    popupId = 'win';
  }

  return {
    title: intl.formatMessage(messages[`spinWheelGame:${popupId}-popup-title`]),
    description:
      awardType === RandomAwardType.Points
        ? undefined
        : intl.formatMessage(messages[`spinWheelGame:${popupId}-popup-description`], {
            vendor: EnvConfiguration.marketing.title,
          }),
    actionButtonText: intl.formatMessage(messages[`spinWheelGame:${popupId}-popup-button`]),
    onActionButtonClick: () => {}, // show button, without any action
    content: <SpinWheelGamePrize award={award} />,
    isServiceLinkEnabled: false,
  };
};

export const getSpinWheelGameGenericErrorPopupParams = (intl: IntlShape): InfoActionSheetProps => ({
  title: intl.formatMessage(messages['spinWheelGame:generic-error-popup-title']),
  description: intl.formatMessage(messages['spinWheelGame:generic-error-popup-description']),
  actionButtonText: intl.formatMessage(buttonTextMessages['button-text:dismiss']),
  onActionButtonClick: () => {}, // show button, without any action
  isServiceLinkEnabled: false,
});

export const mapSegmentsToSectors = (
  prizePool: SpinWheelGameEarningMechanism['prizePool'],
  sectorsConfiguration?: Array<{ icon?: string | null; color?: string | null }>,
) => prizePool.map((prizePoolItem, index) => mapSegmentToSector(prizePoolItem, sectorsConfiguration?.[index]));

export const showConfetti = () =>
  confetti({
    startVelocity: 40,
    particleCount: 200,
    ticks: 500,
    angle: 270,
    spread: 180,
    origin: {
      y: -0.5,
      x: 0.5,
    },
  });
