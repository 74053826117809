import * as Sentry from '@sentry/react';

import { cacheStorage, STORAGE_CACHE_VERSION, STORAGE_CACHE_VERSION_KEY } from './cacheStorage';
import { logInDev } from '../../lib/logger/logger';

export const onCacheLoadFailed = (e: any) => {
  logInDev(e);
  clearCache().catch(logInDev);
};

export const clearCache = () => cacheStorage.clear();

export const syncCacheVersion = async (): Promise<void> => {
  try {
    const version = await cacheStorage.getItem(STORAGE_CACHE_VERSION_KEY);
    if (version === STORAGE_CACHE_VERSION) {
      return;
    }

    // Clear cache and sync version
    await clearCache();
    await cacheStorage.setItem(STORAGE_CACHE_VERSION_KEY, STORAGE_CACHE_VERSION);
  } catch (e) {
    logInDev(e);
    Sentry.captureException(e);
  }
};
