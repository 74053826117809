import { useCallback, useMemo } from 'react';
import * as Sentry from '@sentry/react';

import {
  CustomerUpdateMutationVariables,
  MembershipUpdate,
  useCustomerUpdateMutation,
  useCommunicationPreferenceUpdateMutation,
} from '@ocx/graphql';
import { IProfileFormFields, ProfileFormFieldsInitialValues, UpdateProfileErrorHandler } from '../types';
import { useCreateLoyaltyEvent } from '../../../hooks/useCreateLoyaltyEvent';
import { Membership } from '../../../modules/membership/membership.types';
import { formatDateTime, parseDate } from '../../../lib/date/date';
import { DateTimeFormat } from '../../../lib/date/date.types';
import { useMembership } from '../../../modules/membership/use-membership.hook';
import { useRudderStack } from '../../../lib/rudderStack/useRudderStack';

const composeInitialValues = (membership: Membership): ProfileFormFieldsInitialValues => {
  const birthdate = membership.birthdate
    ? parseDate(membership.birthdate.split('T')[0], DateTimeFormat.DateFull)
    : undefined;
  return {
    birthdate: {
      date: birthdate && birthdate.getDate(),
      month: birthdate && birthdate.getMonth() + 1,
      year: birthdate && birthdate.getFullYear(),
    },
    email: membership.email || '',
    firstName: membership.firstName || '',
    lastName: membership.lastName || '',
    phoneNumber: membership.phone || '',
    termsAccepted: Boolean(membership.programTermsAcceptanceAt),
    isExplicitMarketingSmsAcceptanceOptIn: membership.communicationPreference.smsOptIn,
  };
};

const createUpdateCustomerMutationVariables = (values: IProfileFormFields): CustomerUpdateMutationVariables => {
  const {
    firstName,
    lastName,
    birthdate: { date, month, year },
    email,
    termsAccepted,
  } = values;

  const birthdate = new Date(year, month - 1, date);
  const membership: MembershipUpdate = {
    birthdate: formatDateTime(birthdate, DateTimeFormat.DateFull),
    firstName,
    lastName,
    email,
  };

  if (termsAccepted) {
    membership.programTermsAcceptanceAt = formatDateTime(new Date(), DateTimeFormat.DateFull);
  }

  return {
    customer: {
      membership,
    },
  };
};

type UseProfileControllerReturns = {
  loading: boolean;
  initialValues: ProfileFormFieldsInitialValues;
  updateCustomer: (values: IProfileFormFields) => void;
  membership: Membership;
  isExplicitTermsAcceptanceEnabled: boolean;
};

type UseProfileControllerParams = {
  onError: UpdateProfileErrorHandler;
  onComplete(params: { isProfileCompletionTriggered?: boolean }): void;
  isExplicitTermsAcceptanceRequired: boolean;
  isExplicitSmsPromotionAcceptanceEnabled: boolean;
};

export const useEditProfileController = ({
  onError,
  onComplete,
  isExplicitTermsAcceptanceRequired,
  isExplicitSmsPromotionAcceptanceEnabled,
}: UseProfileControllerParams): UseProfileControllerReturns => {
  const { triggerEvent } = useRudderStack();

  const { membership, loading } = useMembership();
  const [communicationPreferenceUpdate] = useCommunicationPreferenceUpdateMutation();
  const initialValues: ProfileFormFieldsInitialValues = useMemo(() => composeInitialValues(membership), [membership]);
  const isExplicitTermsAcceptanceEnabled = useMemo(() => {
    if (!isExplicitTermsAcceptanceRequired) {
      return false;
    }
    if (isExplicitTermsAcceptanceRequired && membership && Boolean(membership.programTermsAcceptanceAt)) {
      return false;
    }
    return true;
  }, [isExplicitTermsAcceptanceRequired, membership]);

  const [createLoyaltyEvent, { loading: creatingLoyaltyEvent }] = useCreateLoyaltyEvent({
    membershipId: membership.id,
  });

  const [updateCustomerMutation, { loading: isProfileUpdating }] = useCustomerUpdateMutation();

  const updateCustomer = useCallback(
    async (values: IProfileFormFields) => {
      try {
        await updateCustomerMutation({
          variables: createUpdateCustomerMutationVariables({
            ...values,
            // Prevent updating "programTermsAcceptanceAt" if terms already accepted
            // or acceptance is not required
            termsAccepted: isExplicitTermsAcceptanceEnabled ? values.termsAccepted : false,
          }),
        });

        createLoyaltyEvent('profile_completion').catch((error) => {
          Sentry.captureException(error, {
            extra: { event: 'Create loyalty event mutation failed.', membershipId: membership.id },
          });
        });

        if (!membership.isCompleted && isExplicitSmsPromotionAcceptanceEnabled) {
          await communicationPreferenceUpdate({
            variables: {
              input: {
                smsOptInAt: values.isExplicitMarketingSmsAcceptanceOptIn ? new Date() : null,
                smsOptOutAt: values.isExplicitMarketingSmsAcceptanceOptIn ? null : new Date(),
              },
            },
            refetchQueries: ['currentCustomer'],
          });
        }

        // WARN: this works, because it's impossible to edit profile, not submitting all values
        // We should show achievement when user completes profile.
        // Profile completion happens after first submission of the profile form.
        // In other cases we have to show snackbar about successfully form submission
        const isProfileCompletionTriggered = !membership.isCompleted;
        onComplete({ isProfileCompletionTriggered });
        triggerEvent('profile_updated');

        if (isProfileCompletionTriggered) {
          triggerEvent('profile_completed');
        }
        return null;
      } catch (e) {
        return onError(e);
      }
    },
    [
      updateCustomerMutation,
      isExplicitTermsAcceptanceEnabled,
      createLoyaltyEvent,
      isExplicitSmsPromotionAcceptanceEnabled,
      membership.isCompleted,
      membership.id,
      onComplete,
      triggerEvent,
      communicationPreferenceUpdate,
      onError,
    ],
  );

  return {
    loading: loading || isProfileUpdating || creatingLoyaltyEvent,
    updateCustomer,
    initialValues,
    membership,
    isExplicitTermsAcceptanceEnabled,
  };
};
