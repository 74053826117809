import { createTheme as createMuiTheme, responsiveFontSizes, Theme } from '@mui/material';

import { VendorPalette } from './palette/types';
import { createPaletteOverrides } from './palette/createPaletteOverrides';
import { createComponentsOverrides } from './createComponentsOverrides';
import { typography } from './typography';

export const createTheme = (vendorPalette: VendorPalette): Theme => {
  const palette = createPaletteOverrides(vendorPalette);
  const components = createComponentsOverrides(palette);

  return responsiveFontSizes(createMuiTheme({ palette, typography, components }));
};
