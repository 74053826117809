import { useMemo } from 'react';

import { useMembershipProductInterestsQuery } from '@ocx/graphql';
import { ProductCategory } from '../loyalty/loyalty.types';

export const useMembershipProductInterests = () => {
  const { data, loading } = useMembershipProductInterestsQuery({ fetchPolicy: 'cache-and-network' });
  const productCategories = useMemo<ProductCategory[]>(() => {
    if (!data?.customer?.membership?.productInterests) {
      return [];
    }
    return data.customer.membership.productInterests.map((productCategory) => productCategory);
  }, [data?.customer?.membership?.productInterests]);

  return { productCategories, loading };
};
