import React from 'react';
import Button from '@mui/lab/LoadingButton';

import { ButtonProps } from './Button';

export type LoadingButtonProps = ButtonProps & {
  loading: boolean;
};

export const LoadingButton: React.FC<LoadingButtonProps> = React.forwardRef<HTMLButtonElement, LoadingButtonProps>(
  (props, ref) => {
    const { variant = 'contained', ...rest } = props;
    return <Button variant={variant} {...rest} ref={ref} />;
  },
);
