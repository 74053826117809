import { defineMessages } from 'react-intl';

export const rewardExpirationMessages = defineMessages({
  expiresDays: {
    id: 'reward-expires:days',
    defaultMessage: 'Expires in {expiresIn, plural, one {# day} other {# days}}',
    description: 'Reward expiration days',
  },
  expiresMinutes: {
    id: 'reward-expires:minutes',
    defaultMessage: 'Expires in {expiresIn, plural, one {# minute} other {# minutes}}',
    description: 'Reward expiration minutes',
  },
  expiresSoon: {
    id: 'reward-expires:soon',
    defaultMessage: 'Expires soon ({expiresIn})',
    description: 'Reward expiration soon',
  },
  expiresToday: {
    id: 'reward-expires:today',
    defaultMessage: 'Expires today ({expiresIn})',
    description: 'Reward expiration today',
  },
  expiresDateTime: {
    id: 'reward-expires:dateTime',
    defaultMessage: 'Expires {expiresIn}',
    description: 'Reward expiration date and time',
  },
});
