import { IntlShape, useIntl } from 'react-intl';
import { useCallback, useMemo } from 'react';

import { useOfferPurchaseMutation } from '@ocx/graphql';
import { PurchaseVoucherOffer } from '../../../modules/offers/offer.types';
import { useConfiguration } from '../../../modules/configuration/useConfiguration';
import { messages } from './redeem.messages';
import { OfferActivateStartType } from '../../../lib/rudderStack/rudderStack.types';
import { useRudderStack } from '../../../lib/rudderStack/useRudderStack';
import { logInDev } from '../../../lib/logger/logger';
import { expirationMessages } from '../../../modules/offers/offer.messages';
import { calcDateTime, diffDateTime } from '../../../lib/date/date';
import { useConfirmationActionSheet, useEditProfilePopup } from '../../../components/modals';

type RedeemOfferContext = {
  membershipIsCompleted: boolean;
};
type UseRedeemOffer = (params: { onError: (error: unknown) => void; onCompleted?: () => void }) => {
  redeem: (offer: PurchaseVoucherOffer, context: RedeemOfferContext) => void;
  redeeming: boolean;
};

export interface IFGetOfferExpirationDaysOptions {
  // number of days when voucher expires
  // R TODO: rename it
  voucherExpiresAt: number | null;
  // date string of offer expiration
  offerExpiresAt: string | null;
}

export interface IVoucherExpiresIn {
  value: number;
  unit: 'days' | 'hours' | 'minutes';
}

export const getVoucherExpiresIn = (options: IFGetOfferExpirationDaysOptions): IVoucherExpiresIn | null => {
  const { voucherExpiresAt, offerExpiresAt } = options;
  if (voucherExpiresAt && offerExpiresAt) {
    const now = new Date();
    const offerExpiresAtDate = new Date(offerExpiresAt);
    const voucherExpiresAtDate = calcDateTime(now, 'add', voucherExpiresAt, 'day');
    const minExpirationDate = new Date(Math.min(voucherExpiresAtDate.valueOf(), offerExpiresAtDate.valueOf()));

    const diffMinutes = Math.abs(diffDateTime(now, minExpirationDate, 'minute'));
    if (diffMinutes < 60) {
      return { value: diffMinutes, unit: 'minutes' };
    }
    const diffHours = Math.abs(diffDateTime(now, minExpirationDate, 'hour'));
    if (diffHours < 24) {
      return { value: diffHours, unit: 'hours' };
    }
    const diffDays = Math.abs(diffDateTime(now, minExpirationDate, 'day'));
    return { value: diffDays, unit: 'days' };
  }
  if (voucherExpiresAt) {
    return { value: voucherExpiresAt, unit: 'days' };
  }
  return null;
};
export const getVoucherExpiresInMessage = (expiresIn: IVoucherExpiresIn, intl: IntlShape): string => {
  if (expiresIn.unit === 'minutes' && expiresIn.value < 30) {
    return intl.formatMessage(expirationMessages.expiresLessThanHour);
  }
  if (expiresIn.unit === 'minutes' || (expiresIn.unit === 'hours' && expiresIn.value === 1)) {
    return intl.formatMessage(expirationMessages.expiresAnHour);
  }
  if (expiresIn.unit === 'hours') {
    return intl.formatMessage(expirationMessages.expiresHours, { expiresIn: expiresIn.value });
  }
  return intl.formatMessage(expirationMessages.expiresDays, { expiresIn: expiresIn.value });
};
export const useRedeemOffer: UseRedeemOffer = ({ onError, onCompleted }) => {
  const { triggerEvent } = useRudderStack();
  const intl = useIntl();
  const { open: openEditProfilePopup } = useEditProfilePopup();
  const { open: openConfirmationActionSheet } = useConfirmationActionSheet();
  const redeemOfferWithCompletedProfileOnly = useConfiguration('loyaltyProgram.redeemOfferWithCompletedProfileOnly');
  const [createOfferPurchase, { loading: redeeming }] = useOfferPurchaseMutation({
    refetchQueries: ['currentCustomer', 'offers'],
    awaitRefetchQueries: true,
    onError,
    onCompleted,
  });

  const requestMembershipComplete = useCallback(
    () =>
      openConfirmationActionSheet({
        title: intl.formatMessage(messages.completeProfileTitle),
        description: intl.formatMessage(messages.completeProfileDescription),
        confirmButtonText: intl.formatMessage(messages.completeProfileConfirmButton),
        onConfirm: openEditProfilePopup,
      }),
    [intl, openConfirmationActionSheet, openEditProfilePopup],
  );

  const purchaseOffer = useCallback(
    (offer: PurchaseVoucherOffer) => {
      const onPurchaseConfirmed = () => {
        triggerEvent('offer_activate_start', { type: OfferActivateStartType.Redeem, uuid: offer.id });
        createOfferPurchase({
          variables: {
            input: {
              count: 1,
              offerId: offer.id,
            },
          },
        }).catch(logInDev);
      };

      const expiresIn = getVoucherExpiresIn({
        voucherExpiresAt: offer.earningMechanism.award.voucherExpirationDays,
        offerExpiresAt: offer.expireAt,
      });
      const confirmationPopupDescription =
        expiresIn === null
          ? intl.formatMessage(messages.redeemPointsNoExpirationDaysDescription)
          : intl.formatMessage(messages.redeemPointsDescription, {
              expiresIn: getVoucherExpiresInMessage(expiresIn, intl),
            });
      triggerEvent('offer_redeem_confirm_requested', { uuid: offer.id });
      openConfirmationActionSheet({
        title: intl.formatMessage(messages.redeemPointsTitle),
        description: confirmationPopupDescription,
        confirmButtonText: intl.formatMessage(messages.redeemPointsConfirmButton),
        onConfirm: onPurchaseConfirmed,
      }).catch(logInDev);
    },
    [createOfferPurchase, intl, openConfirmationActionSheet, triggerEvent],
  );

  const redeemOffer = useCallback(
    (offer: PurchaseVoucherOffer, { membershipIsCompleted }: RedeemOfferContext) => {
      const isCompleteProfileRequired =
        offer.customData?.completeProfileRestricted !== undefined
          ? offer.customData.completeProfileRestricted
          : redeemOfferWithCompletedProfileOnly;
      if (isCompleteProfileRequired && !membershipIsCompleted) {
        requestMembershipComplete();
        return;
      }

      purchaseOffer(offer);
    },
    [purchaseOffer, redeemOfferWithCompletedProfileOnly, requestMembershipComplete],
  );

  return useMemo(
    () => ({
      redeem: redeemOffer,
      redeeming,
    }),
    [redeemOffer, redeeming],
  );
};

export const isErrorNotEnoughPointsError = (error: unknown): boolean => {
  if (error instanceof Error) {
    if (error.message.includes('You do not have enough points for this reward.')) {
      return true;
    }
  }
  return false;
};
