import { DisplayCategoryFragment, GetExternalOffersQueryVariables } from '@ocx/graphql';

export const getExternalOfferConfigurationUuids = (categories: DisplayCategoryFragment[]): string[] =>
  categories.reduce((acc, { bannerIds }) => (bannerIds?.length ? [...acc, ...bannerIds] : acc), [] as string[]);

export const createExternalOffersQueryVariables = (
  categories: DisplayCategoryFragment[],
): GetExternalOffersQueryVariables => ({
  filter: {
    bannerConfigurationUuidIncludes: getExternalOfferConfigurationUuids(categories),
  },
});
