import { alpha, ThemeOptions } from '@mui/material';

import { PaletteOverrides } from './palette/types';
import { SizingMultiplier } from './constants';

export const createComponentsOverrides = (palette: PaletteOverrides): ThemeOptions['components'] => {
  return {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: palette.background.paper,
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          color: palette.text.primary,
          backgroundColor: palette.background.paper,
          '&:hover': {
            backgroundColor: palette.background.paper,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: palette.text.secondary,
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: palette.text.primary,
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: palette.text.secondary,
          },
          '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            borderColor: palette.text.disabled,
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color: palette.text.secondary,
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        rounded: {
          borderRadius: 16,
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        rounded: {
          borderRadius: 8,
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingLeft: 8,
          paddingRight: 8,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          height: 4,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
          fontWeight: 600,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
        sizeMedium: {
          paddingTop: 12,
          paddingBottom: 12,
        },
        outlinedSizeMedium: {
          paddingTop: 11,
          paddingBottom: 11,
        },
        startIcon: {
          position: 'absolute',
          left: 16,
        },
        endIcon: {
          position: 'absolute',
          right: 16,
        },
      },
    },
    MuiIconButton: {
      variants: [
        {
          // TODO: temporary solution. Sync with designer and improve, cover all states or remove from theme and create a custom component
          props: { variant: 'contained' },
          style: () => ({
            backgroundColor: palette.action.selected,
            color: palette.primary.main,
          }),
        },
      ],
    },
    MuiDivider: {
      variants: [
        {
          props: { variant: 'dashed', orientation: 'vertical' },
          style: () => ({
            height: 'auto',
            alignSelf: 'stretch',
            backgroundImage: `linear-gradient(${palette.divider} 50%, transparent 0%)`,
            backgroundPosition: 'right',
            backgroundSize: '10px 10px',
            backgroundRepeat: 'repeat-y',
            width: 1,
            border: 'none',
          }),
        },
      ],
    },
    MuiBottomNavigationAction: {
      styleOverrides: {
        label: {
          marginTop: 2,
        },
        root: {
          color: palette.tabBar.color,
          '&.Mui-selected': {
            color: palette.tabBar.selectedColor,
          },
        },
      },
    },
    MuiBottomNavigation: {
      styleOverrides: {
        root: {
          justifyContent: 'space-around',
          backgroundColor: palette.tabBar.background,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: palette.appBar.background,
          color: palette.appBar.color,
        },
        colorTransparent: {
          color: palette.appBar.color,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&.Mui-checked.Mui-disabled': {
            // According Material guidelines > Disabled states are displayed at 0.38 opacity of the enabled state
            color: alpha(palette.primary.main, 0.38),
          },
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        valueLabel: ({ theme }) => ({
          paddingTop: theme.spacing(SizingMultiplier.sm),
          paddingBottom: theme.spacing(SizingMultiplier.sm),
          borderRadius: theme.shape.borderRadius * 2,
          ...theme.typography.subtitle1,
        }),
      },
    },
  };
};
