import { TypePolicies } from '@apollo/client';

export const surveyTypePolicy: TypePolicies = {
  WalletSteeringSurvey: {
    keyFields: ['uuid'],
  },
  GeneralSurvey: {
    keyFields: ['uuid'],
  },
};
