/* eslint-disable @typescript-eslint/no-inferrable-types */
export const sameDigitsRegExp = /\b(\d)\1+\b/;
export const onlyDigitsRegExp = /^(\s|\d+)$/;
export const onlyDigitsOrEmptyRegExp = /(^\d+$|^$)/;

export const isOnlyDigits = (value: string = '', allowEmpty: boolean = false) => {
  if (allowEmpty) {
    return onlyDigitsOrEmptyRegExp.test(value);
  }
  return onlyDigitsRegExp.test(value);
};
export const isSameDigits = (value: string = '') => {
  return !sameDigitsRegExp.test(value);
};
export const isConsecutiveDigitsPositive = (value: string = '') => {
  const valueArray = value.split('').map((item) => parseInt(item, 10));
  return !valueArray.every((current, index) => {
    const prev = valueArray[index - 1] ?? current - 1;
    return prev === current - 1;
  });
};
export const isConsecutiveDigitsNegative = (value: string = '') => {
  const valueArray = value.split('').map((item) => parseInt(item, 10));
  return !valueArray.every((current, index) => {
    const prev = valueArray[index - 1] ?? current + 1;
    return prev === current + 1;
  });
};
