import { useCallback, useMemo } from 'react';
import { WatchQueryFetchPolicy } from '@apollo/client';

import { DisplayCategoryFragment, GetDisplayCategoriesQuery, useGetDisplayCategoriesQuery } from '@ocx/graphql';
import { useCacheDisplayCategories } from './useCacheDisplayCategories';
import { filterCategoriesRestriction, sortDisplayCategoriesByNameAndRank } from './utils';
import { useConfiguration } from '../configuration/useConfiguration';
import { useMembership } from '../membership/use-membership.hook';

export interface IUseOfferCategoriesParams {
  fetchPolicy?: WatchQueryFetchPolicy;
}
export interface IUseOfferCategoriesReturns {
  displayCategories: DisplayCategoryFragment[];
  loading: boolean;
  refetch(): Promise<any>;
}

export const useDisplayCategories = (params: IUseOfferCategoriesParams = {}): IUseOfferCategoriesReturns => {
  const { fetchPolicy = 'cache-and-network' } = params;
  const chainId = useConfiguration('chainId');
  const cacheDisplayCategories = useCacheDisplayCategories();
  const { membership } = useMembership();
  const handleQueryCompleted = useCallback(
    (query: GetDisplayCategoriesQuery) => cacheDisplayCategories(query),
    [cacheDisplayCategories],
  );
  const { data, loading, refetch } = useGetDisplayCategoriesQuery({
    fetchPolicy,
    variables: {
      filter: {
        chainIdEquals: chainId,
      },
    },
    onCompleted: handleQueryCompleted,
  });

  const displayCategories: DisplayCategoryFragment[] = useMemo(() => {
    if (!data) {
      return [];
    }
    // Display all categories and sort
    const displayAllCategories: DisplayCategoryFragment[] = data?.displayCategories.edges
      .map((edge) => edge.node)
      .sort(sortDisplayCategoriesByNameAndRank);

    // Return ONLY the categories allowed by Member's account restriction
    return filterCategoriesRestriction(displayAllCategories, membership);
  }, [data, membership]);
  return { displayCategories, loading, refetch };
};
