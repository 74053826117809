import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { PointsStrategy } from '@ocx/graphql';
import { Chip } from '@ocx/ui';
import { messages } from './messages';
import { OfferLabel as TypeOfferLabel } from '../../offer-card.types';

export interface OfferLabelProps {
  label: TypeOfferLabel;
  pointsStrategy?: PointsStrategy;
}

export const OfferLabel: React.FC<OfferLabelProps> = ({ label, pointsStrategy }) => {
  const intl = useIntl();

  const labelText = useMemo(() => {
    if (label.type === 'Reward') {
      return intl.formatMessage(messages[label.type], { voucherCount: label.voucherCount });
    }
    if (label.type === 'Entries') {
      return intl.formatMessage(messages[label.type], { entries: label.entries });
    }
    if (label.type === 'Bonus' && pointsStrategy === PointsStrategy.Points) {
      return intl.formatMessage(messages.BonusPoints);
    }
    if (label.type === 'Bonus' && pointsStrategy === PointsStrategy.Cents) {
      return intl.formatMessage(messages.BonusCents);
    }
    if (label.type === 'Gameplay') {
      return intl.formatMessage(messages[label.type]);
    }
    if (label.type === 'VoucheredGameplay') {
      return intl.formatMessage(messages[label.type], { voucherCount: label.voucherCount });
    }

    return intl.formatMessage(messages[label.type]);
  }, [intl, label, pointsStrategy]);

  return (
    <Chip
      color={`offerLabel.${label.type}.color`}
      bgcolor={`offerLabel.${label.type}.backgroundColor`}
      variant="overline">
      {labelText}
    </Chip>
  );
};
