import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { PinCodeAction } from '../wallet-pin-code-popup.types';
import { actionTitles } from '../wallet-pin-code-popup.messages';

export const useGetDisplayTitle = () => {
  const intl = useIntl();
  return useCallback((action: PinCodeAction) => intl.formatMessage(actionTitles[action]), [intl]);
};
