import React from 'react';
import { Tooltip, Box } from '@mui/material';
import { useIntl } from 'react-intl';

import { SizingMultiplier } from '../../../theme/constants';

export interface IHintProps {
  coords: { top: number; left: number; height: number } | null;
  isOpen: boolean;
}
export const ActivateOfferButtonHint: React.FC<IHintProps> = (props) => {
  const { coords, isOpen } = props;
  const intl = useIntl();
  if (!coords) {
    return null;
  }

  return (
    <Tooltip
      title={intl.formatMessage({
        id: 'home:offerPlusButtonHintText',
        defaultMessage: 'Tap + to add',
        description: 'Hint for first plus button in the offer',
      })}
      open={isOpen}
      arrow
      placement="top-end"
      componentsProps={{
        tooltip: {
          sx: (theme) => ({
            // TODO fix
            // @ts-ignore in sx props theme has "typography" as unknown type
            ...theme.typography.body2,
            // TODO: fix color
            backgroundColor: 'black',
            padding: theme.spacing(SizingMultiplier.sm),
          }),
        },
        arrow: { sx: { color: 'black' } },
        popper: { sx: { zIndex: 0 } },
      }}>
      <Box
        style={{
          position: 'absolute',
          top: coords.top,
          left: coords.left,
          height: coords.height,
        }}
      />
    </Tooltip>
  );
};
