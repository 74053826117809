import { alpha, IconButton } from '@mui/material';
import React, { useCallback } from 'react';

import { GiftBoxIcon } from '../Icons/GiftBoxIcon';

type AwardGiftBoxProps<EntityType> = { onClick: (entity: EntityType) => void; entity: EntityType };

export const AwardGiftBoxButton = <EntityType,>({ onClick, entity }: AwardGiftBoxProps<EntityType>) => {
  const handleOnClick = useCallback(() => {
    onClick(entity);
  }, [entity, onClick]);
  return (
    <IconButton
      onClick={handleOnClick}
      size="small"
      color="primary"
      sx={(theme) => ({
        borderRadius: theme.shape.borderRadius / 2,
        backgroundColor: alpha(theme.palette.primary.main, 0.2),
        '&:hover': {
          backgroundColor: alpha(theme.palette.primary.main, 0.4),
        },
      })}>
      <GiftBoxIcon />
    </IconButton>
  );
};
