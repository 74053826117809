import { useMemo } from 'react';

import { PrimaryTransactionStatus, SecondaryTransactionStatus, TransactionFragment } from '@ocx/graphql';
import { PrimaryInternalFuelingStatus } from '../../../../modules/transaction/types';
import { getTransactionPrimaryFuelingStatus } from '../../../../modules/transaction/utils';

export interface IUseMapTransactionStatusesReturns {
  primaryStatus: PrimaryTransactionStatus | null;
  secondaryStatus: SecondaryTransactionStatus | null;
  primaryFuelingStatus: PrimaryInternalFuelingStatus | null;
}

export interface IUseMapTransactionStatusesParams {
  transaction: TransactionFragment | null;
}

export const useMapTransactionStatuses = (
  params: IUseMapTransactionStatusesParams,
): IUseMapTransactionStatusesReturns => {
  const { transaction } = params;

  return useMemo(() => {
    if (transaction === null) {
      return {
        primaryStatus: null,
        secondaryStatus: null,
        primaryFuelingStatus: null,
      };
    }

    const { primaryStatus, secondaryStatus } = transaction;
    return {
      primaryStatus,
      secondaryStatus,
      primaryFuelingStatus: getTransactionPrimaryFuelingStatus(primaryStatus, secondaryStatus),
    };
    // Disabled for transaction object, we use only primaryStatus and secondaryStatus
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transaction?.primaryStatus, transaction?.secondaryStatus]);
};
