import { useRef, useEffect, ReactNode } from 'react';
import { styled, useTheme } from '@mui/material';
import { lighten, darken, getLuminance } from '@mui/material/styles';

import { SectoredWheelElement, registerSectoredWheelElement } from './SectoredWheel.webcomponent';

const DEFAULT_WHEEL_SIZE = '260px';
const DEFAULT_WHEEL_RIM_WIDTH = 5;

export type SpinWheelSector = {
  id: string;
  color?: string;
};

export type SpinWheelProps = {
  /**
   * Index of selected wheel sector 0 - N
   */
  sectorIndex?: number;
  /**
   * List of wheel sectors
   */
  sectors: SpinWheelSector[];
  /**
   * Wheel size: 200px, 70vw, 10em
   */
  size?: string;
  /**
   * Wheel rim width
   */
  rimWidth?: number;
  /**
   * Wheel rim color
   */
  rimColor?: string;
  /**
   * Start infinity spinning
   */
  isInfinitySpinning?: boolean;
  /**
   * Hide Cursor
   */
  hideCursor?: boolean;
  renderSectorContent: (sector: SpinWheelSector, index: number) => ReactNode;
  onSpinningStart?: (sectorIndex: number) => void;
  onSpinningEnd?: (sectorIndex: number) => void;
};

export const SpinWheel = (props: SpinWheelProps) => {
  const {
    sectorIndex = -1,
    sectors,
    size = DEFAULT_WHEEL_SIZE,
    rimWidth = DEFAULT_WHEEL_RIM_WIDTH,
    rimColor,
    isInfinitySpinning = false,
    hideCursor = false,
    renderSectorContent,
    onSpinningStart,
    onSpinningEnd,
  } = props;

  const { palette } = useTheme();
  const colors = palette.SpinWheel.sectorColors.map(({ color }) => color).join(';');
  const textColors = palette.SpinWheel.sectorColors.map(({ contrastText }) => contrastText).join(';');

  useEffect(() => {
    registerSectoredWheelElement();
  }, []);

  const wheel = useRef<SectoredWheelElement>(null);

  useEffect(() => {
    if (!wheel.current) {
      return;
    }
    const el = wheel.current;
    el.onspinningstart = (event: Event) => {
      const index = (event as CustomEvent).detail?.data;
      if (onSpinningStart) {
        onSpinningStart(index);
      }
    };
    el.onspinningend = (event: Event) => {
      const index = (event as CustomEvent).detail?.data;
      if (onSpinningEnd) {
        onSpinningEnd(index);
      }
    };
  }, [onSpinningEnd, onSpinningStart]);

  useEffect(() => {
    if (!wheel.current) {
      return;
    }
    if (isInfinitySpinning) {
      wheel.current.spin();
      return;
    }
    if (wheel.current?.isInfinitySpinning) {
      // stop infinity spinning on the same/prev sectorIndex and call the callback
      // that usually happens if we get the same random sectorIndex a few times
      // btw, to stop infinity spinning without callbacks on the first sector, the index should be -1
      wheel.current.index = sectorIndex;
    }
  }, [isInfinitySpinning, sectorIndex]);

  return (
    <Container rimWidth={rimWidth} rimColor={rimColor} size={size} hideCursor={hideCursor}>
      <sectored-wheel
        index={sectorIndex}
        ref={wheel}
        colors={colors}
        textColors={textColors}
        size={size}
        azimuth="-90deg"
        direction="cw"
        revolutions={2}
        duration={450}
        padding={rimWidth}
        borderWidth={2}
        borderColor={rimColor || palette.SpinWheel.rimNormalColor}
        shadowColor={palette.SpinWheel.rimShadowColor}
        shadowWidth={rimWidth / 2}>
        {sectors.map((item, i) => {
          let textColor;
          if (item.color) {
            textColor = getLuminance(item.color) > 0.5 ? darken(item.color, 0.7) : lighten(item.color, 0.7);
          }
          return (
            <sectored-wheel-item key={item.id} color={item.color} text-color={textColor}>
              {renderSectorContent(item, i)}
            </sectored-wheel-item>
          );
        })}
      </sectored-wheel>
    </Container>
  );
};

const Container = styled('div', {
  shouldForwardProp: (prop) => !['rimWidth', 'rimColor', 'size', 'hideCursor'].includes(prop as string),
})<{
  rimWidth?: number;
  rimColor?: string;
  size: string;
  hideCursor: boolean;
}>(({ theme, rimWidth = 10, rimColor, size, hideCursor }) => {
  // *** CSS vars, which could be used for custom styles ***
  // --count	        Amount of sectors
  // --sector-height  Sector height
  // --sector-width	  Sector width
  // --sector-angle	  Stroke angle
  // --border-width   Wheel border width
  // --border-color   Wheel border color
  // --shadow-width   Wheel inset shadow width
  // --padding Inner  Wheel padding

  // *** CSS classes, which can used for custom styles ***
  // sectored-wheel-item.selected	    Selected sector
  // sectored-wheel-item.preselected	Default (preselected) sector, in case if index was set

  const palette = theme.palette.SpinWheel;
  let rimLightColor = palette.rimLightColor;
  let rimNormalColor = palette.rimNormalColor;
  let rimDarkColor = palette.rimDarkColor;

  if (rimColor) {
    rimLightColor = lighten(rimColor, 0.2);
    rimNormalColor = rimColor;
    rimDarkColor = darken(rimColor, 0.2);
  }

  return {
    borderRadius: '50%',
    position: 'relative',
    pointerEvents: 'none',
    width: size,
    padding: `${rimWidth}px`,
    aspectRatio: '1/1',
    boxShadow: `${rimDarkColor} 0 0 0 2px`,
    background: `linear-gradient(135deg, ${rimNormalColor} 0%, ${rimNormalColor} 20%, ${rimLightColor} 20%, ${rimLightColor} 40%, ${rimNormalColor} 40%, ${rimNormalColor} 60%, ${rimLightColor} 60%, ${rimLightColor} 80%, ${rimNormalColor} 80%, ${rimLightColor} 100%) border-box`,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
    '&:before': {
      content: '""',
      position: 'absolute',
      width: '100%',
      aspectRatio: '1/1',
      borderRadius: '50%',
      background: palette.sectorColors[0]?.color || '#fff',
      border: `${rimWidth}px solid transparent`,
      backgroundClip: 'padding-box',
    },
    '&:after': {
      display: hideCursor ? 'none' : 'flex',
      content: '"\\25CF"',
      position: 'absolute',
      top: `-${rimWidth * 7.5}px`,
      aspectRatio: '1/1',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: `${rimWidth * 2}px`,
      borderRadius: '50% 50% 50% 15%',
      transform: 'translateY(40%) rotate(-45deg)',
      width: rimWidth * 6,
      backgroundColor: '#fff',
      color: '#fff',
      boxShadow: `inset ${rimDarkColor} 0 0 0 2px, inset 0px ${rimWidth * 13}px 0 -${
        rimWidth * 10
      }px ${rimNormalColor}, inset ${rimLightColor} 0 0 0 ${rimWidth * 6}px, ${palette.rimShadowColor} -${
        rimWidth / 2
      }px ${rimWidth / 2}px 0 0`,
      zIndex: 1,
      pointerEvents: 'none',
      userSelect: 'none',
    },
    'sectored-wheel:after': {
      content: '""',
      position: 'absolute',
      width: rimWidth * 4.5,
      borderRadius: '50%',
      aspectRatio: '1/1',
      backgroundColor: '#fff',
      boxShadow: `inset ${rimDarkColor} 0 0 0 2px, inset ${rimNormalColor} 0 0 0 ${rimWidth}px, ${
        palette.rimShadowColor
      } 0 0 0 ${rimWidth / 2}px`,
    },
    'sectored-wheel': {
      position: 'absolute',
      opacity: 0,
    },
    'sectored-wheel-item': {
      paddingRight: 'calc(var(--sector-width) * 0.15)',
      fontSize: `${rimWidth * 2.5}px`,
    },
    'sectored-wheel-item:before': {
      '--width': `${rimWidth * 2.5}px`,
      '--distance': `calc(var(--sector-width) - ${rimWidth + 1}px - var(--width) / 2)`,
      content: '""',
      position: 'absolute',
      left: 'var(--distance)',
      top: '50%',
      height: 'var(--width)',
      width: 'var(--width)',
      marginTop: 'calc(var(--width) / -2)',
      background: palette.rimShadowColor,
      borderRadius: '50%',
      transformOrigin: 'calc(var(--distance) * -1) 50%',
      transform: 'rotate(calc(var(--sector-angle) / 2))',
      clipPath: 'polygon(0 0, calc(50% - 1px) 0%, calc(50% - 1px) 100%, 0 100%)',
    },
    'sectored-wheel-item:after': {
      '--width': `${rimWidth}px`,
      '--distance': `calc(var(--sector-width) - ${rimWidth + 1}px - var(--width) / 2)`,
      content: '""',
      position: 'absolute',
      left: 'var(--distance)',
      top: '50%',
      height: 'var(--width)',
      width: 'var(--width)',
      marginTop: 'calc(var(--width) / -2)',
      backgroundColor: rimLightColor,
      boxShadow: `${rimDarkColor} 0 0 0 2px`,
      borderRadius: '50%',
      transformOrigin: 'calc(var(--distance) * -1) 50%',
      transform: 'rotate(calc(var(--sector-angle) / 2))',
      zIndex: 2,
    },
  };
});
