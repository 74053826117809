import { Box } from '@mui/material';

import React, { useEffect } from 'react';
import { isAndroidPlatform } from '@ocx-app/modules/device/utils/info';

import { Privacy } from '@ocx-app/lib/privacy/Privacy';
import { useIsAppFocused } from './use-is-app-focused.hook';

type BlurOverlayWhenAppIsInactiveProps = {
  children: React.ReactNode;
};

export const BlurOverlayWhenAppIsInactive = ({ children }: BlurOverlayWhenAppIsInactiveProps) => {
  const isAppFocused = useIsAppFocused();
  useEffect(() => {
    // Ensures screen is blurred when multitasking on Android, since pressing the home button may capture the UI state.
    // iOS handles this natively, so the effect is only needed for Android.
    if (isAndroidPlatform()) {
      Privacy.enable();
    }

    return () => {
      if (isAndroidPlatform()) {
        Privacy.disable();
      }
    };
  }, []);

  return <Box sx={{ filter: isAppFocused ? 'none' : 'blur(10px)', transition: 'filter 0.3s' }}>{children}</Box>;
};
