import { useCallback } from 'react';

import { useFetchSurveys } from './fetch.surveys.hook';
import { useFetchGuestSurvey } from './fetch-guest-survey.hook';
import { SanitizedSurvey } from '../survey.types';
import { getFirstNotDismissedSurvey } from '../survey.utils';

export type UseFetchEligibleSurveyReturns = {
  fetch(membershipId?: string, guestSurveyId?: string): Promise<SanitizedSurvey | null>;
  isLoading: boolean;
};

export const useFetchEligibleSurvey = (): UseFetchEligibleSurveyReturns => {
  const { fetchSurveys, isLoading: isSurveysLoading } = useFetchSurveys();
  const { fetch: fetchGuestSurvey, isLoading: isGuestSurveysLoading } = useFetchGuestSurvey();

  const getAuthSurveys = useCallback(
    async (membershipId?: string): Promise<SanitizedSurvey[]> => {
      if (!membershipId) {
        return [];
      }
      return fetchSurveys();
    },
    [fetchSurveys],
  );

  const getGuestSurvey = useCallback(
    async (guestSurveyId?: string): Promise<SanitizedSurvey | null> => {
      if (!guestSurveyId) {
        return null;
      }
      return fetchGuestSurvey(guestSurveyId);
    },
    [fetchGuestSurvey],
  );

  const fetch = useCallback(
    async (membershipId?: string, guestSurveyId?: string): Promise<SanitizedSurvey | null> => {
      const [surveys, guestSurvey] = await Promise.all([getAuthSurveys(membershipId), getGuestSurvey(guestSurveyId)]);

      if (guestSurvey) {
        return guestSurvey;
      }
      // Dismissed surveys are stored on user's device storage using Membership ID as a key
      if (!membershipId) {
        return null;
      }

      return getFirstNotDismissedSurvey(membershipId, surveys);
    },
    [getAuthSurveys, getGuestSurvey],
  );

  return {
    fetch,
    isLoading: isSurveysLoading || isGuestSurveysLoading,
  };
};
