import { RudderAnalytics } from '@rudderstack/analytics-js';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';

import { GetAppConfigDocument, GetAppConfigQuery } from '@ocx/graphql';
import { Event, AnalyticsInstance, RudderStackPageOptions, RudderStackUserTraits } from './rudderStack.types';
import { EnvConfiguration } from '../../modules/configuration/EnvConfiguration';
import { getHrefWithPathname, maskHref, maskPathname } from './url.utils';
import { withTryCatch } from '../error-handling/utils';

const rudderStack = new RudderAnalytics();

export const getPageOptions = (): RudderStackPageOptions => {
  const maskedPathname = maskPathname(window.location.pathname);
  const maskedHref = maskHref(window.location.href);

  return {
    path: maskedPathname,
    tab_url: maskedHref,
    url: maskedHref,
  };
};

const getIsValidConfig = (): boolean => {
  const { writeKey, dataPlaneUrl } = EnvConfiguration.rudderStack;
  return !!writeKey && !!dataPlaneUrl;
};

export const getCustomPageOptions = (pathname: string): RudderStackPageOptions => {
  const href = getHrefWithPathname(pathname);

  return {
    path: pathname,
    tab_url: href,
    url: href,
  };
};

const init = withTryCatch(() => {
  const { writeKey, dataPlaneUrl } = EnvConfiguration.rudderStack;
  const isValidConfig = getIsValidConfig();

  if (!isValidConfig) {
    return;
  }

  rudderStack.load(writeKey, dataPlaneUrl, {
    storage: {
      type: 'localStorage',
    },
  });
});

const triggerEvent = withTryCatch((...[event, params]: Event): void => {
  const pageOptions = getPageOptions();
  rudderStack.track(event, params, { page: pageOptions });
});

/**
 * Triggers a location event in RudderStack. This function is used to track page views.
 *
 * @param [pathname] - The pathname of the page to track. If provided, it tracks a page view for a different page
 *                     than the current one. This is useful for tracking page views of modals or overlays
 *                     that don't have their own URL, like a survey modal opened on top of the home page.
 */
const triggerLocation = withTryCatch((pathname?: string) => {
  const pageOptions = pathname ? getCustomPageOptions(pathname) : getPageOptions();
  rudderStack.page(undefined, undefined, pageOptions);
});

const identify = withTryCatch((userId: string, userIdentity: RudderStackUserTraits) => {
  const pageOptions = getPageOptions();
  rudderStack.identify(userId, userIdentity, { page: pageOptions });
});

const identifyAudiences = withTryCatch((userId: string, audiences: string[]) => {
  const pageOptions = getPageOptions();
  rudderStack.identify(userId, { audiences }, { page: pageOptions });
});

export const reset = withTryCatch(() => {
  rudderStack.reset();
});

export const getIsRudderStackEnabledFromApolloCache = (apolloClient: ApolloClient<NormalizedCacheObject>) => {
  // It's needed to pass apolloClient as parameter because of circular dependency that happens when importing apolloClient in this file
  const response = apolloClient.readQuery<GetAppConfigQuery>({ query: GetAppConfigDocument });
  return response?.appConfig?.rudderStack?.enabled ?? false;
};

export const getRudderStackInstance = (enabled: boolean): AnalyticsInstance => {
  if (!enabled) {
    return {
      triggerEvent: () => {},
      triggerLocation: () => {},
      identify: () => {},
      identifyAudiences: () => {},
      init: () => {},
      reset: () => {},
    };
  }

  return { triggerEvent, triggerLocation, identify, identifyAudiences, init, reset };
};
