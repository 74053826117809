import { useCallback } from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';

import { useRudderStack } from '../../../lib/rudderStack/useRudderStack';
import { SurveyAwardPopup } from './survey-award-popup';
import { AwardVouchersSurvey } from '../../../modules/survey/survey.types';

export const surveyAwardModalPath = '/survey-award';

const SurveyAwardPopupModal = NiceModal.create(SurveyAwardPopup);

type OpenSurveyAwardPopupParams = {
  offer: AwardVouchersSurvey['offer'];
  isEarned: boolean;
};

export const useSurveyAwardPopup = () => {
  const { triggerLocation } = useRudderStack();
  const { show } = useModal(SurveyAwardPopupModal);

  const open = useCallback(
    ({ offer, isEarned }: OpenSurveyAwardPopupParams) => {
      triggerLocation(surveyAwardModalPath);
      show({ offer, isEarned, onClose: () => triggerLocation() });
    },
    [triggerLocation, show],
  );

  return {
    open,
  };
};
