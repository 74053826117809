export type KountPluginEnvironment = 'TEST' | 'PRODUCTION';

export enum KountCollectorStatus {
  COMPLETED = 'completed',
  FAILED = 'failed',
  IN_PROGRESS = 'in_progress',
  NOT_STARED = 'not_started',
}

export interface IKountPluginInitCollectorParams {
  merchantId: number;

  // web plugin only
  collectorHostname: string;

  // native app plugins only
  environment: KountPluginEnvironment;
}

export interface IKountPluginCollectParams {
  sessionId: string;
}

export interface IKountPlugin {
  initCollector(params: IKountPluginInitCollectorParams): Promise<{ success: boolean }>;
  collect(param: IKountPluginCollectParams): Promise<{ success: boolean }>;
  getCollectorStatus(): Promise<{ status: KountCollectorStatus }>;
}
