import React from 'react';
import { FormattedMessage } from 'react-intl';
import { alpha } from '@mui/material';

import { SupportLink } from '../../../../modules/support/components/support-link';

export const ServiceLink: React.FC = () => {
  return (
    <SupportLink
      typographyProps={{
        variant: 'body1',
        color: 'textSecondary',
        align: 'center',
        sx: {
          // based on default opacity used for MUI Link with underline decorator
          textDecoration: (theme) => `underline ${alpha(theme.palette.text.secondary, 0.4)}`,
        },
      }}>
      <FormattedMessage
        id="actionSheet:serviceLinkTitle"
        defaultMessage="Need help?"
        description="Service link title"
      />
    </SupportLink>
  );
};
