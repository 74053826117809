import React from 'react';
import { Box, styled } from '@mui/material';
import { Typography } from '@ocx/ui';

import { SizingMultiplier } from '../../theme/constants';

export type SurgeonWarningSize = 'default' | 'large';

interface ISurgeonWarning {
  message: string;
  square?: boolean;
  size?: SurgeonWarningSize;
}

// Styles are hardcoded due to Altria Regulations
const SurgeonWarningContainer = styled(Box, {
  shouldForwardProp: (prop) => prop === 'children',
})<{
  square: boolean;
  size: SurgeonWarningSize;
}>(({ theme, size, square }) => ({
  backgroundColor: theme.palette.common.white,
  borderWidth: 4,
  borderStyle: 'solid',
  borderColor: theme.palette.common.black,
  borderRadius: 4,
  padding: theme.spacing(SizingMultiplier.xs),
  color: theme.palette.common.black,
  textAlign: 'center',
  fontFamily: 'Arial, Helvetica, sans-serif',
  fontSize: 12,
  lineHeight: '14px',

  ...(size === 'large'
    ? {
        borderWidth: 6,
        padding: theme.spacing(SizingMultiplier.sm),
        fontSize: 20,
        lineHeight: '22px',
      }
    : {}),

  ...(square
    ? {
        borderRadius: 0,
      }
    : {}),
}));

export const SurgeonWarning: React.FC<ISurgeonWarning> = (props) => {
  const { message, square = false, size = 'default' } = props;

  return (
    <SurgeonWarningContainer square={square} size={size}>
      <Typography component="p" fontSize="inherit" fontFamily="inherit" lineHeight="inherit">
        {message}
      </Typography>
    </SurgeonWarningContainer>
  );
};
