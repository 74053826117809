import { FreshdeskSupportWidgetConfiguration, FreshdeskSupportWidgetEnabledConfiguration } from './app-config.types';

export const isFreshdeskSupportWidgetEnabled = (
  supportWidgetConfiguration: FreshdeskSupportWidgetConfiguration,
): supportWidgetConfiguration is FreshdeskSupportWidgetEnabledConfiguration => {
  return Boolean(
    supportWidgetConfiguration.enabled &&
      supportWidgetConfiguration.id &&
      supportWidgetConfiguration.token &&
      supportWidgetConfiguration.host &&
      supportWidgetConfiguration.sdkUrl,
  );
};
