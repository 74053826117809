import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { MessageDescriptor, useIntl } from 'react-intl';

import { useConfiguration } from '../../modules/configuration/useConfiguration';

type HtmlTitleProps = {
  title?: MessageDescriptor | string;
};

export const HtmlTitle: React.FC<HtmlTitleProps> = ({ title }) => {
  const intl = useIntl();
  const marketingTitle = useConfiguration('marketing.title');
  const htmlTitle = useMemo(() => {
    if (!title) {
      return marketingTitle;
    }
    if (typeof title === 'string') {
      return title;
    }
    return intl.formatMessage(title);
  }, [intl, marketingTitle, title]);

  return (
    <Helmet>
      <title>{htmlTitle}</title>
    </Helmet>
  );
};
