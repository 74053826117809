import { defineMessages } from 'react-intl';

import { PointsStrategy, UnitOfMeasure } from '@ocx/graphql';

export const messages = defineMessages({
  'survey-single-question:title': {
    id: 'survey-single-question:title',
    defaultMessage: 'Quick Question',
    description: 'Single Question Survey popup title',
  },
  'survey:submit-animation:award:title': {
    id: 'survey:submit-animation:award:title',
    defaultMessage: 'Thanks!',
    description: 'Title for survey submit confetti animation for award vouchers',
  },
});

export const sliderTooltipMessages = defineMessages<UnitOfMeasure>({
  [UnitOfMeasure.Amount]: {
    id: 'survey:tooltip:money-amount',
    // eslint-disable-next-line no-template-curly-in-string
    defaultMessage: '${value}',
    description: 'Survey - slider control - tooltip for UnitOfMeasure - Amount',
  },
  [UnitOfMeasure.Gallon]: {
    id: 'survey:tooltip:gallons',
    defaultMessage: '{value, plural, one {# gallon} other {# gallons}}',
    description: 'Survey - slider control - tooltip for UnitOfMeasure - Gallon',
  },
  [UnitOfMeasure.Unit]: {
    id: 'survey:tooltip:units',
    defaultMessage: '{value}',
    description: 'Survey - slider control - tooltip for UnitOfMeasure - Unit',
  },
});

export const awardPointsButtonTextMessages = defineMessages({
  [PointsStrategy.Points]: {
    id: 'survey:submit-button:pointsStrategy:points:title',
    defaultMessage: 'Get My {points} {pointsPlural, plural, zero {Points} one {Point} other {Points}}',
    description: 'Text for "Get My X Points" button for points strategy POINTS',
  },
  [PointsStrategy.Cents]: {
    id: 'survey:submit-button:pointsStrategy:cents:title',
    defaultMessage: 'Get My {points}',
    description: 'Text for "Get My $X" button for points strategy CENTS',
  },
});

export const awardVouchersButtonTextMessages = defineMessages({
  'survey:submit-button:award:title': {
    id: 'survey:submit-button:award:title',
    defaultMessage: 'Get My Award',
    description: 'Text for "Get My Award" button',
  },
});
