import { object, string } from 'zod';

import { intl } from '../../../lib/translation/TranslationProvider';
import { makeValidateSync } from '../../../lib/form/utils';
import { validationMessages } from '../../../modules/localization/validationMessages';

const emailErrorMessage = intl.formatMessage(validationMessages['validation:form:email:required']);

const schema = object({
  email: string({ message: emailErrorMessage }).email(emailErrorMessage),
}).required();

export const completeEmailFormValidationSchema = makeValidateSync(schema);
