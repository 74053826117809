import React, { ReactNode, useCallback } from 'react';
import { Box, Slide, Backdrop, Portal, styled } from '@mui/material';

import { SizingMultiplier } from '../../../../theme/constants';

const ANIMATION_TIMEOUT = 300;
const DECORATOR_HEIGHT = '65px';

export const ModalPaper = styled('div')(({ theme }) => ({
  background: theme.palette.background.paper,
  borderRadius: theme.spacing(SizingMultiplier.md, SizingMultiplier.md, 0, 0),
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  justifyContent: 'center',
}));

type ModalDrawerProps = {
  open: boolean;
  children: ReactNode;
  minPaperHeight?: number;
  isDecorated?: boolean;
  backgroundImageSrc?: string;
  onClose?(): void;
  onExited?(): void;
};

export const ModalDrawer: React.FC<ModalDrawerProps> = (props) => {
  const { open = false, children, onClose, onExited, minPaperHeight = 100, isDecorated, backgroundImageSrc } = props;

  const onClick = useCallback(
    (e: React.MouseEvent) => {
      if (onClose !== undefined && e.target === e.currentTarget) {
        onClose();
      }
    },
    [onClose],
  );

  return (
    <Portal>
      <Backdrop
        open={open}
        onClick={onClick}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          alignItems: 'stretch',
          backgroundImage: backgroundImageSrc ? `url(${backgroundImageSrc})` : undefined,
          backgroundSize: 'cover',
        }}
        timeout={ANIMATION_TIMEOUT}>
        <Slide direction="up" appear in={open} onExited={onExited} timeout={ANIMATION_TIMEOUT} unmountOnExit>
          {/* Box used to prevent "decorators" display partially during animation */}
          <Box
            sx={{
              overflow: 'auto',
              overscrollBehavior: 'contain',
              pt: `calc(${DECORATOR_HEIGHT} + var(--ion-safe-area-top, 0px))`,
              outline: 'none',
            }}
            onClick={onClick}
            tabIndex={0}
            role="button">
            <ModalPaper sx={{ minHeight: minPaperHeight, paddingTop: isDecorated ? DECORATOR_HEIGHT : undefined }}>
              {children}
            </ModalPaper>
          </Box>
        </Slide>
      </Backdrop>
    </Portal>
  );
};
