import { useEffect } from 'react';

import { useConfiguration } from '../../../modules/configuration/useConfiguration';
import { useMembership } from '../../../modules/membership/use-membership.hook';

interface IUseProfileControllerReturns {
  loading: boolean;
  isWalletEnabled: boolean;
  username: string;
  isRefetching: boolean;
  isCommunicationPreferenceEnabled: boolean;
  isProductInterestsEnabled: boolean;
  isPreferredFuelGradeEnabled: boolean;
}

export const useProfileController = (): IUseProfileControllerReturns => {
  const { config } = useConfiguration();
  const { membership, loading, refetch: refetchMembership } = useMembership();
  const username = [membership.firstName, membership.lastName].join(' ').trim();

  // Refetch membership on each "Profile" page visit
  useEffect(() => {
    refetchMembership();
  }, [refetchMembership]);

  return {
    loading,
    username,
    isWalletEnabled: config.wallet.enabled,
    isRefetching: Boolean(loading && membership),
    isCommunicationPreferenceEnabled: config.communicationPreference.enabled,
    isProductInterestsEnabled: config.productInterests.enabled,
    isPreferredFuelGradeEnabled: config.totalAvailableRollback.enabled,
  };
};
