import NiceModal, { useModal } from '@ebay/nice-modal-react';
import React from 'react';

import { PaymentMethodSelectorPopupController } from './payment-method-selector-popup.controller';
import { PaymentMethodSelectorPopupProps } from './payment-method-selector-popup.types';

const paymentMethodSelectorPopupModalId = 'payment-method-selector-popup';

export const PaymentMethodSelectorPopup = NiceModal.create<PaymentMethodSelectorPopupProps>((props) => (
  <PaymentMethodSelectorPopupController {...props} />
));

export const usePaymentMethodSelectorPopup = () => {
  const modal = useModal(PaymentMethodSelectorPopup, { id: paymentMethodSelectorPopupModalId });
  return modal;
};
