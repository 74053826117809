import * as Sentry from '@sentry/react';
import { Capacitor } from '@capacitor/core';
import { useEffect } from 'react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';

import { EnvConfiguration } from '../configuration/EnvConfiguration';

export const sentryInit = () => {
  if (!EnvConfiguration.sentry.dsn) {
    return;
  }
  let platform = 'unknown';
  try {
    platform = Capacitor.getPlatform();
  } catch {
    // eslint-disable-next-line no-console
    console.info('Capacitor.getPlatform() thrown exception. Platform is unknown');
  }
  Sentry.init({
    dsn: EnvConfiguration.sentry.dsn,
    release: EnvConfiguration.release,
    environment: EnvConfiguration.environment,
    tracesSampleRate: EnvConfiguration.sentry.tracesSampleRate,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.0,
    // If the entire session is not sampled, use the below sample rate to sample sessions when an error occurs.
    // https://docs.sentry.io/platforms/javascript/guides/react/session-replay/#verify
    // Sentry recommends having this value as 1.0
    replaysOnErrorSampleRate: 1.0,

    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      // TODO: lazy load Replay module
      // https://docs.sentry.io/platforms/javascript/guides/react/session-replay/#lazy-loading-replay
      // TODO: setup CSP
      // https://docs.sentry.io/platforms/javascript/guides/react/session-replay/#content-security-policy-csp
      // new Sentry.Replay({
      //   maskAllText: true,
      // }),
    ],
    initialScope: {
      tags: {
        retailer: EnvConfiguration.retailer,
        capacitor_platform: platform,
      },
    },
  });
};
