import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  'buy-all-entries-confirmation-action-sheet:title': {
    id: 'buy-all-entries-confirmation-action-sheet:title',
    defaultMessage: 'All in!',
    description: 'Buy all entries confirmation dialog title',
  },
  'buy-all-entries-confirmation-action-sheet:description:points-strategy-points': {
    id: 'buy-all-entries-confirmation-action-sheet:description:points-strategy-points',
    defaultMessage:
      'Get {entries, plural, one {# entry} other {# entries}} for {points, plural, one {# point} other {# points}}',
    description: 'Buy all entries confirmation dialog description for points strategy "Points"',
  },
  'buy-all-entries-confirmation-action-sheet:description:points-strategy-cents': {
    id: 'buy-all-entries-confirmation-action-sheet:description:points-strategy-cents',
    defaultMessage: 'Get {entries, plural, one {# entry} other {# entries}} for {dollars}',
    description: 'Buy all entries confirmation dialog description for points strategy "Cents"',
  },
});
