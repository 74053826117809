import React, { useCallback } from 'react';
import { useModal } from '@ebay/nice-modal-react';

import { InfoActionSheetProps } from './info-action-sheet.types';
import { logInDev } from '../../../lib/logger/logger';
import { InfoActionSheet } from './info-action-sheet';

export const InfoActionSheetController: React.FC<InfoActionSheetProps> = (props) => {
  const {
    title,
    description,
    variant,
    actionButtonText,
    actionButtonVariant = 'contained',
    onActionButtonClick,
    onExited,
    isServiceLinkEnabled,
    content,
  } = props;

  const { visible, hide, remove } = useModal();

  const handleExited = useCallback(() => {
    onExited?.();
    remove();
  }, [remove, onExited]);

  const handleActionButtonClick = useCallback(() => {
    onActionButtonClick?.();
    hide().catch(logInDev);
  }, [hide, onActionButtonClick]);

  return (
    <InfoActionSheet
      title={title}
      description={description}
      variant={variant}
      actionButtonText={actionButtonText}
      actionButtonVariant={actionButtonVariant}
      onActionButtonClick={onActionButtonClick ? handleActionButtonClick : undefined}
      onExited={handleExited}
      onClose={hide}
      isVisible={visible}
      isServiceLinkEnabled={isServiceLinkEnabled}>
      {content}
    </InfoActionSheet>
  );
};
