import React, { useMemo } from 'react';

import { PointsStrategy } from '@ocx/graphql';
import { useConfiguration } from '../../modules/configuration/useConfiguration';
import { useGetNavigationActions } from '../../hooks/useGetNavigationActions';
import { useSanitizedLoyaltyActionHistory } from '../../modules/transaction-history/loyalty-action-history.hooks';
import { PAGINATION_SIZE } from '../../modules/transaction-history/transaction-history.constants';
import {
  mapActivateTransactionToTransactionHistoryItem,
  mapPointsChangeToTransactionHistoryItem,
} from '../../modules/transaction/utils';
import { createHistoryListSegments } from '../../modules/transaction-history/transaction-history.utils';
import { TransactionsListController } from './transactions-list.controller';

export const LoyaltyActionsListController: React.FC = () => {
  const { pushToActivateTransactionReceiptNested } = useGetNavigationActions();
  const pointsStrategy = useConfiguration('loyaltyProgram.pointsStrategy');
  const { data, fetchMore, refetch, networkStatus } = useSanitizedLoyaltyActionHistory({
    variables: {
      size: PAGINATION_SIZE,
      transactionCursor: null,
      pointChangeCursor: null,
      skipPointChanges: pointsStrategy === PointsStrategy.NotUsed,
    },
  });
  const segments = useMemo(() => {
    return createHistoryListSegments([
      ...data.pointChanges.map((edge) => mapPointsChangeToTransactionHistoryItem(edge.node)),
      ...data.transactions.map((edge) => mapActivateTransactionToTransactionHistoryItem(edge.node)),
    ]);
  }, [data]);

  return (
    <TransactionsListController
      networkStatus={networkStatus}
      segments={segments}
      refetch={refetch}
      fetchMore={fetchMore}
      onListItemClick={pushToActivateTransactionReceiptNested}
    />
  );
};
