import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useMemo } from 'react';

import { HowDoesItWorkPopupController } from './how-does-it-work-popup.controller';

const HowDoesItWorkPopupModal = NiceModal.create(HowDoesItWorkPopupController);

export const useHowDoesItWorkPopup = () => {
  const { show } = useModal(HowDoesItWorkPopupModal);
  return useMemo(() => ({ open: show }), [show]);
};
