import { ButtonBase, ButtonBaseProps } from '@mui/material';
import { ReactNode } from 'react';

import { SizingMultiplier } from '../sizing-multiplier.constant';

type ListItemButtonProps = {
  children?: ReactNode;
  onClick?: ButtonBaseProps['onClick'];
  disableRipple?: ButtonBaseProps['disableRipple'];
  selected?: boolean;
};

export const ListItemButton = (props: ListItemButtonProps) => {
  const { children, onClick, selected, disableRipple } = props;
  return (
    <ButtonBase
      onClick={onClick}
      disableRipple={disableRipple}
      sx={{
        display: 'flex',
        alignItems: 'center',
        p: SizingMultiplier.md,
        minHeight: 56,
        color: selected === false ? 'text.secondary' : 'text.primary',
      }}>
      {children}
    </ButtonBase>
  );
};
