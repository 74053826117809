import { IneligibleIfMembershipHasRewardConstraint, RewardOfferId } from './constraints.types';

export const assertIneligibleIfMembershipHasReward = (
  [, name, rewardIdsToAssert]: IneligibleIfMembershipHasRewardConstraint,
  membershipRewardIds: RewardOfferId[],
): boolean => {
  if (membershipRewardIds.find((rewardId) => rewardIdsToAssert.includes(rewardId))) {
    return true;
  }
  return false;
};
