import { PointsStrategy } from '@ocx/graphql';
import { getOfferProgressVariant } from './getOfferProgressVariant';
import { OfferCardMarketingContent, OfferCardMemberProgress, OfferCardPointsDiff } from './offer-card.types';
import { IneligibilityHandlingStrategy } from '../../modules/offers/eligibility.types';
import { DynamicCardAccessoryType } from '../DynamicCard';
import { AwardType } from '../../modules/offers/award.types';
import { Offer, OfferActivationStatus } from '../../modules/offers/offer.types';
import { isPurchaseVoucherOffer } from '../../modules/offers/offer.type-guards';
import { EarningMechanismType } from '../../modules/offers/earning-mechanism.types';

export const getOfferCardMemberProgress = ({
  earningMechanism,
  activationStatus,
  engagementType,
}: Offer): OfferCardMemberProgress => {
  if (earningMechanism?.type !== EarningMechanismType.UnknownWithProgress) {
    return null;
  }
  if (!earningMechanism.memberProgress?.required) {
    return null;
  }

  const progressVariant = getOfferProgressVariant({
    earningMechanismTypename: earningMechanism.__typename,
    engagementType,
    memberProgress: earningMechanism.memberProgress,
  });

  const canShowMemberProgress = activationStatus !== OfferActivationStatus.RequiresActivation;

  return {
    canShowMemberProgress,
    memberProgress: earningMechanism.memberProgress,
    progressVariant,
  };
};

export const getOfferCardPointsDiff = (
  offer: Offer,
  { pointsStrategy }: { pointsStrategy: PointsStrategy },
): OfferCardPointsDiff => {
  if (pointsStrategy === PointsStrategy.NotUsed) {
    return null;
  }

  const awardPoints =
    offer.earningMechanism?.award?.type === AwardType.Points ? offer.earningMechanism.award.points : null;
  const awardPurchasePricePoints = isPurchaseVoucherOffer(offer) ? offer.earningMechanism.fulfilmentPrice : null;

  const diff = awardPoints || (awardPurchasePricePoints ? -awardPurchasePricePoints : null);

  if (typeof diff !== 'number') {
    return null;
  }

  return {
    diff,
    pointsStrategy,
  };
};

export const getOfferCardMarketingContent = (offer: Offer): OfferCardMarketingContent => ({
  title: offer.marketingContent.title,
  shortDescription: offer.marketingContent.shortDescription,
  imageSrc: offer.marketingContent.imageUrl,
  surgeonGeneralsWarning: offer.marketingContent.surgeonGeneralsWarning,
});

export const getOfferCardInteractionStatus = (offer: Offer) => {
  const [, ineligibilityHandlingStrategy] = offer.eligible;
  const shouldDisableInteractions = ineligibilityHandlingStrategy === IneligibilityHandlingStrategy.DisableInteractions;
  const shouldDisableLoyaltyAction =
    ineligibilityHandlingStrategy === IneligibilityHandlingStrategy.DisableLoyaltyAction;

  return {
    shouldDisableLoyaltyAction,
    shouldDisableInteractions,
  };
};

export type GetOfferCardAccessoryTypeParams = {
  loading?: boolean;
  activationStatus?: OfferActivationStatus | null;
  hasOnClick?: boolean;
  hasOnActionClick?: boolean;
  hasProgress?: boolean;
};

export const getOfferCardAccessoryType = (params: GetOfferCardAccessoryTypeParams): DynamicCardAccessoryType => {
  const { loading, activationStatus, hasOnClick, hasOnActionClick, hasProgress } = params;

  if (loading) {
    return 'spinner';
  }

  if (hasOnActionClick && hasOnClick) {
    return 'plusButtonAndDisclosureIndicator';
  }

  if (hasOnActionClick) {
    return 'plusButton';
  }

  if (activationStatus === OfferActivationStatus.Activated && !hasProgress) {
    return 'checkmark';
  }

  if (hasOnClick) {
    return 'disclosureIndicator';
  }

  return 'none';
};
