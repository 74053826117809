import React from 'react';

export const HeartIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  const { color = 'currentColor', ...rest } = props;
  return (
    <svg {...rest} fill={color} width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.4399 3.33315C12.729 2.62962 11.7901 2.20333 10.7925 2.13121C9.79501 2.05908 8.80454 2.34587 7.99986 2.93982C7.15163 2.30891 6.09585 2.02282 5.04514 2.13918C3.99442 2.25553 3.02682 2.76567 2.33717 3.56687C1.64753 4.36808 1.28707 5.40083 1.32839 6.45716C1.36971 7.51349 1.80974 8.51493 2.55986 9.25982L6.55986 13.2932C6.93486 13.6677 7.44319 13.8781 7.97319 13.8781C8.50319 13.8781 9.01152 13.6677 9.38653 13.2932L13.3865 9.25982C13.7805 8.87492 14.0945 8.41595 14.3105 7.90931C14.5265 7.40266 14.6402 6.85832 14.6452 6.30757C14.6502 5.75682 14.5462 5.21052 14.3394 4.70007C14.1325 4.18962 13.8268 3.72508 13.4399 3.33315ZM12.4999 8.30648L8.49986 12.3065C8.43788 12.369 8.36415 12.4186 8.28291 12.4524C8.20167 12.4863 8.11453 12.5037 8.02653 12.5037C7.93852 12.5037 7.85138 12.4863 7.77014 12.4524C7.6889 12.4186 7.61517 12.369 7.55319 12.3065L3.55319 8.30648C3.03036 7.77205 2.7376 7.05413 2.7376 6.30648C2.7376 5.55884 3.03036 4.84092 3.55319 4.30648C4.08596 3.78048 4.80451 3.48553 5.55319 3.48553C6.30188 3.48553 7.02042 3.78048 7.55319 4.30648C7.61517 4.36897 7.6889 4.41857 7.77014 4.45241C7.85138 4.48626 7.93852 4.50368 8.02653 4.50368C8.11453 4.50368 8.20167 4.48626 8.28291 4.45241C8.36415 4.41857 8.43788 4.36897 8.49986 4.30648C9.04704 3.87108 9.73533 3.6523 10.4335 3.69188C11.1316 3.73146 11.7908 4.02662 12.2853 4.52109C12.7797 5.01555 13.0749 5.67471 13.1145 6.37286C13.154 7.07101 12.9353 7.75931 12.4999 8.30648Z" />
    </svg>
  );
};
