import { BaseError } from '../../lib/BaseError/BaseError';

export const PUSH_NOTIFICATIONS_PERMISSION_DENIED_ERROR = 'PUSH_NOTIFICATIONS_PERMISSION_DENIED_ERROR';

export class PushNotificationPermissionDeniedError extends BaseError<
  typeof PUSH_NOTIFICATIONS_PERMISSION_DENIED_ERROR
> {
  constructor() {
    super({ name: PUSH_NOTIFICATIONS_PERMISSION_DENIED_ERROR, message: 'User denied permissions!' });

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, PushNotificationPermissionDeniedError.prototype);
  }
}
