import React, { useMemo } from 'react';
import { Grid, FormHelperText, styled } from '@mui/material';
import { useFormState } from 'react-final-form';
import { useIntl } from 'react-intl';

import { TextField } from '../TextField';

const DatePickerTextFiled = styled(TextField)(() => ({
  '& input': {
    MozAppearance: 'textfield',
    '&::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    '&::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
  },
}));

export interface ISimpleDatePickerProps {
  maxDate: number;
  minYear?: number;
  maxYear?: number;
  monthFieldName: string;
  dateFieldName: string;
  yearFieldName: string;
  disabled?: boolean;
}

export interface IDateInputsRef {
  dateField?: any;
  yearField?: any;
}

export const SimpleDatePicker = React.forwardRef<IDateInputsRef, ISimpleDatePickerProps>(
  ({ maxDate, minYear, maxYear, monthFieldName, dateFieldName, yearFieldName, disabled }, ref) => {
    const intl = useIntl();
    const { errors, touched = {} } = useFormState();

    const validationError = useMemo(() => {
      return (touched[dateFieldName] || touched[monthFieldName] || touched[yearFieldName]) && errors?.birthdate
        ? errors?.birthdate.birthdateError ||
            intl.formatMessage({
              defaultMessage: 'Enter a valid birthday',
              description: 'Error message for simple date picker',
              id: 'simpleDatePicker:errorMessage',
            })
        : null;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [touched[dateFieldName], touched[monthFieldName], touched[yearFieldName], errors?.birthdate]);

    return (
      <div>
        <Grid container spacing={2}>
          <Grid item xs={4} sm={3}>
            <DatePickerTextFiled
              fullWidth
              type="number"
              variant="outlined"
              name={monthFieldName}
              inputProps={{ min: 1, max: 12, pattern: '[0-9]*' }}
              label={intl.formatMessage({
                defaultMessage: 'Month',
                description: 'Month label',
                id: 'simpleDatePicker:monthLabel',
              })}
              FormHelperTextProps={{ sx: { display: 'none' } }}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={4} sm={3}>
            <DatePickerTextFiled
              fullWidth
              type="number"
              variant="outlined"
              name={dateFieldName}
              inputProps={{ min: 1, max: maxDate, pattern: '[0-9]*' }}
              label={intl.formatMessage({
                defaultMessage: 'Day',
                description: 'Day label',
                id: 'simpleDatePicker:dayLabel',
              })}
              ref={(r) => {
                // @ts-ignore
                // eslint-disable-next-line no-param-reassign
                ref.current.dateField = r;
              }}
              FormHelperTextProps={{ sx: { display: 'none' } }}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={4} sm={6}>
            <DatePickerTextFiled
              fullWidth
              type="number"
              variant="outlined"
              name={yearFieldName}
              inputProps={{ min: minYear, max: maxYear, pattern: '[0-9]*' }}
              label={intl.formatMessage({
                defaultMessage: 'Year',
                description: 'Year label',
                id: 'simpleDatePicker:yearLabel',
              })}
              ref={(r) => {
                // @ts-ignore
                // eslint-disable-next-line no-param-reassign
                ref.current.yearField = r;
              }}
              FormHelperTextProps={{ sx: { display: 'none' } }}
              disabled={disabled}
            />
          </Grid>
        </Grid>
        <FormHelperText error={validationError !== null} variant="outlined" sx={{ textAlign: 'center' }}>
          {validationError}
        </FormHelperText>
      </div>
    );
  },
);
