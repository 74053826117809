import { cacheStorage } from '../cache-layer/cacheStorage';

export const PUSH_NOTIFICATIONS_STATE_KEY = 'push_notifications';

export type PushNotificationsState = {
  registrationTimestamp: string | null;
  deviceId: string | null;
  // FCM - Firebase Cloud Messaging
  fcmToken: string | null;
};

export const cachePushNotificationsState = async (params: { deviceId: string | null; fcmToken: string | null }) => {
  const state: PushNotificationsState = {
    registrationTimestamp: new Date().toISOString(),
    deviceId: params.deviceId,
    fcmToken: params.fcmToken,
  };

  await cacheStorage.setItem(PUSH_NOTIFICATIONS_STATE_KEY, state);
};

export const getCachedPushNotificationsState = async (): Promise<PushNotificationsState> => {
  const state: PushNotificationsState | null = await cacheStorage.getItem(PUSH_NOTIFICATIONS_STATE_KEY);
  if (!state) {
    return {
      registrationTimestamp: null,
      deviceId: null,
      fcmToken: null,
    };
  }
  return state;
};
