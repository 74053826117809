import React from 'react';
import { Stack } from '@mui/material';

import { SizingMultiplier } from '../../theme/constants';
import { MemberInformation } from './components/MemberInformation';
import { AboutList } from './components/AboutList';
import { AccountActionsList } from './components/AccountActionsList';
import { AppVersion } from './components/AppVersion';
import { LoyaltyDetailsList } from './components/LoyaltyDetailsList';

export interface IProfileProps {
  username: string;
  onEditProfileClick(): void;
  handleHowDoesItWorkOfferClick?(): void;
  isWalletEnabled: boolean;
  appVersion: string;
  isRefetching: boolean;
  isHowDoesItWorkAvailable: boolean;
  isCommunicationPreferenceEnabled: boolean;
  isProductInterestsEnabled: boolean;
  isPreferredFuelGradeEnabled: boolean;
  handleCommunicationPreferencesClick(): void;
}

export const Profile: React.FC<IProfileProps> = (props) => {
  const {
    username,
    onEditProfileClick,
    handleHowDoesItWorkOfferClick,
    isWalletEnabled,
    appVersion,
    isRefetching,
    isHowDoesItWorkAvailable,
    isCommunicationPreferenceEnabled,
    isProductInterestsEnabled,
    isPreferredFuelGradeEnabled,
    handleCommunicationPreferencesClick,
  } = props;

  return (
    // TODO move "pt" to "layout"
    <Stack spacing={SizingMultiplier.md} px={SizingMultiplier.sm} pt={SizingMultiplier.sm}>
      <MemberInformation username={username} onClick={onEditProfileClick} isRefetching={isRefetching} />
      <LoyaltyDetailsList
        isWalletEnabled={isWalletEnabled}
        isCommunicationPreferenceEnabled={isCommunicationPreferenceEnabled}
        isProductInterestsEnabled={isProductInterestsEnabled}
        isPreferredFuelGradeEnabled={isPreferredFuelGradeEnabled}
        handleCommunicationPreferencesClick={handleCommunicationPreferencesClick}
      />
      <AboutList
        isHowDoesItWorkAvailable={isHowDoesItWorkAvailable}
        handleHowDoesItWorkOfferClick={handleHowDoesItWorkOfferClick}
      />
      <AccountActionsList />
      <AppVersion version={appVersion} />
    </Stack>
  );
};
