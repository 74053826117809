import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useMemo } from 'react';

import { EditProfilePopupController } from './edit-profile-popup.controller';

const EditProfilePopupModal = NiceModal.create(EditProfilePopupController);

export const useEditProfilePopup = () => {
  const { show } = useModal(EditProfilePopupModal);

  return useMemo(() => ({ open: show }), [show]);
};
