import React, { useEffect, useRef } from 'react';
import * as Sentry from '@sentry/react';

import { ButtonOptions } from './types';
import { GooglePayUniversal } from './GooglePayUniversal';

export interface IGooglePayButtonProps {
  onClick?(): void;
  variant: ButtonOptions['buttonColor'];
}

export const GooglePayButton: React.FC<IGooglePayButtonProps> = (props) => {
  const { onClick, variant } = props;

  const root = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const exec = async () => {
      try {
        if (!root.current) {
          return;
        }
        const button = await GooglePayUniversal.getButton({ onClick, buttonColor: variant });
        if (button === null) {
          return;
        }

        if (root.current.lastChild) {
          root.current.removeChild(root.current.lastChild);
        }
        root.current.appendChild(button);
      } catch (e) {
        Sentry.captureException(e);
      }
    };
    exec().catch();
  }, [onClick, variant]);

  // TODO: update with CSS modules
  return <div style={{ width: '100%' }} ref={root} />;
};
