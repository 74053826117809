import React from 'react';

export const MinusIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  const { color = 'currentColor', ...rest } = props;
  return (
    <svg {...rest} fill={color} width="14" height="4" viewBox="0 0 14 4" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.920898 3.5293V0.855957H13.0425V3.5293H0.920898Z" />
    </svg>
  );
};
