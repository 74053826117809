import {
  LoyaltyActivateTransactionItemFragment,
  LoyaltyPointChangeItemFragment,
  TransactionHistoryItemFragment,
} from '@ocx/graphql';

export type SanitizedActivateTransactionEdge = {
  cursor: string;
  node: LoyaltyActivateTransactionItemFragment;
};

export type SanitizedPointChangeEdge = {
  cursor: string;
  node: LoyaltyPointChangeItemFragment;
};

export type SanitizedTransactionHistoryEdge = {
  cursor: string;
  node: TransactionHistoryItemFragment;
};

export enum TransactionHistoryItemType {
  PointsChange = 'PointsChange',
  LoyaltyEvent = 'LoyaltyEvent',
  Transaction = 'Transaction',
}

export interface ITransactionHistoryItem {
  id: string;
  title: string;
  points: number | null;
  type: TransactionHistoryItemType;
  date: string;
  dateTitle: string;
  groupedDate: string;
}

export type TransactionHistorySegment = [string, ITransactionHistoryItem[]];
