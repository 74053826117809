import { Box, styled } from '@mui/material';

import { SizingMultiplier } from '../sizing-multiplier.constant';

// TODO: think about borderRadius
export const Note = styled(Box)(({ theme }) => ({
  background: theme.palette.background.box,
  padding: theme.spacing(SizingMultiplier.md),
  borderRadius: SizingMultiplier.md * theme.shape.borderRadius,
}));
