import { addBreadcrumb } from '@sentry/react';
import { PushNotifications, Token } from '@capacitor/push-notifications';

import { logInDev } from '../../lib/logger/logger';
import { PushNotificationPermissionDeniedError } from './push-notification.exception';
import type { PluginListenerHandle } from '@capacitor/core';

export const PUSH_NOTIFICATIONS_REGISTRATION_EXPIRATION_DAYS = 1;

/**
 * Firebase Cloud Messaging
 */
export const getFCMToken = (): Promise<{ token: string; removeListeners: () => Promise<void> }> => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let registrationListener: PluginListenerHandle;
    let errorListener: PluginListenerHandle;

    const removeListeners = async () => {
      try {
        addBreadcrumb({
          message: '[getFCMToken] removing listeners.',
        });
        await Promise.all([registrationListener?.remove(), errorListener?.remove()]).catch(logInDev);
        addBreadcrumb({
          message: '[getFCMToken] listeners removed.',
        });
      } catch (e) {
        logInDev(e);
        addBreadcrumb({
          message: '[getFCMToken] removeListeners thrown.',
        });
      }
    };
    // "try catch" used to handle any exceptions during registration
    // and reject promise that returned from getFCMToken
    try {
      addBreadcrumb({
        message: '[getFCMToken] starting to check permission',
      });
      let permStatus = await PushNotifications.checkPermissions();
      addBreadcrumb({
        message: '[getFCMToken] permission status received',
        data: {
          status: permStatus.receive,
        },
      });
      if (permStatus.receive === 'prompt') {
        addBreadcrumb({
          message: '[getFCMToken] permission is not granted. Requesting...',
        });
        permStatus = await PushNotifications.requestPermissions();
      }
      if (permStatus.receive !== 'granted') {
        addBreadcrumb({
          message: '[getFCMToken] permission was not granted.',
        });
        reject(new PushNotificationPermissionDeniedError());
        return;
      }

      addBreadcrumb({
        message: '[getFCMToken] subscribing to `registration`.',
      });
      registrationListener = await PushNotifications.addListener('registration', (token: Token) => {
        addBreadcrumb({
          message: '[getFCMToken] token received.',
        });
        resolve({ token: token.value, removeListeners });
        removeListeners();
      });

      addBreadcrumb({
        message: '[getFCMToken] subscribing to `registrationError`.',
      });
      errorListener = await PushNotifications.addListener('registrationError', (payload: any) => {
        addBreadcrumb({
          message: '[getFCMToken] registrationError.',
        });
        reject(new Error(payload?.error || 'Push Notifications registration failed'));
        removeListeners();
      });

      addBreadcrumb({
        message: '[getFCMToken] trying to `PushNotifications.register()`.',
      });
      // "await" used to handle exceptions during registration
      // in case if plugin is broken, not implemented, etc
      await PushNotifications.register();
      addBreadcrumb({
        message: '[getFCMToken] `PushNotifications.register()` resolved.',
      });
    } catch (e) {
      removeListeners().catch();
      reject(e);
    }
  });
};

export const getIsPushNotificationRegistrationExpired = (registrationTimestamp: string | null) => {
  if (!registrationTimestamp) {
    return true;
  }

  const now = new Date();
  const expirationDate = new Date(registrationTimestamp);
  expirationDate.setDate(expirationDate.getDate() + PUSH_NOTIFICATIONS_REGISTRATION_EXPIRATION_DAYS);
  return expirationDate < now;
};

export type GetIsChangedParamsCurrent = { deviceId: string; token: string };
export type GetIsChangedParamsCached = { deviceId: string | null; token: string | null };
export const getIsChanged = (current: GetIsChangedParamsCurrent, cached: GetIsChangedParamsCached) => {
  return !(current.token === cached.token && current.deviceId === cached.deviceId);
};
