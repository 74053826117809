export const MOCK_SPIN_WHEEL_GAME_OFFER = {
  id: 'MOCK-SPIN-WHEEL-GAME-OFFER',
  expireAt: null,
  engagementType: null,
  activationStatus: 'DOES_NOT_REQUIRE_ACTIVATION',
  marketingContent: {
    id: 'MOCK-SPIN-WHEEL-GAME-MARKETINGCONTENT',
    imageUrl: 'https://i.pinimg.com/564x/53/1e/e7/531ee70ce88aff286479d43052628e89.jpg',
    title: 'Fake Spin Wheel Game Offer',
    shortDescription: null,
    longDescription: null,
    disclaimer: null,
    disclaimerUrl: null,
    terms: 'That is a fake t&c, do not trust it!',
    termsUrl: 'https://stuzo.com',
    surgeonGeneralsWarning: null,
    externalUrl: null,
  },
  earningMechanism: {
    type: 'SpinWheelGame',
    award: null,
    gameplayVoucherOfferId: null,
    prizePool: new Array(12).fill(0).map((_, index) => {
      const data: Record<string, unknown> = {
        award: null,
        awardType: 'NO_AWARD',
        limit: 0,
        sequenceNumber: index,
      };
      if ([1, 3, 7, 11].includes(index)) {
        data.awardType = 'POINTS';
        data.award = { __typename: 'AwardPoints', points: [50, 500, 500, 250][[1, 3, 7, 11].indexOf(index)] };
      } else if ([0, 9].includes(index)) {
        data.awardType = 'TRY_AGAIN';
      } else if ([2, 4, 6, 8, 10].includes(index)) {
        data.awardType = 'VOUCHERS';
        data.award = {
          __typename: 'AwardVouchers',
          voucherCount: 1,
          voucherExpirationDays: 1,
          voucheredOffer: {},
        };
      }
      return data as never;
    }),
  },
  customData: {
    version: 1,
    spinWheelGame: {},
  },
  eligible: [true],
  voucherCount: 0,
  presentationPriority: 'FEATURED',
  presentationType: 'DEFAULT',
  displayCategories: [],
};
