import { Device } from '@capacitor/device';
import { Capacitor } from '@capacitor/core';

import { CapacitorPlatform } from '../types/types';

export const getDeviceId = async () => {
  const { identifier: deviceId } = await Device.getId();
  return deviceId;
};

export const getDevicePlatform = () => {
  return Capacitor.getPlatform() as CapacitorPlatform;
};
