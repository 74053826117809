import { useSnackbar as useSnackbarNotistack } from 'notistack';
import { useMemo } from 'react';

export const useSnackbar = () => {
  const { enqueueSnackbar: open, closeSnackbar: close } = useSnackbarNotistack();

  return useMemo(
    () => ({
      open,
      close,
    }),
    [open, close],
  );
};
