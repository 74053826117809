import localforage from 'localforage';

export const STORAGE_CACHE_VERSION_KEY = 'storage_cache_version';
export const STORAGE_CACHE_VERSION = '3.12.0';

export const cacheStorage = localforage.createInstance({
  name: 'Experience',
  storeName: 'ocx-cache',
  description: 'Stores data to speed up app loading',
});
