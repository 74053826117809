import React from 'react';
import { Box } from '@mui/material';
import navLogo from '@active-configuration/assets/nav_logo.svg';

import { EnvConfiguration } from '../../../../modules/configuration/EnvConfiguration';

export interface IVendorLogoComponentProps {
  onClick?: () => void;
}

export const VendorLogoComponent: React.FC<IVendorLogoComponentProps> = (props) => {
  const { onClick } = props;

  return (
    <Box
      component="img"
      src={navLogo}
      onClick={onClick}
      onKeyDown={onClick}
      alt={EnvConfiguration.marketing.title}
      role="presentation"
      sx={{ maxHeight: 48, cursor: 'pointer' }}
    />
  );
};
