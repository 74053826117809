import * as Sentry from '@sentry/react';
import { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { RandomAwardType, useExecuteSpinWheelGameMutation } from '@ocx/graphql';
import { OfferVisibleStatus, SpinWheelGameOffer } from '../../../../modules/offers/offer.types';
import { logInDev } from '../../../../lib/logger/logger';
import { useShowGenericErrorSnackbar } from '../../../../hooks/useShowGenericErrorSnackbar';
import { useInfoActionSheet } from '../../../../components/modals/InfoActionSheet';
import {
  getSpinWheelGameGenericErrorPopupParams,
  getSpinWheelGamePrizePopupParams,
  mapSegmentToSector,
  showConfetti,
} from '../spin-wheel-game.utils';
import { SpinWheelGameEarningMechanism } from '../../../../modules/offers/earning-mechanism.types';

type UseSpinWheelGameControllerParams = { offer: SpinWheelGameOffer; onLastGameplayEnd(): void };

export const useSpinWheelFreeGameController = (params: UseSpinWheelGameControllerParams) => {
  const { offer, onLastGameplayEnd } = params;
  const {
    id: offerId,
    availableAwardCount,
    visibleStatus,
    earningMechanism: { prizePool },
  } = offer;

  const intl = useIntl();
  const showGenericErrorSnackbar = useShowGenericErrorSnackbar();
  const { open: openInfoActionSheet } = useInfoActionSheet();

  const [isSpinning, setIsSpinning] = useState(false);
  const [winningSectorIndex, setWinningSectorIndex] = useState<number | undefined>();
  const [isActionButtonDisabled, setIsActionButtonDisabled] = useState(false);
  const [gameplayCount, setGameplayCount] = useState(availableAwardCount);
  const isGameLimitReached = [OfferVisibleStatus.MemberLimit, OfferVisibleStatus.GlobalLimit].includes(visibleStatus);

  const [executeSpinWheelGame] = useExecuteSpinWheelGameMutation({
    refetchQueries: ['currentCustomer', 'offer'],
    awaitRefetchQueries: true,
    onCompleted: (data) => {
      setIsSpinning(false);
      if (!data.result) {
        showGenericErrorSnackbar();
        Sentry.captureMessage('ExecuteSpinWheelGameMutation did not return result');
        return;
      }
      setWinningSectorIndex(data.result.sequenceNumber - 1); // NOTE: sequenceNumber starts from 1
    },
    onError: () => {
      setIsSpinning(false);
      setWinningSectorIndex(-1); // reset the wheel to the start position, without any callbacks
      openInfoActionSheet(getSpinWheelGameGenericErrorPopupParams(intl));
    },
  });

  const onPrizePopupClosed = useCallback(() => {
    // Close game when limit reached
    if (isGameLimitReached) {
      onLastGameplayEnd();
    }
  }, [isGameLimitReached, onLastGameplayEnd]);

  const handleSpinningEnd = useCallback(
    (sectorIndex: number) => {
      setIsActionButtonDisabled(false);
      setGameplayCount(availableAwardCount);

      const prize = prizePool[sectorIndex];
      if (!prize) {
        return; // error happened, the wheel stopped with a -1 index, or index is outside the range
      }
      if (![RandomAwardType.NoAward, RandomAwardType.TryAgain].includes(prize.awardType)) {
        showConfetti();
      }

      openInfoActionSheet({
        ...getSpinWheelGamePrizePopupParams(prize, intl),
        onExited: onPrizePopupClosed,
      });
    },
    [availableAwardCount, intl, onPrizePopupClosed, openInfoActionSheet, prizePool],
  );

  const handlePlayClick = useCallback(async () => {
    setIsActionButtonDisabled(true);
    setIsSpinning(true);
    executeSpinWheelGame({ variables: { offerId } }).catch(logInDev);
  }, [executeSpinWheelGame, offerId]);

  return useMemo(
    () => ({
      gameplayCount,
      handlePlayClick,
      handleSpinningEnd,
      isSpinning,
      winningSectorIndex,
      isActionButtonDisabled,
      isGameLimitReached,
    }),
    [
      isGameLimitReached,
      gameplayCount,
      handlePlayClick,
      handleSpinningEnd,
      isActionButtonDisabled,
      isSpinning,
      winningSectorIndex,
    ],
  );
};

export const useSpinWheelGameSectorsHook = (
  prizePool: SpinWheelGameEarningMechanism['prizePool'],
  sectorsConfiguration?: Array<{ icon?: string; color?: string }>,
) => {
  return {
    sectors: prizePool.map((prizePoolItem, index) => mapSegmentToSector(prizePoolItem, sectorsConfiguration?.[index])),
  };
};
