import { defineMessage, defineMessages } from 'react-intl';

export const messages = defineMessages({
  completedProfile: {
    id: 'modalEditProfile:completedProfile:titleText',
    defaultMessage: 'Your Information',
    description: 'Modal edit profile title text for completed profile',
  },
  notCompletedProfile: {
    id: 'modalEditProfile:notCompletedProfile:titleText',
    defaultMessage: 'Complete Profile',
    description: 'Modal edit profile title text for not completed profile',
  },
  emailFieldDescription: {
    id: 'editProfile:emailFieldHintText',
    defaultMessage: 'By entering your email address, you agree to receive occasional promotional emails',
    description: 'Hint text for Email field on the Edit profile modal',
  },
  disclaimerMarkdown: {
    id: 'editProfile:disclaimer:markdown',
    defaultMessage: 'none',
    description: 'Markdown-formatted disclaimer above Submit Button',
  },
  birthdateFieldLabel: {
    id: 'profile:birthdateFieldLabel',
    defaultMessage: 'Your Birthday',
    description: 'Birthdate edit field label',
  },
  birthdateFieldDescription: {
    id: 'profile:birthdayFieldTitle',
    defaultMessage: `We'd love to send you a gift on your special day!`,
    description: 'Message about birthdate field mission',
  },
  termsAcceptanceCheckboxLabel: {
    id: 'profile:termsAcceptanceCheckboxLabel',
    defaultMessage: 'I accept T&C',
    description: 'Markdown-formatted label for T&C acceptance checkbox ',
  },
  explicitMarketingSmsAcceptanceToggleLabel: {
    id: 'profile:explicitMarketingSmsAcceptanceToggleLabel',
    defaultMessage: 'none',
    description: 'SMS communication preference toggle label',
  },
});

export const profileSubmittedMessage = defineMessage({
  id: 'editProfile:profileSubmittedSnackbar:titleText',
  defaultMessage: 'Successfully submitted!',
  description: 'Title text for snackbar which opens after user submits new profile data',
});
