export enum AwardType {
  Points = 'AwardPoints',
  PointsPer = 'AwardPointsPer',
  Vouchers = 'AwardVouchers',
  SweepstakeEntries = 'AwardSweepstakeEntries',
}

export type AwardPoints = {
  type: AwardType.Points;
  points: number;
};

export type AwardPointsPer = {
  type: AwardType.PointsPer;
  points: number;
};

export type AwardVouchers = {
  type: AwardType.Vouchers;
  voucheredOfferId: string;
  voucherExpirationDays: number | null;
  /**
   * How many vouchers membership receives after fulfilment
   */
  voucherCount: number;
  /**
   * How many vouchers membership currently holds
   */
  memberVouchers: number;
};

export type AwardSweepstakeEntries = {
  type: AwardType.SweepstakeEntries;
  entriesOfferId: string;
  entryExpirationDays: number | null;
  /**
   * How many entries membership receives after fulfilment
   */
  entriesCount: number;
  /**
   * How many entries membership currently holds
   */
  memberEntries: number;
};

export type Award = AwardPoints | AwardPointsPer | AwardVouchers | AwardSweepstakeEntries;
