import React from 'react';
import { ButtonBase, styled } from '@mui/material';
import { Typography } from '@ocx/ui';

import { CardIcon } from '../../../../../../components/Icons/CardIcon';
import { SizingMultiplier } from '../../../../../../theme/constants';
import { ArrowRightIcon } from '../../../../../../components/Icons/ArrowRightIcon';

const Button = styled(ButtonBase)(({ theme }) => ({
  backgroundColor: theme.palette.action.selected,
  color: theme.palette.text.primary,
  padding: `12px ${theme.spacing(SizingMultiplier.md)}`,
  width: '100%',
  borderRadius: 8,
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
}));

export type PaymentMethodButtonProps = {
  onClick: () => void;
  startIcon?: React.ReactElement;
  withEndArrow?: boolean;
  children: React.ReactNode;
};

export const PaymentMethodButton: React.FC<PaymentMethodButtonProps> = ({
  onClick,
  startIcon = <CardIcon />,
  withEndArrow = false,
  children,
}) => {
  return (
    <Button onClick={onClick}>
      {startIcon}
      <Typography component="span" mx={SizingMultiplier.sm}>
        {children}
      </Typography>
      {withEndArrow && <ArrowRightIcon sx={{ ml: 'auto', mr: -SizingMultiplier.sm }} />}
    </Button>
  );
};
