import { OFFER_MAX_PUNCH_CARD_REQUIRED_PROGRESS } from '../../modules/offers/offers.constants';
import {
  EarningMechanismWithProgressTypename,
  OfferMemberProgress,
} from '../../modules/offers/earning-mechanism.types';
import { OfferEngagementType } from '../../modules/offers/offer.types';
import { OfferProgressVariant } from './offer-card.types';

interface GetOfferProgressVariantParams {
  earningMechanismTypename: EarningMechanismWithProgressTypename;
  engagementType: OfferEngagementType | null;
  memberProgress: OfferMemberProgress;
}

/**
 * Check if there is a technical reason to show a bar (floats, huge numbers in progress)
 */
const isThereTechnicalReasonToShowBar = ({ required, achieved }: OfferMemberProgress) => {
  if (required > OFFER_MAX_PUNCH_CARD_REQUIRED_PROGRESS) {
    return true;
  }

  if (!Number.isInteger(achieved) || !Number.isInteger(required)) {
    return true;
  }

  return false;
};

/**
 * You can find additional documentation here
 * https://confluence.stuzo.com/pages/viewpage.action?pageId=85691220
 */
export const getOfferProgressVariant = ({
  earningMechanismTypename,
  engagementType,
  memberProgress,
}: GetOfferProgressVariantParams): OfferProgressVariant => {
  if (isThereTechnicalReasonToShowBar(memberProgress)) {
    return 'bar';
  }

  if (earningMechanismTypename === 'EarningMechanismTotalSpendOverTime') {
    return 'bar';
  }

  if (
    earningMechanismTypename === 'EarningMechanismVarietyPurchaseOverTime' &&
    engagementType !== OfferEngagementType.PunchcardChallenge
  ) {
    return 'bar';
  }

  return 'punches';
};
