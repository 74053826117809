import { array, string, tuple, nativeEnum, literal } from 'zod';

import { ConstraintHandlingStrategy, ConstraintType } from './constraints.types';

const RewardOfferIdschema = string();

export const IneligibleIfMembershipHasRewardConstraintSchema = tuple([
  literal(ConstraintType.IneligibleIfMembershipHasReward),
  nativeEnum(ConstraintHandlingStrategy),
  array(RewardOfferIdschema),
]);
