import { useMemo } from 'react';

import { OfferFragment, useOffersQuery } from '@ocx/graphql';
import { mapFragmentsToSortedOffersList } from './offer.dto';
import { OPTIMIZED_VOUCHERED_OFFERS_FILTER } from './offers.constants';
import { useMembership } from '../membership/use-membership.hook';

export const useVoucheredOffers = () => {
  const { membership } = useMembership();
  const { data, loading, refetch } = useOffersQuery({
    fetchPolicy: 'cache-and-network',
    variables: { ...OPTIMIZED_VOUCHERED_OFFERS_FILTER, offerVouchersIncluded: true },
  });

  const offers = useMemo(() => {
    if (!data?.customer?.membership?.offers) {
      return [];
    }

    const offerFragments = data.customer.membership.offers.edges
      .filter((edge): edge is { node: OfferFragment } => !!edge?.node)
      .map(({ node }) => node);

    return mapFragmentsToSortedOffersList({
      offers: offerFragments,
      membershipPoints: membership.points,
      membershipIsCompleted: membership.isCompleted,
    });
  }, [data, membership.isCompleted, membership.points]);

  return { offers, loading, refetch };
};
