import { useCallback, useEffect } from 'react';
import { useModal } from '@ebay/nice-modal-react';

import { SurveyPopupLayout } from './survey-popup.layout';
import { AwardVouchersSurvey, SanitizedSurvey, SurveyRFFFormProps } from '../../../modules/survey/survey.types';
import { getSurveyAward, useGetControlForQuestion } from '../../../modules/survey/survey.utils';
import { SurveyPopupForm } from './survey-popup.form';
import { useSurveyPopupController } from './hooks/use-survey.controller';
import { useConfiguration } from '../../../modules/configuration/useConfiguration';
import { useSurveyAwardPopup } from '../SurveyAwardPopup/survey-award-popup.hook';
import { AwardType } from '../../../modules/offers/award.types';
import { useShowGenericErrorSnackbar } from '../../../hooks/useShowGenericErrorSnackbar';

export interface SurveyPopupControllerProps {
  sanitizedSurvey: SanitizedSurvey;
  onClose(): void;
}

export const SurveyPopupController = ({ sanitizedSurvey, onClose }: SurveyPopupControllerProps) => {
  const { hide, visible, remove } = useModal();

  const showGenericErrorSnackbar = useShowGenericErrorSnackbar();
  const { open: openSurveyAwardPopup } = useSurveyAwardPopup();
  const handleOnAwardClick = useCallback(
    (offer: AwardVouchersSurvey['offer']) => {
      openSurveyAwardPopup({ offer, isEarned: false });
    },
    [openSurveyAwardPopup],
  );

  const handleClose = useCallback(() => {
    hide();
    onClose();
  }, [hide, onClose]);

  const award = getSurveyAward(sanitizedSurvey);
  const handleOnAllQuestionsAnswered = useCallback(() => {
    handleClose();

    if (award?.type === AwardType.Vouchers) {
      openSurveyAwardPopup({ offer: award.offer, isEarned: true });
    }
  }, [award, handleClose, openSurveyAwardPopup]);

  const { initialValues, questions, dismiss, submit, handleAnimationComplete, createSurveyView } =
    useSurveyPopupController({
      onAllQuestionsAnswered: handleOnAllQuestionsAnswered,
      sanitizedSurvey,
      onDismissError: showGenericErrorSnackbar,
    });

  const pointsStrategy = useConfiguration('loyaltyProgram.pointsStrategy');

  const handleSubmit: SurveyRFFFormProps['onSubmit'] = useCallback(
    (values) => submit({ questionUUID: questions[0].uuid, values }),
    [questions, submit],
  );

  const handleCloseAndDismiss = useCallback(() => {
    dismiss();
    handleClose();
  }, [dismiss, handleClose]);

  const control = useGetControlForQuestion({ question: questions[0] });
  const hasControl = !!control;

  useEffect(() => {
    if (!hasControl) {
      return;
    }

    createSurveyView();
  }, [createSurveyView, hasControl]);

  useEffect(() => {
    if (!hasControl) {
      // Close the modal immediately if there is no control for the question
      handleClose();
    }
  }, [hasControl, handleClose]);

  if (!control) {
    // The UI is not implemented yet for this control
    return null;
  }

  return (
    <SurveyPopupLayout isOpen={visible} onClose={handleCloseAndDismiss} onExited={remove}>
      <SurveyPopupForm
        initialValues={initialValues}
        onSubmit={handleSubmit}
        award={award}
        handleClose={handleCloseAndDismiss}
        onAnimationComplete={handleAnimationComplete}
        control={control}
        question={questions[0]}
        pointsStrategy={pointsStrategy}
        onAwardClick={handleOnAwardClick}
      />
    </SurveyPopupLayout>
  );
};
