import React from 'react';
import Payment from 'payment';
import { styled } from '@mui/material';

import { TextField, TextFieldProps } from '../../TextField';
import { CardTypeIcon } from '../../Icons/CardTypeIcon';

const StyledCardTypeIcon = styled(CardTypeIcon)(() => ({
  flexShrink: 0,
}));

export const CardNumberField: React.FC<TextFieldProps> = (props) => {
  /**
   * Attaches Payment to input element. Since we don't need a reference to the `<input />` after the initial mount, use
   * the classic callback-style ref pattern rather than the newer `useRef()` approach.
   *
   * We can probably extract this to a `usePaymentRef()` hook if we use this lib to drive other payment-related fields
   * such as expiry and CVV.
   */
  const attachFormatter = React.useCallback((input?: HTMLInputElement) => {
    if (!input) {
      return;
    }
    Payment.formatCardNumber(input);
  }, []);

  /**
   * Payment exposes card type as a classname on the `<input />` element. That would require a DOM query on every
   * input event, so track it in state instead.
   */
  const [cardType, setCardType] = React.useState<string | null>(null);
  const onInputChange = React.useCallback<React.FormEventHandler<HTMLInputElement>>((event) => {
    setCardType(Payment.fns.cardType(event.currentTarget.value));
  }, []);

  return (
    <TextField
      fullWidth
      type="text"
      autoComplete="on"
      inputProps={{
        inputMode: 'numeric',
        autoComplete: 'on',
        autocompletetype: 'cc-number',
        pattern: '\\d*',
        onChange: onInputChange,
      }}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      InputProps={{
        endAdornment: <StyledCardTypeIcon cardType={cardType} />,
      }}
      fieldProps={{
        format: Payment.fns.formatCardNumber,
        formatOnBlur: true,
      }}
      label="Card Number"
      inputRef={attachFormatter}
      {...props}
    />
  );
};
