import { TypePolicies } from '@apollo/client';

export const awardTypePolicy: TypePolicies = {
  AwardPointsOrVouchers: {
    keyFields: false,
  },
  AwardVouchers: {
    keyFields: false,
  },
  AwardPoints: {
    keyFields: false,
  },
  AwardPointsPer: {
    keyFields: false,
  },
};
