import { buildSharedStorageItemName, sharedStorage } from '../../shared-storage/shared.storage';

const DISMISSED_SURVEYS_STORAGE_KEY = 'dismissed-surveys';

export interface IDismissedSurvey {
  id: string;
  // date is milliseconds elapsed since the epoch (Date.now())
  dismissedAt: number;
}

export const add = async (dismissedSurvey: IDismissedSurvey, membershipId: string): Promise<void> => {
  const allItems: IDismissedSurvey[] = await getAll(membershipId);

  await sharedStorage.setItem(buildSharedStorageItemName(membershipId, DISMISSED_SURVEYS_STORAGE_KEY), [
    ...allItems,
    dismissedSurvey,
  ]);
};

export const removeBatch = async (ids: string[], membershipId: string): Promise<void> => {
  const allItems: IDismissedSurvey[] = await getAll(membershipId);

  await sharedStorage.setItem(
    buildSharedStorageItemName(membershipId, DISMISSED_SURVEYS_STORAGE_KEY),
    allItems.filter((survey) => !ids.includes(survey.id)),
  );
};

export const getAll = async (membershipId: string): Promise<IDismissedSurvey[]> => {
  const allItems = await sharedStorage.getItem<IDismissedSurvey>(
    buildSharedStorageItemName(membershipId, DISMISSED_SURVEYS_STORAGE_KEY),
  );
  return Array.isArray(allItems) ? allItems : [];
};
