import { defineMessages } from 'react-intl';

export const ineligibilityMessages = defineMessages({
  'offer:points-strategy:cents:ineligibility-reason:not-enough-funds': {
    id: 'offer:points-strategy:cents:ineligibility-reason:not-enough-funds',
    defaultMessage: 'Sorry, you do not have enough funds.',
    description:
      'Message to display when user does not have enough points to purchase an offer for points strategy "Cents"',
  },
  'offer:points-strategy:points:ineligibility-reason:not-enough-funds': {
    id: 'offer:points-strategy:points:ineligibility-reason:not-enough-funds',
    defaultMessage: 'Sorry, you do not have enough points.',
    description:
      'Message to display when user does not have enough points to purchase an offer for points strategy "Points"',
  },
  'offer:ineligibility-reason:single-reward-restriction': {
    id: 'offer:ineligibility-reason:single-reward-restriction',
    defaultMessage: 'You may only have one reward at a time. Use or return the one you already have.',
    description:
      'Message to display when user not allowed to purchase new offer before using/returning existing reward',
  },
  'offer:ineligibility-reason:unknown': {
    id: 'offer:ineligibility-reason:unknown',
    defaultMessage: 'Offer is not available.',
    description: 'Message to display when user can not purchase an offer with no reason',
  },
});
