import { useMemo } from 'react';
import { useApolloClient } from '@apollo/client';

import { PAYMENT_PROVIDER_TYPE } from '../../configuration/app-config.types';
import { IPaymentProvider } from '../../../lib/payments/PaymentProvider/payment-provider.types';
import { PaymentProviderFiserv } from '../../../lib/payments/PaymentProvider/providers/Fiserv/PaymentProviderFiserv';
import { PaymentProviderWorldpay } from '../../../lib/payments/PaymentProvider/providers/Worldpay/PaymentProviderWorldpay';
import { useConfiguration } from '../../configuration/useConfiguration';

export const usePaymentProvider = () => {
  const apolloClient = useApolloClient();
  const { config } = useConfiguration();

  return useMemo<IPaymentProvider | null>(() => {
    if (!config.paymentProvider) {
      return null;
    }

    if (config.paymentProvider.type === PAYMENT_PROVIDER_TYPE.FISERV) {
      const googlePayGatewayParameters = config.googlePay
        ? {
            gateway: config.googlePay.gateway,
            gatewayMerchantId: config.googlePay.gatewayMerchantId,
          }
        : null;
      return new PaymentProviderFiserv(apolloClient, googlePayGatewayParameters);
    }

    if (config.paymentProvider.type === PAYMENT_PROVIDER_TYPE.WORLDPAY) {
      return new PaymentProviderWorldpay({
        payPageId: config.paymentProvider.eProtectPayPageId,
        googlePayPageId: config.paymentProvider.eProtectGooglePayPageId,
        sdkApiUrl: config.paymentProvider.eProtectWebSdkApiUrl,
        sdkUrl: config.paymentProvider.eProtectWebSdkUrl,
      });
    }

    return null;
  }, [config.paymentProvider, config.googlePay, apolloClient]);
};
