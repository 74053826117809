import React from 'react';
import { Stack, Box } from '@mui/material';
import { Typography } from '@ocx/ui';

import {
  DynamicCard,
  DynamicCardAccessory,
  DynamicCardContent,
  DynamicCardMedia,
  DynamicCardSlot,
} from '../DynamicCard';
import { OfferExpirationChip, OfferLabel, OfferProgress } from './components';
import { SurgeonWarning } from '../SurgeonWarning/SurgeonWarning';
import { OfferTitle } from './components/OfferTitle';
import { OfferCardProps } from './offer-card.types';
import { SizingMultiplier } from '../../theme/constants';
import { PointsChange } from '../PointsChange/PointsChange';

export const OfferCard = React.forwardRef<HTMLDivElement, OfferCardProps>((props: OfferCardProps, ref) => {
  const {
    variant = 'carousel',
    loading = false,
    disabled,
    expiresIn,
    rewardExpiresIn,
    marketingContent: { title, shortDescription, imageSrc, surgeonGeneralsWarning },
    label,
    pointsDiff,
    progress,
    onClick,
    accessoryType = 'none',
    onAccessoryButtonClick,
    accessoryButtonDisabled,
    pointsStrategy,
  } = props;
  return (
    <DynamicCard
      ref={ref}
      loading={loading}
      disabled={disabled}
      onClick={onClick}
      sx={{
        border: variant === 'sheet' ? (theme) => `1px ${theme.palette.divider} solid` : undefined,
      }}>
      {surgeonGeneralsWarning && (
        <Box
          sx={(theme) => ({
            m:
              variant !== 'banner'
                ? theme.spacing(SizingMultiplier.md, SizingMultiplier.md, -SizingMultiplier.sm)
                : SizingMultiplier.sm,
          })}>
          <SurgeonWarning message={surgeonGeneralsWarning} square={variant === 'banner'} />
        </Box>
      )}
      <Box position="relative">
        {variant === 'banner' && imageSrc && <DynamicCardMedia src={imageSrc} alt={title} variant="banner" />}
        {variant === 'banner' && expiresIn && (
          <DynamicCardSlot positionHorizontal="right">
            <OfferExpirationChip time={expiresIn} />
          </DynamicCardSlot>
        )}
        <DynamicCardContent>
          {variant !== 'banner' && imageSrc && <DynamicCardMedia src={imageSrc} alt={title} />}
          <Stack flex={1} alignItems="flex-start" justifyContent="center" spacing={SizingMultiplier.xs}>
            {variant !== 'banner' && label && <OfferLabel label={label} pointsStrategy={pointsStrategy} />}
            <OfferTitle variant={variant}>{title}</OfferTitle>
            {shortDescription && variant !== 'carousel' && (
              <Typography variant="caption" component="p" color="textSecondary">
                {shortDescription}
              </Typography>
            )}
            {progress?.canShowMemberProgress && (
              <OfferProgress progress={progress.memberProgress} variant={progress.progressVariant} />
            )}
            {pointsDiff && <PointsChange pointsStrategy={pointsDiff.pointsStrategy} points={pointsDiff.diff} />}
            {variant === 'banner' && label && <OfferLabel label={label} pointsStrategy={pointsStrategy} />}
            {variant === 'carousel' && rewardExpiresIn && (
              <Typography variant="body2" component="span" color="text.secondary">
                {rewardExpiresIn}
              </Typography>
            )}
          </Stack>
          <DynamicCardAccessory
            type={accessoryType}
            onClick={onAccessoryButtonClick}
            disabled={accessoryButtonDisabled}
          />
        </DynamicCardContent>
      </Box>
    </DynamicCard>
  );
});
