import React from 'react';
import { ButtonBase, Stack, styled } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@ocx/ui';

import { PointsStrategy } from '@ocx/graphql';
import { SizingMultiplier } from '../../../../theme/constants';
import { PointsChange } from '../../../../components/PointsChange/PointsChange';
import { messages } from '../../messages';

const ButtonStyled = styled(ButtonBase)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.main,
  minWidth: 212,
  height: 64,
  padding: theme.spacing(SizingMultiplier.sm, SizingMultiplier.lg),
  borderRadius: 56,
  display: 'flex',
  justifyContent: 'space-between',
  textAlign: 'left',
}));

export interface IBuyAllEntriesButton {
  entries: number;
  price: number;
  onClick: () => void;
  pointsStrategy: Exclude<PointsStrategy, PointsStrategy.NotUsed>;
}

export const BuyAllEntriesButton: React.FC<IBuyAllEntriesButton> = (props) => {
  const { entries, price, onClick, pointsStrategy } = props;
  return (
    <ButtonStyled onClick={onClick}>
      <Stack mr={SizingMultiplier.sm}>
        <Typography variant="button" noWrap>
          <FormattedMessage {...messages.buyAllEntriesButtonText} />
        </Typography>
        <Typography variant="caption" gutterBottom>
          <FormattedMessage {...messages.entriesAmount} values={{ entries }} />
        </Typography>
      </Stack>
      <PointsChange points={-price} position="right" pointsStrategy={pointsStrategy} />
    </ButtonStyled>
  );
};
