import React from 'react';

import { Amex } from './CardTypeIcon/Amex';
import { Discover } from './CardTypeIcon/Discover';
import { Mastercard } from './CardTypeIcon/Mastercard';
import { Prepaid } from './CardTypeIcon/Prepaid';
import { Visa } from './CardTypeIcon/Visa';

export const TYPE_ICON_COMPONENTS: Record<string, React.ComponentType<React.SVGProps<SVGSVGElement>>> = {
  amex: Amex,
  discover: Discover,
  mastercard: Mastercard,
  visa: Visa,
  prepaid: Prepaid,
};

export interface ICardTypeIconProps extends React.SVGProps<SVGSVGElement> {
  cardType: string | null;
}

export const CardTypeIcon: React.FC<ICardTypeIconProps> = ({ cardType, ...props }) => {
  const IconComponent = cardType ? TYPE_ICON_COMPONENTS[cardType] : undefined;

  return IconComponent ? <IconComponent {...props} /> : null;
};
