import * as Sentry from '@sentry/react';

export type AnyFunction = (...args: any[]) => any;

export function withTryCatch<T extends AnyFunction>(func: T): (...funcArgs: Parameters<T>) => ReturnType<T> | void {
  return (...args: Parameters<T>): ReturnType<T> | void => {
    try {
      return func(...args);
    } catch (error) {
      Sentry.captureException(error);
      return undefined;
    }
  };
}
