import { useCallback, useMemo, useState } from 'react';

export interface ListSelector<ListItemType> {
  listItems: ListItemType[];
  selectedListItem: ListItemType | null;
  handleSelect: (listItem: ListItemType | null) => void;
}

type Params<ListItemType> = { listItems: ListItemType[]; onSelect?: (listItem: ListItemType | null) => void };

/**
 * "useListController" - hook provides methods and fields which cover default "select from list" functionality
 * such as: payment instruments select, car was select, etc.
 * "useListController" is not depends on any UI implementation and can be used everywhere.
 * "useListController" implements basic features: get list's items, get selected item, handle select item
 * @param params
 */
export const useListController = <ListItemType>(params: Params<ListItemType>): ListSelector<ListItemType> => {
  const { listItems, onSelect } = params;
  const [selectedListItem, setSelectedListItem] = useState<ListItemType | null>(null);
  const handleSelect = useCallback(
    (listItem: ListItemType | null) => {
      setSelectedListItem(listItem);
      onSelect?.(listItem);
    },
    [onSelect],
  );

  return useMemo(() => ({ selectedListItem, handleSelect, listItems }), [handleSelect, listItems, selectedListItem]);
};
