import { useEffect, useRef, useState } from 'react';
import { ApolloError, NetworkStatus } from '@apollo/client';

import {
  PrimaryTransactionStatus,
  SecondaryTransactionStatus,
  TransactionFragment,
  useGetTransactionQuery,
} from '@ocx/graphql';
import { isPumpAuthorized } from './utils';
import { useConfiguration } from '../../configuration/useConfiguration';

const defaultTransactionStatusTimeoutThreshold = 3 * 60 * 1000; // 3 mins

const transactionStatusTimeoutThresholdOverrides: { [key in SecondaryTransactionStatus]?: number } = {
  [SecondaryTransactionStatus.Fueling]: 5 * 60 * 1000, // 5 mins
};

const getTimeoutThresholdBySecondaryStatus = (secondaryStatus: SecondaryTransactionStatus): number => {
  return transactionStatusTimeoutThresholdOverrides[secondaryStatus] || defaultTransactionStatusTimeoutThreshold;
};

const getIsTransactionStatusTimedOut = (params: {
  secondaryStatus: SecondaryTransactionStatus | null;
  timestamp: Date | null;
}): boolean => {
  const { secondaryStatus, timestamp } = params;
  if (!secondaryStatus || !timestamp) {
    return false;
  }
  const now = new Date();
  return now.getTime() - timestamp.getTime() > getTimeoutThresholdBySecondaryStatus(secondaryStatus);
};

export interface IUseWatchTransactionReturns {
  transaction: TransactionFragment | null;
  loading: boolean;
  isTransactionStatusTimedOut: boolean;
}

export interface IUseWatchTransactionParams {
  transactionId: string | null;
  onError?(error?: ApolloError): void;
  onTransactionCompleted?(): void;
  onTransactionFailed?(): void;
  onPumpAuthorized?(): void;
}

/**
 * Fetch&Polling transaction with callbacks for main events: error, completed, failed
 * If no "transactionId" passed - ignoring and skipping  polling/fetching
 */
export const useWatchTransaction = (params: IUseWatchTransactionParams): IUseWatchTransactionReturns => {
  const { config } = useConfiguration();
  const { transactionId, onError, onTransactionCompleted, onTransactionFailed, onPumpAuthorized } = params;
  const errorsCount = useRef(0);
  const isPumpAuthorizedTriggered = useRef(false);

  const statusTimeoutState = useRef<{
    secondaryStatus: SecondaryTransactionStatus | null;
    timestamp: Date | null;
  }>({ secondaryStatus: null, timestamp: null });
  const [isTransactionStatusTimedOut, setIsTransactionStatusTimedOut] = useState(false);

  const { data, loading, stopPolling, error, networkStatus } = useGetTransactionQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    pollInterval: config.transaction.pollInterval,
    skip: !transactionId,
    variables: {
      uuid: transactionId ?? '',
    },
    onCompleted: (data) => {
      if (!data.transaction) {
        return;
      }
      const { primaryStatus, secondaryStatus } = data.transaction;
      if (statusTimeoutState.current.secondaryStatus !== secondaryStatus) {
        statusTimeoutState.current.secondaryStatus = secondaryStatus;
        statusTimeoutState.current.timestamp = new Date();
      }

      const isStatusTimeout = getIsTransactionStatusTimedOut({
        secondaryStatus: statusTimeoutState.current.secondaryStatus,
        timestamp: statusTimeoutState.current.timestamp,
      });

      if (primaryStatus === PrimaryTransactionStatus.Active && isStatusTimeout) {
        setIsTransactionStatusTimedOut(true);
      }
    },
  });

  useEffect(() => {
    if (isTransactionStatusTimedOut) {
      stopPolling();
    }
  }, [isTransactionStatusTimedOut, stopPolling]);

  const { primaryStatus = null, secondaryStatus = null } = data?.transaction ?? {};

  // Handle "Error" status and count
  useEffect(() => {
    // Handle only "Error" status
    // Prevent error counter incrementing on secondary states
    if (networkStatus !== NetworkStatus.error) {
      return;
    }

    errorsCount.current += 1;
    if (errorsCount.current > config.transaction.errorLimit) {
      stopPolling();
      onError?.(error);
    }
  }, [config.transaction.errorLimit, error, networkStatus, onError, stopPolling]);

  useEffect(() => {
    // Apollo Option "notifyOnNetworkStatusChange" enable rendering on each network status
    // Handle only "Success/Loaded" status (without "Error" status as well)
    if (networkStatus !== NetworkStatus.ready) {
      return;
    }

    // Reset Error Counter on success data loading
    errorsCount.current = 0;

    if (primaryStatus === null) {
      return;
    }

    if (!isPumpAuthorizedTriggered.current && isPumpAuthorized(primaryStatus, secondaryStatus)) {
      isPumpAuthorizedTriggered.current = true;
      onPumpAuthorized?.();
    }

    if (primaryStatus === PrimaryTransactionStatus.Completed) {
      stopPolling();
      onTransactionCompleted?.();
      return;
    }

    if (primaryStatus === PrimaryTransactionStatus.Failed) {
      stopPolling();
      onTransactionFailed?.();
    }
  }, [
    networkStatus,
    onPumpAuthorized,
    onTransactionCompleted,
    onTransactionFailed,
    primaryStatus,
    secondaryStatus,
    stopPolling,
  ]);

  return {
    loading,
    isTransactionStatusTimedOut,
    transaction: data?.transaction ?? null,
  };
};
