import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  'select-otp-channel-action-sheet.title': {
    id: 'select-otp-channel-action-sheet.title',
    defaultMessage: 'Login via code',
    description: '',
  },
  'select-otp-channel-action-sheet.description': {
    id: 'select-otp-channel-action-sheet.description',
    defaultMessage: 'How would you like to receive the code?',
    description: '',
  },
  'select-otp-channel-action-sheet.channel-button-text.sms': {
    id: 'select-otp-channel-action-sheet.channel-button-text.sms',
    defaultMessage: 'Get code via SMS',
    description: '',
  },
  'select-otp-channel-action-sheet.channel-button-text.phone-call': {
    id: 'select-otp-channel-action-sheet.channel-button-text.phone-call',
    defaultMessage: 'Get code via call',
    description: '',
  },
  'select-otp-channel-action-sheet.channel-button-text.email': {
    id: 'select-otp-channel-action-sheet.channel-button-text.email',
    defaultMessage: 'Get code via email',
    description: '',
  },
});
