import { useEffect, useMemo } from 'react';
import { ApolloQueryResult } from '@apollo/client';
import { Capacitor } from '@capacitor/core';
import { captureException } from '@sentry/react';

import { useInMemoryStorage } from '@ocx-app/lib/in-memory-storage/use-in-memory-storage';
import { IM_MEMORY_PIN_CODE_STORAGE_KEY } from '@ocx-app/modules/wallet/wallet.constants';
import {
  CurrentCustomerQuery,
  MEMBERSHIP_STATE,
  useCurrentCustomerQuery,
  useCustomerMakeActiveMutation,
} from '@ocx/graphql';
import { useLogoutController } from '../auth/useLogoutController';
import { sentrySetUser } from '../sentry/sentry.utils';
import { logInDev } from '../../lib/logger/logger';
import { Membership } from './membership.types';
import { getMembershipFromCurrentCustomer } from './membership.dto';
import { useConfigurationContext } from '../configuration/useConfigurationContext';
import { getSanitizedCustomer } from './current-customer.dto';
import { useRudderStack } from '../../lib/rudderStack/useRudderStack';
import { EnvConfiguration } from '../configuration/EnvConfiguration';

export type UseGetMembershipQueryReturns = {
  loading: boolean;
  membership: Membership | null;
  refetch(): Promise<ApolloQueryResult<CurrentCustomerQuery>>;
};

export const useGetMembershipQuery = (): UseGetMembershipQueryReturns => {
  const { isLoggedIn, logout } = useLogoutController();
  const { identify } = useRudderStack();

  const {
    data,
    loading: isMembershipLoading,
    refetch,
  } = useCurrentCustomerQuery({
    fetchPolicy: 'cache-and-network',
    onError: captureException,
    skip: !isLoggedIn,
  });

  const membership = useMemo(() => {
    if (!data?.customer) {
      return null;
    }
    const customer = getSanitizedCustomer(data);
    if (!customer) {
      return null;
    }
    return getMembershipFromCurrentCustomer(customer);
    // app is really interested only in customer data
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.customer]);

  // - Each effect here is responsible for own small responsibility -
  // Suspended Member Fraud Prevention
  useEffect(() => {
    if (membership?.state === MEMBERSHIP_STATE.Suspended) {
      logout().catch(() => {
        // I know it's suboptimal, but the code requires proper refactoring to handle it correctly
        logout({
          skipMutation: true,
        }).catch(logInDev);
      });
    }
  }, [logout, membership?.state]);

  const { setMembershipOverrides } = useConfigurationContext();
  useEffect(() => {
    // Overrides global configuration by values from membership
    setMembershipOverrides(membership?.customData?.configOverrides || null);
  }, [membership?.customData?.configOverrides, setMembershipOverrides]);

  useEffect(() => {
    if (!membership?.id) {
      // No sense to remove context from Sentry and GA
      // If customer logs out and log in under other account - we are covered
      return;
    }
    sentrySetUser({
      id: membership.customerId,
      membership_id: membership.id,
      profile_completed: membership.isCompleted,
      activation_state: membership.state,
    });
  }, [membership?.customerId, membership?.id, membership?.isCompleted, membership?.state]);

  useEffect(() => {
    if (!membership?.customerId) {
      return;
    }

    identify(membership.customerId, {
      customerUuid: membership.customerId,
      membershipId: membership.id,
      points: membership.points,
      memberState: membership.state,
      platform: Capacitor.getPlatform(),
      loyaltyBrand: EnvConfiguration.retailer,
      homeStore: membership.homeStores.length > 0 ? membership.homeStores[0] : '',
    });
  }, [membership?.customerId, membership?.id, membership?.points, membership?.state, membership?.homeStores, identify]);

  const [activateCustomer, { loading: isCustomerActivating }] = useCustomerMakeActiveMutation({
    refetchQueries: ['offers'],
  });
  useEffect(() => {
    if (membership?.state === MEMBERSHIP_STATE.NewMember) {
      activateCustomer().catch(logInDev);
    }
  }, [activateCustomer, membership?.state]);

  // Remove cached (in memory) wallet pin code if user does not have saved pin code
  // examples: all payment method removed, some internal issue and pin code did reset
  const { eraseStorageValue: eraseCachedPinCode, value: cachedPinCode } =
    useInMemoryStorage<string>(IM_MEMORY_PIN_CODE_STORAGE_KEY);
  useEffect(() => {
    if (!membership?.hasPasscode && cachedPinCode) {
      eraseCachedPinCode();
    }
  }, [cachedPinCode, eraseCachedPinCode, membership?.hasPasscode]);

  return {
    loading: isMembershipLoading || isCustomerActivating,
    membership,
    refetch,
  };
};
