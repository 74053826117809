import { useCallback } from 'react';

import { IStoreCarWashOption } from '../../../../modules/stores/types';
import { useRudderStack } from '../../../../lib/rudderStack/useRudderStack';
import { useListController } from '../../../../hooks/useListController';

export const useCarWashController = ({ rawCarWashOptions }: { rawCarWashOptions: IStoreCarWashOption[] }) => {
  const { triggerEvent } = useRudderStack();

  const onSelectCarWash = useCallback(() => {
    triggerEvent('pay_at_pump_carwash');
  }, [triggerEvent]);

  const carWashListController = useListController<IStoreCarWashOption>({
    listItems: rawCarWashOptions,
    onSelect: onSelectCarWash,
  });

  return { carWashListController };
};
