import { string } from 'zod';

import { DynamicCardCustomData } from './custom-data.types';
import { isCustomDataWithAction } from '../offer.type-guards';
import { ActionType } from './actions/types';

const ExternalUrlSchema = string().url();

export const getExternalUrlFromCustomData = (customData: DynamicCardCustomData | null): string | null => {
  if (
    !isCustomDataWithAction(customData) ||
    customData.action.type !== ActionType.NavigateTo ||
    customData.action.payload.openURLInWebView ||
    !ExternalUrlSchema.safeParse(customData.action.payload.url).success
  ) {
    return null;
  }

  return customData.action.payload.url;
};
