import React from 'react';
import { Box, CircularProgress, Backdrop, alpha, styled } from '@mui/material';

export const ScreenLoader: React.FC = () => {
  return (
    <Box textAlign="center" position="sticky" top="40vh">
      <CircularProgress />
    </Box>
  );
};

const BackdropStyled = styled(Backdrop)(({ theme }) => ({
  zIndex: theme.zIndex.appBar - 1,
  backgroundColor: alpha(theme.palette.text.disabled, 0.8),
}));

export interface IScreenLoaderBackdropProps {
  open: boolean;
}

export const ScreenLoaderBackdrop: React.FC<IScreenLoaderBackdropProps> = (props) => {
  const { open } = props;

  return (
    <BackdropStyled data-testid="blocking-screen-loader" open={open} sx={{ display: 'block' }}>
      <ScreenLoader />
    </BackdropStyled>
  );
};
