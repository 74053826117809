import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { ActivateOfferMutation, PointsStrategy } from '@ocx/graphql';
import { useShowSnackbarBarForApollo } from '../../../hooks/useShowSnackbarBarForApollo';
import { isErrorNotEnoughPointsError, useRedeemOffer } from './useRedeemOffer';
import { useActivateOffer } from './useActivateOffer';
import { useSnackbar } from '../../../lib/snackbar/snackbar';
import { messages } from '../messages';
import { Offer, OfferActivationStatus } from '../../../modules/offers/offer.types';
import { IneligibilityHandlingStrategy } from '../../../modules/offers/eligibility.types';
import { useShowExplanationSnackbarForIneligibleOffer } from './useShowExplanationSnackbarForIneligibleOffer';
import { ineligibilityMessages } from './ineligibility.messages';
import { isPurchaseVoucherOffer } from '../../../modules/offers/offer.type-guards';
import { useConfiguration } from '../../../modules/configuration/useConfiguration';

export const useRedeemOfferHelpers = ({ onCompleted }: { onCompleted?: () => unknown }) => {
  const intl = useIntl();
  const snackbar = useSnackbar();
  const showApolloErrorSnackbar = useShowSnackbarBarForApollo();
  const pointsStrategy = useConfiguration('loyaltyProgram.pointsStrategy');

  const onError = useCallback(
    (error: unknown) => {
      if (isErrorNotEnoughPointsError(error) && pointsStrategy === PointsStrategy.Points) {
        snackbar.open(
          intl.formatMessage(
            ineligibilityMessages['offer:points-strategy:points:ineligibility-reason:not-enough-funds'],
          ),
        );
        return;
      }
      if (isErrorNotEnoughPointsError(error) && pointsStrategy === PointsStrategy.Cents) {
        snackbar.open(
          intl.formatMessage(
            ineligibilityMessages['offer:points-strategy:cents:ineligibility-reason:not-enough-funds'],
          ),
        );
        return;
      }
      showApolloErrorSnackbar(error);
    },
    [intl, pointsStrategy, showApolloErrorSnackbar, snackbar],
  );

  return useRedeemOffer({
    onCompleted,
    onError,
  });
};

type UseActivateOfferHelpersParams = {
  onCompleted?: (data: ActivateOfferMutation) => unknown;
  awaitRefetchQueries?: boolean;
};

export const useActivateOfferHelpers = (params?: UseActivateOfferHelpersParams) => {
  const intl = useIntl();
  const snackbar = useSnackbar();

  const showActivateOfferError = useCallback(() => {
    snackbar.open(intl.formatMessage(messages['home:offerActivationFailedErrorMessageText']));
  }, [intl, snackbar]);

  return useActivateOffer({
    onCompleted: params?.onCompleted,
    onError: showActivateOfferError,
    awaitRefetchQueries: params?.awaitRefetchQueries,
  });
};

export type OfferLoyaltyActionOnClick = () => unknown;
export type OfferLoyaltyActionTitle = 'offers:activateButtonTitle' | 'offers:redeemButtonTitle';
export type OfferLoyaltyAction = [OfferLoyaltyActionOnClick, boolean, OfferLoyaltyActionTitle];
export type GetOfferLoyaltyAction = (offer: Offer) => OfferLoyaltyAction | [];

export type UseGetOfferOnLoyaltyActionClick = (params: {
  membershipIsCompleted: boolean;
  onCompleted?: () => unknown;
}) => GetOfferLoyaltyAction;
export const useGetOfferLoyaltyAction: UseGetOfferOnLoyaltyActionClick = ({ membershipIsCompleted, onCompleted }) => {
  const { activate, activating } = useActivateOfferHelpers({ onCompleted });
  const { redeem, redeeming } = useRedeemOfferHelpers({ onCompleted });

  const showIneligibilityExplanationSnackbar = useShowExplanationSnackbarForIneligibleOffer();

  return useCallback(
    (offer: Offer) => {
      let actionType: OfferLoyaltyActionTitle | null = null;

      if (isPurchaseVoucherOffer(offer)) {
        actionType = 'offers:redeemButtonTitle';
      }
      if (offer.activationStatus === OfferActivationStatus.RequiresActivation) {
        actionType = 'offers:activateButtonTitle';
      }

      if (!actionType) {
        return [];
      }

      const [isEligible, ineligibilityHandlingStrategy, ineligibilityReasons] = offer.eligible;
      if (!isEligible && ineligibilityHandlingStrategy === IneligibilityHandlingStrategy.DisableLoyaltyAction) {
        return [() => showIneligibilityExplanationSnackbar(ineligibilityReasons[0]), false, actionType];
      }

      if (offer.activationStatus === OfferActivationStatus.RequiresActivation) {
        return [
          () => {
            activate(offer);
          },
          activating,
          'offers:activateButtonTitle',
        ];
      }

      if (isPurchaseVoucherOffer(offer)) {
        return [
          () => {
            redeem(offer, { membershipIsCompleted });
          },
          redeeming,
          'offers:redeemButtonTitle',
        ];
      }

      return [];
    },
    [activate, activating, showIneligibilityExplanationSnackbar, membershipIsCompleted, redeem, redeeming],
  );
};
