import { string } from 'zod';

import { isOnlyDigits } from '../../lib/test-utils/validations';

export const DEFAULT_PIN_SIZE = 4;

export const getPinValidation = (size = DEFAULT_PIN_SIZE) => {
  return string()
    .length(size)
    .refine((value) => isOnlyDigits(value));
};

export const getInputPinValidation = (size = DEFAULT_PIN_SIZE) => {
  return string()
    .max(size)
    .refine((value) => isOnlyDigits(value, true));
};
