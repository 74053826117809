import { StrictMode } from 'react';
import { Container, createRoot } from 'react-dom/client';

import App from './App';
import { sentryInit } from './modules/sentry/sentry-init';

const container = document.getElementById('root') as Container;
const root = createRoot(container);
sentryInit();
root.render(
  <StrictMode>
    <App />
  </StrictMode>,
);
