import { useIntl } from 'react-intl';
import { isApolloError } from '@apollo/client';

import { formatApolloError, hasGraphQLErrorCode } from '@ocx/apollo-client';
import { useSnackbar } from '../../lib/snackbar/snackbar';
import { UpdateProfileErrorHandler } from './types';
import { genericMessages } from '../../modules/localization/genericMessages';

export const useHandleUpdateProfileError = () => {
  const intl = useIntl();
  const snackbar = useSnackbar();
  const handleError: UpdateProfileErrorHandler = (error) => {
    if (!(error instanceof Error) || !isApolloError(error)) {
      snackbar.open(
        intl.formatMessage(genericMessages['generic:errorMessageText'], {
          variant: 'error',
        }),
      );
      return null;
    }
    const message = formatApolloError(error, {
      ignoreNetworkError: true,
      ignoreGraphqlError: true,
    });
    snackbar.open(message, {
      variant: 'error',
    });
    // WARN: it can be reformatted to a nice switch-case if needed
    if (hasGraphQLErrorCode(error, 'OC_DUPLICATE_MEMBERSHIP_EMAIL_ERROR')) {
      return {
        email: message,
      };
    }
    return null;
  };
  return handleError;
};
