import React from 'react';

import { StoreLocatorButtonComponent } from './StoreLocatorButtonComponent';
import { useGetNavigationActions } from '../../../../hooks/useGetNavigationActions';

export const StoreLocatorButton: React.FC = () => {
  const { pushToStoreLocator } = useGetNavigationActions();

  return <StoreLocatorButtonComponent onClick={pushToStoreLocator} />;
};
