import { Button, Stack } from '@mui/material';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useModal } from '@ebay/nice-modal-react';
import { Typography } from '@ocx/ui';

import { ModalDrawer, CompleteDecoration } from '../components';
import { EIAVPromotionSection } from './components';
import { useConfiguration } from '../../../modules/configuration/useConfiguration';
import { useGetNavigationActions } from '../../../hooks/useGetNavigationActions';
import { SizingMultiplier } from '../../../theme/constants';
import { messages } from './profile-completed-popup.messages';
import { buttonTextMessages } from '../../../modules/localization/button-text.messages';

export const ProfileCompletedPopupController: React.FC = (props) => {
  const { visible, hide, remove } = useModal();
  const { isEAIVPromotionOnProfileCompletedEnabled } = useConfiguration('productInterests');
  const { pushToProductInterests } = useGetNavigationActions();

  const handleNavigateToProductInterests = useCallback(() => {
    pushToProductInterests();
    hide();
  }, [hide, pushToProductInterests]);

  return (
    <ModalDrawer open={visible} onClose={hide} onExited={remove}>
      <CompleteDecoration />
      {/* Decorator offset */}
      <div style={{ height: 65 }} />

      <Stack p={SizingMultiplier.lg} pt={SizingMultiplier.xl} spacing={SizingMultiplier.md}>
        <Typography textAlign="center" variant="h5">
          <FormattedMessage {...messages['profile-completed-action-sheet:complete-title']} />
        </Typography>
        {isEAIVPromotionOnProfileCompletedEnabled && (
          <EIAVPromotionSection onClick={handleNavigateToProductInterests} />
        )}
        <Button variant="text" onClick={hide} fullWidth>
          <FormattedMessage {...buttonTextMessages['button-text:close']} />
        </Button>
      </Stack>
    </ModalDrawer>
  );
};

export default ProfileCompletedPopupController;
