import { Coordinates } from '../../../modules/stores/types';

const deg2rad = (deg: any) => {
  return deg * (Math.PI / 180);
};

export const calculateDistance = (from: Coordinates, to: Coordinates, units: 'km' | 'mi' = 'mi'): number => {
  const R = units === 'mi' ? 3968.8 : 6371;
  const dLat = deg2rad(to.lat - from.lat); // deg2rad below
  const dLon = deg2rad(to.lng - from.lng);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(from.lat)) * Math.cos(deg2rad(to.lat)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c;
};
