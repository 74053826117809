import { useCallback } from 'react';
import { useReactiveVar } from '@apollo/client';

import { AuthMethod, OtpChannel } from '@ocx/graphql';
import { getDevicePlatform } from '../../lib/device/utils/info';
import { Event } from '../../lib/rudderStack/rudderStack.types';
import { useRudderStack } from '../../lib/rudderStack/useRudderStack';
import { isLoggedInVar } from './authReactiveVariables';

const platform = getDevicePlatform();

type Params = { method: AuthMethod.MagicLink } | { method: AuthMethod.Otp; otpChannel: OtpChannel };

const getEventAuthMethodPrefix = (params: Params): 'magic_link' | 'otp_sms' | 'otp_phone_call' | 'otp_email' => {
  if (params.method === AuthMethod.MagicLink) {
    return 'magic_link';
  }
  if (params.method === AuthMethod.Otp && params.otpChannel === OtpChannel.Sms) {
    return 'otp_sms';
  }
  if (params.method === AuthMethod.Otp && params.otpChannel === OtpChannel.PhoneCall) {
    return 'otp_phone_call';
  }
  if (params.method === AuthMethod.Otp && params.otpChannel === OtpChannel.Email) {
    return 'otp_email';
  }

  // TODO add comment
  return 'unknown_auth_method' as any;
};
const getSignupRequestedEvent = (params: Params): Event => {
  return [`${getEventAuthMethodPrefix(params)}_requested_${platform}`];
};
const getSignupRequestFailedEvent = (params: Params): Event => {
  return [`${getEventAuthMethodPrefix(params)}_request_failed_${platform}`];
};
const getSignupSessionCreatedEvent = (params: Params): Event => {
  return [`${getEventAuthMethodPrefix(params)}_session_created_${platform}`];
};
const getSignupSessionReplacedEvent = (params: Params): Event => {
  return [`${getEventAuthMethodPrefix(params)}_session_replaced_${platform}`];
};
const getMagicLinkInvalidUnauthorizedEvent = (): Event => {
  return [`magic_link_invalid_unauthorized_${platform}`];
};
const getMagicLinkInvalidAuthorizedEvent = (): Event => {
  return [`magic_link_invalid_authorized_${platform}`];
};

export const useSignupEvents = () => {
  const { triggerEvent } = useRudderStack();
  const isLoggedIn = useReactiveVar(isLoggedInVar);

  const triggerSignupRequested = useCallback(
    (params: Params, isSuccess: boolean) => {
      const event = isSuccess ? getSignupRequestedEvent(params) : getSignupRequestFailedEvent(params);
      triggerEvent(...event);
    },
    [triggerEvent],
  );

  const triggerSignupSessionCreated = useCallback(
    (params: Params) => {
      const event = isLoggedIn ? getSignupSessionReplacedEvent(params) : getSignupSessionCreatedEvent(params);
      triggerEvent(...event);
    },
    [isLoggedIn, triggerEvent],
  );

  const triggerMagicLinkInvalid = useCallback(() => {
    const event = isLoggedIn ? getMagicLinkInvalidAuthorizedEvent() : getMagicLinkInvalidUnauthorizedEvent();
    triggerEvent(...event);
  }, [isLoggedIn, triggerEvent]);

  return {
    triggerSignupRequested,
    triggerSignupSessionCreated,
    triggerMagicLinkInvalid,
  };
};
