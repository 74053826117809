import { ServerError, isApolloError } from '@apollo/client';
import * as Sentry from '@sentry/react';

import { ErrorLink } from '@apollo/client/link/error';
import { GLOBAL_ERRORS } from './global-error-handler.constants';
import { GlobalErrorCode } from './global-error-handler.types';
import { globalErrorHandler } from './global-error-handler';

export const isErrorHandledGlobally = (error: unknown) => {
  if (!(error instanceof Error)) {
    return false;
  }

  if (isApolloError(error)) {
    return !!error.graphQLErrors.find((gqlError) => GLOBAL_ERRORS.includes(gqlError.extensions?.code));
  }

  return false;
};

export const isGlobalErrorCode = (errorCode: string): errorCode is GlobalErrorCode => {
  return GLOBAL_ERRORS.includes(errorCode as GlobalErrorCode);
};

export const handleGlobalError: ErrorLink.ErrorHandler = ({ graphQLErrors, networkError }) => {
  if ((networkError as ServerError)?.statusCode && (networkError as ServerError).statusCode !== 200) {
    Sentry.captureException(networkError);
  }

  graphQLErrors?.forEach(({ extensions }) => {
    const code = extensions?.code;
    const exceptionCode = extensions?.exception?.code;
    if (isGlobalErrorCode(code)) {
      globalErrorHandler[code]();
    }
    if (isGlobalErrorCode(exceptionCode)) {
      globalErrorHandler[exceptionCode]();
    }
  });
};
