import React, { useMemo, useRef, useEffect, useCallback, MutableRefObject } from 'react';
import { ButtonBase, styled } from '@mui/material';
import min from 'lodash/min';
import { Typography } from '@ocx/ui';

import { PumpIcon } from '../../../../../components/Icons/PumpIcon';

const BUTTON_SIZE = 64;
const BUTTON_MARGIN = 3;
const SCROLL_START_OFFSET = 10; // To prevent IOS scroll behavior on scroll end

const PumpButtonComponent = styled(ButtonBase)(({ theme }) => ({
  width: BUTTON_SIZE,
  height: BUTTON_SIZE,
  margin: `0 ${BUTTON_MARGIN}px`,
  backgroundColor: theme.palette.action.selected,
  color: theme.palette.text.primary,
  borderRadius: 8,
  flexShrink: 0,
}));

const ScrollComponent = styled('div', { shouldForwardProp: (propName) => propName !== 'isInfinite' })<{
  isInfinite: boolean;
}>(({ isInfinite }) => ({
  width: '100%',
  display: 'flex',
  overflowX: 'auto',
  // Hide scrollbar for Chrome, Safari and Opera
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  ...(!isInfinite && { justifyContent: 'center' }),
}));

export interface ISelectPumpCarouselProps {
  pumps: string[];
  onPumpSelect(pump: string): void;
}

export const SelectPumpCarousel: React.FC<ISelectPumpCarouselProps> = (props) => {
  const { pumps, onPumpSelect } = props;
  const slider = useRef<HTMLDivElement | null>(null);
  const pumpRef = useRef<HTMLButtonElement | null>(null);

  const renderSlides = useCallback(
    (key: string | number) =>
      pumps.map((pump) => (
        <PumpButtonComponent key={`${key}${pump}`} onClick={() => onPumpSelect(pump)}>
          <Typography variant="h4" component="span">
            {pump}
          </Typography>
        </PumpButtonComponent>
      )),
    [onPumpSelect, pumps],
  );

  const renderPumpIcon = useCallback(
    (ref?: MutableRefObject<HTMLButtonElement | null>) => (
      <PumpButtonComponent ref={ref} key="icon" disabled sx={{ bgcolor: 'transparent' }}>
        <PumpIcon sx={{ color: 'text.disabled' }} />
      </PumpButtonComponent>
    ),
    [],
  );
  const { slides, isInfinite } = useMemo(() => {
    const maxSlideToShow = Math.floor(document.body.offsetWidth / (BUTTON_SIZE + BUTTON_MARGIN * 2));
    const slidesToShow = min([pumps.length, maxSlideToShow]) || 0;
    const isInfinite = pumps.length > slidesToShow;

    const slides = [...renderSlides('start')];
    if (isInfinite) {
      slides.push(renderPumpIcon(pumpRef));
      slides.push(...renderSlides('end'));
    }

    return { slides, isInfinite };
  }, [pumps.length, renderPumpIcon, renderSlides]);

  useEffect(() => {
    if (!slider.current || !pumpRef.current || !isInfinite) {
      return;
    }

    // Set "scroll" event handler
    slider.current.addEventListener('scroll', () => {
      if (!slider.current || !pumpRef.current) {
        return;
      }

      // Handle scroll end
      if (slider.current.scrollLeft >= slider.current.scrollWidth - slider.current.offsetWidth) {
        pumpRef.current.scrollIntoView({ inline: 'end' });
        slider.current.scrollTo({
          left: slider.current.scrollLeft - pumpRef.current.offsetWidth,
        });
      }

      // Handle scroll start
      if (slider.current.scrollLeft <= SCROLL_START_OFFSET) {
        pumpRef.current.scrollIntoView({ inline: 'start' });
        slider.current.scrollTo({
          left: slider.current.scrollLeft + pumpRef.current.offsetWidth + SCROLL_START_OFFSET,
        });
      }
    });

    // Set initial scroll position if 'Infinite' mode
    pumpRef.current?.scrollIntoView({ inline: 'center' });
  }, [isInfinite]);

  return (
    <ScrollComponent ref={slider} isInfinite={isInfinite}>
      {slides}
    </ScrollComponent>
  );
};
