import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useCallback, useMemo } from 'react';

import { useInMemoryStorage } from '../../../lib/in-memory-storage/use-in-memory-storage';
import { IM_MEMORY_PIN_CODE_STORAGE_KEY } from '../../../modules/wallet/wallet.constants';
import { WalletPinCodePopupController } from './wallet-pin-code-popup.controller';

const ConfirmationActionSheetModal = NiceModal.create(WalletPinCodePopupController);

export const useWalletPinCodePopup = () => {
  const { show } = useModal(ConfirmationActionSheetModal);
  const { value: cachedPinCode } = useInMemoryStorage<string>(IM_MEMORY_PIN_CODE_STORAGE_KEY);

  const open = useCallback((): Promise<string | null> => {
    if (cachedPinCode) {
      return Promise.resolve(cachedPinCode);
    }
    return show() as Promise<string | null>;
  }, [cachedPinCode, show]);

  return useMemo(
    () => ({
      open,
    }),
    [open],
  );
};
