import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'sweeps:title',
    defaultMessage: 'Enter to Win!',
    description: 'Points Sweeps title',
  },
  ticketTitle: {
    id: 'sweeps:ticket:title',
    defaultMessage: 'You’ve got',
    description: 'Entries ticket title',
  },
  entriesAmount: {
    id: 'sweeps:common:entriesAmount',
    defaultMessage: '{entries, plural, one {# entry} other {# entries}}',
    description: 'Entries amount text',
  },
  buyEntriesButtonText: {
    id: 'pointsSweeps:buyEntriesButton:text',
    defaultMessage: 'Get {entries, plural, one {# entry} other {# entries}}',
    description: 'Buy entries button text',
  },
  buyAllEntriesButtonText: {
    id: 'pointsSweeps:buyAllEntriesButton:text',
    defaultMessage: 'Get all in!',
    description: 'Buy all entries button title text',
  },
  entriesCounterTitle: {
    id: 'pointsSweeps:entriesCounter:title',
    defaultMessage: 'I want',
    description: 'Entries counter title',
  },
  cancelButtonText: {
    id: 'pointsSweeps:buyAllEntriesConfirmationDialog:cancelButtonText',
    defaultMessage: 'Cancel',
    description: 'Cancel button text on the Confirmation Dialog',
  },
  notEnoughCashText: {
    id: 'pointsSweeps:pointsStrategy:cents:notEnoughCash:text',
    defaultMessage: 'Your cash balance is too low. Earn and come back to enter!',
    description: 'Not enough points text for points strategy "Cents"',
  },
  notEnoughPointsText: {
    id: 'pointsSweeps:pointsStrategy:points:notEnoughPoints:text',
    defaultMessage: 'You don’t have enough points yet. Earn points and come back to enter!',
    description: 'Not enough points text for points strategy "Points"',
  },
  buyEntriesCongratsDialogTitle: {
    id: 'pointsSweeps:buyEntries:congratsDialog:title',
    defaultMessage: 'Awesome!',
    description: 'Buy entries congrats dialog title',
  },
  buyEntriesCongratsDialogDescription: {
    id: 'pointsSweeps:buyEntries:congratsDialog:description',
    defaultMessage: 'You bought {entries, plural, one {# entry} other {# entries}}',
    description: 'Buy entries congrats dialog description',
  },
});
