import NiceModal, { useModal } from '@ebay/nice-modal-react';

import { BuyAllEntriesConfirmationActionSheetController } from './buy-all-entries-confirmation-action-sheet.controller';
import { BuyAllEntriesConfirmationActionSheetProps } from './buy-all-entries-confirmation-action-sheet.types';

const modalId = 'buy-all-entries-confirmation-action-sheet';
export const BuyAllEntriesConfirmationActionSheet = NiceModal.create<BuyAllEntriesConfirmationActionSheetProps>(
  (props) => <BuyAllEntriesConfirmationActionSheetController {...props} />,
);

export const useBuyAllEntriesConfirmationActionSheet = () => {
  const modal = useModal(BuyAllEntriesConfirmationActionSheet, { id: modalId });
  return modal;
};
