import React, { ReactNode } from 'react';

import styles from './pure-backdrop.module.css';

type PureBackdropProps = {
  children: ReactNode;
};

export const PureBackdrop = ({ children }: PureBackdropProps) => {
  return <div className={styles.root}>{children}</div>;
};
