import React from 'react';
import Payment from 'payment';

import { TextField, TextFieldProps } from '../../TextField';
import { formatExpirationDate } from './utils';

export const CardExpirationDateField: React.FC<TextFieldProps> = ({ fieldProps, ...props }) => {
  const attachFormatter = React.useCallback((input?: HTMLInputElement) => {
    if (!input) {
      return;
    }
    Payment.formatCardExpiry(input);
  }, []);

  return (
    <TextField
      fullWidth
      type="text"
      autoComplete="on"
      inputProps={{
        inputMode: 'numeric',
        autoComplete: 'on',
        autocompletetype: 'cc-exp',
        pattern: '\\d*',
        // 2 symbols for day + 3 for ' / ' + 4 for year
        maxLength: 9,
      }}
      label="Expiration"
      inputRef={attachFormatter}
      fieldProps={{
        ...fieldProps,
        parse: formatExpirationDate,
      }}
      {...props}
    />
  );
};
