import { Network } from '@capacitor/network';
import { useState, useEffect } from 'react';

export const useIsOnline = (): boolean => {
  const [isOnline, setIsOnline] = useState(true);

  useEffect(() => {
    Network.getStatus().then((status) => setIsOnline(status.connected));

    Network.addListener('networkStatusChange', (status) => {
      setIsOnline(status.connected);
    });
    return () => {
      Network.removeAllListeners();
    };
  }, []);

  return isOnline;
};
