import React from 'react';

import coinsIcon from '../../../../@active-configuration/assets/coins-icon.svg';

type PointsCoinProps = {
  width?: number;
  height?: number;
};

export const PointsCoins = (props: PointsCoinProps) => {
  return <img src={coinsIcon} alt="coins" {...props} />;
};
