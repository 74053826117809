import React, { Suspense, useEffect } from 'react';
import { Navigate, Outlet, useRoutes } from 'react-router-dom';
import { wrapUseRoutes } from '@sentry/react';
import { Capacitor } from '@capacitor/core';

import { RouteLoading } from '../components/Layout/route-loading';
import usePageTracking from '../hooks/usePageTracking';
import { useKount } from '../hooks/useKount';
import { useAppUrlListener } from '../modules/deep-linking/app-url-listener.hooks';
import { EventController } from './Event/EventController';
import { SignupWithLinkController } from './Signup/SignupWithLinkController';
import { AuthGuard } from '../modules/auth/AuthGuard';
import { HomeController } from './Home/HomeController';
import { ProfileController } from './Profile/ProfileController';
import { ConfigGuard } from '../modules/configuration/ConfigGuard';
import { ReadyToUseController } from './ReadyToUse/ReadyToUseController';
import { SelectPumpController } from './PayAtPump/SelectPump/SelectPumpController';
import { FuelingStatusController } from './PayAtPump/FuelingStatus/FuelingStatusController';
import { ReceiptController } from './PayAtPump/Receipt/ReceiptController';
import { AppRoutePath } from './AppRoutePath';
import { ReplaceToHome } from './Home/ReplaceToHome';
import { TransactionHistory } from './TransactionHistory/transaction-history';
import { ProductInterestsGuard } from './ProductInterests/ProductInterestsGuard';
import { CrashMe } from '../modules/sentry/CrashMe';
import { MembershipGuard } from '../modules/membership/context/membership.guard';
import { useRudderStack } from '../lib/rudderStack/useRudderStack';
import { usePushNotificationReceivedListener } from '../modules/notifications/push-notification.hooks';
import { useCreateMarketingNotificationsChannel } from '../modules/notifications/useCreateMarketingNotificationsChannel';

// TODO: handle cross dependencies
// eslint-disable-next-line @nx/enforce-module-boundaries
const RedeemPromocodeController = React.lazy(async () => import('@ocx/feat-promocodes'));

const PayInsideController = React.lazy(() => import('./PayInside/PayInsideController'));
const SignupController = React.lazy(() => import('./Signup/signup.controller'));
const WalletController = React.lazy(() => import('./Wallet/WalletController'));
const CommunicationPreferencesController = React.lazy(
  () => import('./CommunicationPreferences/CommunicationPreferencesController'),
);
const GuestSurveyController = React.lazy(() => import('./GuestSurvey/guest-survey.controller'));
const StoreDetailsController = React.lazy(() => import('./StoreDetails/StoreDetailsController'));
const StoreLocatorController = React.lazy(() => import('./StoreLocator/StoreLocatorController'));
const DeleteAccountController = React.lazy(() => import('./DeleteAccount/DeleteAccountController'));
const ProductInterestsController = React.lazy(() => import('./ProductInterests/ProductInterestsController'));
const SurveysController = React.lazy(() => import('./Surveys/SurveysController'));
const PreferredFuelGradeController = React.lazy(() => import('./PreferredFuelGrade/PreferredFuelGradeController'));
const TotalAvailableRollbackController = React.lazy(
  () => import('./TotalAvailableRollback/TotalAvailableRollbackController'),
);

const useSentryRoutes = wrapUseRoutes(useRoutes);

const payAtPumpRoutes = {
  path: AppRoutePath.PayAtPump,
  element: (
    <AuthGuard>
      <ConfigGuard _isRedirectRequired={(c) => !c.payAtPump.enabled}>
        <MembershipGuard _isRedirectRequired={(m) => !m.email}>
          <Outlet />
        </MembershipGuard>
      </ConfigGuard>
    </AuthGuard>
  ),
  children: [
    {
      index: true,
      element: <ReplaceToHome />,
    },
    {
      path: AppRoutePath.PayAtPumpSelectPump,
      element: <SelectPumpController />,
    },
    {
      path: AppRoutePath.PayAtPumpFuelingStatus,
      element: <FuelingStatusController />,
    },
    {
      path: AppRoutePath.PayAtPumpReceipt,
      element: <ReceiptController safeAreaBottomOffsetEnabled />,
    },
    {
      path: '*',
      element: <Navigate to={AppRoutePath.Home} />,
    },
  ],
};

const appRoutes = [
  {
    path: AppRoutePath.EventTracker,
    element: <EventController />,
  },
  {
    path: AppRoutePath.Auth,
    element: <SignupController />,
  },
  {
    path: AppRoutePath.LoginsWithLink,
    element: <SignupWithLinkController />,
  },
  {
    path: AppRoutePath.GuestSurvey,
    element: <GuestSurveyController />,
  },

  {
    path: AppRoutePath.Home,
    element: (
      <AuthGuard>
        <MembershipGuard>
          <Outlet />
        </MembershipGuard>
      </AuthGuard>
    ),
    children: [
      {
        index: true,
        path: '*',
        element: <HomeController />,
      },
      {
        path: `${AppRoutePath.ReadyToUseOffers}/*`,
        element: <ReadyToUseController />,
      },
      {
        path: AppRoutePath.RedeemPromocode,
        element: <RedeemPromocodeController />,
      },
      {
        path: `${AppRoutePath.TotalAvailableRollbackOffers}/*`,
        element: (
          <ConfigGuard _isRedirectRequired={(c) => !c.totalAvailableRollback.enabled}>
            <TotalAvailableRollbackController />
          </ConfigGuard>
        ),
      },
    ],
  },
  {
    path: AppRoutePath.Profile,
    element: (
      <AuthGuard>
        <MembershipGuard>
          <Outlet />
        </MembershipGuard>
      </AuthGuard>
    ),
    children: [
      {
        index: true,
        element: <ProfileController />,
      },
      {
        path: AppRoutePath.CommunicationPreferences,
        element: (
          <ConfigGuard
            _isRedirectRequired={({
              communicationPreference: { enabled, isSmsOptInConfigurable, isPushOptInConfigurable },
            }) => !enabled || !(isSmsOptInConfigurable || isPushOptInConfigurable)}>
            <CommunicationPreferencesController />
          </ConfigGuard>
        ),
      },
      {
        path: `${AppRoutePath.ProductInterests}/*`,
        element: (
          <ConfigGuard _isRedirectRequired={(c) => !c.productInterests.enabled}>
            <ProductInterestsGuard Component={ProductInterestsController} />
          </ConfigGuard>
        ),
      },
      {
        path: AppRoutePath.Wallet,
        element: (
          <ConfigGuard _isRedirectRequired={(c) => !c.wallet.enabled}>
            <WalletController />
          </ConfigGuard>
        ),
      },
      {
        path: AppRoutePath.PreferredFuelGrade,
        element: (
          <ConfigGuard _isRedirectRequired={(c) => !c.totalAvailableRollback.enabled}>
            <PreferredFuelGradeController />
          </ConfigGuard>
        ),
      },
    ],
  },

  {
    path: AppRoutePath.Surveys,
    element: (
      <AuthGuard>
        <MembershipGuard>
          <SurveysController />
        </MembershipGuard>
      </AuthGuard>
    ),
  },

  payAtPumpRoutes,
  {
    path: AppRoutePath.PayInside,
    element: (
      <AuthGuard>
        <ConfigGuard _isRedirectRequired={(c) => !c.payInside.enabled || !c.wallet.enabled}>
          <MembershipGuard _isRedirectRequired={(m) => !m.email}>
            <PayInsideController />
          </MembershipGuard>
        </ConfigGuard>
      </AuthGuard>
    ),
  },

  {
    path: `${AppRoutePath.TransactionHistory}/*`,
    element: (
      <AuthGuard>
        <MembershipGuard>
          <TransactionHistory />
        </MembershipGuard>
      </AuthGuard>
    ),
  },

  {
    path: `${AppRoutePath.StoreLocator}/*`,
    element: (
      <AuthGuard>
        <MembershipGuard>
          <StoreLocatorController />
        </MembershipGuard>
      </AuthGuard>
    ),
  },
  {
    path: AppRoutePath.StoreDetails,
    element: (
      <AuthGuard>
        <MembershipGuard>
          <StoreDetailsController />
        </MembershipGuard>
      </AuthGuard>
    ),
  },

  {
    path: AppRoutePath.DeleteAccount,
    element: (
      <AuthGuard>
        <ConfigGuard _isRedirectRequired={(config) => !config.deleteAccount.enabled}>
          <MembershipGuard>
            <DeleteAccountController />
          </MembershipGuard>
        </ConfigGuard>
      </AuthGuard>
    ),
  },

  {
    path: AppRoutePath.CrashMe,
    element: <CrashMe />,
  },

  {
    path: '*',
    element: <Navigate to={AppRoutePath.Home} />,
  },
];

export const AppRoutes: React.FC = () => {
  const { triggerEvent, init: initRudderStack } = useRudderStack();

  useEffect(() => {
    initRudderStack();
  }, [initRudderStack]);

  useAppUrlListener();
  usePushNotificationReceivedListener();
  useCreateMarketingNotificationsChannel();
  usePageTracking();
  useKount();

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      triggerEvent('native_app_opened');
    }
  }, [triggerEvent]);

  const routes = useSentryRoutes(appRoutes);
  return <Suspense fallback={<RouteLoading />}>{routes}</Suspense>;
};
