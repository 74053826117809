import { useCallback } from 'react';

import { useSurveys } from '../../../modules/survey/survey.context.hook';
import { isSurveyWasTriggeredOnAppStart } from '../../../modules/survey/survey.reactive-variables';

export const useTriggerSurveyOnMembershipCompleted = () => {
  const { fetchAndOpenSurveyModal } = useSurveys();

  const triggerSurveyOnMembershipCompleted = useCallback(() => {
    if (!isSurveyWasTriggeredOnAppStart()) {
      fetchAndOpenSurveyModal();
    }

    isSurveyWasTriggeredOnAppStart(true);
  }, [fetchAndOpenSurveyModal]);

  return {
    triggerSurveyOnMembershipCompleted,
    fetchAndOpenSurveyModal,
  };
};
