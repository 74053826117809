/**
 * This function cuts decimal. It does not add trailing 0 and does not round
 * @param number - number to format
 * @param decimalPartSize - how many signs can remain
 */
export const formatDecimal = (number: number, decimalPartSize: number = 2): number => {
  if (decimalPartSize <= 0) {
    // || 0 covers -0
    return Math.trunc(number) || 0;
  }

  const [intPart, decimalPart] = `${number}`.split('.');

  if (!decimalPart) {
    // || 0 covers -0
    return +intPart || 0;
  }

  return +`${intPart}.${decimalPart.slice(0, decimalPartSize)}`;
};
