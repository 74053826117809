import z from 'zod';

import {
  ApplePayConfiguration,
  BrandAgreement,
  GooglePayConfiguration,
  Maybe,
  PayAtPump,
  PayInside,
  Scalars,
  KountConfiguration,
  PinCodeConfiguration,
  WalletConfiguration as GraphQLWalletConfiguration,
  DeleteAccountConfiguration,
  PointsStrategy,
  GetAppConfigQuery,
  CommunicationPreferenceConfiguration,
  EaivAgeVerificationConfiguration,
  EaivIdentityVerificationConfiguration,
  ProductInterestsConfiguration,
  AppRatingConfiguration,
  AuthMethod,
  SmsOptInConfiguration,
  OtpChannel,
  StoreLocatorConfiguration,
} from '@ocx/graphql';
import { FuelGradesConfigurationSchema, LocalRetailerConfigurationSchema } from './app-config.schema';

export type RemoteConfiguration = GetAppConfigQuery['appConfig'];

export type LoyaltyProgramConfiguration = {
  pointsStrategy: PointsStrategy;
  homePageOffersLimit: number;
  redeemOfferWithCompletedProfileOnly: boolean;
  membershipMinAge: number;
  membershipMaxAge: number;
  isExplicitTermsAcceptanceRequired: boolean;
  isReturnRewardEnabled: boolean;
  isExplicitSmsPromotionAcceptanceEnabled: boolean;
};

export type RuntimeConfig = {
  release: string;
  brandUuid: ISchemaConfig['brandUuid'];
  chainId: ISchemaConfig['chainId'];

  termsConditions: BrandAgreement;
  privacyPolicy: BrandAgreement;
  supportTeamEmail: Scalars['String'];
  supportPhoneNumber: Scalars['String'] | null;
  loyaltyProgram: LoyaltyProgramConfiguration;
  payAtPump: PayAtPump;
  payInside: PayInside;
  transaction: {
    pollInterval: number;
    errorLimit: number;
  };
  applePay: Maybe<ApplePayConfiguration>;
  googlePay: Maybe<GooglePayConfiguration>;
  marketing: {
    title: ISchemaConfig['marketing']['title'];
  };
  analytics: {
    googleMeasurementId: ISchemaConfig['analytics']['googleMeasurementId'];
  };
  rudderStack: ISchemaConfig['rudderStack'] & {
    enabled: boolean;
  };
  totalAvailableRollback: {
    enabled: boolean;
  };
  localRetailerConfig: LocalRetailerConfiguration;

  kount: Maybe<KountConfiguration>;
  pinCode: Partial<Pick<PinCodeConfiguration, 'blockingDuration' | 'attemptThrottle' | 'maxAttempts'>> & {
    pinInMemoryTtl: number;
  };
  wallet: WalletConfiguration;
  deleteAccount: DeleteAccountConfiguration;
  paymentProvider: PaymentProviderConfiguration;
  communicationPreference: CommunicationPreferenceConfiguration;
  eaivAgeVerification: EaivAgeVerificationConfiguration;
  eaivIdentityVerification: EaivIdentityVerificationConfiguration;
  productInterests: ProductInterestsConfiguration;
  appRating: AppRatingConfiguration;
  auth: {
    primaryMethod: AuthMethod;
    otp: {
      enabled: boolean;
      channels: OtpChannel[];
    };
  };
  freshdesk: {
    supportWidget: FreshdeskSupportWidgetConfiguration;
  };
  smsOptIn: SmsOptInConfiguration;
  storeLocator: StoreLocatorConfiguration &
    ISchemaConfig['storeLocator'] & { watchTimeout: number; atTheStoreDistance: number };
};

export type FuelGradesConfiguration = z.infer<typeof FuelGradesConfigurationSchema>;
export type LocalRetailerConfiguration = z.infer<typeof LocalRetailerConfigurationSchema>;

export enum PAYMENT_PROVIDER_TYPE {
  FISERV = 'FISERV',
  WORLDPAY = 'WORLDPAY',
}
export interface PaymentProviderBaseConfiguration {
  type: PAYMENT_PROVIDER_TYPE;
}
export interface PaymentProviderFiservConfiguration extends PaymentProviderBaseConfiguration {
  type: PAYMENT_PROVIDER_TYPE.FISERV;
}
export interface PaymentProviderWorldpayConfiguration extends PaymentProviderBaseConfiguration {
  type: PAYMENT_PROVIDER_TYPE.WORLDPAY;
  eProtectPayPageId: string;
  eProtectGooglePayPageId: string;
  eProtectWebSdkApiUrl: string;
  eProtectWebSdkUrl: string;
}
export type PaymentProviderConfiguration =
  | PaymentProviderFiservConfiguration
  | PaymentProviderWorldpayConfiguration
  | null;

export interface WalletConfiguration extends GraphQLWalletConfiguration {
  sizeLimit: number;
  prepaidAccountTypeEnabled: boolean;
}

export type FreshdeskSupportWidgetConfiguration = {
  id: string | null;
  token: string | null;
  host: string | null;
  sdkUrl: string | null;
  enabled: boolean;
};

export type FreshdeskSupportWidgetEnabledConfiguration = Exclude<
  {
    [P in keyof FreshdeskSupportWidgetConfiguration]: NonNullable<FreshdeskSupportWidgetConfiguration[P]>;
  },
  'isEnabled'
> & { isEnabled: true };
