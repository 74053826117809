import React from 'react';
import { CircularProgress, ButtonBase, Stack } from '@mui/material';
import { useIntl } from 'react-intl';
import { Typography } from '@ocx/ui';

import { ProfileIcon } from '@ocx/ui-icons';
import { SizingMultiplier } from '../../../theme/constants';
import { messages } from '../messages';

export interface IMemberInformationProps {
  username: string;
  onClick(): void;
  isRefetching: boolean;
}

export const MemberInformation: React.FC<IMemberInformationProps> = ({ username, onClick, isRefetching }) => {
  const intl = useIntl();
  return (
    <ButtonBase
      sx={{
        borderRadius: 4,
        backgroundColor: 'background.paper',
        p: SizingMultiplier.md,
        minHeight: 64,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
      }}
      onClick={onClick}>
      <ProfileIcon sx={{ color: 'action.active' }} />
      <Stack ml={SizingMultiplier.sm} mr="auto" alignItems="flex-start">
        <Typography component="span">{username}</Typography>
        <Typography variant="caption" color="textSecondary">
          {intl.formatMessage(messages['profile:profileSection:subtitle'])}
        </Typography>
      </Stack>
      {isRefetching && <CircularProgress size={20} />}
    </ButtonBase>
  );
};
