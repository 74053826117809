import React, { ReactNode } from 'react';

import { ModalDrawer } from '../../components/modals';

type ContactSupportLayoutProps = {
  children: ReactNode;
  isOpen: boolean;
  onClose(): void;
  onExited(): void;
};

export const ContactSupportLayout: React.FC<ContactSupportLayoutProps> = (props) => {
  const { children, isOpen, onClose, onExited } = props;
  return (
    <ModalDrawer minPaperHeight={280} open={isOpen} onClose={onClose} onExited={onExited}>
      {children}
    </ModalDrawer>
  );
};
