import { formatDecimal } from './formatDecimal';

export const formatPrice = (cents: number, options?: { displayCentsWhenLessThanDollar?: boolean }) => {
  if (options?.displayCentsWhenLessThanDollar && cents < 100) {
    return `${cents}¢`;
  }

  const dollars = convertCentsToDollars(cents);
  const maximumFractionDigits = 2;
  const formattedPrice = formatDecimal(dollars, maximumFractionDigits);

  return new Intl.NumberFormat(navigator.languages, {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits,
  }).format(formattedPrice);
};

export const convertCentsToDollars = (cents: number): number => cents / 100;

export const convertDollarsToCents = (dollars: number): number => dollars * 100;
