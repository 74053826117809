import React, { useCallback } from 'react';
import { useModal } from '@ebay/nice-modal-react';
import { useIntl } from 'react-intl';
import { Box } from '@mui/material';

import { ModalDrawer } from '../components';
import { EditProfileController } from '../../../pages/EditProfile/EditProfileController';
import { profileSubmittedMessage } from '../../../pages/EditProfile/messages';
import { useSnackbar } from '../../../lib/snackbar/snackbar';
import { useProfileCompletedPopup } from '../ProfileCompletedPopup';
import { EditProfilePopupProps } from './edit-profile-popup.types';
import { SizingMultiplier } from '../../../theme/constants';

export const EditProfilePopupController = (props: EditProfilePopupProps) => {
  const intl = useIntl();
  const snackbar = useSnackbar();
  const { open: openProfileCompletedPopup } = useProfileCompletedPopup();
  const { visible, hide, remove } = useModal();

  const handleProfileUpdated = useCallback(
    (params?: { isProfileCompletionTriggered?: boolean }) => {
      hide();

      // TODO handle returnUrl from props

      if (params?.isProfileCompletionTriggered) {
        openProfileCompletedPopup();
      } else {
        snackbar.open(intl.formatMessage(profileSubmittedMessage), {
          variant: 'success',
        });
      }
    },
    [hide, intl, openProfileCompletedPopup, snackbar],
  );

  return (
    <ModalDrawer open={visible} onClose={hide} onExited={remove}>
      <Box p={SizingMultiplier.lg}>
        <EditProfileController onCancelClick={hide} onProfileUpdated={handleProfileUpdated} />
      </Box>
    </ModalDrawer>
  );
};
