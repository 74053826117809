import { Offer } from './offer.types';
import { ActionType } from './custom-data/actions/types';
import { Eligible, IneligibilityHandlingStrategy, IneligibilityReason } from './eligibility.types';
import { IneligibleIfMembershipHasRewardConstraint } from './custom-data/constraints/constraints.types';
import { assertIneligibleIfMembershipHasReward } from './custom-data/constraints/constraint';
import { inelegibilityReasonStrategyComparator } from './eligibility.utils';
import { isPurchaseVoucherOffer } from './offer.type-guards';

export type CalculateEligibilityContext = {
  membershipIsCompleted: boolean;
  membershipPoints: number;
  membershipRewardOfferIds: string[];
};

export const isCompleteProfileOffer = (offer: Offer): boolean => {
  return offer.customData?.action?.type === ActionType.RequestCompleteProfile;
};

export const calculateEligibilityFromConstraint = (
  constraint: IneligibleIfMembershipHasRewardConstraint,
  context: CalculateEligibilityContext,
): boolean => {
  if (!context.membershipRewardOfferIds.length) {
    return true;
  }

  return !assertIneligibleIfMembershipHasReward(constraint, context.membershipRewardOfferIds);
};

export const calculateEligibility = (offer: Offer, context: CalculateEligibilityContext): Eligible => {
  const reasons: IneligibilityReason[] = [];

  if (isCompleteProfileOffer(offer) && context.membershipIsCompleted) {
    // TODO: add strategy
    reasons.push({
      type: 'complete_profile_offer_on_completed_membership',
      strategy: IneligibilityHandlingStrategy.Hide,
    });
  }

  if (isPurchaseVoucherOffer(offer) && offer.earningMechanism.fulfilmentPrice > context.membershipPoints) {
    reasons.push({ type: 'not_enough_points', strategy: IneligibilityHandlingStrategy.DisableInteractions });
  }

  offer.customData?.constraints
    ?.filter((c) => !calculateEligibilityFromConstraint(c, context))
    .forEach((constraint) => {
      reasons.push({
        type: 'constraint_failed',
        strategy: constraint[1] as any, // TODO: map,
        constraint,
      });
    });

  if (!reasons.length) {
    return [true];
  }

  const sortedReasons = reasons.sort(inelegibilityReasonStrategyComparator);
  return [false, sortedReasons[0].strategy, sortedReasons];
};
