import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  'storeLocator:provideLocationPopupTitle': {
    id: 'storeLocator:provideLocationPopupTitle',
    defaultMessage: 'Please provide your location',
    description: 'Provide location popup title',
  },
  'storeLocator:provideLocationPopupDescription': {
    id: 'storeLocator:provideLocationPopupDescription',
    defaultMessage: 'We need access to your location so we can help you find your nearest store.',
    description: 'Provide location popup description',
  },
  'storeLocator:provideLocationPopupConfirmButton': {
    id: 'storeLocator:provideLocationPopupConfirmButton',
    defaultMessage: 'Share location',
    description: 'Provide location popup confirm button',
  },
  'storeLocator:listView:adjustLocationToSeeMoreStoresMessage': {
    id: 'storeLocator:listView:adjustLocationToSeeMoreStoresMessage',
    defaultMessage: 'Adjust your location to see more stores in the nearby area',
    description: 'Message at the end of store locator list view',
  },
});
