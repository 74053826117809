export enum PAYMENT_STATUS {
  PENDING = 'PENDING',
  FINAL = 'FINAL',
}

export interface IPaymentItem {
  label: string;
  price: string;
  status: PAYMENT_STATUS;
}

export interface IPaymentAvailability {
  loading: boolean;
  available: boolean;
}

export enum PAYMENT_INSTRUMENT_TYPE {
  APPLE_PAY = 'APPLE_PAY',
  GOOGLE_PAY = 'GOOGLE_PAY',
  // TODO: rename to Credit and add Prepaid?
  // This seems to be used only to show correct button type in different scenarious
  // This might be needed to be extended, when custom behaviour for a gift card is introduced
  CARD = 'CARD', // works for both CREDIT and PREPAID
  UNKNOWN = 'UNKNOWN',
}
