import React from 'react';

export const Mastercard: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg width="32" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 4.471A3.765 3.765 0 013.765.707h24.47A3.765 3.765 0 0132 4.47v15.06a3.765 3.765 0 01-3.765 3.764H3.765A3.765 3.765 0 010 19.53V4.471z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.235 1.648H3.765A2.824 2.824 0 00.94 4.471v15.06a2.823 2.823 0 002.824 2.823h24.47a2.823 2.823 0 002.824-2.824V4.471a2.824 2.824 0 00-2.824-2.823zM3.765.707A3.765 3.765 0 000 4.47v15.06a3.765 3.765 0 003.765 3.764h24.47A3.765 3.765 0 0032 19.53V4.471A3.765 3.765 0 0028.235.707H3.765z"
      fill="#E1E1E1"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.169 16.792a6.402 6.402 0 01-4.14 1.506c-3.525 0-6.382-2.823-6.382-6.305 0-3.483 2.857-6.306 6.382-6.306 1.58 0 3.025.567 4.14 1.506a6.402 6.402 0 014.14-1.506c3.524 0 6.38 2.823 6.38 6.306 0 3.482-2.856 6.306-6.38 6.306a6.402 6.402 0 01-4.14-1.507z"
      fill="#ED0006"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.168 16.792a6.262 6.262 0 002.243-4.8c0-1.921-.87-3.642-2.243-4.799a6.402 6.402 0 014.14-1.506c3.524 0 6.382 2.823 6.382 6.306 0 3.482-2.858 6.306-6.382 6.306a6.402 6.402 0 01-4.14-1.507z"
      fill="#F9A000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.169 16.793a6.261 6.261 0 002.242-4.799c0-1.922-.87-3.643-2.242-4.8a6.262 6.262 0 00-2.243 4.8c0 1.922.87 3.643 2.243 4.8z"
      fill="#FF5E00"
    />
  </svg>
);
