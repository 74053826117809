import { IntlShape } from 'react-intl';
import { AwardVouchersFragment, OfferEngagementType, OfferFragment } from '@ocx/graphql';
import { diffDateTime, formatDateTime, isToday } from '../../lib/date/date';
import { DateTimeFormat } from '../../lib/date/date.types';
import { rewardExpirationMessages } from './award.messages';
import { Award, AwardPoints, AwardPointsPer, AwardSweepstakeEntries, AwardType, AwardVouchers } from './award.types';
import { isSweepstake } from './offer.type-guards';

const getAwardFrom = ({ earningMechanism }: OfferFragment) => {
  switch (earningMechanism?.__typename) {
    case 'EarningMechanismProductDiscount':
    case 'EarningMechanismTransactionDiscount':
    case 'EarningMechanismVarietyDiscount':
      return null;

    case 'EarningMechanismExternalFulfillment':
    case 'EarningMechanismLoyaltyEvent':
      return earningMechanism.optionalAward;

    case 'EarningMechanismMembershipEvent':
    case 'EarningMechanismProductPurchase':
    case 'EarningMechanismTotalEligiblePurchase':
    case 'EarningMechanismTotalEligiblePurchaseOverTime':
    case 'EarningMechanismTotalSpendOverTime':
    case 'EarningMechanismVarietyPurchase':
    case 'EarningMechanismVarietyPurchaseOverTime':
    case 'EarningMechanismVisits':
      return earningMechanism.award;

    default:
      return null;
  }
};

export const mapAwardVouchersFragment = ({
  award,
  engagementType,
}: {
  award: AwardVouchersFragment;
  engagementType: OfferEngagementType | null;
}): AwardSweepstakeEntries | AwardVouchers => {
  if (isSweepstake(engagementType)) {
    const result: AwardSweepstakeEntries = {
      type: AwardType.SweepstakeEntries,
      entriesCount: award.voucherCount,
      entryExpirationDays: award.voucherExpirationDays,
      entriesOfferId: award.voucheredOffer.id,
      memberEntries: award.voucheredOffer.voucherCount,
    };
    return result;
  }

  const result: AwardVouchers = {
    type: AwardType.Vouchers,
    voucherCount: award.voucherCount,
    voucherExpirationDays: award.voucherExpirationDays,
    voucheredOfferId: award.voucheredOffer.id,
    memberVouchers: award.voucheredOffer.voucherCount,
  };
  return result;
};

export const mapAward = (fragment: OfferFragment): Award | null => {
  const award = getAwardFrom(fragment);

  if (award?.__typename === 'AwardPoints') {
    const result: AwardPoints = {
      type: AwardType.Points,
      points: award.points,
    };
    return result;
  }

  if (award?.__typename === 'AwardPointsPer') {
    const result: AwardPointsPer = {
      type: AwardType.PointsPer,
      points: award.points,
    };
    return result;
  }

  if (award?.__typename === 'AwardVouchers') {
    return mapAwardVouchersFragment({ award, engagementType: fragment.engagementType });
  }

  return null;
};

export type RewardExpiresInReturn =
  | {
      value: number;
      unit: 'days' | 'minutes';
    }
  | {
      value: string;
      period?: 'today' | 'soon';
      unit: 'dateTime';
    };

export const getRewardExpiresIn = (params: {
  rewardExpiresIn: string | null;
  showFullDateTime?: boolean;
}): RewardExpiresInReturn | null => {
  const { rewardExpiresIn, showFullDateTime } = params;
  if (!rewardExpiresIn) {
    return null;
  }

  const now = new Date();
  const voucherExpiresAtDate = new Date(rewardExpiresIn);

  if (showFullDateTime) {
    return {
      value: formatDateTime(voucherExpiresAtDate, DateTimeFormat.DateSlashedTimeFull),
      unit: 'dateTime',
    };
  }

  const diffMinutes = Math.abs(diffDateTime(now, voucherExpiresAtDate, 'minute'));

  if (diffMinutes < 60) {
    return { value: diffMinutes, unit: 'minutes' };
  }
  const diffHours = Math.abs(diffDateTime(now, voucherExpiresAtDate, 'hour'));

  if (diffHours < 48 && !isToday(voucherExpiresAtDate)) {
    return {
      value: formatDateTime(voucherExpiresAtDate, DateTimeFormat.DateSlashedTimeFull),
      period: 'soon',
      unit: 'dateTime',
    };
  }

  if (diffHours < 24 && isToday(voucherExpiresAtDate)) {
    return {
      value: formatDateTime(voucherExpiresAtDate, DateTimeFormat.TimeFull),
      period: 'today',
      unit: 'dateTime',
    };
  }

  const diffDays = Math.abs(diffDateTime(now, voucherExpiresAtDate, 'day'));

  if (diffDays > 7) {
    return {
      value: formatDateTime(voucherExpiresAtDate, DateTimeFormat.DateSlashedTimeFull),
      unit: 'dateTime',
    };
  }

  if (diffDays >= 2 && diffDays <= 7) {
    return { value: diffDays, unit: 'days' };
  }

  return {
    value: formatDateTime(voucherExpiresAtDate, DateTimeFormat.DateSlashedTimeFull),
    unit: 'dateTime',
  };
};

export const getRewardExpiresInMessage = ({
  expiresIn,
  intl,
  isMultipleVouchers = false,
}: {
  expiresIn: RewardExpiresInReturn | null;
  intl: IntlShape;
  isMultipleVouchers?: boolean;
}): string => {
  if (!expiresIn) {
    return '';
  }

  const prefix = isMultipleVouchers ? 'Next Reward' : '';

  if (expiresIn.unit === 'minutes' && expiresIn.value < 60) {
    return `${prefix} ${intl.formatMessage(rewardExpirationMessages.expiresMinutes, {
      expiresIn: expiresIn.value,
    })}`.trimStart();
  }
  if (expiresIn.unit === 'days') {
    return `${prefix} ${intl.formatMessage(rewardExpirationMessages.expiresDays, {
      expiresIn: expiresIn.value,
    })}`.trimStart();
  }
  if (expiresIn.unit === 'dateTime' && expiresIn.period === 'today') {
    return `${prefix} ${intl.formatMessage(rewardExpirationMessages.expiresToday, {
      expiresIn: expiresIn.value,
    })}`.trimStart();
  }

  if (expiresIn.unit === 'dateTime' && expiresIn.period === 'soon') {
    return `${prefix} ${intl.formatMessage(rewardExpirationMessages.expiresSoon, {
      expiresIn: expiresIn.value,
    })}`.trimStart();
  }

  if (expiresIn.unit === 'dateTime') {
    return `${prefix} ${intl.formatMessage(rewardExpirationMessages.expiresDateTime, {
      expiresIn: expiresIn.value,
    })}`.trimStart();
  }

  return '';
};
