import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  'product-interests:html-title': {
    id: 'product-interests:html-title',
    defaultMessage: 'Product Interests',
    description: 'HTML title for Product Interests page',
  },
  'product-interests:page-title': {
    id: 'product-interests:page-title',
    defaultMessage: 'Product Interests',
    description: 'Page title for Product Interests page',
  },
  'product-interests:general:title': {
    id: 'product-interests:general:title',
    defaultMessage: 'I’m most interested in these products:',
    description: 'Title for section with general categories ',
  },
  'product-interests:age-restricted:title': {
    id: 'product-interests:age-restricted:title',
    defaultMessage: 'I’m most interested in these age-restricted products:',
    description: 'Title for for section with age and/or identity restricted categories',
  },
  'product-interests:age-restricted:tobacco-certification': {
    id: 'product-interests:age-restricted:tobacco-certification',
    defaultMessage: `By choosing any of the tobacco products shown above, I certify that I am a tobacco consumer 21 years of age or older.`,
    description: 'Tobacco certification text for age and/or identity restricted categories',
  },
  'product-interests:age-restricted:legal-text': {
    id: 'product-interests:age-restricted:legal-text',
    defaultMessage: `By clicking Done, I consent to COCO Rewards selling my personal information (such as name, date of birth, email, etc.) to Altria’s tobacco operating companies including Philip Morris USA Inc., U.S. Smokeless Tobacco Company, John Middleton Co., Helix Innovations, and their affiliates, and Reynold American Incorporated's operating companies, including R.J. Reynolds Tobacco Company, American Snuff Company, Santa Fe Natural Tobacco Company, and R.J. Reynolds Vapor Company, so that I may receive their marketing or other communications.`,
    description: 'Legal text for section with age and/or identity restricted categories',
  },
  'product-interests:age-restricted:info-text': {
    id: 'product-interests:age-restricted:info-text',
    defaultMessage: 'Age-restricted offers are only available to members over 21 years of age',
    description: 'Info text for section with age and/or identity restricted categories',
  },
  'product-interests:update:success-message': {
    id: 'product-interests:update:success-message',
    defaultMessage: 'Changes were saved',
    description: 'Message after successfully updated Product Interests',
  },
  'product-interests:pending-verification-description': {
    id: 'product-interests:pending-verification-description',
    defaultMessage: 'To view age-restricted offers please verify your age and identity first.',
    description: 'Text displayed on "Pending verification" card',
  },
  'product-interests:pending-age-verification-description': {
    id: 'product-interests:pending-age-verification-description',
    defaultMessage: 'To view age-restricted offers please verify your age first.',
    description: 'Text displayed on "Pending age verification" card',
  },
});
