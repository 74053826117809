import { useMemo } from 'react';

import { OfferVisibleStatus, PublicationStatusType, useGetOffersByLoyaltyEventQuery } from '@ocx/graphql';
import { Offer } from '../../offers/offer.types';
import { mapSmsOptInOfferFragmentToOffer } from '../utils/sms-opt-in.utils';

export const useSmsOptInOffer = (params: { skip: boolean; loyaltyEventName: string }) => {
  const { skip, loyaltyEventName } = params;
  const { data, loading: isLoading } = useGetOffersByLoyaltyEventQuery({
    skip,
    fetchPolicy: 'network-only',
    variables: {
      loyaltyEventEquals: loyaltyEventName,
      publicationStatusEquals: PublicationStatusType.Published,
      visibleStatusEquals: OfferVisibleStatus.Visible,
    },
  });

  const offer = useMemo<Offer | null>(() => {
    const offerFragment = data?.currentCustomer?.membership?.offers?.edges?.[0]?.node;
    if (!offerFragment) {
      return null;
    }
    return mapSmsOptInOfferFragmentToOffer(offerFragment);
  }, [data]);

  return useMemo(() => ({ offer, isLoading }), [isLoading, offer]);
};
