import React from 'react';

export const Visa: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg width="32" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 4.471A3.765 3.765 0 013.765.707h24.47A3.765 3.765 0 0132 4.47v15.06a3.765 3.765 0 01-3.765 3.764H3.765A3.765 3.765 0 010 19.53V4.471z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.235 1.648H3.765A2.824 2.824 0 00.94 4.471v15.06a2.823 2.823 0 002.824 2.823h24.47a2.823 2.823 0 002.824-2.824V4.471a2.824 2.824 0 00-2.824-2.823zM3.765.707A3.765 3.765 0 000 4.47v15.06a3.765 3.765 0 003.765 3.764h24.47A3.765 3.765 0 0032 19.53V4.471A3.765 3.765 0 0028.235.707H3.765z"
      fill="#E1E1E1"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.667 15.666H7.705l-1.47-5.777c-.07-.266-.219-.501-.437-.612A6.26 6.26 0 004 8.666v-.223h3.16c.436 0 .763.335.818.723l.763 4.167 1.96-4.89h1.908l-2.942 7.223zm4.033 0h-1.853l1.526-7.223h1.852L13.7 15.666zm3.922-5.222c.055-.389.382-.611.763-.611.6-.056 1.253.056 1.798.333l.328-1.555a4.572 4.572 0 00-1.69-.334c-1.798 0-3.106 1-3.106 2.389 0 1.056.927 1.61 1.58 1.945.709.333.981.555.927.888 0 .5-.545.723-1.09.723a4.56 4.56 0 01-1.907-.445l-.327 1.556a5.211 5.211 0 002.016.389c2.016.055 3.27-.945 3.27-2.445 0-1.89-2.562-2-2.562-2.833zm9.044 5.222l-1.47-7.223h-1.58a.821.821 0 00-.764.556l-2.724 6.667h1.907l.381-1.056h2.343l.218 1.056h1.69zm-2.778-5.278l.544 2.722h-1.526l.982-2.722z"
      fill="#172B85"
    />
  </svg>
);
