// The actual radius of the circular indicator.
export const RADIUS = 12;

// The stroke width of the 'tail' part of the indicator.
export const TAIL_SIZE = 4;

// The length of the 'head' part of the indicator.
export const HEAD_SIZE = TAIL_SIZE * 2; // ie, twice the tail width

// The maximum diameter including the outermost corner of the arrowhead.
export const OUTER_DIAMETER = (RADIUS + HEAD_SIZE) * 2;

// Center-point for drawing relative to center
export const CENTER = RADIUS + HEAD_SIZE;

// Tail circumference for calculating stroke lengths/offsets
export const TAIL_CIRCUMFERENCE = 2 * Math.PI * RADIUS;

// A downward-pointing right-angle triangle, at the 3-o'clock position
export const HEAD_PATH = `
M ${OUTER_DIAMETER}, ${CENTER}
l -${HEAD_SIZE},${HEAD_SIZE}
l -${HEAD_SIZE},-${HEAD_SIZE}
`;

// The threshold for considering a downard drag to have started
export const START_THRESHOLD = 50;

// The threshold for considering a downward drag to be a legitimate refresh request.
export const TRIGGER_THRESHOLD = 75;

// Easing magic numbers that work well with an active threshold of 75.
export const TRIGGERED_EASING = {
  a: 812,
  b: 46,
  c: 103,

  // may be changed independently of a,b and c
  friction: 0.5,
};

// Tail length when starting to pull
export const INITIAL_TAIL_LENGTH = 0;

// Tail length when pulled to trigger threshold
export const ACTIVE_TAIL_LENGTH = TAIL_CIRCUMFERENCE * 0.9; // 10%

// Indicator rotates to this offset when pulled to maximum extent
export const FINAL_ROTATION_OFFSET = 25;

// Indicator should have reduced opacity until it crosses the trigger threshold
export const PULLING_OPACITY = 0.5;
