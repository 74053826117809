import { defineMessage } from 'react-intl';

export const messages = {
  'offers:activateButtonTitle': defineMessage({
    id: 'offers:activateButtonTitle',
    defaultMessage: 'Activate',
    description: 'Activate offer button text',
  }),
  'offers:redeemButtonTitle': defineMessage({
    id: 'offers:redeemButtonTitle',
    defaultMessage: 'Redeem',
    description: 'Redeem offer button text',
  }),
};

export const expirationMessages = {
  expiresDays: defineMessage({
    id: 'voucherDuration:days',
    defaultMessage: '{expiresIn, plural, one {# day} other {# days}}',
    description: 'Redeem offer expiration days',
  }),
  expiresHours: defineMessage({
    id: 'voucherDuration:hours',
    defaultMessage: '{expiresIn, plural, one {# hour} other {# hours}}',
    description: 'Redeem offer expiration hours',
  }),
  expiresLessThanHour: defineMessage({
    id: 'voucherDuration:lessThanHour',
    defaultMessage: 'less than one hour',
    description: 'Redeem offer expiration less than hour',
  }),
  expiresAnHour: defineMessage({
    id: 'voucherDuration:anHour',
    defaultMessage: 'one hour',
    description: 'Redeem offer expiration an hour',
  }),
};
