import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Capacitor } from '@capacitor/core';

import { useSnackbar } from '../../lib/snackbar/snackbar';
import { useGetNavigationActions } from '../../hooks/useGetNavigationActions';
import { useOpenSystemSettings } from '../../lib/open-native-settings/useOpenSystemSettings';
import { useCompleteEmailActionSheet } from '../../components/modals/CompleteEmailPopup';
import { messages as openNativeSettingMessages } from '../../pages/StoreLocator/messages';
import { messages } from './messages';
import { useInfoActionSheet } from '../../components/modals/InfoActionSheet';

export const useAlerts = () => {
  const { pushToStoreLocator } = useGetNavigationActions();
  const { open: openCompleteEmailActionSheet } = useCompleteEmailActionSheet();
  const snackbar = useSnackbar();
  const { open: openInfoActionSheet } = useInfoActionSheet();
  const intl = useIntl();

  const openNativeSettings = useOpenSystemSettings();

  const showNoGeolocationAvailableAlert = useCallback(
    () =>
      openInfoActionSheet({
        title: intl.formatMessage(openNativeSettingMessages['storeLocator:provideLocationPopupTitle']),
        description: intl.formatMessage(openNativeSettingMessages['storeLocator:provideLocationPopupDescription']),
        actionButtonText: intl.formatMessage(
          openNativeSettingMessages['storeLocator:provideLocationPopupConfirmButton'],
        ),
        onActionButtonClick: Capacitor.isNativePlatform() ? () => openNativeSettings('application_details') : undefined,
        isServiceLinkEnabled: true,
      }),
    [intl, openInfoActionSheet, openNativeSettings],
  );

  const showNoStoreFoundAlert = useCallback(
    () =>
      openInfoActionSheet({
        title: intl.formatMessage(messages['notClosePayAtPumpPopup:title']),
        description: intl.formatMessage(messages['notClosePayAtPumpPopup:description']),
        actionButtonText: intl.formatMessage(messages['notClosePayAtPumpPopup:actionButtonText']),
        onActionButtonClick: pushToStoreLocator,
        isServiceLinkEnabled: true,
      }),
    [intl, openInfoActionSheet, pushToStoreLocator],
  );

  const showNoMobilePaymentAlert = useCallback(() => {
    openInfoActionSheet({
      title: intl.formatMessage(messages['cantPayAtPumpPopup:title']),
      description: intl.formatMessage(messages['cantPayAtPumpPopup:description']),
      actionButtonText: intl.formatMessage(messages['cantPayAtPumpPopup:actionButtonText']),
      onActionButtonClick: pushToStoreLocator,
      isServiceLinkEnabled: true,
    });
  }, [intl, openInfoActionSheet, pushToStoreLocator]);

  const showCompleteEmailAlert = useCallback(
    (params: { onCompleted?(): void } = {}) => {
      openCompleteEmailActionSheet({
        onCompleted: params.onCompleted,
      });
    },
    [openCompleteEmailActionSheet],
  );

  const showNoConnectionAlert = useCallback(() => {
    snackbar.open(intl.formatMessage(messages['noConnectionPopup:noConnectionText']), { variant: 'error' });
  }, [intl, snackbar]);

  return useMemo(
    () => ({
      showNoGeolocationAvailableAlert,
      showNoStoreFoundAlert,
      showNoConnectionAlert,
      showNoMobilePaymentAlert,
      showCompleteEmailAlert,
    }),
    [
      showNoMobilePaymentAlert,
      showNoConnectionAlert,
      showNoGeolocationAvailableAlert,
      showNoStoreFoundAlert,
      showCompleteEmailAlert,
    ],
  );
};
