import { Stack, Box } from '@mui/material';

import { PoweredByLogo } from '@ocx/ui-icons';
import { SizingMultiplier } from '../../sizing-multiplier.constant';

export const PoweredByOpenCommerce = () => {
  return (
    <Box mt="auto">
      <Stack alignItems="center" color="text.disabled" m={SizingMultiplier.xl}>
        <PoweredByLogo sx={{ width: 171, height: 40 }} />
      </Stack>
    </Box>
  );
};
