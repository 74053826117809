import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Paper } from '@mui/material';
import AdminPanelSettingsOutlined from '@mui/icons-material/AdminPanelSettingsOutlined';
import AssignmentOutlined from '@mui/icons-material/AssignmentOutlined';
import ForumOutlined from '@mui/icons-material/ForumOutlined';
import LiveHelpOutlined from '@mui/icons-material/LiveHelpOutlined';

import { List, ListItemLink, ListItemText, ListItemButton, ListItemStartIcon, ListHeader } from '@ocx/ui';
import { useConfiguration } from '../../../modules/configuration/useConfiguration';
import { messages } from '../messages';
import { SizingMultiplier } from '../../../theme/constants';
import { SupportListItem } from '../../../modules/support/components/support-list-item';

interface IAboutListProps {
  isHowDoesItWorkAvailable: boolean;
  handleHowDoesItWorkOfferClick?(): void;
}

export const AboutList: React.FC<IAboutListProps> = ({ isHowDoesItWorkAvailable, handleHowDoesItWorkOfferClick }) => {
  const { config } = useConfiguration();

  return (
    <Paper elevation={0} sx={{ overflow: 'hidden', paddingTop: SizingMultiplier.md }}>
      <ListHeader>
        <FormattedMessage {...messages['profile:aboutSectionTitle']} />
      </ListHeader>

      <List>
        {isHowDoesItWorkAvailable && (
          <ListItemButton onClick={handleHowDoesItWorkOfferClick}>
            <ListItemStartIcon>
              <LiveHelpOutlined />
            </ListItemStartIcon>
            <ListItemText>
              <FormattedMessage {...messages['profile:aboutActions:howDoesItWork']} />
            </ListItemText>
          </ListItemButton>
        )}

        <ListItemLink href={config.privacyPolicy.url} target="_blank">
          <ListItemStartIcon>
            <AdminPanelSettingsOutlined />
          </ListItemStartIcon>
          <ListItemText>
            <FormattedMessage {...messages['profile:about:privacyPolicyButtonTitle']} />
          </ListItemText>
        </ListItemLink>

        <ListItemLink href={config.termsConditions.url} target="_blank">
          <ListItemStartIcon>
            <AssignmentOutlined />
          </ListItemStartIcon>
          <ListItemText>
            <FormattedMessage {...messages['profile:about:termsAndConditionsButtonTitle']} />
          </ListItemText>
        </ListItemLink>

        <SupportListItem>
          <ListItemStartIcon>
            <ForumOutlined />
          </ListItemStartIcon>
          <ListItemText>
            <FormattedMessage {...messages['profile:about:supportAndFeedbackButtonTitle']} />
          </ListItemText>
        </SupportListItem>
      </List>
    </Paper>
  );
};
