import { TypePolicies } from '@apollo/client';

export const externalOffersTypePolicy: TypePolicies = {
  Banner: {
    keyFields: ['bannerConfigurationUuid', 'trackingId'],
  },
  /*
   * Cached inside of Banner
   */
  BannerContent: {
    keyFields: false,
  },
};
