import { IneligibilityHandlingStrategy, IneligibilityReason } from './eligibility.types';

export const inelegibilityReasonStrategyComparator = (a: IneligibilityReason, b: IneligibilityReason): number => {
  if (a.strategy === b.strategy) {
    return 0;
  }
  if (a.strategy === IneligibilityHandlingStrategy.Hide) {
    return -1;
  }
  if (a.strategy === IneligibilityHandlingStrategy.DisableInteractions) {
    return -1;
  }

  return 1;
};
