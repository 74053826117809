import React from 'react';

export const HomeTabBarIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  const { color = 'currentColor', ...rest } = props;
  return (
    <svg {...rest} fill={color} height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
      <path d="m20.0001 8.00049-6-5.26c-.55-.49195-1.2621-.76393-2-.76393s-1.45.27198-2 .76393l-6.00001 5.26c-.31763.28408-.5711.63256-.74352 1.02225-.17243.38969-.25986.81164-.25648 1.23776v8.74c0 .7956.31608 1.5587.87868 2.1213.56261.5626 1.32568.8787 2.12132.8787h12.00001c.7956 0 1.5587-.3161 2.1213-.8787s.8787-1.3257.8787-2.1213v-8.75c.002-.42444-.0862-.84447-.2585-1.23232-.1724-.38786-.4251-.73473-.7415-1.01769zm-6 12.00001h-4v-5c0-.2652.1054-.5196.2929-.7071s.4419-.2929.7071-.2929h2c.2652 0 .5196.1054.7071.2929s.2929.4419.2929.7071zm5-1c0 .2652-.1054.5196-.2929.7071s-.4419.2929-.7071.2929h-2v-5c0-.7957-.3161-1.5587-.8787-2.1213s-1.3257-.8787-2.1213-.8787h-2c-.7957 0-1.55872.3161-2.12133.8787s-.87868 1.3256-.87868 2.1213v5h-2c-.26521 0-.51957-.1054-.7071-.2929-.18754-.1875-.2929-.4419-.2929-.7071v-8.75c.00018-.142.0306-.28231.08922-.41163.05863-.12931.14412-.24467.25079-.33838l6-5.25c.1825-.16031.4171-.24873.66-.24873s.4775.08842.66.24873l6 5.25c.1067.09371.1922.20907.2508.33838.0586.12932.089.26963.0892.41163z" />
    </svg>
  );
};
