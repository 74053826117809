import localforage from 'localforage';

export const sharedStorage = localforage.createInstance({
  name: 'Experience-Shared-Storage',
  storeName: 'ocx-shared-storage',
  description: 'Stores shared data',
});

export const buildSharedStorageItemName = (membershipId: string, storageName: string) =>
  `${membershipId}-${storageName}`;
