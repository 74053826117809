import { Box, styled } from '@mui/material';

import { SizingMultiplier } from '../sizing-multiplier.constant';

// TODO: think about borderRadius
export const Paper = styled(Box)(({ theme }) => ({
  background: theme.palette.background.paper,
  padding: theme.spacing(SizingMultiplier.md),
  borderRadius: SizingMultiplier.xl * theme.shape.borderRadius,
}));
