import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  'car-wash-selector-popup:title-text': {
    id: 'car-wash-selector-popup:title-text',
    defaultMessage: 'Add car wash',
    description: 'Car Wash options list title text',
  },
  'car-wash-selector-popup:no-car-wash-option-text': {
    id: 'car-wash-selector-popup:no-car-wash-option-text',
    defaultMessage: 'No Car Wash',
    description: 'Car Wash options list: no car wash option next',
  },
});
