import { useSearchParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';

import { useApolloClient } from '@apollo/client';
import { ActivateOfferMutation, OfferActivationStatus } from '@ocx/graphql';
import { Offer } from '../../modules/offers/offer.types';
import { useActivateOfferHelpers } from '../Home/features/useGetOfferOnLoyaltyActionClick';

export const useOfferAutoActivator = (offer: Offer | null) => {
  const [isActivated, setIsActivated] = useState(false);
  const [searchParams] = useSearchParams();
  const client = useApolloClient();

  const handleActivateCompleted = useCallback(
    (data: ActivateOfferMutation) => {
      if (!data.offerActivate?.id) {
        return;
      }

      const { activationStatus } = data.offerActivate;

      setIsActivated(activationStatus === OfferActivationStatus.Activated);

      if (activationStatus !== OfferActivationStatus.Activated) {
        return;
      }

      // Issue: When an offer is "activating", a slow "offers" query is still in flight. "Activation" always completes faster,
      // but the slow "offers" query finishes last and then updates the Apollo cache with outdated data.
      // Important: We can't use "cache.modify" inside the "update" function (inside mutation hook) because "update" runs immediately after the mutation, ignoring the "awaitRefetchQueries" flag.
      // Solution: The correct place to run "cache.modify" is inside "onCompleted", which respects "awaitRefetchQueries" and waits for the slow "offers" query to finish.

      const cacheId = client.cache.identify({ __typename: 'Offer', id: data.offerActivate.id });

      client.cache.modify({
        id: cacheId,
        fields: { activationStatus: () => activationStatus },
        broadcast: true,
      });
    },
    [client.cache],
  );

  const { activate, activating } = useActivateOfferHelpers({
    onCompleted: handleActivateCompleted,
    awaitRefetchQueries: true,
  });

  useEffect(() => {
    if (offer?.activationStatus !== OfferActivationStatus.RequiresActivation || !searchParams.has('activate', 'true')) {
      return;
    }
    if (activating) {
      return;
    }
    if (isActivated) {
      return;
    }

    activate(offer);
  }, [searchParams, offer?.activationStatus, offer, activate, activating, isActivated]);

  return { activating };
};
