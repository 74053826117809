import { useCallback, useMemo } from 'react';

import { useOfferPurchaseMutation } from '@ocx/graphql';
import { OfferEngagementType, SpinWheelGameOffer } from '../../offers/offer.types';
import { logInDev } from '../../../lib/logger/logger';

type UseGameplayVoucherParams = {
  offer: SpinWheelGameOffer; // Will be accepting more game types later
  onPurchased: () => void;
  onError: () => void;
};

export const useGameplayVoucher = (params: UseGameplayVoucherParams) => {
  const { offer, onPurchased, onError } = params;
  const {
    voucherCount,
    engagementType,
    earningMechanism: { gameplayVoucher },
  } = offer;

  const [createOfferPurchase, { loading: isPurchasing }] = useOfferPurchaseMutation({
    refetchQueries: ['currentCustomer', 'offer'],
    awaitRefetchQueries: true,
    onCompleted: onPurchased,
    onError,
  });

  const purchase = useCallback(() => {
    if (!gameplayVoucher?.id) {
      return;
    }
    createOfferPurchase({ variables: { input: { count: 1, offerId: gameplayVoucher.id } } }).catch(logInDev);
  }, [createOfferPurchase, gameplayVoucher?.id]);

  return useMemo(() => {
    const isVoucheredGame = engagementType === OfferEngagementType.GameVoucher;
    if (!isVoucheredGame || !gameplayVoucher) {
      return null;
    }

    return {
      isPurchasing,
      count: voucherCount,
      id: gameplayVoucher.id,
      price: gameplayVoucher.price,
      purchase,
    };
  }, [engagementType, gameplayVoucher, isPurchasing, purchase, voucherCount]);
};
