import { useNavigate } from 'react-router-dom';
import { useCallback, useState } from 'react';

import { useGetSurveysQuery } from '@ocx/graphql';
import { useShowGenericErrorSnackbar } from '../../hooks/useShowGenericErrorSnackbar';
import { sanitizeSurvey } from '../../modules/survey/survey.utils';

type UseSurveyDetailsControllerParams = { surveyUuid: string; returnPath: string };

export const useSurveyDetailsController = ({ surveyUuid, returnPath }: UseSurveyDetailsControllerParams) => {
  const [isOpen, setIsOpen] = useState(true);

  const showGenericErrorSnackbar = useShowGenericErrorSnackbar();
  const navigate = useNavigate();

  const { data, loading } = useGetSurveysQuery({
    variables: { filter: { includeDismissed: true, uuidContains: [surveyUuid] } },
    skip: !surveyUuid,
    fetchPolicy: 'cache-and-network',
    onError: showGenericErrorSnackbar,
  });

  const survey = data?.surveys.edges[0]?.node;
  const sanitizedSurvey = survey ? sanitizeSurvey(survey) : null;

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleExit = useCallback(() => {
    navigate(returnPath, { replace: true });
  }, [navigate, returnPath]);

  return {
    isOpen,
    handleClose,
    handleExit,
    sanitizedSurvey,
    loading,
  };
};
