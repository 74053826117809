import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useMemo } from 'react';

import { InfoActionSheetController } from './info-action-sheet.controller';

const InfoActionSheetModal = NiceModal.create(InfoActionSheetController);

export const useInfoActionSheet = () => {
  const { show } = useModal(InfoActionSheetModal);
  return useMemo(() => ({ open: show }), [show]);
};
