import React from 'react';
import { ButtonBase, ButtonBaseProps } from '@mui/material';

import { PlusIcon } from '../../Icons/PlusIcon';

export interface IPlusButtonProps {
  onClick: ButtonBaseProps['onClick'];
  name: string;
}

export const PlusButton: React.FC<IPlusButtonProps> = ({ onClick, name }) => {
  return (
    <ButtonBase
      name={name}
      onClick={onClick}
      sx={{
        width: 32,
        height: 32,
        borderRadius: 2,
        backgroundColor: 'action.selected',
        color: 'text.primary',
      }}>
      <PlusIcon />
    </ButtonBase>
  );
};
