import React from 'react';
import { Box, Button, Stack, useTheme } from '@mui/material';
import { useIntl } from 'react-intl';
import { Typography } from '@ocx/ui';
import { RisingSun } from '@ocx/spin-wheel';

import { SizingMultiplier } from '../../theme/constants';
import { buttonTextMessages } from '../../modules/localization/button-text.messages';
import { OfferDetailsTermsAndDisclaimer } from '../../components/OfferDetails/OfferDetailsTermsAndDisclaimer';
import { LegalMarkdown } from '../../components/LegalMarkdown/LegalMarkdown';

export type SmsOptInProps = {
  title: string;
  shortDescription: string | null;
  imageUrl: string | null;
  terms: string | null;
  disclaimer: string | null;
  disclaimerUrl: string | null;
  onAccepted(): void;
  onRejected(): void;
};

const SHINE_EFFECT_HEIGHT = 600;
const SHINE_EFFECT_OFFSET = -150;
const OFFER_IMAGE_MAX_HEIGHT = '35vh';
const OFFER_IMAGE_MAX_WIDTH = '100%';

export const SmsOptIn = (props: SmsOptInProps) => {
  const { title, shortDescription, imageUrl, disclaimer, disclaimerUrl, terms, onAccepted, onRejected } = props;
  const intl = useIntl();
  const theme = useTheme();

  return (
    <Box sx={{ position: 'relative', overflowY: 'auto', overflowX: 'hidden' }}>
      {!!imageUrl && (
        <RisingSun
          style={{
            alignSelf: 'center',
            position: 'absolute',
            top: SHINE_EFFECT_OFFSET,
            height: SHINE_EFFECT_HEIGHT,
            width: '100%',
            opacity: '30%',
          }}
          backColor="transparent"
          raysColor={theme.palette.primary.main}
          sideFadingEndPosition={90}
        />
      )}
      <Stack
        position="relative"
        alignItems="stretch"
        spacing={SizingMultiplier.md}
        px={SizingMultiplier.lg}
        py={SizingMultiplier.xl}>
        {imageUrl && (
          <img
            src={imageUrl}
            alt={title}
            style={{ alignSelf: 'center', maxHeight: OFFER_IMAGE_MAX_HEIGHT, maxWidth: OFFER_IMAGE_MAX_WIDTH }}
          />
        )}
        <Typography align="center">{title}</Typography>

        {!!terms && <LegalMarkdown content={terms} />}

        <Button variant="contained" size="large" onClick={onAccepted}>
          <Stack>
            {intl.formatMessage(buttonTextMessages['button-text:yes'])}
            {!!shortDescription && (
              <Typography
                variant="caption"
                sx={{
                  textTransform: 'none',
                }}>
                {shortDescription}
              </Typography>
            )}
          </Stack>
        </Button>
        <Button variant="text" size="large" onClick={onRejected}>
          {intl.formatMessage(buttonTextMessages['button-text:no-thanks'])}
        </Button>
        {Boolean(disclaimer || disclaimerUrl) && (
          <OfferDetailsTermsAndDisclaimer disclaimer={disclaimer} disclaimerUrl={disclaimerUrl} />
        )}
      </Stack>
    </Box>
  );
};
