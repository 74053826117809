import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useRudderStack } from '../lib/rudderStack/useRudderStack';

const usePageTracking = () => {
  const location = useLocation();
  const { triggerEvent, triggerLocation } = useRudderStack();

  useEffect(() => {
    if (location.state?.skipPageTracking) {
      return;
    }

    triggerLocation();
  }, [location, triggerLocation]);

  useEffect(() => {
    triggerEvent('loading_completed');
  }, [triggerEvent]);
};

export default usePageTracking;
