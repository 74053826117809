import React from 'react';

export const StarInCircleIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  const { color = 'currentColor', ...rest } = props;
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...rest} fill={color}>
      <path d="M12 1C9.82441 1 7.69767 1.64514 5.88873 2.85383C4.07979 4.06253 2.66989 5.78049 1.83733 7.79048C1.00477 9.80047 0.786929 12.0122 1.21137 14.146C1.6358 16.2798 2.68345 18.2398 4.22183 19.7782C5.76021 21.3166 7.72022 22.3642 9.85401 22.7886C11.9878 23.2131 14.1995 22.9952 16.2095 22.1627C18.2195 21.3301 19.9375 19.9202 21.1462 18.1113C22.3549 16.3023 23 14.1756 23 12C23 9.08262 21.8411 6.28473 19.7782 4.22183C17.7153 2.15893 14.9174 1 12 1ZM12 21C10.22 21 8.47992 20.4722 6.99987 19.4832C5.51983 18.4943 4.36628 17.0887 3.68509 15.4442C3.0039 13.7996 2.82567 11.99 3.17294 10.2442C3.5202 8.49836 4.37737 6.89471 5.63604 5.63604C6.89472 4.37737 8.49836 3.5202 10.2442 3.17293C11.99 2.82567 13.7996 3.0039 15.4442 3.68508C17.0887 4.36627 18.4943 5.51983 19.4832 6.99987C20.4722 8.47991 21 10.22 21 12C21 14.3869 20.0518 16.6761 18.364 18.364C16.6761 20.0518 14.387 21 12 21Z" />
      <path d="M17.9799 10.215C17.942 10.1085 17.8732 10.0148 17.7817 9.94555C17.6903 9.87625 17.5801 9.83426 17.4646 9.82464L14.0552 9.34104L12.5273 6.32868C12.4782 6.23017 12.4016 6.14709 12.3062 6.08896C12.2109 6.03083 12.1006 6 11.988 6C11.8755 6 11.7652 6.03083 11.6698 6.08896C11.5744 6.14709 11.4978 6.23017 11.4488 6.32868L9.92084 9.33521L6.51148 9.82464C6.40059 9.83997 6.29633 9.88522 6.21054 9.95525C6.12475 10.0253 6.06087 10.1173 6.02614 10.2209C5.99435 10.322 5.9915 10.4297 6.01789 10.5324C6.04428 10.635 6.09892 10.7288 6.17594 10.8035L8.65057 13.1342L8.05139 16.4437C8.02999 16.5529 8.04119 16.6658 8.08366 16.769C8.12613 16.8723 8.1981 16.9616 8.29106 17.0263C8.38167 17.0893 8.48853 17.1265 8.59967 17.1337C8.7108 17.1409 8.8218 17.1178 8.9202 17.0671L11.988 15.5114L15.0439 17.0729C15.128 17.1191 15.2229 17.1432 15.3195 17.1429C15.4464 17.1433 15.5702 17.1045 15.673 17.0321C15.766 16.9674 15.8379 16.8781 15.8804 16.7749C15.9229 16.6716 15.9341 16.5587 15.9127 16.4495L15.3135 13.14L17.7881 10.8093C17.8746 10.7381 17.9386 10.6444 17.9725 10.5392C18.0065 10.434 18.009 10.3216 17.9799 10.215Z" />
    </svg>
  );
};
