import { Stack } from '@mui/material';

import { OfferPresentationType } from '@ocx/graphql';
import { Offer } from '../../../modules/offers/offer.types';
import { OfferCardController } from './OfferCardController';
import { OfferCard } from '../../../components/OfferCard';

export type FeaturedOffersProps = {
  offers: Offer[];
};

export const FeaturedOffersController = ({ offers }: FeaturedOffersProps) => {
  if (!offers.length) {
    return null;
  }

  return (
    <Stack spacing={1}>
      {offers.map((offer) => (
        <OfferCardController key={offer.id} offer={offer}>
          {(props) => (
            <OfferCard
              variant={offer.presentationType === OfferPresentationType.Banner ? 'banner' : 'featured'}
              {...props}
            />
          )}
        </OfferCardController>
      ))}
    </Stack>
  );
};
