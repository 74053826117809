import { Box } from '@mui/material';
import { useMemo } from 'react';
import { Typography, TypographyProps } from '@ocx/ui';

import { PointsStrategy } from '@ocx/graphql';
import { SizingMultiplier } from '../../theme/constants';
import { formatPrice } from '../../lib/formatters/formatPrice';
import { PointsCoin } from '../Icons/PointsCoin';
import { DollarCoin } from '../Icons/DollarCoin';

type IconSize = 'sm' | 'md' | 'lg';

export type IPointsChangeProps = {
  points: number;
  allowPlus?: boolean;
  position?: 'right' | 'left';
  pointsStrategy: Exclude<PointsStrategy, PointsStrategy.NotUsed>;
  typographyProps?: TypographyProps;
  iconSize?: IconSize;
};

const iconSizeMap: Record<IconSize, number> = {
  sm: 16,
  md: 20,
  lg: 32,
};

export const PointsChange = (props: IPointsChangeProps) => {
  const { points, allowPlus = true, position = 'left', pointsStrategy, typographyProps, iconSize = 'sm' } = props;
  const isPositionRight = position === 'right';
  const iconWidthHeight = iconSizeMap[iconSize] || iconSizeMap.sm;

  const value = useMemo(() => {
    if (pointsStrategy === PointsStrategy.Cents) {
      return formatPrice(points);
    }

    return points;
  }, [pointsStrategy, points]);

  // TODO: discuss points position with designer - it should be the same
  return (
    <Box display="flex" alignItems="center">
      {pointsStrategy === PointsStrategy.Cents && <DollarCoin width={iconWidthHeight} height={iconWidthHeight} />}
      {pointsStrategy === PointsStrategy.Points && <PointsCoin width={iconWidthHeight} height={iconWidthHeight} />}
      <Typography
        variant="subtitle2"
        component="span"
        sx={{
          order: isPositionRight ? -1 : 1,
          wordBreak: 'keep-all',
          mr: isPositionRight ? SizingMultiplier.xs : 0,
          ml: !isPositionRight ? SizingMultiplier.xs : 0,
        }}
        {...typographyProps}>
        {allowPlus && points > 0 && '+'}
        {value}
      </Typography>
    </Box>
  );
};
