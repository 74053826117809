import { MaskingRule } from './rudderStack.types';
import { maskingRules as defaultMaskingRules } from './rudderStack.constants';

export function maskPathname(pathname: string, maskingRules: MaskingRule[] = defaultMaskingRules) {
  for (let i = 0; i < maskingRules.length; i += 1) {
    const { pattern, replacement } = maskingRules[i];

    if (pattern.test(pathname)) {
      return pathname.replace(pattern, replacement);
    }
  }

  return pathname;
}

export function maskHref(href: string, maskingRules: MaskingRule[] = defaultMaskingRules) {
  const url = new URL(href);
  const maskedPathname = maskPathname(url.pathname, maskingRules);
  url.pathname = maskedPathname;

  return url.href;
}

export function getHrefWithPathname(pathname: string) {
  const url = new URL(window.location.href);
  url.pathname = pathname;
  return url.href;
}
