import orderBy from 'lodash/orderBy';
import groupBy from 'lodash/groupBy';
import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';

import { LoyaltyActionHistoryQuery, TransactionHistoryQuery } from '@ocx/graphql';
import { formatDateTime, parseDate } from '../../lib/date/date';
import { ITransactionHistoryItem, TransactionHistorySegment } from './transaction-history.types';
import { DateTimeFormat } from '../../lib/date/date.types';

// Transactions are grouped by YYYY + Month combination
const getTransactionsGroupTitle = (date: string): string =>
  formatDateTime(parseDate(date, DateTimeFormat.DateYearMonthGroupBy), DateTimeFormat.MonthYear);

export const createTransactionsMonthYearGroup = ([YYYYMM, items]: [
  string,
  ITransactionHistoryItem[],
]): TransactionHistorySegment => [
  getTransactionsGroupTitle(YYYYMM),
  // Sorts items within group
  orderBy(items, ['date'], ['desc']),
];

export const createHistoryListSegments = (items: ITransactionHistoryItem[]): TransactionHistorySegment[] => {
  // Creates an array, grouped by YYYYMM, [
  //    [202110, [C, D]],
  //    [202210, [B, A]]
  // ]
  const historyGroupedMyMonthYear = Object.entries(groupBy(items, 'groupedDate'));
  // Sorts array by YYYYMM, [
  //    [202210, [B, A]]
  //    [202110, [C, D]],
  // ]
  const orderedGroups = orderBy(historyGroupedMyMonthYear, ['[0]'], ['desc']);
  // Creates sections, [
  //    [November 2022, [A, B]]
  //    [October 2022, [C, D]],
  // ]
  return orderedGroups.map(createTransactionsMonthYearGroup);
};

export const transactionHistoryFetchMoreResolver = (
  prev: TransactionHistoryQuery,
  next: TransactionHistoryQuery | undefined,
): TransactionHistoryQuery => {
  if (!next?.customer?.transactions?.edges?.length) {
    return prev;
  }

  const transactionsPath = 'customer.transactions.edges';
  const transactions = [...(prev.customer?.transactions?.edges || []), ...(next?.customer?.transactions.edges || [])];

  const response = cloneDeep(prev);
  set(response, transactionsPath, transactions);
  return response;
};

export const loyaltyActivitiesFetchMoreResolver = (
  prev: LoyaltyActionHistoryQuery,
  next: LoyaltyActionHistoryQuery | undefined,
): LoyaltyActionHistoryQuery => {
  if (
    !next?.customer?.membership?.pointChanges?.edges.length &&
    !next?.customer?.membership?.transactions?.edges.length
  ) {
    return prev;
  }

  const pointChangesTransactionsPath = 'customer.membership.pointChanges.edges';
  const activateTransactionsPath = 'customer.membership.transactions.edges';

  const pointChangesTransactions = [
    ...(prev.customer?.membership?.pointChanges?.edges || []),
    ...(next?.customer?.membership?.pointChanges?.edges || []),
  ];
  const activateTransactions = [
    ...(prev.customer?.membership?.transactions?.edges || []),
    ...(next?.customer?.membership?.transactions?.edges || []),
  ];

  const response = cloneDeep(prev);
  set(response, pointChangesTransactionsPath, pointChangesTransactions);
  set(response, activateTransactionsPath, activateTransactions);
  return response;
};
