import { Box } from '@mui/material';

import { Loading } from '../../components/Loading/Loading';

export const SignupWithLink = () => {
  return (
    <Box textAlign="center" margin="auto">
      <Loading />
    </Box>
  );
};
