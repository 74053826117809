import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const ArrowRightIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M14.83 11.29l-4.24-4.24a1 1 0 10-1.42 1.41L12.71 12l-3.54 3.54a1 1 0 000 1.41.999.999 0 00.71.29.998.998 0 00.71-.29l4.24-4.24a1 1 0 000-1.42z" />
    </SvgIcon>
  );
};
