import { withTryCatch } from '../error-handling/utils';
import { Event, AnalyticsInstance } from './rudderStack.types';

export const triggerEvent = withTryCatch((...[event, params]: Event): void => {
  window.gtag?.('event', event, params);
});

export const triggerLocation = withTryCatch((path?: string) => {
  const pagePath = path || window.location.pathname;

  window.gtag?.('event', 'page_view', {
    pagePath,
  });
});

export const setGAUserId = withTryCatch((userId: string) => {
  window.gtag?.('set', { user_id: userId });
});

export const cleanGAUserId = withTryCatch(() => {
  window.gtag?.('set', {
    user_id: undefined,
  });
});

export const getGaInstance = (): AnalyticsInstance => {
  return {
    triggerEvent,
    triggerLocation,
    identify: setGAUserId,
    identifyAudiences: () => {},
    init: () => {},
    reset: cleanGAUserId,
  };
};
