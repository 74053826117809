import { useMemo } from 'react';
import { SpinWheelGame } from '@ocx/spin-wheel';

import { IPointsChangeProps, PointsChange } from '../../../../components/PointsChange/PointsChange';
import { SizingMultiplier } from '../../../../theme/constants';
import { useConfiguration } from '../../../../modules/configuration/useConfiguration';
import { SpinWheelGameOffer } from '../../../../modules/offers/offer.types';
import { useMembership } from '../../../../modules/membership/use-membership.hook';
import { mapSegmentsToSectors, mapSpinWheelGameCustomDataToProps } from '../spin-wheel-game.utils';
import { SpinWheelSectorContent } from '../components';
import { useSpinWheelVoucheredGameController } from './use-spin-wheel-vouchered-game-controller.hook';

type SpinWheelVoucheredGameControllerProps = {
  offer: SpinWheelGameOffer;
  onLastGameplayEnd(): void;
};
export const SpinWheelVoucheredGameController = (props: SpinWheelVoucheredGameControllerProps) => {
  const pointsStrategy = useConfiguration('loyaltyProgram.pointsStrategy');
  const { membership } = useMembership();
  const { offer, onLastGameplayEnd } = props;
  const {
    customData,
    earningMechanism: { prizePool },
    marketingContent: { terms, termsUrl, disclaimer, disclaimerUrl },
  } = offer;

  const {
    gameplayCount,
    handlePlayClick,
    handleSpinningEnd,
    isSpinning,
    winningSectorIndex,
    isActionButtonDisabled,
    // Purchase
    isPurchaseAvailable,
    isPurchasing,
    handlePurchaseClick,
    purchasePrice,
    isGameLimitReached,
  } = useSpinWheelVoucheredGameController({ offer, onLastGameplayEnd });

  const { segments: sectorsConfiguration } = customData?.spinWheelGame ?? {};
  const sectors = useMemo(
    () => mapSegmentsToSectors(prizePool, sectorsConfiguration),
    [prizePool, sectorsConfiguration],
  );

  return (
    <SpinWheelGame
      // play button configuration
      numberOfGamePlays={gameplayCount}
      onPlayClick={handlePlayClick}
      isPlayButtonVisible={gameplayCount > 0}
      isPlayButtonDisabled={isActionButtonDisabled || isGameLimitReached}
      // purchase button configuration
      onPurchaseClick={handlePurchaseClick}
      isPurchaseButtonVisible={gameplayCount === 0 && isPurchaseAvailable}
      isPurchaseButtonLoading={isPurchasing}
      isPurchaseButtonDisabled={
        isGameLimitReached ||
        isActionButtonDisabled ||
        !isPurchaseAvailable ||
        isPurchasing ||
        (purchasePrice !== null && purchasePrice > membership.points)
      }
      // animation
      onSpinningEnd={handleSpinningEnd}
      isSpinning={isSpinning}
      winningSectorIndex={winningSectorIndex}
      // configuration
      sectors={sectors}
      sectorContentRender={SpinWheelSectorContent}
      sizingMultiplier={SizingMultiplier}
      purchasePrice={
        isPurchaseAvailable && purchasePrice !== null ? (
          <PointsChange
            points={-purchasePrice}
            pointsStrategy={pointsStrategy as IPointsChangeProps['pointsStrategy']}
            position="right"
          />
        ) : null
      }
      // disclaimer
      terms={terms || undefined}
      termsUrl={termsUrl || undefined}
      disclaimer={disclaimer || undefined}
      disclaimerUrl={disclaimerUrl || undefined}
      // customisation
      {...mapSpinWheelGameCustomDataToProps(customData)}
    />
  );
};
