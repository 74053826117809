import { Palette } from '@mui/material';

import { OfferLabelKey } from './types';

type CreateOfferLabelPalette = (offerLabel: { [key in OfferLabelKey]: string }) => Palette['offerLabel'];

export const createOfferLabelPalette: CreateOfferLabelPalette = (offerLabel) => ({
  PunchCard: {
    color: offerLabel.punchCardText,
    backgroundColor: offerLabel.punchCardBackground,
  },
  Reward: {
    color: offerLabel.rewardText,
    backgroundColor: offerLabel.rewardBackground,
  },
  Gameplay: {
    color: offerLabel.gameplayText,
    backgroundColor: offerLabel.gameplayBackground,
  },
  VoucheredGameplay: {
    color: offerLabel.gameplayText,
    backgroundColor: offerLabel.gameplayBackground,
  },
  Bonus: {
    color: offerLabel.bonusText,
    backgroundColor: offerLabel.bonusBackground,
  },
  Savings: {
    color: offerLabel.savingsText,
    backgroundColor: offerLabel.savingsBackground,
  },
  Challenge: {
    color: offerLabel.challengeText,
    backgroundColor: offerLabel.challengeBackground,
  },
  // TODO: add colors to DS
  Expires: {
    color: offerLabel.expiresText,
    backgroundColor: offerLabel.expiresBackground,
  },
  // TODO: remove Entries or EntriesReward
  Entries: {
    color: offerLabel.entriesText,
    backgroundColor: offerLabel.entriesBackground,
  },
  EntriesReward: {
    color: offerLabel.entriesText,
    backgroundColor: offerLabel.entriesBackground,
  },
});
