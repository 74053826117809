import React, { useCallback } from 'react';
import { useModal } from '@ebay/nice-modal-react';
import { Box } from '@mui/material';

import { ModalFullScreenDrawer, ModalFullScreenHeader } from '../components';
import { Receipt } from '../../../pages/PayAtPump/Receipt/Receipt';
import { IReceiptOption } from '../../../modules/transaction/types';

export type TransactionReceiptPopupProps = {
  onClose?(): void;
  receipt: {
    addressLine: string;
    carWashCode?: string | null;
    receiptLines: string[];
    options: IReceiptOption[];
    total: number;
  };
};

export const TransactionReceiptPopupController = (props: TransactionReceiptPopupProps) => {
  const { visible, hide, remove } = useModal();

  const { receipt, onClose } = props;

  const handleClose = useCallback(() => {
    onClose?.();
    hide();
  }, [onClose, hide]);

  return (
    <ModalFullScreenDrawer open={visible} onClose={handleClose} onExited={remove}>
      <ModalFullScreenHeader onBackClick={hide} />
      <Box
        sx={{
          overflow: 'auto',
          overscrollBehavior: 'contain',
          alignSelf: 'stretch',
          paddingBottom: 'var(--ion-safe-area-bottom, 0px)',
        }}>
        <Receipt {...receipt} onCloseButtonClick={handleClose} />
      </Box>
    </ModalFullScreenDrawer>
  );
};
