import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useMemo } from 'react';

import { ProfileCompletedPopupController } from './profile-completed-popup.controller';

const ProfileCompletedPopupModal = NiceModal.create(ProfileCompletedPopupController);

export const useProfileCompletedPopup = () => {
  const { show } = useModal(ProfileCompletedPopupModal);
  return useMemo(() => ({ open: show }), [show]);
};
