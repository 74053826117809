import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  headerTitle: {
    id: 'ready-to-use:header:title',
    defaultMessage: 'My Rewards',
    description: 'Ready to use offers header title',
  },
  headerSubtitle: {
    id: 'ready-to-use:header:subtitle',
    defaultMessage: 'To redeem next time you fuel up, just enter your phone number at the pump!',
    description: 'Ready to use offers header subtitle',
  },
  noOffersTitle: {
    id: 'ready-to-use:no-offers:title',
    defaultMessage: 'No Rewards Available',
    description: 'Title for empty offers list',
  },
  noOffersDescription: {
    id: 'ready-to-use:no-offers:description',
    defaultMessage: 'See what offers are available to earn and redeem for rewards',
    description: 'Description for empty offers list',
  },
});
