import { styled } from '@mui/material';

import { SizingMultiplier } from '../../theme/constants';

export type DynamicCardSlotPositionVertical = 'top' | 'bottom';
export type DynamicCardSlotPositionHorizontal = 'left' | 'right';
export interface IDynamicCardSlotProps {
  positionVertical?: DynamicCardSlotPositionVertical;
  positionHorizontal?: DynamicCardSlotPositionHorizontal;
}

export const DynamicCardSlot = styled('div', {
  shouldForwardProp: (prop: string) => !['positionVertical', 'positionHorizontal'].includes(prop),
})<IDynamicCardSlotProps>(({ theme, positionVertical, positionHorizontal }) => ({
  position: 'absolute',
  top: positionVertical === 'bottom' ? 'unset' : theme.spacing(SizingMultiplier.sm),
  bottom: positionVertical === 'bottom' ? theme.spacing(SizingMultiplier.sm) : 'unset',
  left: positionHorizontal === 'right' ? 'unset' : theme.spacing(SizingMultiplier.sm),
  right: positionHorizontal === 'right' ? theme.spacing(SizingMultiplier.sm) : 'unset',
}));

DynamicCardSlot.defaultProps = {
  positionVertical: 'top',
  positionHorizontal: 'left',
};
