import React, { ReactNode } from 'react';
import { defineMessage } from 'react-intl';

import { NavigationBar, Page, PageContent } from '@ocx/ui';
import { HtmlTitle } from '../../../components/router/HtmlTitle';
import { VendorLogo } from '../../../components/Layout/NavigationBar/VendorLogo/VendorLogo';
import { BackButton } from '../../../components/Layout/NavigationBar/BackButton/BackButton';

export type ReceiptLayoutProps = {
  children: ReactNode;
  modal?: boolean;
  safeAreaBottomOffsetEnabled?: boolean;
};

export const ReceiptLayout: React.FC<ReceiptLayoutProps> = ({ children, modal, safeAreaBottomOffsetEnabled }) => {
  return (
    <Page>
      <HtmlTitle
        title={defineMessage({
          id: 'receipt:htmlTitle',
          defaultMessage: 'Receipt',
          description: 'HTML title tag for Receipt Page',
        })}
      />
      <NavigationBar left={modal ? <BackButton /> : undefined} center={<VendorLogo />} />
      <PageContent bgcolor="background.paper" safeAreaBottomOffsetEnabled={safeAreaBottomOffsetEnabled}>
        {children}
      </PageContent>
    </Page>
  );
};
