import { setUser } from '@sentry/react';

type SentryUserContext = {
  id: string;
  membership_id: string;
  profile_completed: boolean;
  activation_state: string;
};

export const sentrySetUser = (context: SentryUserContext | null) => {
  setUser(context);
};
