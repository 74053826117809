import React from 'react';
import { styled } from '@mui/material';

import { IPinItemProps } from './types';

const PinItemComponent = styled('div', { shouldForwardProp: () => false })<IPinItemProps>(({ theme, value }) => ({
  height: 40,
  width: 40,
  margin: theme.spacing(0, 1.5),
  borderRadius: '50%',
  border: `solid 2px ${theme.palette.divider}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  ...(!!value && {
    height: 16,
    width: 16,
    margin: theme.spacing(1.5, 3),
    border: 'unset',
    backgroundColor: theme.palette.text.primary,
  }),
}));

export const PinItem: React.FC<IPinItemProps> = (props) => {
  const { value } = props;

  return <PinItemComponent value={value} />;
};
