import React from 'react';

import { useHowDoesItWork } from '../../../modules/tutorial/howDoesItWork/hooks/useHowDoesItWork';
import { HowDoesItWorkDynamicCard } from '../../../modules/tutorial/howDoesItWork/components/HowDoesItWorkDynamicCard';

export const HowDoesItWorkCardController: React.FC = () => {
  const {
    isHowDoesItWorkAvailable,
    handleHowDoesItWorkOfferClick,
    loading: loadingHowDoesItWorks,
  } = useHowDoesItWork({
    tutorialOnly: true,
  });
  if (!isHowDoesItWorkAvailable) {
    return null;
  }
  return <HowDoesItWorkDynamicCard onClick={handleHowDoesItWorkOfferClick} loading={loadingHowDoesItWorks} />;
};
