import { useCallback } from 'react';

import { useLoyaltyEventCreateMutation } from '@ocx/graphql';
import { EnvConfiguration } from '../modules/configuration/EnvConfiguration';
import { wait } from '../utils/utils';

type UseCreateLoyaltyEventParams = {
  membershipId: string;
  onError?(): void;
};

const REFETCH_OFFERS_DELAY_MS = 1200;

export const useCreateLoyaltyEvent = ({ membershipId, onError }: UseCreateLoyaltyEventParams) => {
  const [mutate, meta] = useLoyaltyEventCreateMutation({
    refetchQueries: ['currentCustomer', 'offers'],
    onQueryUpdated: async (observable) => {
      await wait(REFETCH_OFFERS_DELAY_MS);
      observable.refetch();
    },
    awaitRefetchQueries: true,
    onError,
  });

  const createEvent = useCallback(
    async (eventType: string) =>
      mutate({
        variables: {
          input: {
            chainId: EnvConfiguration.chainId,
            // TODO: this value is supposed to be overwritten
            eventType,
            eventData: {
              membership_id: membershipId,
            },
          },
        },
      }),
    [membershipId, mutate],
  );

  return [createEvent, meta] as const;
};
