import { useCallback } from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';

import { useRudderStack } from '../../../lib/rudderStack/useRudderStack';
import { SurveyPopupController } from './survey-popup.controller';
import { SanitizedSurvey } from '../../../modules/survey/survey.types';

export const surveyModalPath = '/survey';

const SurveyPopupControllerModal = NiceModal.create(SurveyPopupController);

export const useSurveyPopup = () => {
  const { triggerLocation } = useRudderStack();
  const { show } = useModal(SurveyPopupControllerModal);

  const open = useCallback(
    (survey: SanitizedSurvey) => {
      triggerLocation(surveyModalPath);
      show({ sanitizedSurvey: survey, onClose: () => triggerLocation() });
    },
    [triggerLocation, show],
  );

  return {
    open,
  };
};
