import { boolean, number, object, string } from 'zod';

export const MembershipConfigOverridesSchema = object({
  walletEnabled: boolean().optional(),
  prepaidCardsEnabled: boolean().optional(),
  payInsideEnabled: boolean().optional(),
  payAtPumpEnabled: boolean().optional(),
  deleteAccountEnabled: boolean().optional(),
  appRatingEnabled: boolean().optional(),
  productInterestsEnabled: boolean().optional(),
  productInterestsEAIVPromotionOnProfileCompletedEnabled: boolean().optional(),
  eaivAgeVerificationEnabled: boolean().optional(),
  eaivIdentityVerificationEnabled: boolean().optional(),
  freshdeskEnabled: boolean().optional(),
  freshdeskWidgetId: string().optional(),
  freshdeskWidgetToken: string().optional(),
  freshdeskWidgetHost: string().optional(),
  freshdeskWidgetSdkUrl: string().optional(),
  promptIntervalDays: number().optional(),
  transactionHistoryLookupDays: number().optional(),
  totalAvailableRollbackEnabled: boolean().optional(),
}).strict();

export const MembershipCustomDataSchema = object({
  configOverrides: MembershipConfigOverridesSchema.optional(),
});
