import { calcDateTime, isAfterDateTime } from '../../../lib/date/date';

const GETTING_STARTED_STEPS = 'GETTING_STARTED_STEPS';
const HOW_DOES_IT_WORK_HOME_PAGE = 'HOW_DOES_IT_WORK_HOME_PAGE';
const HOW_DOES_IT_WORK_HOME_PAGE_KEEP_ACTIVE = 'HOW_DOES_IT_WORK_HOME_PAGE_KEEP_ACTIVE';
const HOW_DOES_IT_WORK_HOME_PAGE_DURATION_MINUTES = 10; // minutes

export enum STEPS {
  OFFER_PLUS_HINT = 'OFFER_PLUS_HINT',
}

export interface ITutorialSteps {
  [key: string]: boolean;
}

export interface IHowDoesItWorkHomePage {
  completed: boolean;
}

export class TutorialStorage {
  public static getSteps = (): ITutorialSteps => {
    const steps: ITutorialSteps = JSON.parse(localStorage.getItem(GETTING_STARTED_STEPS) || '{}');
    return steps;
  };

  public static setSteps = (steps: ITutorialSteps): void => {
    localStorage.setItem(GETTING_STARTED_STEPS, JSON.stringify(steps));
  };

  public static getStep = (step: STEPS): boolean => {
    const steps = TutorialStorage.getSteps();
    return Boolean(steps[step]);
  };

  public static setStep = (step: STEPS, value: boolean): void => {
    const steps = TutorialStorage.getSteps();
    steps[step] = value;
    TutorialStorage.setSteps(steps);
  };

  // How Does It Work section

  private static defaultHowDoesItWorkHomePage: IHowDoesItWorkHomePage = {
    completed: true,
  };

  private static getHowDoesItWorkHomePage(): IHowDoesItWorkHomePage {
    const howDoesItWorkHomePageJSON = localStorage.getItem(HOW_DOES_IT_WORK_HOME_PAGE);
    if (howDoesItWorkHomePageJSON === null) {
      TutorialStorage.setHowDoesItWorkHomePage(TutorialStorage.defaultHowDoesItWorkHomePage);
      TutorialStorage.startHowDoesItWorkHomePageSession();
      return TutorialStorage.defaultHowDoesItWorkHomePage;
    }
    return JSON.parse(howDoesItWorkHomePageJSON);
  }

  private static setHowDoesItWorkHomePage(howDoesItWorkHomePage: IHowDoesItWorkHomePage): IHowDoesItWorkHomePage {
    localStorage.setItem(HOW_DOES_IT_WORK_HOME_PAGE, JSON.stringify(howDoesItWorkHomePage));
    return howDoesItWorkHomePage;
  }

  private static startHowDoesItWorkHomePageSession() {
    sessionStorage.setItem(
      HOW_DOES_IT_WORK_HOME_PAGE_KEEP_ACTIVE,
      calcDateTime(new Date(), 'add', HOW_DOES_IT_WORK_HOME_PAGE_DURATION_MINUTES, 'minute').toISOString(),
    );
  }

  public static isHowDoesItWorkVisibleOnHomePage() {
    const howDoesItWorkHomePage = TutorialStorage.getHowDoesItWorkHomePage();
    const expiresAt = sessionStorage.getItem(HOW_DOES_IT_WORK_HOME_PAGE_KEEP_ACTIVE);
    return !howDoesItWorkHomePage.completed || (expiresAt !== null && isAfterDateTime(new Date(expiresAt), new Date()));
  }
}
