import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useMemo } from 'react';

import { SelectOtpChannelActionSheetController } from './select-otp-channel-action-sheet.controller';

const SelectOtpChannelActionSheetModal = NiceModal.create(SelectOtpChannelActionSheetController);

export const useSelectOTPChannelActionSheet = () => {
  const { show } = useModal(SelectOtpChannelActionSheetModal);
  return useMemo(() => ({ open: show }), [show]);
};
