import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  returnRewardButtonTitle: {
    id: 'offer-details:return-reward:button-title',
    defaultMessage: 'Return reward',
    description: 'Return reward button title',
  },
  returnRewardConfirmationTitle: {
    id: 'offer-details:return-reward-confirm:title',
    defaultMessage: 'Please confirm',
    description: 'Return reward confirmation title',
  },
  returnRewardConfirmationText: {
    id: 'offer-details:return-reward-confirm:text',
    defaultMessage: 'Are you sure you want to return this reward?',
    description: 'Return reward confirmation text',
  },
  returnRewardConfirmationButtonTitle: {
    id: 'offer-details:return-reward-confirm:button-title',
    defaultMessage: 'Confirm return',
    description: 'Return reward confirmation button title',
  },
  returnRewardForCentsSuccessText: {
    id: 'offer-details:pointsStrategy:cents:return-reward:success-text',
    defaultMessage: 'Returning your reward. Your balance will be restored in a few minutes',
    description: 'Return reward success text for points strategy "Cents"',
  },
  returnRewardForPointsSuccessText: {
    id: 'offer-details:pointsStrategy:points:return-reward:success-text',
    defaultMessage: 'Returning your reward. Your points balance will be restored in a few minutes',
    description: 'Return reward success text for points strategy "Points"',
  },
});
