import { InternalUrl } from './deep-linking.types';

export const isInternalUniversalLinkTest = (value: unknown, universalLinkOrigins: string[]): value is InternalUrl => {
  if (!Array.isArray(universalLinkOrigins)) {
    throw new Error('`universalLinkOrigins` has not been provided.');
  }
  if (typeof value !== 'string') {
    return false;
  }

  const { origin } = new URL(value);
  return universalLinkOrigins.includes(origin);
};
