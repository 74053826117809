import { Radio, Stack } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useModal } from '@ebay/nice-modal-react';
import { Typography, List, ListItemAction, ListItemButton, ListItemText } from '@ocx/ui';

import { CarWashSelectorPopupProps } from './car-wash-selector-popup.types';
import { ModalDrawer } from '../components';
import { SizingMultiplier } from '../../../theme/constants';
import { messages } from './car-wash-selector-popup.messages';

export const CarWashSelectorPopupController: React.FC<CarWashSelectorPopupProps> = (props) => {
  const modal = useModal();
  const { carWashListController } = props;
  const { listItems, selectedListItem, handleSelect } = carWashListController;

  const handleCancelSelection = useCallback(() => {
    handleSelect(null);
    modal.hide();
  }, [handleSelect, modal]);

  const carWashOptions = useMemo(
    () =>
      listItems.map((carWashOption) => {
        const isSelected = carWashOption.id === selectedListItem?.id;
        return (
          <ListItemButton
            onClick={() => {
              handleSelect(carWashOption);
              modal.hide();
            }}
            key={carWashOption.id}
            selected={isSelected}>
            <ListItemText>{carWashOption.name}</ListItemText>
            <Stack direction="row" alignItems="center">
              <Typography>{carWashOption.price}</Typography>
              <ListItemAction>
                <Radio checked={isSelected} />
              </ListItemAction>
            </Stack>
          </ListItemButton>
        );
      }),
    [handleSelect, listItems, modal, selectedListItem?.id],
  );

  return (
    <ModalDrawer open={modal.visible} onClose={modal.hide} onExited={modal.remove}>
      <Stack spacing={SizingMultiplier.sm} p={SizingMultiplier.lg}>
        <Typography variant="subtitle2" color="textSecondary">
          <FormattedMessage {...messages['car-wash-selector-popup:title-text']} />
        </Typography>
        <List disabledPaddings>
          {carWashOptions}
          <ListItemButton onClick={handleCancelSelection} selected={!selectedListItem}>
            <ListItemText>
              <FormattedMessage {...messages['car-wash-selector-popup:no-car-wash-option-text']} />
            </ListItemText>
            <ListItemAction>
              <Radio checked={!selectedListItem} />
            </ListItemAction>
          </ListItemButton>
        </List>
      </Stack>
    </ModalDrawer>
  );
};
