import { useCallback } from 'react';

import { IPaymentItem, PAYMENT_INSTRUMENT_TYPE } from '../payments.types';
import { useShowApplePayPaymentRequest } from '../ApplePay/hooks/useShowApplePayPaymentRequest';
import { useGooglePayRequestPay } from '../GooglePay/hooks/useGooglePayRequestPay';

export type CreatePaymentType = (
  type: PAYMENT_INSTRUMENT_TYPE | null,
  paymentItems: IPaymentItem[],
) => Promise<ICreatedPayment>;

export interface ICreatedPayment {
  token: string;
  nonce: string | null;
  canceled: boolean;
  completePayment?(): Promise<void>;
  cancelPayment?(): Promise<void>;
}

export const useCreateDigitalWalletPaymentRequest = () => {
  const handleApplePay = useShowApplePayPaymentRequest();
  const handleGooglePay = useGooglePayRequestPay();

  return useCallback<CreatePaymentType>(
    async (type, paymentItems) => {
      if (type === PAYMENT_INSTRUMENT_TYPE.APPLE_PAY) {
        return handleApplePay(paymentItems);
      }
      if (type === PAYMENT_INSTRUMENT_TYPE.GOOGLE_PAY) {
        return handleGooglePay(paymentItems);
      }
      if (type === PAYMENT_INSTRUMENT_TYPE.CARD) {
        // TODO make it better
        return {
          token: '',
          nonce: null,
          canceled: false,
        };
      }
      throw new Error('Unknown payment method');
    },
    [handleApplePay, handleGooglePay],
  );
};
