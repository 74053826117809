import { styled } from '@mui/material';

import { SizingMultiplier } from '../../theme/constants';

export const DynamicCardContent = styled('div', {
  shouldForwardProp: (prop) => prop === 'children',
})(({ theme }) => {
  return {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    padding: theme.spacing(SizingMultiplier.md),
  };
});
