import React, { ChangeEvent, RefObject, useCallback, useMemo, FormEvent, useState, useRef, useEffect } from 'react';
import Box from '@mui/material/Box';

import { PinItem } from './PinItem';
import { getPinValidation, getInputPinValidation } from './pinValidation';
import { DashPinItem } from './DashPinItem';
import { IPinItemProps } from './types';

const DEFAULT_PIN_SIZE = 4;

export interface IPinInputProps {
  size?: number;
  pinItemType?: 'circle' | 'dash';
  cleanOnSubmit?: boolean;
  autoSubmit?: boolean;
  inputType?: React.HTMLInputTypeAttribute;
  blurOnSubmitEnabled?: boolean;
  onSubmit(value: string): void;
  onFocus?(): void;
  onBlur?(): void;
}

export const PinInput: React.FC<IPinInputProps> = (props) => {
  const {
    size = DEFAULT_PIN_SIZE,
    pinItemType = 'circle',
    cleanOnSubmit = false,
    autoSubmit = false,
    inputType = 'password',
    blurOnSubmitEnabled = false,
    onFocus,
    onBlur,
    onSubmit,
  } = props;
  const validation = useMemo(() => getPinValidation(size), [size]);
  const inputValidation = useMemo(() => getInputPinValidation(size), [size]);

  const [value, setValue] = useState('');
  const input = useRef<HTMLInputElement>(null);
  const pinItems = useMemo<RefObject<HTMLInputElement>[]>(() => Array(size).fill(null), [size]);

  const handleFocus = useCallback(() => {
    input.current?.focus();
  }, []);

  // Safari does not support auto focus
  useEffect(handleFocus, [handleFocus]);

  const cleanInput = useCallback(() => {
    setValue('');
  }, []);

  const handleSubmit = useCallback(
    (e?: FormEvent, _value?: string) => {
      e?.preventDefault();
      const valueToSubmit = _value ?? value;

      if (!validation.safeParse(valueToSubmit).success) {
        return;
      }

      onSubmit(valueToSubmit);
      if (cleanOnSubmit) {
        cleanInput();
      }
      if (blurOnSubmitEnabled) {
        input.current?.blur();
      }
    },
    [blurOnSubmitEnabled, cleanInput, cleanOnSubmit, onSubmit, validation, value],
  );

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      if (!inputValidation.safeParse(value).success) {
        return;
      }

      setValue(value);
      if (autoSubmit) {
        handleSubmit(undefined, value);
      }
    },
    [autoSubmit, handleSubmit, inputValidation],
  );

  const PinItemComponent = useMemo<React.FC<IPinItemProps>>(
    () => (pinItemType === 'circle' ? PinItem : DashPinItem),
    [pinItemType],
  );

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
    <form onClick={handleFocus} onSubmit={handleSubmit} onFocus={onFocus} onBlur={onBlur}>
      <Box
        component="input"
        // Prop for input only to handle OTP autocomplete
        autoComplete="one-time-code"
        type={inputType}
        inputMode="numeric"
        pattern="\\d*"
        ref={input}
        value={value}
        onChange={handleChange}
        sx={{
          height: 0,
          opacity: 0,
          padding: 0,
          margin: 0,
          top: 0,
          left: 0,
          border: 'none',
          position: 'absolute',
        }}
      />
      <Box display="flex" justifyContent="center">
        {pinItems.map((ref, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <PinItemComponent key={index} value={value.charAt(index)} />
        ))}
      </Box>
      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
      <button type="submit" hidden />
    </form>
  );
};
