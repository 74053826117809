import { OfferActivityLogChannelType, OfferActivityLogType, useLogOfferActivityMutation } from '@ocx/graphql';
import { logInDev } from '../../lib/logger/logger';

export const useLogOfferActivity = () => {
  const [logActivity] = useLogOfferActivityMutation();

  /**
   * Log the card view event.
   */
  const logImpression = (offerId: string) => {
    logActivity({
      variables: {
        input: {
          channelType: OfferActivityLogChannelType.App,
          activityAt: new Date(),
          activityType: OfferActivityLogType.Impression,
          offerId,
        },
      },
    }).catch(logInDev);
  };

  /**
   * Log the card click event.
   */
  const logClick = (offerId: string) => {
    logActivity({
      variables: {
        input: {
          channelType: OfferActivityLogChannelType.App,
          activityAt: new Date(),
          activityType: OfferActivityLogType.Click,
          offerId,
        },
      },
    }).catch(logInDev);
  };

  return {
    logImpression,
    logClick,
  };
};
