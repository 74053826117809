import { LocalRetailerConfiguration } from './app-config.types';

export const retailerConfigurationFallback: LocalRetailerConfiguration = {
  version: 3,
  universalLinkOrigins: [''],
  fuelGrades: {
    default: '',
    mapping: [],
  },
  appStoreURLs: {
    ios: null,
    android: null,
  },
};

/**
 *  Updating transaction status interval (milliseconds)
 */
export const TRANSACTION_POLL_INTERVAL = 1500;
/**
 * Updating transaction status error limit
 */
export const TRANSACTION_ERROR_LIMIT = 2;
/**
 * Pin code in memory lifetime (milliseconds)
 */
export const PIN_IN_MEMORY_TTL = 3 * 60 * 1000; // 3 minutes
/**
 * Store locator: watch geolocation timeout (milliseconds)
 */
export const STORE_LOCATOR_WATCH_TIMEOUT = 15 * 1000; // 15 seconds
/**
 * Radius, which define that user is located "At The Store" (meters)
 */
export const STORE_LOCATOR_AT_THE_STORE_DISTANCE = 500;
/**
 * Maximum legal age to participate in Loyalty Program
 */
export const MEMBERSHIP_MAX_AGE = 123;
