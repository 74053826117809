import { useMemo } from 'react';

import { sharedStorage } from '../shared-storage/shared.storage';
import { APP_RATE_PENDING_REQUEST_STORAGE_KEY } from './app-rate.constants';

export const useAppRatePendingState = () => {
  return useMemo(() => {
    return [
      async () => {
        const value = await sharedStorage.getItem(APP_RATE_PENDING_REQUEST_STORAGE_KEY);
        return value !== null ? (value as boolean) : false;
      },
      async (value: boolean): Promise<void> => {
        await sharedStorage.setItem(APP_RATE_PENDING_REQUEST_STORAGE_KEY, value);
      },
    ] as const;
  }, []);
};
