import React from 'react';

export interface ICheckmarkIconProps {
  color?: string;
}

export const CheckmarkIcon: React.FC<ICheckmarkIconProps> = ({ color = 'currentColor' }) => {
  return (
    <svg fill={color} height="12" viewBox="0 0 16 12" width="16" xmlns="http://www.w3.org/2000/svg">
      <path d="m14.7099 1.20986c-.0929-.09373-.2035-.16812-.3254-.218891-.1218-.050768-.2525-.076907-.3846-.076907-.132 0-.2627.026139-.3845.076907-.1219.050771-.2325.125161-.3254.218891l-7.45005 7.46-3.13-3.14c-.09652-.09324-.21046-.16655-.33532-.21576-.12485-.0492-.25818-.07333-.39236-.07101s-.26659.03105-.38967.08454c-.12307.0535-.23441.13071-.32765.22723s-.16655.21046-.21576.33532c-.049201.12485-.073331.25818-.07101.39236.002322.13418.03105.26659.08454.38967.0535.12308.13071.23441.22723.32765l3.84 3.84004c.09296.0937.20356.1681.32542.2189.12186.0507.25257.0769.38458.0769s.26272-.0262.38458-.0769c.12186-.0508.23246-.1252.32542-.2189l8.15995-8.16004c.1016-.09364.1826-.20729.238-.33379s.084-.26311.084-.40121-.0286-.27471-.084-.40121-.1364-.24015-.238-.33379z" />
    </svg>
  );
};
