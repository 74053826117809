import React from 'react';
import { defineMessages } from 'react-intl';

import { PaymentAccountType, PaymentInstrumentType } from '@ocx/graphql';
import { TYPE_ICON_COMPONENTS } from '../../components/Icons/CardTypeIcon';
import { ApplePayIcon } from '../../components/Icons/ApplePayIcon';
import { GooglePayIcon } from '../../components/Icons/GooglePayIcon';
import { PaymentInstrument, PaymentInstrumentOrder } from './wallet.types';

export const ON_DEVICE_ACCOUNT_TYPES = [PaymentAccountType.ApplePay, PaymentAccountType.GooglePay] as const;
export const TOKENIZABLE_ACCOUNT_TYPES = [PaymentAccountType.Credit, PaymentAccountType.Prepaid] as const;

export const digitalWalletInstrumentsTitle = defineMessages({
  [PaymentInstrumentType.ApplePay]: {
    id: 'paymentMethodSelect:ApplePayTitle',
    defaultMessage: 'Apple Pay',
    description: 'Apple Pay Instrument Type title',
  },
  [PaymentInstrumentType.GooglePay]: {
    id: 'paymentMethodSelect:GooglePayTitle',
    defaultMessage: 'Google Pay',
    description: 'Google Pay Instrument Type title',
  },
});

export const paymentTypeToPaymentIconMap: {
  [key in PaymentInstrumentType]?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
} = {
  [PaymentInstrumentType.Visa]: TYPE_ICON_COMPONENTS.visa,
  [PaymentInstrumentType.ApplePayVisa]: TYPE_ICON_COMPONENTS.visa,
  [PaymentInstrumentType.GooglePayVisa]: TYPE_ICON_COMPONENTS.visa,
  [PaymentInstrumentType.Mastercard]: TYPE_ICON_COMPONENTS.mastercard,
  [PaymentInstrumentType.ApplePayMastercard]: TYPE_ICON_COMPONENTS.mastercard,
  [PaymentInstrumentType.GooglePayMastercard]: TYPE_ICON_COMPONENTS.mastercard,
  [PaymentInstrumentType.Discover]: TYPE_ICON_COMPONENTS.discover,
  [PaymentInstrumentType.ApplePayDiscover]: TYPE_ICON_COMPONENTS.discover,
  [PaymentInstrumentType.GooglePayDiscover]: TYPE_ICON_COMPONENTS.discover,
  [PaymentInstrumentType.AmericanExpress]: TYPE_ICON_COMPONENTS.amex,
  [PaymentInstrumentType.ApplePayAmericanExpress]: TYPE_ICON_COMPONENTS.amex,
  [PaymentInstrumentType.GooglePayAmericanExpress]: TYPE_ICON_COMPONENTS.amex,
  [PaymentInstrumentType.Prepaid]: TYPE_ICON_COMPONENTS.prepaid,
  [PaymentInstrumentType.ApplePay]: ApplePayIcon,
  [PaymentInstrumentType.GooglePay]: GooglePayIcon,
};

export const googlePayPaymentInstrument: PaymentInstrument = {
  uuid: 'GOOGLE_PAY',
  accountType: PaymentAccountType.GooglePay,
  alias: 'GOOGLE_PAY',
  paymentType: PaymentInstrumentType.GooglePay,
  providerNickName: 'GOOGLE_PAY',
  address: null,
  accountNumber: null,
  last4: null,
  displayName: null,
  balance: null,
  canBeDeleted: false,
  // It's verified by a payment module and button is visible only if it's possible to use Google Pay
  canBeUsedToPay: true,
  isDefault: false,
  order: PaymentInstrumentOrder.Priority,
};

export const applePayPaymentInstrument: PaymentInstrument = {
  uuid: 'APPLE_PAY',
  accountType: PaymentAccountType.ApplePay,
  alias: 'APPLE_PAY',
  providerNickName: 'APPLE_PAY',
  paymentType: PaymentInstrumentType.ApplePay,
  address: null,
  accountNumber: null,
  last4: null,
  displayName: null,
  balance: null,
  canBeDeleted: false,
  // It's verified by a payment module and button is visible only if it's possible to use Apple Pay
  canBeUsedToPay: true,
  isDefault: false,
  order: PaymentInstrumentOrder.Priority,
};

export const IM_MEMORY_PIN_CODE_STORAGE_KEY = 'WALLET_PIN_CODE' as const;
