import { ReactNode } from 'react';
import { SizingMultiplier } from '../sizing-multiplier.constant';
import { Typography } from '../typography/typography.component';

type ListHeaderProps = {
  children?: ReactNode;
};

export const ListHeader = (props: ListHeaderProps) => {
  const { children } = props;
  return (
    <Typography variant="subtitle1" ml={SizingMultiplier.md}>
      {children}
    </Typography>
  );
};
