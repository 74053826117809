import React from 'react';

import { useGetNavigationActions } from '../../../../hooks/useGetNavigationActions';
import { VendorLogoComponent } from './VendorLogoComponent';

export const VendorLogo: React.FC = () => {
  const { pushToHome } = useGetNavigationActions();

  return <VendorLogoComponent onClick={pushToHome} />;
};
