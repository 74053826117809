import { InView } from 'react-intersection-observer';
import { useCallback } from 'react';

import { ExternalOfferCard } from '../../../../../components/OfferCard/ExternalOfferCard';
import { ExternalOfferContent } from '../../../../../modules/external-loyalty/external-offers.types';

export type ExternalBannerCardProps = {
  trackingId: string;
  impressionTrackingDelay?: number;
  impressionTrackingThreshold?: number;
  onImpression?: (trackingId: string) => unknown;
  onClick?: (trackingId: string) => unknown;
  content: ExternalOfferContent;
};

export const ExternalBannerCard = (props: ExternalBannerCardProps) => {
  const {
    trackingId,
    content,
    onImpression,
    impressionTrackingDelay = 150,
    impressionTrackingThreshold = 0.7,
    onClick,
  } = props;
  const onInViewChange = useCallback(
    (inView: boolean) => {
      if (inView) {
        onImpression?.(trackingId);
      }
    },
    [onImpression, trackingId],
  );

  const onCardClick = useCallback(() => {
    onClick?.(trackingId);
  }, [onClick, trackingId]);

  return (
    <InView
      onChange={onInViewChange}
      delay={impressionTrackingDelay}
      threshold={impressionTrackingThreshold}
      triggerOnce
      fallbackInView>
      {({ ref }) => <ExternalOfferCard ref={ref} onClick={onClick ? onCardClick : undefined} content={content} />}
    </InView>
  );
};
