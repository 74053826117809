import { defineMessages, MessageDescriptor, useIntl } from 'react-intl';
import { useCallback, useMemo } from 'react';

import { genericMessages } from '../../../../modules/localization/genericMessages';
import { useConfiguration } from '../../../../modules/configuration/useConfiguration';

export enum PinCodeErrorCode {
  OC_PINCODE_VERIFY_ERROR = 'OC_PINCODE_VERIFY_ERROR',
  OC_PINCODE_VALIDATE_ERROR = 'OC_PINCODE_VALIDATE_ERROR',
}

export const messages: { [key in PinCodeErrorCode]: MessageDescriptor } = defineMessages({
  [PinCodeErrorCode.OC_PINCODE_VERIFY_ERROR]: {
    id: 'startTransaction:pinCodeAttemptsLimitErrorMessage',
    defaultMessage: 'Please try again in {blockingDurationMinutes, plural, =1 {# minute} other {# minutes}}',
    description: 'Start transaction blocked according to wrong pin message',
  },
  [PinCodeErrorCode.OC_PINCODE_VALIDATE_ERROR]: {
    id: 'startTransaction:pinCodeAttemptsLeft',
    defaultMessage:
      'Wrong PIN. After {maxAttempts, plural, =1 {# attempt} other {# attempts}}, you must wait {blockingDurationMinutes, plural, =1 {# minute} other {# minutes}} to try again',
    description: 'Wrong start transaction pin message',
  },
});

export const getStartTransactionErrorMessage = (code: PinCodeErrorCode): MessageDescriptor => {
  return messages[code] || genericMessages['generic:errorMessageText'];
};

export const useGetErrorMessage = () => {
  const intl = useIntl();
  const { maxAttempts, blockingDuration } = useConfiguration('pinCode');

  const params = useMemo(() => {
    if (!maxAttempts || !blockingDuration) {
      return {};
    }
    return {
      blockingDurationMinutes: Math.ceil(blockingDuration / 60),
      maxAttempts,
    };
  }, [blockingDuration, maxAttempts]);

  return useCallback(
    (code: PinCodeErrorCode) => intl.formatMessage(getStartTransactionErrorMessage(code), params),
    [params, intl],
  );
};
