import { Stack } from '@mui/material';
import { ReactNode } from 'react';

import { SizingMultiplier } from '../sizing-multiplier.constant';

type ListItemProps = {
  children?: ReactNode;
};

export const ListItem = (props: ListItemProps) => {
  const { children } = props;
  return (
    <Stack direction="row" alignItems="center" p={SizingMultiplier.md} minHeight={56}>
      {children}
    </Stack>
  );
};
