import React from 'react';

// TODO: adjust to allow PointsStrategy.Cents + customize dollar icon(s) for each vendor - import from @active-configuration/assets
export const DollarCoin = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask id="mask0_13011_250979" maskUnits="userSpaceOnUse" x="0" y="0" width="21" height="20">
        <circle cx="10.5" cy="10" r="10" fill="#FEC807" />
      </mask>
      <g mask="url(#mask0_13011_250979)">
        <circle cx="10.5" cy="10" r="10" fill="#FEC807" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-3.63698 9.13632L9.57132 -4.07199L13.814 0.170654L0.605657 13.379L-3.63698 9.13632Z"
          fill="#FFDA56"
        />
        <path
          opacity="0.8"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.50011 20.0001L20.5001 4.00011L24.0356 7.53564L8.03565 23.5356L4.50011 20.0001Z"
          fill="#FFDA56"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.5 14.5C12.9853 14.5 15 12.4853 15 10C15 7.51472 12.9853 5.5 10.5 5.5C8.01472 5.5 6 7.51472 6 10C6 12.4853 8.01472 14.5 10.5 14.5ZM10.5 17C14.366 17 17.5 13.866 17.5 10C17.5 6.13401 14.366 3 10.5 3C6.63401 3 3.5 6.13401 3.5 10C3.5 13.866 6.63401 17 10.5 17Z"
          fill="#EEB13D"
        />
        <g filter="url(#filter0_i_13011_250979)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.5 17C14.366 17 17.5 13.866 17.5 10C17.5 6.13401 14.366 3 10.5 3C6.63401 3 3.5 6.13401 3.5 10C3.5 13.866 6.63401 17 10.5 17Z"
            fill="#EEB13D"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.668 17V15.3696C8.61664 15.3183 7.59671 15.0091 7 14.6325L7.471 12.6251C8.13056 13.0196 9.05663 13.3801 10.0761 13.3801C10.9708 13.3801 11.5826 13.0017 11.5826 12.3159C11.5826 11.664 11.081 11.2522 9.91901 10.8239C8.23993 10.2059 7.09428 9.34835 7.09428 7.68316C7.09428 6.17372 8.0677 4.98953 9.74636 4.62992V3H11.2846V4.5099C12.336 4.56166 13.0421 4.80125 13.56 5.07657L13.1054 7.01433C12.6972 6.82605 11.9752 6.43163 10.8455 6.43163C9.82514 6.43163 9.49578 6.91218 9.49578 7.39181C9.49578 7.95848 10.0451 8.31854 11.3789 8.86781C13.2462 9.5884 14 10.5316 14 12.0763C14 13.6027 13.0111 14.9074 11.2063 15.2501V17H9.668Z"
          fill="#FEC807"
        />
        <path
          opacity="0.8"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.66801 15.3696V17H11.2063V15.2501C13.0111 14.9074 14 13.6027 14 12.0764C14 11.5753 13.9207 11.1376 13.7498 10.7502L9.17328 15.3267C9.33637 15.347 9.5018 15.3615 9.66801 15.3696Z"
          fill="#FFD646"
        />
      </g>
      <defs>
        <filter
          id="filter0_i_13011_250979"
          x="3.5"
          y="3"
          width="14"
          height="14"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="0.8" dy="0.8" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.883333 0 0 0 0 0.578751 0 0 0 0 0.298125 0 0 0 0.6 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_13011_250979" />
        </filter>
      </defs>
    </svg>
  );
};
