import { MarketingContent } from './offer.types';

// R TODO: rename it. It accepts MC and not an offer
export const hasOfferDetailedMarketingContent = (content: MarketingContent): boolean => {
  return !!(
    content?.shortDescription ||
    content?.longDescription ||
    content?.disclaimer ||
    content?.terms ||
    content?.termsUrl ||
    content?.disclaimerUrl
  );
};

export const isOfferDisclaimerAvailable = ({
  disclaimer,
  disclaimerUrl,
  terms,
  termsUrl,
}: MarketingContent): boolean => {
  return !!(disclaimer || disclaimerUrl || terms || termsUrl);
};
