import { useMemo } from 'react';

import { OfferFragment, useBaseOffersQuery } from '@ocx/graphql';
import { mapFragmentsToSortedOffersList } from './offer.dto';
import { OPTIMIZED_BASE_OFFERS_FILTER } from './offers.constants';

type UseBaseOffersProps = {
  skip?: boolean;
};

// We need to have separate query for Base offers only
// To prevent unnecessary refetch when "home offers" refetching
export const useBaseOffers = (props: UseBaseOffersProps = {}) => {
  const { skip } = props;
  const { data, loading } = useBaseOffersQuery({
    skip,
    fetchPolicy: 'cache-and-network',
    variables: OPTIMIZED_BASE_OFFERS_FILTER,
  });

  const offers = useMemo(() => {
    if (!data?.customer?.membership?.offers) {
      return [];
    }

    const offerFragments = data.customer.membership.offers.edges
      .filter((edge): edge is { node: OfferFragment } => !!edge?.node)
      .map(({ node }) => node);

    return mapFragmentsToSortedOffersList({
      offers: offerFragments,
      membershipPoints: 0, // TODO get value from membership context
      membershipIsCompleted: false, // TODO get value from membership context
    });
  }, [data]);

  return { offers, loading };
};
