import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { useSnackbar } from '../lib/snackbar/snackbar';
import { genericMessages } from '../modules/localization/genericMessages';

export const useShowGenericErrorSnackbar = () => {
  const intl = useIntl();
  const snackbar = useSnackbar();

  return useCallback(() => {
    snackbar.open(intl.formatMessage(genericMessages['generic:errorMessageText']), {
      variant: 'error',
    });
  }, [intl, snackbar]);
};
