import React from 'react';
import { IconButton as MUIIconButton } from '@mui/material';

import { ButtonProps } from './Button';

export type IconButtonProps = Omit<ButtonProps, 'variant'> & {
  color?: 'inherit' | 'default';
  variant?: 'contained';
};

export const IconButton: React.FC<IconButtonProps> = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  (props, ref) => {
    return <MUIIconButton {...props} ref={ref} />;
  },
);
