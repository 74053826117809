import { boolean, custom, object, literal, string } from 'zod';

import { DynamicCardAction, ActionType } from './types';

export const CompeteProfileActionSchema = object({
  type: literal(ActionType.RequestCompleteProfile),
  payload: object({
    return_url: string().optional(),
  })
    .strict()
    .optional(),
})
  .strict()
  .optional();

export const ExternalLinkActionSchema = object({
  type: literal(ActionType.NavigateTo),
  payload: object({
    url: string(),
    openURLInWebView: boolean().optional(),
  }).strict(),
}).strict();

const isValidAction = (data: unknown): data is DynamicCardAction => {
  return [CompeteProfileActionSchema, ExternalLinkActionSchema].some(
    (validation) => validation.safeParse(data).success,
  );
};

export const DynamicCardActionSchema = custom<DynamicCardAction>(isValidAction, 'Action is not supported or invalid');
