import { useAppContext } from '../app-context/AppContext';
import { IConfigurationContextValue } from './app-config.context';

export const useConfigurationContext = (): IConfigurationContextValue => {
  const appContext = useAppContext();
  if (!appContext.configuration) {
    throw new Error('useConfiguration can not be used before AppContext is initialized');
  }
  return appContext.configuration;
};
