import React, { useMemo } from 'react';

import { PrimaryTransactionStatus, SecondaryTransactionStatus } from '@ocx/graphql';
import { useGetNavigationActions } from '../../hooks/useGetNavigationActions';
import { useSanitizedTransactionHistory } from '../../modules/transaction-history/transaction-history.hooks';
import { PAGINATION_SIZE } from '../../modules/transaction-history/transaction-history.constants';
import { createHistoryListSegments } from '../../modules/transaction-history/transaction-history.utils';
import { mapTransactionToTransactionHistoryItem } from '../../modules/transaction/utils';
import { TransactionsListController } from './transactions-list.controller';

export const MobileTransactionsListController: React.FC = () => {
  const { pushToTransactionDetailsNested } = useGetNavigationActions();
  const { refetch, fetchMore, data, networkStatus } = useSanitizedTransactionHistory({
    variables: {
      primaryTransactionStatus: PrimaryTransactionStatus.Completed,
      secondaryTransactionStatus: SecondaryTransactionStatus.Closed,
      size: PAGINATION_SIZE,
      cursor: null,
    },
  });

  const segments = useMemo(() => {
    return createHistoryListSegments(data.map((edge) => mapTransactionToTransactionHistoryItem(edge.node)));
  }, [data]);

  return (
    <TransactionsListController
      networkStatus={networkStatus}
      segments={segments}
      refetch={refetch}
      fetchMore={fetchMore}
      onListItemClick={pushToTransactionDetailsNested}
    />
  );
};
