import React from 'react';
import { Box } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@ocx/ui';

import { SizingMultiplier } from '../../../theme/constants';
import { PinInput } from '../../PinInput/PinInput';
import { Button } from '../../Button';

type WalletPinCodePopupViewProps = {
  title: string;
  onSubmit(value: string): void;
  validationError: string | null;
  onReset?(): void;
};
export const WalletPinCodePopupView: React.FC<WalletPinCodePopupViewProps> = (props) => {
  const { title, onSubmit, validationError, onReset } = props;

  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: (theme) => `calc(${theme.mixins.toolbar.minHeight}px + var(--ion-safe-area-top, 0px))`,
      }}>
      <Typography variant="h5" gutterBottom>
        {title}
      </Typography>
      <Typography variant="caption" color="textSecondary" mb={SizingMultiplier.lg}>
        <FormattedMessage
          id="pinPopup:subtitle"
          defaultMessage="Tap the circles to start typing"
          description="Hint for the pincode input"
        />
      </Typography>
      <PinInput onSubmit={onSubmit} cleanOnSubmit autoSubmit />
      <Box mt={SizingMultiplier.sm} mb={SizingMultiplier.md} mx={SizingMultiplier.md} minHeight={32}>
        {validationError && (
          <Typography align="center" color="error" variant="caption" component="p">
            {validationError}
          </Typography>
        )}
      </Box>
      {onReset && (
        <Button variant="text" onClick={onReset}>
          <FormattedMessage
            id="pinPopup:resetPinButtonTitle"
            defaultMessage="Reset pin"
            description="Reset pin button title"
          />
        </Button>
      )}
    </Box>
  );
};
