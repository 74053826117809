import { useMemo } from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';

import { ContactSupportController } from './contact-support.controller';

export const ContactSupportControllerModal = NiceModal.create(ContactSupportController);

export const useContactSupportPage = () => {
  const modal = useModal(ContactSupportControllerModal);

  return useMemo(() => ({ open: modal.show }), [modal.show]);
};
