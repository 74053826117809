import { DynamicCardConstraint } from './custom-data/custom-data.types';

export type IneligibilityReasonUnknown = {
  type: 'unknown';
  strategy: IneligibilityHandlingStrategy.DisableInteractions;
};
export type IneligibilityReasonConstraintFailed = {
  type: 'constraint_failed';
  constraint: DynamicCardConstraint;
  strategy: IneligibilityHandlingStrategy;
};
export type IneligibilityReasonProfileAlreadyCompleted = {
  type: 'complete_profile_offer_on_completed_membership';
  strategy: IneligibilityHandlingStrategy.Hide;
};
export type IneligibilityReasonNonEnoughPoints = {
  type: 'not_enough_points';
  // strategy: IneligibilityHandlingStrategy.DisableLoyaltyAction;
  strategy: IneligibilityHandlingStrategy.DisableInteractions;
};

export type IneligibilityReason =
  | IneligibilityReasonUnknown
  | IneligibilityReasonConstraintFailed
  | IneligibilityReasonProfileAlreadyCompleted
  | IneligibilityReasonNonEnoughPoints;

/**
 * Determines how to handle non-eligible offer
 * If hide strategy is used - no further processing is expected
 */
export enum IneligibilityHandlingStrategy {
  Hide = 'hide',
  DisableInteractions = 'disable',
  DisableLoyaltyAction = 'disable_loyalty_action',
}

export type Eligible = [true] | [false, IneligibilityHandlingStrategy, IneligibilityReason[]];
