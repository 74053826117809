import { useCallback } from 'react';

import { SurveyType, useGetSurveysLazyQuery } from '@ocx/graphql';
import { sanitizeSurveys } from '../survey.utils';
import { SanitizedSurvey } from '../survey.types';

export type UseFetchSurveysReturns = {
  isLoading: boolean;
  fetchSurveys: () => Promise<SanitizedSurvey[]>;
};

export type UseFetchSurveysParams = {
  includeDismissed?: boolean;
  typeEquals?: SurveyType;
};

export const useFetchSurveys = (params?: UseFetchSurveysParams): UseFetchSurveysReturns => {
  const includeDismissed = params?.includeDismissed;
  const typeEquals = params?.typeEquals;

  const [fetchSurveysGQL, { loading }] = useGetSurveysLazyQuery({
    fetchPolicy: 'network-only',
    variables: {
      filter: {
        includeDismissed,
        typeEquals,
      },
    },
  });

  const fetchSurveys: UseFetchSurveysReturns['fetchSurveys'] = useCallback(async () => {
    const { data } = await fetchSurveysGQL();

    return sanitizeSurveys({ surveys: data?.surveys });
  }, [fetchSurveysGQL]);

  return {
    fetchSurveys,
    isLoading: loading,
  };
};
