import React, { useCallback } from 'react';
import { ButtonBase, Checkbox } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@ocx/ui';

export interface IPrintReceiptProps {
  checked?: boolean;
  disabled: boolean;
  onClick?(): void;
}

export const PrintReceipt = (props: IPrintReceiptProps) => {
  const { checked, disabled, onClick } = props;

  const handleClick = useCallback(() => {
    if (typeof onClick === 'function') {
      onClick();
    }
  }, [onClick]);

  return (
    <ButtonBase
      sx={{
        width: '100%',
        height: 48,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
      onClick={handleClick}>
      <Typography color={disabled ? 'text.disabled' : 'text.primary'}>
        <FormattedMessage
          id="payAtPump:printReceiptButtonText"
          defaultMessage="Print receipt"
          description="Pay At Pump section: print receipt text"
        />
      </Typography>
      <Checkbox checked={checked} disabled={disabled} />
    </ButtonBase>
  );
};
