import { Offer, OfferEngagementType } from '../../modules/offers/offer.types';
import { AwardType } from '../../modules/offers/award.types';
import { isSweepstake } from '../../modules/offers/offer.type-guards';
import { OfferLabelType } from './offer-card.types';
import { EarningMechanismType } from '../../modules/offers/earning-mechanism.types';

const isReward = ({ engagementType }: Offer): boolean => {
  return [OfferEngagementType.Purchaseable, OfferEngagementType.Vouchered].some((et) => et === engagementType);
};

const isPunchCard = ({ earningMechanism, engagementType }: Offer): boolean => {
  return (
    earningMechanism?.type === EarningMechanismType.UnknownWithProgress &&
    earningMechanism?.__typename === 'EarningMechanismVarietyPurchaseOverTime' &&
    engagementType === OfferEngagementType.PunchcardChallenge
  );
};

const CHALLENGES_EM = [
  'EarningMechanismTotalSpendOverTime',
  'EarningMechanismVisits',
  'EarningMechanismTotalEligiblePurchaseOverTime',
  'EarningMechanismVarietyPurchaseOverTime',
];
const isChallenge = ({ earningMechanism }: Offer) => {
  if (earningMechanism?.type !== EarningMechanismType.UnknownWithProgress) {
    return false;
  }
  return CHALLENGES_EM.some((name) => name === earningMechanism?.__typename);
};

const isBonus = (awardType: AwardType) => {
  return [AwardType.Points, AwardType.PointsPer].includes(awardType);
};

const isSavings = (engagementType: OfferEngagementType | null): boolean => {
  return [OfferEngagementType.Promotional, OfferEngagementType.Transactional, OfferEngagementType.Behavioral].some(
    (et) => et === engagementType,
  );
};

const isGameplay = (engagementType: OfferEngagementType | null): boolean => {
  return engagementType === OfferEngagementType.Game;
};

const isVoucheredGameplay = (engagementType: OfferEngagementType | null): boolean => {
  return engagementType === OfferEngagementType.GameVoucher;
};

/**
 * Please refer to https://confluence.stuzo.com/pages/viewpage.action?pageId=85691220
 * @param offer
 */
export const getOfferLabelType = (offer: Offer): OfferLabelType | null => {
  if (isSweepstake(offer.engagementType)) {
    return 'Entries';
  }
  if (isReward(offer)) {
    return 'Reward';
  }
  if (isPunchCard(offer)) {
    return 'PunchCard';
  }
  if (isChallenge(offer)) {
    return 'Challenge';
  }
  if (offer.earningMechanism?.award && isBonus(offer.earningMechanism.award.type)) {
    return 'Bonus';
  }
  if (isSavings(offer.engagementType)) {
    return 'Savings';
  }
  if (isGameplay(offer.engagementType)) {
    return 'Gameplay';
  }
  if (isVoucheredGameplay(offer.engagementType)) {
    return 'VoucheredGameplay';
  }

  return null;
};
