import React from 'react';

export const Discover: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg width="32" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 4.471A3.765 3.765 0 013.765.707h24.47A3.765 3.765 0 0132 4.47v15.06a3.765 3.765 0 01-3.765 3.764H3.765A3.765 3.765 0 010 19.53V4.471z"
      fill="#fff"
    />
    <path d="M13.177 22.354l17.882-5.412v2.745a2.667 2.667 0 01-2.667 2.667H13.177z" fill="#FD6020" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.665 9.282c.984 0 1.525.455 1.525 1.314.049.658-.394 1.214-.984 1.315l1.328 1.871h-1.033l-1.131-1.82h-.099v1.82h-.836v-4.5h1.23zm-.394 2.073h.246c.541 0 .787-.253.787-.708 0-.405-.246-.657-.787-.657h-.246v1.365zm-3.738 2.427h2.36v-.758H24.37V11.81h1.476v-.759h-1.476v-1.01h1.525v-.76h-2.361v4.501zm-2.46-1.466l-1.131-3.034h-.886l1.82 4.601h.443l1.82-4.601h-.885l-1.18 3.034zm-9.985-.759c0 1.264.984 2.326 2.213 2.326.394 0 .738-.101 1.082-.253V12.62c-.245.303-.59.506-.983.506a1.405 1.405 0 01-1.427-1.416v-.101a1.497 1.497 0 011.377-1.568c.394 0 .787.202 1.033.506V9.534c-.295-.202-.688-.252-1.033-.252-1.278-.102-2.262.96-2.262 2.275zm-1.525-.556c-.492-.202-.64-.304-.64-.556.05-.304.295-.557.59-.506.246 0 .492.152.69.354l.442-.607c-.345-.303-.787-.506-1.23-.506-.689-.05-1.279.506-1.328 1.214v.05c0 .607.246.961 1.033 1.214.197.05.393.152.59.253a.535.535 0 01.246.455c0 .354-.295.658-.59.658h-.05a.972.972 0 01-.885-.607l-.54.556c.294.556.885.86 1.475.86.787.05 1.426-.557 1.476-1.366v-.151c-.05-.607-.296-.91-1.28-1.315zM6.71 13.782h.836v-4.5H6.71v4.5zm-3.886-4.5h1.475c1.18.05 2.115 1.062 2.066 2.275 0 .657-.295 1.264-.787 1.72-.442.353-.984.556-1.525.505h-1.23v-4.5zm1.082 3.742c.393.05.836-.102 1.131-.354.295-.304.443-.708.443-1.163 0-.405-.148-.81-.443-1.113-.295-.253-.738-.405-1.131-.354H3.66v2.984h.246z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.892 9.177c-1.23 0-2.263 1.012-2.263 2.326 0 1.264.984 2.326 2.263 2.377 1.279.05 2.263-1.011 2.312-2.326-.05-1.315-1.033-2.377-2.312-2.377z"
      fill="#FD6020"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.235 1.648H3.765A2.824 2.824 0 00.94 4.471v15.06a2.823 2.823 0 002.824 2.823h24.47a2.823 2.823 0 002.824-2.824V4.471a2.824 2.824 0 00-2.824-2.823zM3.765.707A3.765 3.765 0 000 4.47v15.06a3.765 3.765 0 003.765 3.764h24.47A3.765 3.765 0 0032 19.53V4.471A3.765 3.765 0 0028.235.707H3.765z"
      fill="#E1E1E1"
    />
  </svg>
);
