export enum ConstraintType {
  IneligibleIfMembershipHasReward = 'ineligible_if_membership_has_reward',
}

export enum ConstraintHandlingStrategy {
  Hide = 'hide',
  DisableInteractions = 'disable',
  DisableLoyaltyAction = 'disable_loyalty_action',
}

export type RewardOfferId = string;
export type IneligibleIfMembershipHasRewardConstraint = [
  ConstraintType.IneligibleIfMembershipHasReward,
  ConstraintHandlingStrategy,
  RewardOfferId[],
];
