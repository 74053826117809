import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useMemo } from 'react';

import { ConfirmationActionSheetController } from './confirmation-action-sheet.controller';

const ConfirmationActionSheetModal = NiceModal.create(ConfirmationActionSheetController);

export const useConfirmationActionSheet = () => {
  const { show } = useModal(ConfirmationActionSheetModal);
  return useMemo(() => ({ open: show }), [show]);
};
