import { defineMessages } from 'react-intl';

import { OfferLabelType } from '../../offer-card.types';

type MessagesKey = OfferLabelType | 'Expires' | 'BonusPoints' | 'BonusCents';

export const messages = defineMessages<MessagesKey>({
  Reward: {
    id: 'OfferLabels.Reward',
    defaultMessage: '{voucherCount, plural, =0 {Reward} one {Reward} other {# Rewards}}',
  },
  Gameplay: {
    id: 'OfferLabels.Gameplay',
    defaultMessage: 'Free gameplay',
  },
  VoucheredGameplay: {
    id: 'OfferLabels.VoucheredGameplay',
    defaultMessage: '{voucherCount, plural, =0 {Gameplay} one {1 Gameplay} other {# Gameplays}}',
  },
  Savings: {
    id: 'OfferLabels.Savings',
    defaultMessage: 'Savings',
  },
  Bonus: {
    id: 'OfferLabels.pointsStrategy:notUsed:Bonus',
    defaultMessage: 'Bonus Entries',
  },
  BonusPoints: {
    id: 'OfferLabels.pointsStrategy:points:Bonus',
    defaultMessage: 'Bonus Points',
  },
  BonusCents: {
    id: 'OfferLabels.pointsStrategy:cents:Bonus',
    defaultMessage: 'Bonus $',
  },
  PunchCard: {
    id: 'OfferLabels.PunchCard',
    defaultMessage: 'Punch Card',
  },
  Challenge: {
    id: 'OfferLabels.PunchCardChallenge',
    defaultMessage: 'Challenge',
  },
  Expires: {
    id: 'OfferLabels:Expires',
    defaultMessage: '{time} left',
    description: 'Offer expires in value in chip label',
  },
  Entries: {
    id: 'OfferLabels:Entries',
    defaultMessage: '{entries, plural, =0 {Sweepstakes} one {# entry} other {# entries}}',
    description: 'Offer entries amount chip label',
  },
});
