import { Divider, FormControlLabel, Radio, RadioGroup, Stack } from '@mui/material';
import { Field } from 'react-final-form';

export type RadiosFieldProps = {
  name: string;
  options: Array<{
    uuid: string;
    label: string;
  }>;
};

export const RadiosField = ({ name, options }: RadiosFieldProps) => {
  return (
    <RadioGroup>
      <Stack divider={<Divider />}>
        {options.map(({ uuid, label }) => (
          <Field
            key={uuid}
            name={name}
            type="radio"
            value={uuid}
            render={({ input }) => (
              <FormControlLabel
                control={<Radio {...input} />}
                label={label}
                labelPlacement="start"
                sx={{ justifyContent: 'space-between', ml: 0, minHeight: 56 }}
              />
            )}
          />
        ))}
      </Stack>
    </RadioGroup>
  );
};
