import React, { ReactNode } from 'react';
import {
  SnackbarProviderProps as SnackbarProviderPropsNotistack,
  SnackbarProvider as SnackbarProviderNotistack,
} from 'notistack';
import { styled } from '@mui/material';

const snackbarProviderProps: Omit<SnackbarProviderPropsNotistack, 'children'> = {
  autoHideDuration: 3000,
  maxSnack: 5,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
};

const StyledSnackbarProvider = styled(SnackbarProviderNotistack)(({ theme: { palette } }) => ({
  '&.SnackbarItem-contentRoot': {
    bottom: 'var(--ion-safe-area-bottom, 20px)',
  },
  '&.SnackbarItem-variantSuccess': {
    backgroundColor: palette.success.light,
    color: palette.success.contrastText,
  },
  '&.SnackbarItem-variantError': {
    backgroundColor: palette.error.main,
    color: palette.error.contrastText,
  },
  '&.SnackbarItem-variantInfo': {
    backgroundColor: palette.info.dark,
    color: palette.info.contrastText,
  },
}));

export type SnackbarProviderProps = {
  children: ReactNode;
};

export const SnackbarProvider: React.FC<SnackbarProviderProps> = ({ children }) => {
  return (
    <StyledSnackbarProvider hideIconVariant {...snackbarProviderProps}>
      {children}
    </StyledSnackbarProvider>
  );
};
