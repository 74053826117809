import React from 'react';
import { Stack } from '@mui/material';

import { StoreAddress } from '../../components/StoreAddress/StoreAddress';
import { OptionItem } from '../PayAtPump/Receipt/components/OptionItem';
import { IReceiptOptionQuantity, OptionQuantityList } from './components/OptionList';

export interface ITransactionReceiptProps {
  address: string | null;
  date: string;
  lineItems: IReceiptOptionQuantity[];
}

export const TransactionReceipt: React.FC<ITransactionReceiptProps> = (props) => {
  const { address, date, lineItems } = props;

  return (
    <Stack p={2} spacing={3} mb="auto">
      {address && <StoreAddress>{address}</StoreAddress>}
      <OptionItem option={{ title: 'Date', value: date }} />
      <OptionQuantityList options={lineItems} />
    </Stack>
  );
};
