import { useState, useEffect } from 'react';
import * as Sentry from '@sentry/react';

import { useGetApplePayRequest } from './useGetApplePayRequests';
import { ApplePay } from '../ApplePay';
import { IPaymentAvailability } from '../../payments.types';
import { useConfiguration } from '../../../../modules/configuration/useConfiguration';

export const useIsApplePayAvailable = (): IPaymentAvailability => {
  const { config } = useConfiguration();
  const { getApplePayCanMakeRequestData } = useGetApplePayRequest();
  const [loading, setLoading] = useState(true);
  const [available, setAvailable] = useState(false);

  useEffect(() => {
    const exec = async () => {
      if (config.applePay === null) {
        setAvailable(false);
        return;
      }

      const request = getApplePayCanMakeRequestData();
      if (request === null) {
        setAvailable(false);
        return;
      }

      const { available } = await ApplePay.canMakePayments(request);
      setAvailable(available);
    };
    exec()
      .catch(Sentry.captureException)
      .finally(() => setLoading(false));
  }, [config.applePay, getApplePayCanMakeRequestData]);

  return { loading, available };
};
