import { useCallback } from 'react';
import { TabList } from '@mui/lab';
import { Divider, Tab } from '@mui/material';

import CarouselScrollButton from './carousel-scroll-button.component';
import { OffersCarouselCategory } from '../offers-carousel.types';
import { useRudderStack } from '../../../../../lib/rudderStack/useRudderStack';

export type OffersCarouselHeaderProps = {
  categories: OffersCarouselCategory[];
  onCategoryChange: (nextCategoryId: string) => unknown;
};

export const OffersCarouselHeader = (props: OffersCarouselHeaderProps) => {
  const { categories, onCategoryChange } = props;

  const { triggerEvent } = useRudderStack();

  const onChange = useCallback(
    (_: unknown, nextCategoryId: string) => {
      onCategoryChange(nextCategoryId);
      triggerEvent('display_category_clicked', { displayCategoryId: nextCategoryId });
    },
    [onCategoryChange, triggerEvent],
  );

  if (categories.length < 2) {
    return null;
  }

  return (
    <>
      <TabList
        onChange={onChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons
        sx={{ position: 'relative' }}
        ScrollButtonComponent={CarouselScrollButton}
        allowScrollButtonsMobile>
        {categories.map(({ id, title }) => (
          <Tab sx={{ flexGrow: 1, maxWidth: 'none' }} key={id} value={id} label={title} />
        ))}
      </TabList>
      <Divider />
    </>
  );
};
