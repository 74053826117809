import React from 'react';

import { ProfileLayout } from './ProfileLayout';
import { ScreenLoaderBackdrop } from '../../components/ScreenLoader/ScreenLoader';
import { Profile } from './Profile';
import { useProfileController } from './hooks/useProfileController';
import { useHowDoesItWork } from '../../modules/tutorial/howDoesItWork/hooks/useHowDoesItWork';
import { useConfiguration } from '../../modules/configuration/useConfiguration';
import { useGetNavigationActions } from '../../hooks/useGetNavigationActions';
import { useEditProfilePopup } from '../../components/modals';

export const ProfileController: React.FC = () => {
  const {
    loading,
    username,
    isWalletEnabled,
    isRefetching,
    isCommunicationPreferenceEnabled,
    isProductInterestsEnabled,
    isPreferredFuelGradeEnabled,
  } = useProfileController();
  const { pushToCommunicationPreferences } = useGetNavigationActions();

  const { open: openEditProfilePopup } = useEditProfilePopup();
  const { handleHowDoesItWorkOfferClick, isHowDoesItWorkAvailable } = useHowDoesItWork();
  const release = useConfiguration('release');

  if (loading && !isRefetching) {
    return (
      <ProfileLayout>
        <ScreenLoaderBackdrop open />
      </ProfileLayout>
    );
  }

  return (
    <ProfileLayout>
      <Profile
        username={username}
        onEditProfileClick={openEditProfilePopup}
        handleHowDoesItWorkOfferClick={handleHowDoesItWorkOfferClick}
        isWalletEnabled={isWalletEnabled}
        appVersion={release}
        isRefetching={isRefetching}
        isHowDoesItWorkAvailable={isHowDoesItWorkAvailable}
        isCommunicationPreferenceEnabled={isCommunicationPreferenceEnabled}
        isProductInterestsEnabled={isProductInterestsEnabled}
        isPreferredFuelGradeEnabled={isPreferredFuelGradeEnabled}
        handleCommunicationPreferencesClick={pushToCommunicationPreferences}
      />
    </ProfileLayout>
  );
};

export default ProfileController;
