import { useMemo } from 'react';

import { AnalyticsInstance } from './rudderStack.types';
import { getRudderStackInstance } from './rudderStack.utils';
import { useConfiguration } from '../../modules/configuration/useConfiguration';
import { getGaInstance } from './ga.utils';

export const useRudderStack = (): AnalyticsInstance => {
  const {
    config: {
      rudderStack: { enabled },
    },
  } = useConfiguration();

  const analyticsInstance = useMemo(() => {
    if (!enabled) {
      return getGaInstance();
    }

    return getRudderStackInstance(true);
  }, [enabled]);

  return analyticsInstance;
};
