import { ReactNode } from 'react';
import { SizingMultiplier } from '../sizing-multiplier.constant';
import { Typography, TypographyProps } from '../typography/typography.component';

type ChipProps = {
  children?: ReactNode;
  elevated?: boolean;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  variant?: TypographyProps['variant'];
  color?: TypographyProps['color'];
  bgcolor?: TypographyProps['bgcolor'];
};

export const Chip = (props: ChipProps) => {
  const { children, elevated, startIcon, endIcon, variant, color, bgcolor } = props;

  return (
    <Typography
      variant={variant}
      color={color}
      bgcolor={bgcolor}
      sx={(theme) => ({
        display: 'inline-flex',
        alignItems: 'center',
        gap: theme.spacing(SizingMultiplier.xs),
        padding: theme.spacing(SizingMultiplier.xs, SizingMultiplier.sm),
        borderRadius: theme.spacing(SizingMultiplier.md),
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        boxShadow: elevated ? theme.shadows[2] : undefined,
      })}>
      {startIcon}
      {children}
      {endIcon}
    </Typography>
  );
};
