import { useCallback } from 'react';

import { SubmitAnswerParams } from '../../../../modules/survey/survey.submit-answer';
import { SanitizedSurvey } from '../../../../modules/survey/survey.types';

export type UseGetSubmitSurveyParams = {
  sanitizedSurvey: SanitizedSurvey;
  handleError: () => void;
  handleSubmitAnswerSuccess: () => void;
  submit(params: SubmitAnswerParams): Promise<void>;
};

export type UseGetSubmitSurveyReturns = (params: Pick<SubmitAnswerParams, 'questionUUID' | 'values'>) => Promise<void>;

export const useGetSubmitSurvey = ({
  submit,
  sanitizedSurvey,
  handleError,
  handleSubmitAnswerSuccess,
}: UseGetSubmitSurveyParams): UseGetSubmitSurveyReturns => {
  return useCallback<UseGetSubmitSurveyReturns>(
    ({ questionUUID, values }) => {
      if (sanitizedSurvey.type === 'authenticated') {
        return submit({
          onError: handleError,
          onSuccess: handleSubmitAnswerSuccess,
          questionUUID,
          values,
          type: sanitizedSurvey.type,
          surveyTypename: sanitizedSurvey.__typename,
        });
      }

      return submit({
        onError: handleError,
        onSuccess: handleSubmitAnswerSuccess,
        questionUUID,
        values,
        type: sanitizedSurvey.type,
        guestSurveyId: sanitizedSurvey.guestSurveyId,
        surveyTypename: sanitizedSurvey.__typename,
      });
    },
    [handleError, handleSubmitAnswerSuccess, sanitizedSurvey, submit],
  );
};
