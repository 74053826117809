import uniq from 'lodash/uniq';
import { IntlShape } from 'react-intl/src/types';

import { StorePumpInfoFragment, StoreCarWashItemFragment } from '@ocx/graphql';
import { Coordinates, IStore, IStorePumpInfo, SanitizedStorePumpInfo } from './types';
import { calculateDistance } from '../../lib/geolocation/utils/calculateDistance';
import { DEFAULT_SEARCH_RADIUS_METERS, MAP_MOVEMENT_THRESHOLD_PERCENT } from '../../pages/StoreLocator/constants';

export const isStorePumpInfoReady = (store?: StorePumpInfoFragment): store is SanitizedStorePumpInfo => {
  if (!store) {
    return false;
  }
  if (!store.address) {
    return false;
  }
  if (!store.siteSystemConfiguration[0]) {
    return false;
  }
  return true;
};

export const sanitizeStorePumpInfo = (intl: IntlShape, store?: StorePumpInfoFragment): IStorePumpInfo | null => {
  if (!isStorePumpInfoReady(store)) {
    return null;
  }
  const [siteSystemConfiguration] = store.siteSystemConfiguration;

  const data: IStorePumpInfo = {
    id: store.uuid,
    siteId: siteSystemConfiguration.identifier,
    pumps: [],
    carWashOptions: [],
    address: {
      addressLine1: store.address?.street1 || '',
      addressLine2: `${store.address?.city}, ${store.address?.state}`,
    },
  };

  if (!siteSystemConfiguration.details?.fuelConfigDetails) {
    return data;
  }
  const pumps = siteSystemConfiguration.details.fuelConfigDetails
    .map((item) => item.outdoorPosition)
    .filter((item): item is string => item !== null);
  data.pumps = uniq(pumps);

  if (!siteSystemConfiguration.details?.carWashDetails) {
    return data;
  }

  data.carWashOptions = siteSystemConfiguration.details.carWashDetails.map((item: StoreCarWashItemFragment) => ({
    id: item.posCode + item.posCodeModifier,
    name: item.description || '',
    price: intl.formatNumber(item.unitPrice, {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 2,
    }),
    unitPrice: item.unitPrice,
    posCode: item.posCode,
    posCodeModifier: item.posCodeModifier,
    productCode: item.productCode,
  }));

  return data;
};

export const checkMapPositionDiff = (
  coordinatesOld: Coordinates,
  coordinatesNew: Coordinates,
  searchRadiusMeters: number,
) => {
  const movementDistanceMeters = calculateDistance(coordinatesOld, coordinatesNew, 'km') * 1000;
  const movementDistancePercent = (movementDistanceMeters * 100) / searchRadiusMeters;
  return movementDistancePercent > MAP_MOVEMENT_THRESHOLD_PERCENT;
};

export const checkLastItemsSame = (prev: IStore[], next: IStore[]) =>
  prev[prev.length - 1]?.id === next[next.length - 1]?.id;

// eslint-disable-next-line no-undef
export const getSearchRadiusMeters = (map: google.maps.Map | null) => {
  if (!map) {
    return DEFAULT_SEARCH_RADIUS_METERS;
  }
  const bound = map.getBounds();
  if (!bound) {
    return DEFAULT_SEARCH_RADIUS_METERS;
  }
  const southWest = bound.getSouthWest();
  const northEast = bound.getNorthEast();
  if (!southWest || !northEast) {
    return DEFAULT_SEARCH_RADIUS_METERS;
  }

  const distanceKm = calculateDistance(
    { lat: southWest.lat(), lng: southWest.lng() },
    { lat: northEast.lat(), lng: northEast.lng() },
    'km',
  );

  return distanceKm * 1000;
};
