import { PureBackdrop } from '../../pure-backdrop';
import { PureCircularProgress } from '../../pure-circular-progress';

export const RouteLoading = () => {
  return (
    <PureBackdrop>
      <PureCircularProgress />
    </PureBackdrop>
  );
};
