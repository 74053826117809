import { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { ZodError } from 'zod';

import { useCustomerSetPinCodeMutation } from '@ocx/graphql';
import { useRudderStack } from '../../../../lib/rudderStack/useRudderStack';
import { useShowGenericErrorSnackbar } from '../../../../hooks/useShowGenericErrorSnackbar';
import { PinCodeAction } from '../wallet-pin-code-popup.types';
import { getPinValidation, messages } from '../wallet-pin-code-popup.validation';
import { useConfiguration } from '../../../../modules/configuration/useConfiguration';
import { useInMemoryStorage } from '../../../../lib/in-memory-storage/use-in-memory-storage';
import { IM_MEMORY_PIN_CODE_STORAGE_KEY } from '../../../../modules/wallet/wallet.constants';

export type UseSetFlowParam = {
  onComplete(pinCode: string | null): void;
};
export const useSetFlow = (params: UseSetFlowParam) => {
  const intl = useIntl();
  const { triggerEvent } = useRudderStack();
  const showGenericErrorSnackbar = useShowGenericErrorSnackbar();
  const pinInMemoryTtl = useConfiguration('pinCode.pinInMemoryTtl');

  const { onComplete } = params;
  const { setStorageValue: setCachedPinCode } = useInMemoryStorage<string>(IM_MEMORY_PIN_CODE_STORAGE_KEY);
  const [setCustomerPinCode, { loading }] = useCustomerSetPinCodeMutation({
    refetchQueries: ['currentCustomer'],
    awaitRefetchQueries: true,
  });
  const [activeAction, setActiveAction] = useState<PinCodeAction.SET | PinCodeAction.SET_CONFIRM>(PinCodeAction.SET);
  const [validationError, setValidationError] = useState<string | null>(null);
  const [valueToConfirm, setValueToConfirm] = useState<string | null>(null);

  const validation = useMemo(
    () =>
      getPinValidation({
        errorMessages: {
          incorrectSize: intl.formatMessage(messages.requiredSize),
          required: intl.formatMessage(messages.requiredSize),
          onlyDigits: intl.formatMessage(messages.onlyDigits),
          sameDigits: intl.formatMessage(messages.sameDigits),
          consecutiveDigits: intl.formatMessage(messages.consecutiveDigits),
        },
      }),
    [intl],
  );

  const handleSubmit = useCallback(
    async (value: string) => {
      try {
        validation.parse(value);
        setValidationError(null);

        if (activeAction === PinCodeAction.SET) {
          setActiveAction(PinCodeAction.SET_CONFIRM);
          setValueToConfirm(value);
          return;
        }

        if (valueToConfirm !== value) {
          setValidationError(intl.formatMessage(messages.incorrectConfirmation));
          return;
        }

        // Handle confirmation
        await setCustomerPinCode({ variables: { pinCode: value } });
        setCachedPinCode(value, pinInMemoryTtl);
        onComplete(value);
        triggerEvent('set_pin');
      } catch (e) {
        if (e instanceof ZodError) {
          const { _errors: errors } = e.format();
          setValidationError(errors.join('. '));
        } else {
          showGenericErrorSnackbar();
        }
      }
    },
    [
      activeAction,
      intl,
      onComplete,
      pinInMemoryTtl,
      setCachedPinCode,
      setCustomerPinCode,
      showGenericErrorSnackbar,
      triggerEvent,
      validation,
      valueToConfirm,
    ],
  );
  return { loading, handleSubmit, validationError, action: activeAction };
};
