import { useCallback, useEffect } from 'react';

import { AuthMethod, SignupWithTokenMutation, useSignupWithTokenMutation } from '@ocx/graphql';
import { EnvConfiguration } from '../../../modules/configuration/EnvConfiguration';
import { authService } from '../../../modules/auth/instances/authService';
import { useSignupEvents } from '../../../modules/auth/useSignupEvents';

export interface LoginByTokenControllerParams {
  onCompleted(): void;
  onError(error: Error): void;
}

export const useSignupWithLinkController = (
  sessionId: string,
  { onCompleted, onError }: LoginByTokenControllerParams,
) => {
  const { triggerSignupSessionCreated, triggerMagicLinkInvalid } = useSignupEvents();

  const handleCompleted = useCallback(
    ({
      signupWithToken: {
        tokens: { accessToken, refreshToken },
      },
    }: SignupWithTokenMutation) => {
      authService.setTokens(accessToken, refreshToken);
      triggerSignupSessionCreated({ method: AuthMethod.MagicLink });
      onCompleted();
    },
    [onCompleted, triggerSignupSessionCreated],
  );

  const handleError = useCallback(
    (error: Error) => {
      triggerMagicLinkInvalid();
      onError(error);
    },
    [onError, triggerMagicLinkInvalid],
  );

  const [signupWithToken] = useSignupWithTokenMutation({
    onCompleted: handleCompleted,
    onError: handleError,
  });

  useEffect(() => {
    if (!sessionId) {
      return;
    }
    signupWithToken({
      variables: {
        input: {
          sessionId,
          brandUuid: EnvConfiguration.brandUuid,
          chainId: EnvConfiguration.chainId,
        },
      },
    });
  }, [sessionId, signupWithToken]);
};
