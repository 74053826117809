import React from 'react';
import { useModal } from '@ebay/nice-modal-react';

import { ModalFullScreenDrawer, ModalFullScreenHeader } from '../components';
import { SmsOptIn, SmsOptInProps } from '../../../pages/SmsOptIn/sms-opt-in.component';

export const SmsOptInPopupController = (props: SmsOptInProps) => {
  const { title, shortDescription, imageUrl, terms, disclaimer, disclaimerUrl, onAccepted, onRejected } = props;

  const modal = useModal();

  return (
    <ModalFullScreenDrawer open={modal.visible} onClose={modal.hide} onExited={modal.remove}>
      <ModalFullScreenHeader />
      <SmsOptIn
        title={title}
        shortDescription={shortDescription}
        imageUrl={imageUrl}
        terms={terms}
        disclaimer={disclaimer}
        disclaimerUrl={disclaimerUrl}
        onAccepted={() => {
          modal.hide();
          onAccepted();
        }}
        onRejected={() => {
          modal.hide();
          onRejected();
        }}
      />
    </ModalFullScreenDrawer>
  );
};
