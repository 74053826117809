import React, { ReactNode } from 'react';
import { defineMessage } from 'react-intl';

import { NavigationBar, Page, PageContent } from '@ocx/ui';
import { HtmlTitle } from '../../components/router/HtmlTitle';
import { BackButtonComponent } from '../../components/Layout/NavigationBar/BackButton/BackButtonComponent';

type ReadyToUseLayoutProps = {
  children: ReactNode;
  onBackButtonClick: () => void;
};

export const ReadyToUseLayout: React.FC<ReadyToUseLayoutProps> = ({ children, onBackButtonClick }) => {
  return (
    <Page>
      <HtmlTitle
        title={defineMessage({
          id: 'readyToUse:htmlTitle',
          defaultMessage: 'Ready to use offers',
          description: 'HTML title tag for Ready To Use Page',
        })}
      />
      <NavigationBar
        left={<BackButtonComponent onClick={onBackButtonClick} />}
        position="fixed"
        isElevationDisabled
        color="transparent"
      />
      <PageContent>{children}</PageContent>
    </Page>
  );
};
