import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  'tabBar:homeButtonText': {
    defaultMessage: 'Home',
    description: 'Home button text on the tab bar.',
    id: 'tabBar:homeButtonText',
  },
  'tabBar:surveysButtonText': {
    defaultMessage: 'Surveys',
    description: 'Survey button text on the tab bar.',
    id: 'tabBar:surveysButtonText',
  },
  'tabBar:payAtPumpButtonText': {
    defaultMessage: 'Pay at Pump',
    description: 'Pay At Pump button text on the tab bar.',
    id: 'tabBar:payAtPumpButtonText',
  },
  'tabBar:payInsideButtonText': {
    defaultMessage: 'Scan',
    description: 'Scan (Pay Inside) button text on the tab bar.',
    id: 'tabBar:payInsideButtonText',
  },
  'notClosePayAtPumpPopup:title': {
    defaultMessage: 'Not close enough',
    description: 'Not close to store popup title',
    id: 'notClosePayAtPumpPopup:title',
  },
  'notClosePayAtPumpPopup:description': {
    defaultMessage: 'It looks like you’re not close enough to a store to start a transaction.',
    description: 'Not close to store popup description',
    id: 'notClosePayAtPumpPopup:description',
  },
  'notClosePayAtPumpPopup:actionButtonText': {
    defaultMessage: 'Find nearest store',
    description: 'Not close to store confirm button text',
    id: 'notClosePayAtPumpPopup:actionButtonText',
  },
  'cantPayAtPumpPopup:title': {
    defaultMessage: 'Mobile payments unavailable',
    description: 'Mobile payments unavailable title',
    id: 'cantPayAtPumpPopup:title',
  },
  'cantPayAtPumpPopup:description': {
    defaultMessage: 'Mobile pay is not yet supported at this store. To pay with mobile, please find another store.',
    description: 'Mobile payments unavailable description',
    id: 'cantPayAtPumpPopup:description',
  },
  'cantPayAtPumpPopup:actionButtonText': {
    defaultMessage: 'Find another store',
    description: 'Mobile payments unavailable confirm button text',
    id: 'cantPayAtPumpPopup:actionButtonText',
  },
  'noConnectionPopup:noConnectionText': {
    defaultMessage: 'No internet connection. Please try again later',
    description: 'No connection popup text',
    id: 'noConnectionPopup:noConnectionText',
  },
});
