import { defineMessages } from 'react-intl';

export const surveyAwardMessages = defineMessages({
  'survey:vouchered-award-not-earned:popup-title-text': {
    id: 'survey:vouchered-award-not-earned:popup-title-text',
    defaultMessage: 'Earn an award!',
    description: 'Title for popup when survey vouchered not earned award popup is displayed',
  },
  'survey:vouchered-award-not-earned:popup-description-text': {
    id: 'survey:vouchered-award-not-earned:popup-description-text',
    defaultMessage: 'Complete the survey to earn an award!',
    description: 'Description for popup when survey vouchered not earned award popup is displayed',
  },
  'survey:vouchered-award-earned:popup-title-text': {
    id: 'survey:vouchered-award-earned:popup-title-text',
    defaultMessage: 'Your award is here!',
    description: 'Title for popup when survey vouchered earned award popup is displayed',
  },
  'survey:vouchered-award-earned:popup-description-text': {
    id: 'survey:vouchered-award-earned:popup-description-text',
    defaultMessage: 'Your awards can be found in the My Awards section on the Home screen!',
    description: 'Description for popup when survey vouchered earned award popup is displayed',
  },
  'survey:vouchered-award:popup-close-button-text': {
    id: 'survey:vouchered-award:popup-close-button-text',
    defaultMessage: 'Awesome!',
    description: 'Close button text for popup when survey vouchered award popup is displayed',
  },
});
