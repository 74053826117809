import { object, custom, ZodIssueCode } from 'zod';

import { InternalUrl } from '../deep-linking/deep-linking.types';
import { isInternalUniversalLinkTest } from '../deep-linking/deep-linking.schema';

export const getPushNotificationDataSchema = (universalLinkOrigins: string[]) => {
  return object({
    url: custom<InternalUrl>().superRefine((value, ctx) => {
      if (!Array.isArray(universalLinkOrigins) || !universalLinkOrigins.length) {
        ctx.addIssue({
          code: ZodIssueCode.custom,
          message: '`universalLinkOrigins` has not been provided.',
        });
        return false;
      }

      if (!isInternalUniversalLinkTest(value, universalLinkOrigins)) {
        ctx.addIssue({
          code: ZodIssueCode.custom,
          message: `${value} must be a valid internal URL.`,
        });
        return false;
      }

      return true;
    }),
  }).strip();
};
