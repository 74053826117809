import { Divider, FormGroup, Stack } from '@mui/material';

import { CheckboxField } from '../CheckboxField/CheckboxField';

export type CheckboxesFieldProps = {
  name: string;
  options: Array<{
    uuid: string;
    label: string;
  }>;
};

export const CheckboxesField = ({ name, options }: CheckboxesFieldProps) => {
  return (
    <FormGroup>
      <Stack divider={<Divider />}>
        {options.map(({ uuid, label }) => (
          <CheckboxField
            key={uuid}
            name={name}
            label={label}
            value={uuid}
            formControlLabelProps={{
              labelPlacement: 'start',
              sx: { justifyContent: 'space-between', ml: 0, minHeight: 56 },
            }}
          />
        ))}
      </Stack>
    </FormGroup>
  );
};
