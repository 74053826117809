import { string } from 'zod';

import { OfferFragment } from '@ocx/graphql';
import { MarketingContent, Offer } from './offer.types';
import { sortOffersByExpirationDateAndRank } from './offers.utils';
import { DynamicCardCustomDataSchema, SpinWheelGameCustomDataSchema } from './custom-data/custom-data.schema';
import { buildEarningMechanism } from './earning-mechanism.utils';
import { IneligibilityHandlingStrategy } from './eligibility.types';
import { calculateEligibility } from './eligibility';
import { isReadyToUseOffer } from './offer.type-guards';
import { DynamicCardCustomData } from './custom-data/custom-data.types';
import { getExternalUrlFromCustomData } from './custom-data/custom-data.dto';
import { getAvailableAwardCount } from './limit.utils';

export type SanitizedOfferFragment = Omit<OfferFragment, 'marketingContents'> & {
  marketingContents: [MarketingContent];
};

export const mapOfferFragmentToOffer = (fragment: SanitizedOfferFragment): Offer => {
  const earningMechanism = buildEarningMechanism(fragment);
  const customData =
    DynamicCardCustomDataSchema.safeParse(fragment.customData).success ||
    SpinWheelGameCustomDataSchema.safeParse(fragment.customData).success
      ? fragment.customData
      : null;

  return {
    id: fragment.id,
    expireAt: fragment.expireAt,
    engagementType: fragment.engagementType,
    earningMechanism,
    activationStatus: fragment.activationStatus,
    visibleStatus: fragment.visibleStatus,
    eligible: [
      false,
      IneligibilityHandlingStrategy.DisableInteractions,
      [{ type: 'unknown', strategy: IneligibilityHandlingStrategy.DisableInteractions }],
    ],
    marketingContent: sanitizeMarketingContent({ marketingContent: fragment.marketingContents[0], customData }),
    presentationPriority: fragment.presentationPriority,
    presentationType: fragment.presentationType,
    displayCategories: fragment.displayCategories,
    customData,
    voucherCount: fragment.voucherCount,
    vouchers: sanitizeVouchers(fragment.vouchers),
    availableAwardCount: fragment.limits ? getAvailableAwardCount(fragment.limits) : 0,
  };
};

export const isReadyToDisplayOfferFragment = (fragment: OfferFragment): fragment is SanitizedOfferFragment => {
  if (fragment.engagementType === 'VOUCHERED' && fragment.voucherCount === 0) {
    return false;
  }
  // isOfferHasMinimalRequiredMarketingContent
  return !!fragment.marketingContents?.[0]?.title;
};

const ExternalUrlSchema = string().url();

export interface ISanitizeMarketingContentParams {
  marketingContent: MarketingContent;
  customData: DynamicCardCustomData | null;
}

export const sanitizeMarketingContent = ({
  marketingContent: _marketingContent,
  customData,
}: ISanitizeMarketingContentParams): MarketingContent => {
  const marketingContent = { ..._marketingContent };

  if (!ExternalUrlSchema.safeParse(marketingContent.externalUrl).success) {
    marketingContent.externalUrl = getExternalUrlFromCustomData(customData);
  }

  return marketingContent;
};

export const sanitizeVouchers = (vouchers: SanitizedOfferFragment['vouchers']) => {
  return vouchers?.edges?.filter((edge) => !!edge?.node).map(({ node }) => node) || [];
};

export const mapFragmentsToSortedOffersList = (params: {
  offers: OfferFragment[];
  membershipPoints: number;
  membershipIsCompleted: boolean;
}): Offer[] => {
  const offersWithoutEligibility = params.offers
    .filter(isReadyToDisplayOfferFragment)
    .map(mapOfferFragmentToOffer)
    .sort(sortOffersByExpirationDateAndRank);

  const eligibilityMapper = (o: Offer): Offer => ({
    ...o,
    eligible: calculateEligibility(o, {
      membershipIsCompleted: params.membershipIsCompleted,
      membershipPoints: params.membershipPoints,
      membershipRewardOfferIds: params.offers.filter(isReadyToUseOffer).map(({ id }) => id),
    }),
  });

  return offersWithoutEligibility
    .map(eligibilityMapper)
    .filter((o: Offer): boolean => o.eligible[1] !== IneligibilityHandlingStrategy.Hide);
};
