import React from 'react';

import { PaymentAccountType } from '@ocx/graphql';
import { AddCardLayout } from './AddCardLayout';
import { AddCreditCard } from './AddCreditCard';
import { useAddCardController } from './hooks/useAddCardController';
import { AddPrepaidCard } from './AddPrepaidCard';

export type AddCardControllerProps = {
  type: Extract<PaymentAccountType, 'CREDIT' | 'PREPAID'>;
  onCardAdded?(params: { paymentInstrumentId: string | null }): void;
};

export const AddCardController = (props: AddCardControllerProps) => {
  const { type, onCardAdded } = props;
  const { loading, cardholderName, handleSubmit } = useAddCardController({ onCardAdded });

  return (
    <AddCardLayout loading={loading}>
      {type === PaymentAccountType.Prepaid ? (
        <AddPrepaidCard onSubmit={handleSubmit} />
      ) : (
        <AddCreditCard cardholderName={cardholderName} onSubmit={handleSubmit} />
      )}
    </AddCardLayout>
  );
};
