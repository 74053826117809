import React, { ReactNode } from 'react';
import { createIntl, createIntlCache, IntlProvider } from 'react-intl';
import en from '@active-configuration/en.locale.json';

const cache = createIntlCache();
export const intl = createIntl(
  {
    locale: 'en',
    messages: en,
  },
  cache,
);

export type TranslationProviderProps = {
  children: ReactNode;
};

export const TranslationProvider: React.FC<TranslationProviderProps> = ({ children }) => (
  <IntlProvider locale="en" defaultLocale="en" messages={en}>
    {children}
  </IntlProvider>
);
