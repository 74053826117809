import { useCallback, useMemo } from 'react';

import { TutorialStorage } from '../../utils/TutorialStorage';
import { useBaseOffers } from '../../../offers/use-base-offers.hook';
import { useHowDoesItWorkPopup } from '../../../../components/modals';

export interface IUseHowDoesItWorkProps {
  // Fetch base offers only when tutorial is not completed
  tutorialOnly?: boolean;
}

export const useHowDoesItWork = (props: IUseHowDoesItWorkProps = {}) => {
  const { tutorialOnly } = props;
  const { open: openHowDoesItWorkPopup } = useHowDoesItWorkPopup();

  // check if HowDoesItWork should be displayed on homepage or display on other pages
  const isHowDoesItWorkHidden = useMemo(
    () => (tutorialOnly ? !TutorialStorage.isHowDoesItWorkVisibleOnHomePage() : false),
    [tutorialOnly],
  );
  const { offers, loading } = useBaseOffers({ skip: isHowDoesItWorkHidden });
  const isHowDoesItWorkAvailable = useMemo(() => !!offers.length, [offers.length]);

  const handleHowDoesItWorkOfferClick = useCallback(() => {
    const marketingContentsWithTerms = offers.find(
      ({ marketingContent }) => marketingContent?.terms && marketingContent?.termsUrl,
    )?.marketingContent;
    return openHowDoesItWorkPopup({
      offers,
      terms: marketingContentsWithTerms?.terms,
      termsUrl: marketingContentsWithTerms?.termsUrl,
    });
  }, [offers, openHowDoesItWorkPopup]);

  return { handleHowDoesItWorkOfferClick, isHowDoesItWorkAvailable, loading, isHowDoesItWorkHidden };
};
