import { useCallback } from 'react';

import { IPaymentItem } from '../../payments.types';
import { ApplePay } from '../ApplePay';
import { APPLE_PAY_ERROR_CODE, APPLE_PAYMENT_STATUS } from '../types';
import { useGetApplePayRequest } from './useGetApplePayRequests';
import { useCreateApplePaySession } from './useCreateApplePaySession';
import { usePaymentProvider } from '../../../../modules/transaction/hooks/usePaymentProvider';
import { useConfiguration } from '../../../../modules/configuration/useConfiguration';

export const useShowApplePayPaymentRequest = () => {
  const { config } = useConfiguration();
  const { getApplePayPaymentRequestData } = useGetApplePayRequest();
  const createAppleSession = useCreateApplePaySession();
  const paymentProvider = usePaymentProvider();

  return useCallback(
    async (paymentItems: IPaymentItem[]) => {
      if (paymentProvider === null) {
        throw new Error('Error creating ApplePay payment request data. No Payment Provider specified');
      }
      const request = getApplePayPaymentRequestData({ paymentItems });
      if (request === null) {
        throw new Error('Error creating ApplePay payment request data');
      }
      if (config.applePay === null) {
        throw new Error('No ApplePay config available');
      }

      await ApplePay.configure({ merchantIdentifier: config.applePay.merchantId });

      try {
        const { requestId, transactionData } = await ApplePay.showPaymentRequest(request, createAppleSession);
        const tokenized = await paymentProvider.tokenizeApplePay(JSON.parse(transactionData));
        return {
          token: transactionData,
          nonce: tokenized ? tokenized.nonce : null,
          completePayment: async () => {
            await ApplePay.updateTransactionStatus({ requestId, statusCode: APPLE_PAYMENT_STATUS.SUCCESS });
          },
          cancelPayment: async () => {
            await ApplePay.updateTransactionStatus({ requestId, statusCode: APPLE_PAYMENT_STATUS.FAILURE });
          },
          canceled: false,
        };
      } catch (e: any) {
        if (e.code === APPLE_PAY_ERROR_CODE.CANCELED) {
          return { token: '', nonce: null, canceled: true };
        }
        throw e;
      }
    },
    [config.applePay, createAppleSession, getApplePayPaymentRequestData, paymentProvider],
  );
};
