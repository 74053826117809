import { defineMessage } from 'react-intl';

import { PrimaryInternalFuelingStatus } from '../../../modules/transaction/types';
import { MessageDescriptor } from '../../../lib/translation/types';

export const primaryStatusMessages: { [key in PrimaryInternalFuelingStatus]: MessageDescriptor } = {
  [PrimaryInternalFuelingStatus.Authorizing]: defineMessage({
    id: 'fuelingStatusLabel:authorizing',
    defaultMessage: 'Authorizing pump #{pumpNumber}',
    description: 'Fueling status label for authorizing status',
  }),
  [PrimaryInternalFuelingStatus.BeginFueling]: defineMessage({
    id: 'fuelingStatusLabel:beginFueling',
    defaultMessage: 'Begin Fueling',
    description: 'Fueling status label for begin fueling status',
  }),
  [PrimaryInternalFuelingStatus.Fueling]: defineMessage({
    id: 'fuelingStatusLabel:fueling',
    defaultMessage: 'Fueling started',
    description: 'Fueling status label for fueling started status',
  }),
  [PrimaryInternalFuelingStatus.Cancelling]: defineMessage({
    id: 'fuelingStatusLabel:canceling',
    defaultMessage: 'Fueling canceling',
    description: 'Fueling status label for canceling status',
  }),
  [PrimaryInternalFuelingStatus.CompletedCancelled]: defineMessage({
    id: 'fuelingStatusLabel:canceled',
    defaultMessage: 'Fueling canceled',
    description: 'Fueling status label for canceled status',
  }),
  [PrimaryInternalFuelingStatus.CompletedSuccessfully]: defineMessage({
    id: 'fuelingStatusLabel:completedSuccessfully',
    defaultMessage: 'Fueling complete',
    description: 'Fueling status label for completed successfully status',
  }),
  [PrimaryInternalFuelingStatus.CompletedUnsuccessfully]: defineMessage({
    id: 'fuelingStatusLabel:completedUnsuccessfully',
    defaultMessage: 'Transaction failed',
    description: 'Fueling status label for completed unsuccessfully status',
  }),
  [PrimaryInternalFuelingStatus.Unknown]: defineMessage({
    id: 'fuelingStatusLabel:unknown',
    defaultMessage: '...',
    description: 'Fueling status label for Unknown',
  }),
};

export const secondaryFuelingMessage = defineMessage({
  id: 'fuelingStatusSecondaryLabel:beginFueling',
  defaultMessage: 'Select a fuel grade on the pump and begin fueling',
  description: 'Fueling status secondary label for begin fueling state',
});

export const fuelingFailedMessage = defineMessage({
  id: 'fuelingFailed:title',
  defaultMessage: 'Transaction failed',
  description: 'Transaction failed title for popup when fueling is failed or network request occured',
});

export const transactionStatusTimedOutMessage = defineMessage({
  id: 'transactionStatus:secondaryLabel:transactionStatusReachedTimeoutLimit',
  defaultMessage: 'There was a connection error. Please reconnect.',
  description: 'Message when transaction does not change status for long time',
});
