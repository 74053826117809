import React from 'react';
import { styled } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import { PointsStrategy } from '@ocx/graphql';
import { PointsChange } from '../../../../components/PointsChange/PointsChange';
import { SizingMultiplier } from '../../../../theme/constants';
import { messages } from '../../messages';
import { LoadingButton } from '../../../../components/LoadingButton';

const PointsWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'disabled',
})<{ disabled?: boolean }>(({ theme, disabled }) => ({
  position: 'absolute',
  right: theme.spacing(SizingMultiplier.md),

  '& svg': {
    opacity: disabled ? 0.5 : 1,
  },
}));

export interface IBuyEntriesButtonProps {
  entries: number;
  price: number;
  onClick: () => void;
  loading: boolean;
  disabled?: boolean;
  pointsStrategy: Exclude<PointsStrategy, PointsStrategy.NotUsed>;
}

export const BuyEntriesButton: React.FC<IBuyEntriesButtonProps> = (props) => {
  const { entries, price, onClick, loading, disabled = false, pointsStrategy } = props;
  return (
    <LoadingButton fullWidth disabled={disabled} onClick={onClick} loading={loading}>
      <FormattedMessage {...messages.buyEntriesButtonText} values={{ entries }} />
      {!loading && (
        <PointsWrapper disabled={disabled}>
          <PointsChange points={-price} position="right" pointsStrategy={pointsStrategy} />
        </PointsWrapper>
      )}
    </LoadingButton>
  );
};
