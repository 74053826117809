import React from 'react';
import { Box, Collapse, Stack } from '@mui/material';

import backgroundLight from './assets/background-light.jpg';
import backgroundDark from './assets/background-dark.jpg';
import { SelectedStoreCard } from '../../../components/SelectedStoreCard/SelectedStoreCard';
import { PumpSelector } from './components/PumpSelector/PumpSelector';
import { PaymentSection } from './components/PaymentSection/PaymentSection';
import { SizingMultiplier } from '../../../theme/constants';
import { PAYMENT_INSTRUMENT_TYPE } from '../../../lib/payments/payments.types';
import { PaymentInstrument } from '../../../modules/wallet/wallet.types';
import { ListSelector } from '../../../hooks/useListController';
import { IStoreCarWashOption } from '../../../modules/stores/types';

export interface IPropsForSelectPump {
  addressLine1: string;
  addressLine2: string;
  pumps: string[];
  selectedPump: string | null;
  selectPump(pump: string): void;
  shouldPrintReceipt: boolean;
  togglePrintReceipt(value?: boolean): void;
  handlePurchaseGooglePay(): void;
  handlePurchaseApplePay(): void;
  onAddPaymentMethodClick(): void;
  handlePurchaseCard(): void;
  deselectPump(): void;
  carWashListController: ListSelector<IStoreCarWashOption>;
  paymentInstrumentsListSelector: ListSelector<PaymentInstrument>;
  isPaymentInstrumentsLoading: boolean;
  selectedPaymentInstrumentType: PAYMENT_INSTRUMENT_TYPE | null;
  isCardsAvailable: boolean;
}

export const SelectPump: React.FC<IPropsForSelectPump> = (props) => {
  const {
    addressLine1,
    addressLine2,
    pumps,
    selectPump,
    deselectPump,
    selectedPump,
    shouldPrintReceipt,
    togglePrintReceipt,
    handlePurchaseGooglePay,
    handlePurchaseApplePay,
    handlePurchaseCard,
    carWashListController,
    paymentInstrumentsListSelector,
    isPaymentInstrumentsLoading,
    selectedPaymentInstrumentType,
    onAddPaymentMethodClick,
    isCardsAvailable,
  } = props;

  return (
    <Stack
      flexGrow="1"
      sx={{
        background: (theme) =>
          `url(${theme.palette.retailerMode === 'light' ? backgroundLight : backgroundDark}) no-repeat center fixed`,
        backgroundSize: 'cover',
      }}>
      <Box m="auto 0" p={SizingMultiplier.lg}>
        <SelectedStoreCard addressLine1={addressLine1} addressLine2={addressLine2} />
      </Box>
      <Collapse in={selectedPump === null}>
        <PumpSelector pumps={pumps} selectPump={selectPump} />
      </Collapse>
      <Collapse in={selectedPump !== null} mountOnEnter>
        <PaymentSection
          carWashListController={carWashListController}
          paymentInstrumentsListSelector={paymentInstrumentsListSelector}
          isPaymentInstrumentsLoading={isPaymentInstrumentsLoading}
          selectedPump={selectedPump}
          shouldPrintReceipt={shouldPrintReceipt}
          togglePrintReceipt={togglePrintReceipt}
          handlePurchaseGooglePay={handlePurchaseGooglePay}
          handlePurchaseApplePay={handlePurchaseApplePay}
          onAddPaymentMethodClick={onAddPaymentMethodClick}
          handlePurchaseCard={handlePurchaseCard}
          deselectPump={deselectPump}
          selectedPaymentInstrumentType={selectedPaymentInstrumentType}
          isCardsAvailable={isCardsAvailable}
        />
      </Collapse>
    </Stack>
  );
};
