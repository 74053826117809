import React from 'react';

import coinIcon from '../../@active-configuration/assets/coin-icon.svg';

type PointsCoinProps = {
  width?: number;
  height?: number;
};

export const PointsCoin = (props: PointsCoinProps) => {
  const { width = 20, height = 20 } = props;
  return <img width={width} height={height} src={coinIcon} alt="coin" />;
};
