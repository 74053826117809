import React from 'react';
import { Box, LinearProgress } from '@mui/material';
import { Typography } from '@ocx/ui';

import { SizingMultiplier } from '../../../../../theme/constants';
import { OfferMemberProgress } from '../../../../../modules/offers/earning-mechanism.types';

export const PROGRESS_BAR_TEST_ID = 'offer_membership_progress_bar';

export const Bar: React.FC<OfferMemberProgress> = ({ required, achieved }) => {
  return (
    <Box display="flex" flexGrow={1} alignItems="center" mr={SizingMultiplier.xs} data-testid={PROGRESS_BAR_TEST_ID}>
      <LinearProgress
        variant="determinate"
        value={required > 0 ? (achieved / required) * 100 : 0}
        sx={{
          height: 6,
          borderRadius: 10,
          width: '100%',
          display: 'flex',
          flexWrap: 'wrap',
          mr: SizingMultiplier.sm,
          backgroundColor: 'text.disabled',
        }}
      />
      <Typography variant="subtitle2" component="span">
        {achieved}
      </Typography>
      <Typography variant="subtitle2" component="span" color="text.disabled">
        /{required}
      </Typography>
    </Box>
  );
};
