import { useCallback } from 'react';

import { useAlerts } from './useAlerts';
import { useIsOnline } from '../../hooks/useIsOnline';
import { useLoader } from '../../lib/loader/LoaderProvider';
import { useGetNavigationActions } from '../../hooks/useGetNavigationActions';
import { IRefreshState } from '../../lib/geolocation/useGeolocation';
import { useIsPayAtPumpAvailable } from './useIsPayAtPumpAvailable';
import { useRudderStack } from '../../lib/rudderStack/useRudderStack';

export interface IUseStartPayPumpParams {
  refreshGeolocation: IRefreshState;
  isEmailCompleted: boolean;
}

export const useStartPayAtPump = (params: IUseStartPayPumpParams) => {
  const { triggerEvent } = useRudderStack();

  const alerts = useAlerts();
  const isOnline = useIsOnline();
  const { turnLoader } = useLoader();
  const { pushToSelectPump } = useGetNavigationActions();
  const { refreshGeolocation, isEmailCompleted } = params;
  const canPayAtPump = useIsPayAtPumpAvailable({ refreshGeolocation });

  return useCallback(async () => {
    triggerEvent('pay_at_pump_clicked');

    try {
      if (!isOnline) {
        alerts.showNoConnectionAlert();
        return;
      }

      turnLoader(true);
      const { isAvailable, noPumpsAvailable, notClose, noMobilePaymentEnabled, noGeolocation, storeId } =
        await canPayAtPump();
      turnLoader(false);

      if (!isAvailable) {
        if (notClose) {
          alerts.showNoStoreFoundAlert();
        } else if (noMobilePaymentEnabled || noPumpsAvailable) {
          alerts.showNoMobilePaymentAlert();
        } else if (noGeolocation) {
          alerts.showNoGeolocationAvailableAlert();
        }
        return;
      }

      if (storeId === null) {
        return;
      }

      if (!isEmailCompleted) {
        alerts.showCompleteEmailAlert({ onCompleted: () => pushToSelectPump(storeId) });
        return;
      }

      pushToSelectPump(storeId);
    } finally {
      turnLoader(false);
    }
  }, [isEmailCompleted, isOnline, turnLoader, canPayAtPump, alerts, pushToSelectPump, triggerEvent]);
};
