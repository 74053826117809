import { IPaymentItem } from '../payments.types';
import {
  ApplePayLineItem,
  ApplePayPaymentRequest,
  IGetApplePayCanMakeRequestData,
  IGetApplePayCanMakeRequestParams,
  IGetApplePayPaymentRequestParams,
} from './types';
import { paymentNetworksMap, paymentStatusMap } from './constants';

const mapPaymentItemsToLineItems = (paymentItems: IPaymentItem[]): ApplePayLineItem[] => {
  return paymentItems.map((paymentItem) => ({
    label: paymentItem.label,
    amount: paymentItem.price,
    type: paymentStatusMap[paymentItem.status],
  }));
};

const calculateLineItemsTotal = (paymentItems: IPaymentItem[]): number => {
  return paymentItems.reduce((previousValue, currentValue) => {
    return previousValue + parseFloat(currentValue.price);
  }, 0);
};

export const getPaymentRequestData = (params: IGetApplePayPaymentRequestParams): ApplePayPaymentRequest => {
  const { paymentItems } = params;

  const total = calculateLineItemsTotal(paymentItems);
  const lineItems = mapPaymentItemsToLineItems(paymentItems);

  return {
    merchantCapabilities: ['supports3DS'],
    supportedNetworks: params.supportedNetworks.map((network) => paymentNetworksMap[network]),
    countryCode: 'US',
    currencyCode: 'USD',
    // TODO: Refactor it to get rid of hardcoded pending
    total: { label: params.merchantName, amount: total.toString(), type: 'pending' },
    lineItems,
  };
};

export const getCanMakeRequestData = (params: IGetApplePayCanMakeRequestParams): IGetApplePayCanMakeRequestData => {
  return {
    merchantId: params.merchantId,
    merchantCapabilities: ['supports3DS'],
    supportedNetworks: params.supportedNetworks.map((network) => paymentNetworksMap[network]),
  };
};
