import { useIntl } from 'react-intl';
import { useCallback, useMemo } from 'react';

import { PointsStrategy, useReturnPurchasedVoucherMutation } from '@ocx/graphql';
import { messages } from '../../../components/OfferDetails/offer-details.messages';
import { useSnackbar } from '../../../lib/snackbar/snackbar';
import { useShowGenericErrorSnackbar } from '../../../hooks/useShowGenericErrorSnackbar';
import { useConfiguration } from '../../../modules/configuration/useConfiguration';
import { useConfirmationActionSheet } from '../../../components/modals';

type UseReturnReward = (params: { onCompleted?: () => void }) => {
  returnReward: (offerId: string) => void;
  returningReward: boolean;
};
export const useReturnReward: UseReturnReward = ({ onCompleted }) => {
  const intl = useIntl();
  const snackbar = useSnackbar();
  const showGenericErrorSnackbar = useShowGenericErrorSnackbar();
  const { open: openConfirmationActionSheet } = useConfirmationActionSheet();

  const { pointsStrategy } = useConfiguration('loyaltyProgram');

  const [returnReward, { loading: isReturningReward }] = useReturnPurchasedVoucherMutation({
    refetchQueries: ['getOfferVoucher', 'offers'],
  });

  const onSuccessfulReturn = useCallback(() => {
    snackbar.open(
      intl.formatMessage(
        pointsStrategy === PointsStrategy.Points
          ? messages.returnRewardForPointsSuccessText
          : messages.returnRewardForCentsSuccessText,
      ),
      { variant: 'success' },
    );
    onCompleted?.();
  }, [intl, onCompleted, pointsStrategy, snackbar]);

  const onReturnConfirmed = useCallback(
    async (offerId: string) => {
      return returnReward({
        variables: {
          input: { offerId },
        },
        onCompleted: onSuccessfulReturn,
        refetchQueries: ['currentCustomer', 'offers'],
        onError: showGenericErrorSnackbar,
      });
    },
    [onSuccessfulReturn, returnReward, showGenericErrorSnackbar],
  );

  const openReturnRewardConfirmation = useCallback(
    (offerId: string) => {
      openConfirmationActionSheet({
        title: intl.formatMessage(messages.returnRewardConfirmationTitle),
        description: intl.formatMessage(messages.returnRewardConfirmationText),
        confirmButtonText: intl.formatMessage(messages.returnRewardConfirmationButtonTitle),
        onConfirm: () => onReturnConfirmed(offerId),
      });
    },
    [intl, onReturnConfirmed, openConfirmationActionSheet],
  );

  return useMemo(
    () => ({
      returnReward: openReturnRewardConfirmation,
      returningReward: isReturningReward,
    }),
    [isReturningReward, openReturnRewardConfirmation],
  );
};
