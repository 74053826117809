import * as Sentry from '@sentry/react';

import { LocalRetailerConfiguration } from '../app-config.types';
import { LocalRetailerConfigurationSchema } from '../app-config.schema';

export const isValidLocalRetailerConfiguration = (config: unknown): config is LocalRetailerConfiguration => {
  if (!LocalRetailerConfigurationSchema.safeParse(config).success) {
    Sentry.captureException(new Error(`${JSON.stringify(config)}: config is invalid.`));
    return false;
  }
  return true;
};
