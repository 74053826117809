import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useMemo } from 'react';

import { TransactionReceiptPopupController } from './transaction-receipt-popup.controller';

const TransactionReceiptPopupModal = NiceModal.create(TransactionReceiptPopupController);
export const useTransactionReceiptPopup = () => {
  const { show } = useModal(TransactionReceiptPopupModal);
  return useMemo(() => ({ open: show }), [show]);
};
