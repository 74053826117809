import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const GiftBoxIcon = (props: SvgIconProps) => {
  const { color = 'currentColor', ...rest } = props;
  return (
    <SvgIcon {...rest} fill={color}>
      <path d="M4.20002 21.6V10.8H2.40002V5.40001H7.00002C6.91669 5.21668 6.85419 5.02555 6.81252 4.82664C6.77086 4.6277 6.75002 4.41883 6.75002 4.20001C6.75002 3.36668 7.04169 2.65835 7.62503 2.07501C8.20836 1.49168 8.91669 1.20001 9.75002 1.20001C10.1881 1.20001 10.5977 1.29168 10.9786 1.47501C11.3596 1.65835 11.7 1.90835 12 2.22501C12.2834 1.90835 12.6209 1.65835 13.0125 1.47501C13.4042 1.29168 13.8167 1.20001 14.25 1.20001C15.0834 1.20001 15.7917 1.49168 16.375 2.07501C16.9584 2.65835 17.25 3.36668 17.25 4.20001C17.25 4.40001 17.2209 4.60001 17.1625 4.80001C17.1042 5.00001 17.05 5.20001 17 5.40001H21.6V10.8H19.8V21.6H4.20002ZM14.25 3.00001C13.91 3.00001 13.625 3.11501 13.395 3.34501C13.165 3.57501 13.05 3.86001 13.05 4.20001C13.05 4.54001 13.165 4.82501 13.395 5.05501C13.625 5.28501 13.91 5.40001 14.25 5.40001C14.59 5.40001 14.875 5.28501 15.105 5.05501C15.335 4.82501 15.45 4.54001 15.45 4.20001C15.45 3.86001 15.335 3.57501 15.105 3.34501C14.875 3.11501 14.59 3.00001 14.25 3.00001ZM8.55002 4.20001C8.55002 4.54001 8.66502 4.82501 8.89502 5.05501C9.12502 5.28501 9.41002 5.40001 9.75002 5.40001C10.09 5.40001 10.375 5.28501 10.605 5.05501C10.835 4.82501 10.95 4.54001 10.95 4.20001C10.95 3.86001 10.835 3.57501 10.605 3.34501C10.375 3.11501 10.09 3.00001 9.75002 3.00001C9.41002 3.00001 9.12502 3.11501 8.89502 3.34501C8.66502 3.57501 8.55002 3.86001 8.55002 4.20001ZM4.20002 7.20001V9.00001H11.1V7.20001H4.20002ZM11.1 19.8V10.8H6.00002V19.8H11.1ZM12.9 19.8H18V10.8H12.9V19.8ZM19.8 9.00001V7.20001H12.9V9.00001H19.8Z" />
    </SvgIcon>
  );
};
