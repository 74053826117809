import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const InfoIcon: React.FC<SvgIconProps> = (props) => {
  const { color = 'currentColor', ...rest } = props;
  return (
    <SvgIcon {...rest} viewBox="0 0 16 16" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path d="m8.00003 7.3335c-.17681 0-.34638.07023-.4714.19526-.12503.12502-.19526.29459-.19526.4714v2.00004c0 .1768.07023.3463.19526.4714.12502.125.29459.1952.4714.1952s.34638-.0702.47141-.1952c.12502-.1251.19526-.2946.19526-.4714v-2.00004c0-.17681-.07024-.34638-.19526-.4714-.12503-.12503-.2946-.19526-.47141-.19526zm0-2c-.13185 0-.26074.0391-.37038.11235-.10963.07325-.19508.17737-.24554.29919s-.06366.25586-.03793.38518c.02572.12932.08921.24811.18245.34135.09323.09323.21202.15673.34134.18245s.26337.01252.38519-.03794c.12181-.05046.22593-.1359.29919-.24554.07325-.10963.11235-.23852.11235-.37038 0-.17681-.07024-.34638-.19526-.4714-.12503-.12503-.2946-.19526-.47141-.19526zm0-4c-.87548 0-1.74238.17243-2.55122.50747-.80884.33503-1.54377.82609-2.16282 1.44515-1.25024 1.25024-1.95262 2.94593-1.95262 4.71404-.00583 1.53943.52719 3.03234 1.50666 4.22004l-1.33333 1.3333c-.09251.0937-.15517.2128-.18009.3421-.02491.1293-.01096.2632.04009.3846.05537.1199.14514.2207.2579.2896.11276.0688.24343.1026.37543.097h6c1.76811 0 3.46377-.7023 4.71407-1.9526 1.2502-1.2502 1.9526-2.94593 1.9526-4.71404s-.7024-3.4638-1.9526-4.71404c-1.2503-1.25024-2.94596-1.95262-4.71407-1.95262zm0 12h-4.39333l.62-.62c.06299-.0617.1131-.1354.14744-.2166.03433-.0813.05219-.1685.05256-.2567-.00251-.1759-.07439-.3436-.2-.4667-.87294-.872-1.41655-2.01964-1.53821-3.24747-.12165-1.22783.18617-2.45986.87103-3.48619.68485-1.02632 1.70437-1.78344 2.88486-2.14236 1.18048-.35893 2.44889-.29745 3.58912.17396 1.1402.4714 2.0818 1.32357 2.6642 2.41132.5823 1.08774.7696 2.34377.5298 3.55407-.2399 1.21027-.8919 2.30007-1.8451 3.08347-.9532.7835-2.14853 1.2122-3.38237 1.2132z" />
    </SvgIcon>
  );
};
