export enum DateTimeFormat {
  DateTimeFull = 'MMMM d, yyyy h:mm a', //  November 7, 2022 1:21 PM
  DateSlashedTimeFull = 'M/d/yy, hh:mm aaa', // 11/7/22, 01:21 pm
  DateFull = 'yyyy-MM-dd', // 2022-11-07
  Time = 'HH:mm', // 13:21
  TimeFull = 'hh:mm aaa', // 01:21 pm
  WeekdayFull = 'EEEE', // Monday
  MonthYear = 'MMMM yyyy', // November 2022
  DateYearMonthGroupBy = 'yyyyMM', // 202211
}
