import { useCallback, memo } from 'react';
import { useIntl } from 'react-intl';
import { useReactiveVar } from '@apollo/client';
import { useParams } from 'react-router-dom';

import { SignupWithLink } from './SignupWithLink';
import { SignupWithLinkLayout } from './SignupWithLinkLayout';
import { useSignupWithLinkController } from './hooks/useSignupWithLinkController';
import { useGetNavigationActions } from '../../hooks/useGetNavigationActions';
import { useSnackbar } from '../../lib/snackbar/snackbar';
import { isLoggedInVar } from '../../modules/auth/authReactiveVariables';
import { clearCache } from '../../modules/cache-layer/utils';
import { logInDev } from '../../lib/logger/logger';

export const SignupWithLinkController = memo(() => {
  const intl = useIntl();
  const snackbar = useSnackbar();
  const { pushRequestLink, pushToHome } = useGetNavigationActions();

  const { token = '' } = useParams<{ token: string }>();
  const isLoggedIn = useReactiveVar(isLoggedInVar);

  const onSignupCompleted = useCallback(() => {
    const navigateToHome = () => {
      snackbar.open(
        intl.formatMessage({
          id: 'signUp:successfullyLoggedInToastMessage',
          defaultMessage: 'Welcome!',
          description: 'SignUp page: displayed message when successfully logged in',
        }),
        {
          variant: 'success',
        },
      );
      pushToHome();
    };
    clearCache().catch(logInDev).finally(navigateToHome);
  }, [snackbar, intl, pushToHome]);

  const onSignupFailed = useCallback(() => {
    if (isLoggedIn) {
      pushToHome();
      return;
    }

    snackbar.open(
      intl.formatMessage({
        id: 'signUp:expiredLinkMessage',
        defaultMessage: 'This link is expired. Please enter your phone number for a new link.',
        description: 'SignUp page: displayed message when sign-up link has been expired',
      }),
      {
        variant: 'error',
      },
    );
    pushRequestLink();
  }, [isLoggedIn, snackbar, intl, pushRequestLink, pushToHome]);

  useSignupWithLinkController(token, {
    onCompleted: onSignupCompleted,
    onError: onSignupFailed,
  });

  return (
    <SignupWithLinkLayout>
      <SignupWithLink />
    </SignupWithLinkLayout>
  );
});

export default SignupWithLinkController;
