import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  buyEntriesCongratsDialogTitle: {
    id: 'pointsSweeps:buyEntries:congratsDialog:title',
    defaultMessage: 'Awesome!',
    description: 'Buy entries congrats dialog title',
  },
  buyEntriesCongratsDialogDescription: {
    id: 'pointsSweeps:buyEntries:congratsDialog:description',
    defaultMessage: 'You bought {entries, plural, one {# entry} other {# entries}}',
    description: 'Buy entries congrats dialog description',
  },
  offerDetailsNotFoundPopupDescription: {
    id: 'offer-details:not-found:popup:description',
    defaultMessage: 'Looks like that offer is not available, check out some other great offers in the app today!',
    description: 'Not offer found description',
  },
});
