import { styled } from '@mui/material';
import React from 'react';

import { SizingMultiplier } from '../../theme/constants';
import { DynamicCardType } from './dynamic-card.types';

export interface IDynamicCardMediaProps {
  src: string;
  alt?: string;
  variant?: DynamicCardType;
  objectFit?: React.CSSProperties['objectFit'];
}

export const DynamicCardMedia = styled('img', {
  shouldForwardProp: (prop) => prop !== 'variant',
})<IDynamicCardMediaProps>(({ variant = 'default', objectFit = 'cover', theme }) => {
  if (variant === 'default') {
    return {
      marginTop: 'auto',
      marginBottom: 'auto',
      marginRight: theme.spacing(SizingMultiplier.sm),
      objectFit,
      borderRadius: theme.spacing(SizingMultiplier.sm),
      overflow: 'hidden',
      width: 80,
      height: 80,
    };
  }

  return {
    height: 120,
    width: '100%',
    objectFit,
    overflow: 'hidden',
  };
});
