import { useCallback } from 'react';
import { PermissionState } from '@capacitor/core';

import { useUpdatePushNotificationPreference } from './useUpdatePushNotificationPreference';

export interface ISyncPushNotificationPermissionStateParams {
  oldIsOptedIn: boolean;
  newState: PermissionState;
  customerId: string;
}

export const useSyncPushNotificationPermissionState = () => {
  const updatePushNotificationPreference = useUpdatePushNotificationPreference();

  return useCallback(
    ({ oldIsOptedIn, newState, customerId }: ISyncPushNotificationPermissionStateParams) => {
      const newIsOptIn = newState === 'granted';

      if (oldIsOptedIn === newIsOptIn) {
        return;
      }

      updatePushNotificationPreference({ isOptedIn: newIsOptIn, customerId });
    },
    [updatePushNotificationPreference],
  );
};
