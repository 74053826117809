import { calcDateTime, diffDateTime } from '../../lib/date/date';
import { useConfiguration } from '../configuration/useConfiguration';
import { sharedStorage } from '../shared-storage/shared.storage';
import { APP_RATE_LAST_DATE_STORAGE_KEY } from './app-rate.constants';

export const useAppRateUtils = () => {
  const { transactionHistoryLookupDays, promptIntervalDays } = useConfiguration('appRating');

  const getIsRecentTransaction = (transactionAt: string) => {
    const date = new Date(transactionAt);
    const today = new Date();
    const daysDiff = diffDateTime(today, date, 'day');
    return daysDiff <= transactionHistoryLookupDays;
  };
  const getLastAppRateDate = async (): Promise<Date | null> => {
    const value = await sharedStorage.getItem(APP_RATE_LAST_DATE_STORAGE_KEY);
    return value !== null ? new Date(value as string) : null;
  };

  const getIsAppRateDelayPassed = (lastAppRateRequestDate: Date): boolean => {
    const now = new Date();
    const appRateDelayExpiresAt = calcDateTime(lastAppRateRequestDate, 'add', promptIntervalDays, 'day');
    return now > appRateDelayExpiresAt;
  };

  return { getIsRecentTransaction, getLastAppRateDate, getIsAppRateDelayPassed };
};
