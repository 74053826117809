import { useCallback, useEffect, useRef, useState } from 'react';
import { Capacitor } from '@capacitor/core';
import { Geolocation, PermissionStatus, Position } from '@capacitor/geolocation';
import * as Sentry from '@sentry/react';
import get from 'lodash/get';

import { Coordinates } from '../../modules/stores/types';
import { calculateDistance } from './utils/calculateDistance';
import { useInterval } from '../../hooks/useSetInterval';
import { useConfiguration } from '../../modules/configuration/useConfiguration';

export interface IUseGeolocationState {
  coordinates: Coordinates | null;
  permissionStatus: PermissionStatus['location'] | null;
}

export type IRefreshState = () => Promise<IUseGeolocationState>;

export interface IUseGeolocation {
  coordinates: Coordinates | null;
  permissionStatus: PermissionStatus['location'] | null;
  loading: boolean;
  refreshState: IRefreshState;
}

export interface IUseGeolocationOptions {
  requestPermissions?: boolean;
  watch?: boolean;
}

const canRequestPermissions = (currentPermissionStatus: PermissionStatus): boolean => {
  if (!Capacitor.isNativePlatform()) {
    return false;
  }
  return currentPermissionStatus.location === 'prompt' || currentPermissionStatus.location === 'prompt-with-rationale';
};

// https://developer.mozilla.org/ru/docs/Web/API/GeolocationPositionError
const handlePositionError = (e: Error | { code: number }): boolean => {
  return get(e, 'code', null) === 1;
};

const checkPermissions = async (): Promise<PermissionStatus> => {
  // IOS WEB does not have Permissions API.
  // We can ignore permissions checking for WEB and handle 'permission denied' later
  if (Capacitor.isNativePlatform()) {
    try {
      return await Geolocation.checkPermissions();
    } catch (e) {
      return {
        location: 'denied',
        coarseLocation: 'denied',
      };
    }
  }
  return { location: 'prompt', coarseLocation: 'prompt' };
};

const requestPermissions = async (): Promise<PermissionStatus> => {
  try {
    return await Geolocation.requestPermissions();
  } catch (e) {
    return {
      location: 'denied',
      coarseLocation: 'denied',
    };
  }
};

export const SIGNIFICANT_LOCATION_CHANGE_DISTANCE_M = 15;
export const isSignificantLocationChange = (a: Coordinates, b: Coordinates) => {
  const distanceBetweenPoints = calculateDistance(a, b, 'km') * 1000; // km -> meters
  return distanceBetweenPoints >= SIGNIFICANT_LOCATION_CHANGE_DISTANCE_M;
};

export const positionToCoordinates = (position: Position): Coordinates => {
  return { lat: position.coords.latitude, lng: position.coords.longitude };
};

export const useGeolocation = (options: IUseGeolocationOptions = {}): IUseGeolocation => {
  const watchTimeout = useConfiguration('storeLocator.watchTimeout');
  const [loading, setLoading] = useState(true);

  const [permissionStatus, setPermissionStatus] = useState<PermissionStatus['location'] | null>(null);
  const [coordinates, setCoordinates] = useState<Coordinates | null>(null);
  const coordinatesRef = useRef<Coordinates | null>(null);
  const [locationPollingStarted, setLocationPollingStarted] = useState(false);

  const refreshPermissionsStatus = useCallback(async (): Promise<PermissionStatus['location']> => {
    let currentPermissionStatus = await checkPermissions();
    if (options.requestPermissions && canRequestPermissions(currentPermissionStatus)) {
      currentPermissionStatus = await requestPermissions();
    }
    setPermissionStatus(currentPermissionStatus.location);
    return currentPermissionStatus.location;
  }, [options.requestPermissions]);

  const refreshCoordinates = useCallback(async (): Promise<Coordinates | null> => {
    const newPosition = await Geolocation.getCurrentPosition({
      // Timeout should be smaller that polling interval
      timeout: watchTimeout ? watchTimeout * 0.8 : undefined,
      maximumAge: 30000, // 15 seconds
    });

    // Since we don't have Permissions API for WEB IOS
    // We can set permission status manually for all platforms in case of successful geolocation request
    // And handle all exceptions in 'catch' statement
    setPermissionStatus('granted');

    const newCoordinates = positionToCoordinates(newPosition);
    if (coordinatesRef.current && !isSignificantLocationChange(coordinatesRef.current, newCoordinates)) {
      return coordinatesRef.current;
    }
    setCoordinates(newCoordinates);
    coordinatesRef.current = newCoordinates;
    return newCoordinates;
  }, [watchTimeout]);

  const refreshState = useCallback<IRefreshState>(async () => {
    let coordinates = null;
    let status: PermissionStatus['location'] | null = null;
    try {
      status = await refreshPermissionsStatus();
      if (status !== 'denied') {
        coordinates = await refreshCoordinates();
      }
    } catch (e: any) {
      const isWebPermissionDenied = handlePositionError(e);
      if (isWebPermissionDenied) {
        status = 'denied';
        setPermissionStatus(status);
      }
    }

    return {
      coordinates,
      permissionStatus: status,
    };
  }, [refreshCoordinates, refreshPermissionsStatus]);

  useInterval(refreshState, watchTimeout, !options.watch || !locationPollingStarted);

  const init = useCallback(async () => {
    setLoading(true);
    await refreshState();
    setLocationPollingStarted(!!options.watch);
    setLoading(false);
  }, [options.watch, refreshState]);

  useEffect(() => {
    init().catch(Sentry.captureException);
  }, [init]);

  return {
    loading,
    permissionStatus,
    coordinates,
    refreshState,
  };
};
