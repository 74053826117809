import { ReactNode } from 'react';
import { Typography } from '../typography/typography.component';

type ListItemTextProps = { children?: ReactNode; primary?: ReactNode; secondary?: ReactNode };

export const ListItemText = (props: ListItemTextProps) => {
  const { children, primary, secondary } = props;
  return (
    <Typography display="flex" flexDirection="column" flexGrow="1" align="left" color="inherit">
      {children}
      {primary}
      {secondary && (
        <Typography variant="caption" align="left" color="textSecondary">
          {secondary}
        </Typography>
      )}
    </Typography>
  );
};
