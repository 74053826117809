import { Stack, Theme, Divider } from '@mui/material';
import { ReactNode } from 'react';

import { SizingMultiplier } from '../sizing-multiplier.constant';

type ListProps = {
  children?: ReactNode;
  disabledPaddings?: boolean;
};

const disabledPaddingMx = (theme: Theme) => `${theme.spacing(-SizingMultiplier.md)}!important`;

export const List = (props: ListProps) => {
  const { children, disabledPaddings } = props;
  return (
    <Stack
      divider={<Divider variant="middle" />}
      mx={disabledPaddings ? disabledPaddingMx : undefined}
      color="text.primary">
      {children}
    </Stack>
  );
};
