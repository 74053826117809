import { captureMessage } from '@sentry/react';

import { CurrentCustomerQuery } from '@ocx/graphql';

type NonNullCustomerFragment = NonNullable<CurrentCustomerQuery['customer']>;
type NonNullMembershipFragment = NonNullable<NonNullCustomerFragment['membership']>;

export type SanitizedCurrentCustomer = Omit<NonNullCustomerFragment, 'membership'> & {
  membership: NonNullMembershipFragment;
};

export const getSanitizedCustomer = (data: CurrentCustomerQuery | null | undefined): SanitizedCurrentCustomer => {
  if (!data?.customer?.uuid) {
    captureMessage('customer.uuid is null');
    throw new Error('customer.uuid is null');
  }

  if (!data.customer.membership) {
    captureMessage('customer.membership is null', { extra: { customerId: data.customer.uuid } });
    throw new Error('customer.membership is null');
  }

  return data.customer as SanitizedCurrentCustomer;
};
