import libPhoneNumberParse, { isValidPhoneNumber as libPhoneNumberIsValid, MetadataJson } from 'libphonenumber-js/core';

import metadata from './metadata.custom.json';

export const parsePhoneNumber = (phoneNumber: string) => {
  return libPhoneNumberParse(phoneNumber || '', 'US', metadata as MetadataJson);
};
export const isValidPhoneNumber = (phoneNumber: string): boolean => {
  return libPhoneNumberIsValid(phoneNumber || '', 'US', metadata as MetadataJson);
};

export const phoneNumberFormatInternational = (phoneNumber: string) => {
  const phoneNumberObject = parsePhoneNumber(phoneNumber);
  return phoneNumberObject ? phoneNumberObject.formatInternational() : phoneNumber;
};

export const phoneNumberFormatNationalForUs = (phoneNumber: string) => {
  const phoneNumberObject = parsePhoneNumber(phoneNumber);

  if (!phoneNumberObject) {
    return phoneNumber;
  }

  // If US phone number - returns "national format" else returns "international formats"
  return phoneNumberObject.country === 'US'
    ? phoneNumberObject.formatNational()
    : phoneNumberObject.formatInternational();
};

export { metadata };
