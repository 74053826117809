import React from 'react';

import { IConfigurationContextValue } from '../configuration/app-config.context';

export type AppContextValue = {
  configuration: IConfigurationContextValue | null;
};

export const AppContext = React.createContext<AppContextValue>({
  configuration: null,
});

export const useAppContext = (): AppContextValue => React.useContext(AppContext);
