import { TabPanel } from '@mui/lab';
import { Divider, Stack } from '@mui/material';

import { ExternalOfferFragment } from '@ocx/graphql';
import { ExternalBannerCardController } from './external-banner-card.controller';
import { OfferCardController } from '../../components/OfferCardController';
import { OfferCard } from '../../../../components/OfferCard';
import { Offer } from '../../../../modules/offers/offer.types';

export type OffersCarouselPanelControllerProps = {
  id: string;
  offers: Offer[];
  banners: ExternalOfferFragment[];
};

export const OffersCarouselPanelController = (props: OffersCarouselPanelControllerProps) => {
  const { id, offers, banners } = props;

  return (
    <TabPanel value={id} sx={{ p: 0 }}>
      <Stack divider={<Divider variant="middle" />}>
        {banners.map((banner) => (
          <ExternalBannerCardController
            key={`${banner.bannerConfigurationUuid}_${banner.trackingId}`}
            banner={banner}
          />
        ))}
        {offers.map((offer) => (
          <OfferCardController key={offer.id} offer={offer}>
            {(props) => <OfferCard {...props} />}
          </OfferCardController>
        ))}
      </Stack>
    </TabPanel>
  );
};
