import { useCallback } from 'react';
import * as Sentry from '@sentry/react';
import { isApolloError } from '@apollo/client';

import { useDeviceTokenRegisterMutation } from '@ocx/graphql';
import { logInDev } from '../../lib/logger/logger';
import { getDeviceId, getDevicePlatform } from '../../lib/device/utils/info';
import { getDeviceType } from '../device/utils/info';
import { getCachedPushNotificationsState, cachePushNotificationsState } from './push-notification.cache';
import { PushNotificationPermissionDeniedError } from './push-notification.exception';
import { getFCMToken, getIsChanged, getIsPushNotificationRegistrationExpired } from './push-notifications.utils';

const getFreshDeviceIdAndTokenOrNull = async () => {
  const devicePlatform = getDevicePlatform();
  // Push notifications are not supported on the web.
  if (devicePlatform === 'web') {
    return null;
  }
  const cachedState = await getCachedPushNotificationsState();
  const isStateExpired = getIsPushNotificationRegistrationExpired(cachedState.registrationTimestamp);

  const { token, removeListeners } = await getFCMToken();
  const deviceId = await getDeviceId();

  const isTokenChanged = getIsChanged(
    { deviceId, token },
    { deviceId: cachedState.deviceId, token: cachedState.fcmToken },
  );

  await removeListeners();

  // Do not register token if device ID and token are not changed and not expired
  if (!isStateExpired && !isTokenChanged) {
    return null;
  }

  return {
    token,
    deviceId,
    deviceType: getDeviceType(),
  };
};

export const useRegisterPushNotifications = () => {
  const [registerDeviceToken] = useDeviceTokenRegisterMutation();

  const registerPushNotifications = useCallback(async (): Promise<void> => {
    try {
      const input = await getFreshDeviceIdAndTokenOrNull();
      if (!input) {
        return;
      }
      await registerDeviceToken({
        variables: {
          input,
        },
      });
      await cachePushNotificationsState({ deviceId: input.deviceId, fcmToken: input.token });
    } catch (e) {
      logInDev(e);
      if (e instanceof PushNotificationPermissionDeniedError) {
        return;
      }
      if (e instanceof Error && !isApolloError(e)) {
        Sentry.captureException(e);
      }
    }
  }, [registerDeviceToken]);

  return { registerPushNotifications };
};
