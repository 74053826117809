import React from 'react';
import { Box } from '@mui/material';

import { DynamicCard, DynamicCardAccessory, DynamicCardContent, DynamicCardMedia } from '../DynamicCard';
import { SurgeonWarning } from '../SurgeonWarning/SurgeonWarning';
import { SizingMultiplier } from '../../theme/constants';
import { ExternalOfferTitle } from './components/ExternalOfferTitle';
import { ExternalOfferContent } from '../../modules/external-loyalty/external-offers.types';

export type ExternalOfferCardProps = {
  onClick?: () => void;
  content: ExternalOfferContent;
};

export const ExternalOfferCard = React.forwardRef<HTMLDivElement, ExternalOfferCardProps>(
  (props: ExternalOfferCardProps, ref) => {
    const {
      content: { title, titleMiddle, title2, imageUrl, surgeonGeneralWarning },
      onClick,
    } = props;
    return (
      <DynamicCard ref={ref} loading={false} disabled={false} onClick={onClick}>
        {surgeonGeneralWarning && (
          <Box
            sx={(theme) => ({
              m: theme.spacing(SizingMultiplier.md, SizingMultiplier.md, -SizingMultiplier.sm),
            })}>
            <SurgeonWarning message={surgeonGeneralWarning} />
          </Box>
        )}
        <Box position="relative">
          <DynamicCardContent>
            {imageUrl && <DynamicCardMedia src={imageUrl} alt={title} objectFit="contain" />}
            <Box flex={1} alignItems="flex-start" justifyContent="center">
              <ExternalOfferTitle title={title} titleMiddle={titleMiddle} title2={title2} />
            </Box>
            <DynamicCardAccessory type="disclosureIndicator" />
          </DynamicCardContent>
        </Box>
      </DynamicCard>
    );
  },
);
