import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography, NavigationBar, Page, PageContent } from '@ocx/ui';

import { HtmlTitle } from '../../components/router/HtmlTitle';
import { BackButton } from '../../components/Layout/NavigationBar/BackButton/BackButton';
import { messages } from './product-interests.messages';

type ProductInterestsLayoutProps = {
  children: ReactNode;
};

export const ProductInterestsLayout: React.FC<ProductInterestsLayoutProps> = ({ children }) => {
  return (
    <Page>
      <HtmlTitle title={messages['product-interests:html-title']} />
      <NavigationBar
        left={<BackButton />}
        center={
          <Typography component="h2">
            <FormattedMessage {...messages['product-interests:page-title']} />
          </Typography>
        }
      />
      <PageContent>{children}</PageContent>
    </Page>
  );
};
