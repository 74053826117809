import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  successTitle: {
    id: 'loyaltyEvent:success:offer:title',
    defaultMessage: 'Scan successful!',
    description: 'Title of message when successfully created a loyalty event by scanning a code.',
  },
  successDescription: {
    id: 'loyaltyEvent:success:offer:description',
    defaultMessage: 'Check your rewards to see what offer you may have unlocked.',
    description:
      'Body of message when successfully created a loyalty event by scanning a code if nor title or description is present.',
  },
  errorTitle: {
    id: 'loyaltyEvent:error:noOffer:title',
    defaultMessage: 'Sorry!',
    description: 'Title of message when loyalty event was not created by scanning a code.',
  },
  errorDescription: {
    id: 'loyaltyEvent:error:noOffer:description',
    defaultMessage: 'This promotion is not available.',
    description: 'Body of message when loyalty event was not created by scanning a code.',
  },
});
