import * as Sentry from '@sentry/react';

import { MembershipCustomData } from './membership.types';
import { MembershipCustomDataSchema } from './membership-custom-data.schema';

export const isMembershipCustomDataValid = (data: unknown): data is MembershipCustomData => {
  const parsedMembershipCustomData = MembershipCustomDataSchema.safeParse(data);
  const isValid = parsedMembershipCustomData.success;

  if (!isValid) {
    Sentry.captureMessage('membership.customData is not valid', {
      extra: { data: parsedMembershipCustomData.error.toString() },
    });
  }
  return isValid;
};
