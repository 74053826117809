// Function Overloading
/* eslint-disable no-redeclare */
import { RuntimeConfig } from './app-config.types';
import { useConfigurationContext } from './useConfigurationContext';
import { GetFieldType, getValue } from '../../utils/getValue';

export function useConfiguration(path?: undefined): { config: RuntimeConfig };
export function useConfiguration<TPath extends string>(path: TPath): GetFieldType<RuntimeConfig, TPath>;
export function useConfiguration(path?: string): any {
  const { config } = useConfigurationContext();

  // null is a valid configuration value
  if (path && getValue(config, path) === undefined) {
    throw new Error(`configuration does not have following path '${path}'`);
  }

  return path ? getValue(config, path) : { config };
}
