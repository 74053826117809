import { PointsStrategy, SpinWheelGamePrizeFragment } from '@ocx/graphql';
import { PointsChange } from '../../../../../components/PointsChange/PointsChange';
import { OfferCard } from '../../../../../components/OfferCard';
import { useConfiguration } from '../../../../../modules/configuration/useConfiguration';

export const SpinWheelGamePrize = ({ award }: { award?: SpinWheelGamePrizeFragment['award'] }) => {
  const pointsStrategy = useConfiguration('loyaltyProgram.pointsStrategy');

  if (award?.__typename === 'AwardPoints' && pointsStrategy !== PointsStrategy.NotUsed) {
    return (
      <PointsChange
        points={award.points}
        iconSize="lg"
        pointsStrategy={pointsStrategy}
        typographyProps={{ variant: 'h5' }}
      />
    );
  }
  if (award?.__typename === 'AwardVouchers') {
    const {
      title,
      shortDescription = null,
      surgeonGeneralsWarning = null,
      imageUrl: imageSrc = null,
    } = award.voucheredOffer.marketingContents[0] || {};

    return (
      <OfferCard
        variant="sheet"
        disabled={false}
        pointsDiff={null}
        progress={null}
        label={null}
        pointsStrategy={pointsStrategy}
        marketingContent={{
          title: title ?? '',
          imageSrc,
          shortDescription,
          surgeonGeneralsWarning,
        }}
      />
    );
  }
  return null;
};
