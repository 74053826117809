import { styled } from '@mui/material';

import { SizingMultiplier } from '../../theme/constants';

export const OfferDetailsMedia = styled('img', { shouldForwardProp: (prop) => prop !== 'variant' })<{
  variant?: 'default' | 'banner' | 'big-image';
}>(({ theme, variant }) => ({
  width: 200,
  height: 200,
  margin: theme.spacing(SizingMultiplier.xl, 'auto', 0),
  borderRadius: theme.spacing(SizingMultiplier.sm),
  objectFit: 'cover',
  overflow: 'hidden', // to handle unexpected "objectFit" result
  ...(variant === 'banner'
    ? {
        height: 128,
        width: '100%',
        margin: 0,
        borderRadius: 0,
      }
    : {}),
  ...(variant === 'big-image'
    ? {
        width: '100%',
        maxWidth: 552,
        height: 'auto',
        margin: '0 auto',
        borderRadius: 0,
      }
    : {}),
}));
