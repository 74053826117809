import React, { useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { lastEventId } from '@sentry/react';

import { RouteLoading } from './components/Layout/route-loading';
import { restoreDisplayCategoriesCache } from './modules/loyalty/restoreDisplayCategoriesCache';
import { logInDev } from './lib/logger/logger';
import { clearCache, syncCacheVersion } from './modules/cache-layer/utils';
import { useConfigurationContext } from './modules/configuration/useConfigurationContext';
import { GooglePayUniversal } from './lib/payments/GooglePay/GooglePayUniversal';
import { ErrorBoundaryFallback } from './modules/sentry/ErrorBoundaryFallback';
import { useInitSession } from './modules/auth/useInitSession';

export interface IAppLoadingGuardProps {
  children: React.ReactElement;
}

export const AppLoadingGuard = ({ children }: IAppLoadingGuardProps) => {
  const [loaded, setLoaded] = useState(false);
  const client = useApolloClient();
  const initSession = useInitSession();

  const configContext = useConfigurationContext();

  useEffect(() => {
    const restore = async () => {
      try {
        await initSession();
        // syncCacheVersion does not throw
        await syncCacheVersion();
      } catch (e) {
        logInDev(e);
        clearCache().catch(logInDev);
      }

      try {
        // For whatever reason display categories require token.
        await restoreDisplayCategoriesCache(client);
      } catch (error) {
        clearCache().catch(logInDev);
        throw error;
      } finally {
        setLoaded(true);
      }
    };
    restore().catch(logInDev);

    // didMount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!configContext.loaded || !configContext.config?.googlePay?.environment) {
      return;
    }
    GooglePayUniversal.init(configContext.config.googlePay.environment).catch(logInDev);
  }, [configContext.config?.googlePay?.environment, configContext.loaded]);

  if (!loaded || !configContext.loaded) {
    return <RouteLoading />;
  }

  if (configContext.error) {
    return <ErrorBoundaryFallback eventId={lastEventId()} reason="app-config-not-loaded" />;
  }

  return children;
};
