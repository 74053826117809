import React, { useMemo } from 'react';
import { Box, Stack } from '@mui/material';

import { MarketingContent, OfferActionHandler, OfferPresentationType } from '../../modules/offers/offer.types';
import { messages } from '../../modules/offers/offer.messages';
import { EntriesTicket } from './components/EntriesTicket/EntriesTicket';
import { OfferDetails, OfferDetailsAction, OfferDetailsProps } from '../../components/OfferDetails/OfferDetails';
import { ModalPaper } from '../../components/modals';

export interface IProductSweepsProps {
  memberEntries: number;
  offerId: string;
  marketingContent: MarketingContent;
  presentationType: OfferPresentationType;
  offerAction: OfferActionHandler | null;
  onClose(): void;
}
export const ProductSweeps: React.FC<IProductSweepsProps> = ({
  memberEntries,
  offerId,
  marketingContent,
  presentationType,
  offerAction,
  onClose,
}) => {
  const offerDetails: OfferDetailsProps = useMemo(() => {
    const loyaltyAction: OfferDetailsAction | null =
      offerAction && offerAction.title
        ? {
            onClick: () => {
              offerAction.handler(offerId);
              onClose();
            },
            title: messages[offerAction.title],
          }
        : null;
    return {
      marketingContent,
      variant: presentationType === OfferPresentationType.Banner ? 'banner' : 'default',
      isLoading: false,
      isReturnable: false,
      loyaltyAction,
    };
  }, [marketingContent, offerAction, offerId, onClose, presentationType]);

  return (
    <Stack direction="column" minHeight="100%" overflow="auto">
      <Stack position="relative" minHeight={200} justifyContent="center" m="auto">
        <EntriesTicket entries={memberEntries} />
      </Stack>
      <Box zIndex={1}>
        <ModalPaper>
          <OfferDetails {...offerDetails} />
        </ModalPaper>
      </Box>
    </Stack>
  );
};
