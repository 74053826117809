import { useCallback, useMemo } from 'react';

import { getIsReadyToPayRequestData, getPaymentRequestData } from '../constants';
import { GooglePayEnvironment, IIsReadyToPayRequestData, IPaymentRequestData } from '../types';
import { IPaymentItem } from '../../payments.types';
import { usePaymentProvider } from '../../../../modules/transaction/hooks/usePaymentProvider';
import { useConfiguration } from '../../../../modules/configuration/useConfiguration';

export interface IUseGetGooglePayRequestReturns {
  getGooglePayPaymentRequestData(params: { paymentItems: IPaymentItem[] }): IPaymentRequestData | null;
  getGooglePayIsReadyToPayRequestData(): IIsReadyToPayRequestData | null;
}

export const useGetGooglePayRequest = (): IUseGetGooglePayRequestReturns => {
  const paymentProvider = usePaymentProvider();
  const { config } = useConfiguration();

  const getGooglePayPaymentRequestData: IUseGetGooglePayRequestReturns['getGooglePayPaymentRequestData'] = useCallback(
    (params: { paymentItems: IPaymentItem[] }) => {
      if (!paymentProvider) {
        return null;
      }
      if (!config.googlePay) {
        return null;
      }
      return getPaymentRequestData({
        environment: GooglePayEnvironment[config.googlePay.environment],
        merchantId: config.googlePay.merchantId,
        merchantName: config.googlePay.merchantName || undefined,
        allowedCardNetworks: config.googlePay.allowedCardNetworks,
        paymentItems: params.paymentItems,
        gatewayParameters: paymentProvider.getGooglePayGatewayParameters(),
      });
    },
    [config.googlePay, paymentProvider],
  );

  const getGooglePayIsReadyToPayRequestData: IUseGetGooglePayRequestReturns['getGooglePayIsReadyToPayRequestData'] =
    useCallback(
      () =>
        config.googlePay
          ? getIsReadyToPayRequestData({
              environment: GooglePayEnvironment[config.googlePay.environment],
              allowedCardNetworks: config.googlePay.allowedCardNetworks,
            })
          : null,
      [config.googlePay],
    );

  return useMemo(
    () => ({
      getGooglePayPaymentRequestData,
      getGooglePayIsReadyToPayRequestData,
    }),
    [getGooglePayIsReadyToPayRequestData, getGooglePayPaymentRequestData],
  );
};
