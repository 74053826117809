export type LinearScaleOptions = {
  domain: number[];
  range: number[];
};

export const linearScale = ({ domain, range }: LinearScaleOptions) => {
  if (domain.length !== range.length) {
    throw new Error('Domain and Range must be of equal length');
  }

  return (value: number) => {
    if (value <= domain[0]) {
      return range[0];
    }

    if (value >= domain[domain.length - 1]) {
      return range[range.length - 1];
    }

    const index = domain.findIndex((domainValue) => value <= domainValue);

    const domainMin = domain[index - 1];
    const domainMax = domain[index];
    const domainWidth = domainMax - domainMin;
    const valueWidth = value - domainMin;

    const rangeMin = range[index - 1];
    const rangeMax = range[index];
    const rangeWidth = rangeMax - rangeMin;

    return (valueWidth / domainWidth) * rangeWidth + rangeMin;
  };
};
