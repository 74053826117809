import React, { ReactNode } from 'react';

import classes from './page.module.css';

type PageProps = {
  children: ReactNode;
};

export const Page = ({ children }: PageProps) => {
  return <div className={classes.page}>{children}</div>;
};
