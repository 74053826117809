import React from 'react';
import { Box, Stack } from '@mui/material';

import { PointsStrategy } from '@ocx/graphql';
import { List, ListHeader, PageContent } from '@ocx/ui';
import { DollarIcon } from '../../components/Icons/DollarIcon';
import { StarInCircleIcon } from '../../components/Icons/StarInCircleIcon';
import { ChampionCupIcon } from '../../components/Icons/ChampionCumIcon';
import { SizingMultiplier } from '../../theme/constants';
import { TransactionHistoryItem } from './components/TransactionHistoryItem';
import {
  TransactionHistoryItemType,
  TransactionHistorySegment,
} from '../../modules/transaction-history/transaction-history.types';
import { ScreenLoaderBackdrop } from '../../components/ScreenLoader/ScreenLoader';
import { PullToRefresh } from '../../components/PullToRefresh/PullToRefresh';

// TODO: Probably should be dynamic for different retailers?
export const transactionIconMap: { [key in TransactionHistoryItemType]: React.ReactNode } = {
  [TransactionHistoryItemType.Transaction]: <DollarIcon />,
  [TransactionHistoryItemType.PointsChange]: <StarInCircleIcon />,
  [TransactionHistoryItemType.LoyaltyEvent]: <ChampionCupIcon />,
};

export interface ITransactionsListProps {
  segments: TransactionHistorySegment[];
  navigateToTransaction(transactionId: string): void;
  pointsStrategy: PointsStrategy;
  loading: boolean;
  onRefresh: any;
  onScroll: any;
}

export const TransactionsList: React.FC<ITransactionsListProps> = (props) => {
  const { loading, segments, onRefresh, onScroll, navigateToTransaction, pointsStrategy } = props;
  const contentRef = React.useRef<HTMLDivElement>(null);

  return (
    <PageContent bgcolor="background.paper" onScroll={onScroll} ref={contentRef}>
      <PullToRefresh onRefresh={onRefresh} scrollRef={contentRef}>
        <Box px={SizingMultiplier.sm} py={SizingMultiplier.lg}>
          {segments.map(([date, transactions]) => (
            <Stack key={date} spacing={SizingMultiplier.sm} mb={SizingMultiplier.xl}>
              <ListHeader>{date}</ListHeader>
              <List>
                {transactions.map((transaction) => (
                  <TransactionHistoryItem
                    key={transaction.id}
                    date={transaction.dateTitle}
                    title={transaction.title}
                    points={transaction.points}
                    icon={transactionIconMap[transaction.type]}
                    showArrow={transaction.type === TransactionHistoryItemType.Transaction}
                    onClick={
                      transaction.type === TransactionHistoryItemType.Transaction
                        ? () => navigateToTransaction(transaction.id)
                        : undefined
                    }
                    pointsStrategy={pointsStrategy}
                  />
                ))}
              </List>
            </Stack>
          ))}
          <ScreenLoaderBackdrop open={loading} />
        </Box>
      </PullToRefresh>
    </PageContent>
  );
};
