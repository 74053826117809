import { ButtonBaseProps, CircularProgress, Icon, styled } from '@mui/material';
import React from 'react';

import { PlusButton } from './PlusButton';
import { DetailsArrow } from './DetailsArrow';
import { DynamicCardAccessoryType } from '../dynamic-card.types';
import { SELECTORS } from '../../../modules/tutorial/settings';
import { SizingMultiplier } from '../../../theme/constants';
import { CheckmarkIcon } from '../../Icons/CheckmarkIcon';

export interface IDynamicCardAccessoryProps {
  type: DynamicCardAccessoryType;
  onClick?(): void;
  disabled?: boolean;
}

export const DynamicCardAction = styled('div')<{ disabled?: boolean }>(({ theme, disabled }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginLeft: theme.spacing(SizingMultiplier.sm),
  opacity: disabled ? 0.5 : 1,
}));

export const DynamicCardAccessory: React.FC<IDynamicCardAccessoryProps> = ({ type, onClick, disabled }) => {
  const onPlusButtonClick: ButtonBaseProps['onClick'] = (e) => {
    e.stopPropagation();
    onClick?.();
  };

  switch (type) {
    case 'none':
      return null;

    case 'disclosureIndicator':
      return <DetailsArrow />;

    case 'plusButton':
      return (
        <DynamicCardAction disabled={disabled}>
          <PlusButton name={SELECTORS.OFFER_PLUS_BUTTON_NAME} onClick={onClick} />
        </DynamicCardAction>
      );

    case 'plusButtonAndDisclosureIndicator':
      return (
        <DynamicCardAction disabled={disabled}>
          <PlusButton name={SELECTORS.OFFER_PLUS_BUTTON_NAME} onClick={onPlusButtonClick} />
          <DetailsArrow />
        </DynamicCardAction>
      );

    case 'checkmark':
      return (
        <Icon color="primary">
          <CheckmarkIcon />
        </Icon>
      );

    case 'spinner': {
      const circularProgressSize = 8 * SizingMultiplier.md;
      return <CircularProgress color="primary" size={circularProgressSize} />;
    }

    default:
      return null;
  }
};
