import { ReactNode } from 'react';
import { useIntl } from 'react-intl';

import { DrinkIcon } from './components/drink.icon';
import { FoodIcon } from './components/food.icon';
import { PrizeIcon } from './components/prize.icon';
import { PumpIcon } from './components/pump.icon';
import { messages } from './messages';

const ICONS = {
  drink: DrinkIcon,
  prize: PrizeIcon,
  pump: PumpIcon,
  food: FoodIcon,
};

export type SpinWheelGameSectorType = 'NO_AWARD' | 'POINTS' | 'TRY_AGAIN' | 'VOUCHERS';

export type SpinWheelGameSectorIcon = keyof typeof ICONS;

export const isSpinWheelGameSectorIcon = (icon?: string | null): icon is SpinWheelGameSectorIcon =>
  !!icon && icon in ICONS;

export type SpinWheelGameSectorRenderParams = {
  index: number;
  type: SpinWheelGameSectorType;
  points?: number;
  placeholder?: ReactNode;
};

type SpinWheelGameSectorProps = {
  index: number;
  type: SpinWheelGameSectorType;
  icon?: SpinWheelGameSectorIcon;
  points?: number;
  onRender?: (params: SpinWheelGameSectorRenderParams) => ReactNode;
};

export const SpinWheelGameSector = ({ index, type, icon, points, onRender }: SpinWheelGameSectorProps) => {
  const intl = useIntl();
  if (type === 'TRY_AGAIN') {
    return intl.formatMessage(messages['spinWheelGame:wheel-spin']);
  }
  if (type === 'NO_AWARD') {
    return intl.formatMessage(messages['spinWheelGame:wheel-no-lock']);
  }
  if (type === 'POINTS') {
    return (onRender ? onRender({ index, type, points: points || 0 }) : null) || (points ? `+${points}` : null);
  }

  const IconComponent = ICONS[icon || 'prize'];
  const IconPlaceholderComponent = <IconComponent fontSize="large" sx={{ transform: 'rotate(90deg)' }} />;

  if (onRender) {
    return onRender({ index, type, placeholder: IconPlaceholderComponent });
  }

  return IconPlaceholderComponent;
};
