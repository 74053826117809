import React from 'react';
import { Stack, StackProps } from '@mui/material';

import { SizingMultiplier } from '../../theme/constants';

// TODO: this should be part of Paper style and not as a bordered section
export const BorderedSection: React.FC<StackProps> = (props) => (
  <Stack
    spacing={1}
    direction="row"
    alignItems="center"
    py={2}
    px={1.5}
    border={(theme) => `1px solid ${theme.palette.divider}`}
    borderRadius={(theme) => theme.spacing(SizingMultiplier.sm)}
    {...props}
  />
);
