import { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { useInView } from 'react-intersection-observer';

import { TutorialStorage, STEPS } from '../../../modules/tutorial/utils/TutorialStorage';
import { SELECTORS, HINT_TIMEOUT, HINT_SEARCH_INTERVAL } from '../../../modules/tutorial/settings';

export const useActivateOfferButtonHint = () => {
  const [inViewRef, inView, inViewElement] = useInView({
    fallbackInView: true,
  });
  const intervalId = useRef<any | null>(null);
  const timeoutId = useRef<any | null>(null);
  const buttonsRef = useRef<NodeList | null>(null);
  const [coords, setCoords] = useState<{ left: number; top: number; height: number } | null>(null);

  const handleComplete = useCallback(() => {
    // Hide hint
    setCoords(null);
    // Stop watching
    inViewRef(null);

    buttonsRef.current?.forEach((item) => item.removeEventListener('click', handleComplete));

    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }
  }, [inViewRef]);

  // Is element in view
  const isOpen = useMemo(() => !(!inView && inViewElement?.target), [inView, inViewElement?.target]);

  const search = useCallback(() => {
    intervalId.current = setInterval(() => {
      const content = document.getElementById(SELECTORS.HOME_CONTAINER_ID);
      const buttons = document.querySelectorAll(`button[name="${SELECTORS.OFFER_PLUS_BUTTON_NAME}"]`);
      const button = buttons[0];

      // Continue searching
      if (!button || !content) {
        setCoords(null);
        return;
      }

      // Stop searching
      if (intervalId.current) {
        clearInterval(intervalId.current);
        intervalId.current = null;
      }

      // Handle storage and add event
      TutorialStorage.setStep(STEPS.OFFER_PLUS_HINT, true);
      buttons.forEach((item) => item.addEventListener('click', handleComplete));
      buttonsRef.current = buttons;

      // Displaying hint
      const rect = button.getBoundingClientRect();
      const contentRect = content.getBoundingClientRect();
      setCoords({
        left: (rect.left + rect.right) / 2, // middle point of element
        top: rect.top - contentRect.top, // top point of element
        height: rect.height,
      });
      inViewRef(button);

      // Remove hint
      timeoutId.current = setTimeout(handleComplete, HINT_TIMEOUT);
    }, HINT_SEARCH_INTERVAL);
  }, [handleComplete, inViewRef]);

  useEffect(() => {
    if (!TutorialStorage.getStep(STEPS.OFFER_PLUS_HINT)) {
      search();
    }

    return () => {
      // Prevent "Can't perform a React state update on an unmounted component" error
      inViewRef(null);
      if (intervalId.current) {
        clearInterval(intervalId.current);
      }
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
    };
  }, [inViewRef, search]);

  return { coords, isOpen };
};
