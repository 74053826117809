import React from 'react';
import { Button as MUIButton, ButtonProps as MUIButtonProps } from '@mui/material';

// TODO: add margin/sx?
export interface ButtonProps {
  children: MUIButtonProps['children'];
  type?: 'submit' | 'reset' | 'button';
  variant?: MUIButtonProps['variant'];
  size?: MUIButtonProps['size'];
  fullWidth?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
  onClick?: MUIButtonProps['onClick'];
  href?: HTMLAnchorElement['href'];
  rel?: HTMLAnchorElement['rel'];
  target?: HTMLAnchorElement['target'];
}

export const Button: React.FC<ButtonProps> = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const { variant = 'contained', ...rest } = props;
  return <MUIButton variant={variant} {...rest} ref={ref} />;
});
