import { ButtonBase } from '@mui/material';
import { ReactNode } from 'react';

import { SizingMultiplier } from '../sizing-multiplier.constant';

type ListItemLinkProps = {
  href: string;
  target?: HTMLAnchorElement['target'];
  children?: ReactNode;
  onClick?: () => void;
};

export const ListItemLink = (props: ListItemLinkProps) => {
  const { children, href, target, onClick } = props;
  return (
    <ButtonBase
      onClick={onClick}
      href={href}
      component="a"
      rel="noreferrer"
      target={target}
      sx={{
        display: 'flex',
        alignItems: 'center',
        p: SizingMultiplier.md,
        minHeight: 56,
      }}>
      {children}
    </ButtonBase>
  );
};
