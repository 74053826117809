import { useGetTotalAvailableRollbackMoneyAmountQuery } from '@ocx/graphql';

type UseTotalAvailableRollbackMoneyAmountProps = {
  skip?: boolean;
};

export const useTotalAvailableRollbackMoneyAmount = (props: UseTotalAvailableRollbackMoneyAmountProps = {}) => {
  const { data, loading, refetch } = useGetTotalAvailableRollbackMoneyAmountQuery({
    skip: props.skip,
    fetchPolicy: 'cache-and-network',
  });
  const moneyAmount = data?.customer?.membership?.totalAvailableRollback?.moneyAmount ?? 0;

  return { moneyAmount, refetch, loading };
};
