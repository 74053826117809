import { Avatar, Stack } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import AgeRestricted from '@active-configuration/assets/age-restricted.svg';
import { Typography } from '@ocx/ui';

import { messages } from '../../profile-completed-popup.messages';
import { Button } from '../../../../Button';
import { SizingMultiplier } from '../../../../../theme/constants';
import { RoundedBorderStack } from '../../../../RoundedBorderStack/RoundedBorderStack';

type EIAVPromotionSectionProps = {
  onClick(): void;
};

export const EIAVPromotionSection: React.FC<EIAVPromotionSectionProps> = (props) => {
  const { onClick } = props;
  return (
    <RoundedBorderStack spacing={SizingMultiplier.md} padding={SizingMultiplier.md}>
      <Stack direction="row" alignItems="center" spacing={SizingMultiplier.md}>
        <Avatar src={AgeRestricted} />
        <Typography variant="subtitle1">
          <FormattedMessage {...messages['profile-completed-action-sheet:eaiv-promotion-message']} />
        </Typography>
      </Stack>
      <Button onClick={onClick} fullWidth>
        <FormattedMessage {...messages['profile-completed-action-sheet:eaiv-promotion-button-text']} />
      </Button>
    </RoundedBorderStack>
  );
};
