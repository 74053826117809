import React from 'react';
import { styled } from '@mui/material/styles';

const SvgStyled = styled('svg')(({ theme }) => ({
  fill: theme.palette.sweepsTicket.background,
  '& rect': {
    stroke: theme.palette.sweepsTicket.color,
  },
}));

export const Ticket = (props: React.SVGProps<SVGSVGElement>) => {
  const { className, ...rest } = props;

  return (
    <SvgStyled {...rest} width="240" height="137" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.77.201c-2.21 0-3.946 1.824-4.536 3.953C12.506 10.39 6.788 14.97 0 14.97v12a4.615 4.615 0 010 9.231v9.23a4.615 4.615 0 010 9.232v9.23a4.615 4.615 0 010 9.231v9.231a4.615 4.615 0 010 9.23v9.232a4.615 4.615 0 110 9.23v12c6.788 0 12.506 4.579 14.234 10.817.59 2.129 2.326 3.953 4.535 3.953h202.462c2.209 0 3.945-1.824 4.535-3.953 1.728-6.238 7.446-10.817 14.234-10.817v-12a4.615 4.615 0 110-9.23v-9.231a4.615 4.615 0 010-9.231v-9.23a4.615 4.615 0 010-9.231v-9.231a4.615 4.615 0 010-9.231v-9.23a4.615 4.615 0 010-9.232v-12c-6.788 0-12.506-4.579-14.234-10.816C225.176 2.025 223.44.2 221.231.2H18.769z"
        stroke="none"
      />
      <rect x="19.962" y="20.162" width="200.077" height="96.692" rx="14.5" strokeOpacity=".3" strokeWidth="3" />
    </SvgStyled>
  );
};
