export enum ActionType {
  RequestCompleteProfile = 'request_complete_profile',
  NavigateTo = 'navigate_to',
}

export interface ActionCompleteProfile {
  type: ActionType.RequestCompleteProfile;
  payload?: {
    // eslint-disable-next-line camelcase
    return_url?: string;
  };
}

export interface ActionNavigateTo {
  type: ActionType.NavigateTo;
  payload: {
    url: string;
    openURLInWebView?: boolean;
  };
}

export type DynamicCardAction = ActionCompleteProfile | ActionNavigateTo;
