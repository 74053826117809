import { useCallback, useEffect, useMemo, useState } from 'react';
import { TabContext } from '@mui/lab';
import { Paper } from '@mui/material';

import { Offer } from '../../../../modules/offers/offer.types';
import { useRudderStack } from '../../../../lib/rudderStack/useRudderStack';
import { OffersCarouselHeader } from './components';
import { useOffersCarouselCategories } from './use-offers-carousel-categories.hook';
import { OffersCarouselPanelController } from './offers-carousel-panel.controller';

export type OffersCarouselControllerProps = {
  offers: Offer[];
};

export const OffersCarouselController = ({ offers }: OffersCarouselControllerProps) => {
  const categories = useOffersCarouselCategories(offers);
  const [selectedCategoryId, setSelectedCategoryId] = useState<string | null>(null);
  const selectedCategory = useMemo(
    () => categories.find(({ id }) => id === selectedCategoryId) || null,
    [categories, selectedCategoryId],
  );

  const { triggerEvent } = useRudderStack();
  const handleCategoryChanged = useCallback(
    (nextCategoryId: string) => {
      const nextCategory = categories.find(({ id }) => id === nextCategoryId);
      if (!nextCategory || nextCategoryId === selectedCategoryId) {
        return;
      }

      triggerEvent('home_categories', { category: nextCategory.title });
      setSelectedCategoryId(nextCategoryId);
    },
    [categories, selectedCategoryId, triggerEvent],
  );

  useEffect(() => {
    if (selectedCategory || !categories[0]) {
      return;
    }
    handleCategoryChanged(categories[0].id);
  }, [selectedCategory, handleCategoryChanged, categories]);

  if (!categories.length || !selectedCategory || !selectedCategoryId) {
    return null;
  }

  return (
    <TabContext value={selectedCategoryId}>
      <Paper elevation={0} sx={{ overflow: 'hidden' }}>
        <OffersCarouselHeader categories={categories} onCategoryChange={handleCategoryChanged} />
        {categories.map(({ id, offers, banners }) => (
          <OffersCarouselPanelController id={id} key={id} offers={offers} banners={banners} />
        ))}
      </Paper>
    </TabContext>
  );
};
