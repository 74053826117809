import React from 'react';
import { Box, SvgIcon } from '@mui/material';
import { Typography } from '@ocx/ui';

import { SizingMultiplier } from '../../../theme/constants';

export interface IFormIconHelperTextProps {
  icon: React.ComponentType<{ className?: string }>;
  text: string;
}

export const FormIconHelperText: React.FC<IFormIconHelperTextProps> = ({ text, icon: FormIcon }) => (
  <Box display="flex" alignItems="center" marginTop={SizingMultiplier.xs}>
    <SvgIcon component={FormIcon} sx={{ flexShrink: 0, color: 'text.disabled', width: 16, height: 16 }} />
    <Typography color="textSecondary" variant="caption" marginLeft={SizingMultiplier.sm}>
      {text}
    </Typography>
  </Box>
);
