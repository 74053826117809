import React, { ForwardedRef, ReactNode } from 'react';
import { ButtonBase, styled } from '@mui/material';

interface IDynamicCardUnstyledProps {
  className?: string;
  loading?: boolean;
  onClick?(): void;
  children: ReactNode;
}
const DynamicCardUnstyled: React.FC<IDynamicCardUnstyledProps> = React.forwardRef<
  HTMLDivElement,
  IDynamicCardUnstyledProps
>((props, ref) => {
  const { children, onClick, loading, className } = props;
  if (!onClick) {
    return (
      <div ref={ref} className={className}>
        {children}
      </div>
    );
  }

  return (
    <ButtonBase ref={ref} component="div" className={className} disabled={loading} onClick={onClick}>
      {children}
    </ButtonBase>
  );
});

interface IDynamicCardProps {
  loading?: boolean;
  disabled?: boolean;
  children: ReactNode;
  ref: ForwardedRef<HTMLDivElement>;
}

export const DynamicCard = styled(DynamicCardUnstyled)<IDynamicCardProps>(
  // TODO: refactor to configure it using MUI theme and component variants
  ({ theme, loading, disabled }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    overflow: 'hidden',
    borderRadius: 16,
    opacity: disabled || loading ? 0.5 : 1,
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
  }),
);
