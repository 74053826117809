import get from 'lodash/get';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useField, useForm } from 'react-final-form';

import { IDateInputsRef, SimpleDatePicker } from './SimpleDatePicker';
import { getDaysInMonth } from '../../lib/date/date';

interface ISimpleDatePickerControllerProps {
  fieldNamePrefix?: string;
  disabled?: boolean;
  minYear?: number;
  maxYear?: number;
}

export const SimpleDatePickerController: React.FC<ISimpleDatePickerControllerProps> = React.memo(
  ({ fieldNamePrefix, disabled, minYear, maxYear }) => {
    const monthFieldName = useMemo(() => `${fieldNamePrefix}.month`, [fieldNamePrefix]);
    const dateFieldName = useMemo(() => `${fieldNamePrefix}.date`, [fieldNamePrefix]);
    const yearFieldName = useMemo(() => `${fieldNamePrefix}.year`, [fieldNamePrefix]);
    const [maxDate, setMaxDate] = useState(31);
    const dateInputsRef = useRef<IDateInputsRef>({ dateField: null, yearField: null });

    const {
      input: { value: year },
    } = useField(yearFieldName);
    const {
      input: { value: month },
    } = useField(monthFieldName);
    const {
      input: { value: date },
    } = useField(dateFieldName);
    const form = useForm();
    const formState = form.getState();

    useEffect(() => {
      if (month && date) {
        const selectedDate = new Date();
        selectedDate.setMonth(month - 1);
        if (year) {
          selectedDate.setFullYear(year);
        }

        const daysInMonth = getDaysInMonth(selectedDate);
        if (date > daysInMonth) {
          form.change(dateFieldName, daysInMonth);
        }
        setMaxDate(daysInMonth);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [year, month]);

    useEffect(() => {
      if (!formState.dirty || !month) {
        return;
      }
      const value = month.toString();
      const [firstCharacter] = value.split('');
      if (!get(formState.errors, monthFieldName) && (value.length === 2 || Number(firstCharacter) > 1)) {
        // TODO: form.focus(dateFieldName); doesn't work
        // https://github.com/final-form/react-final-form/issues/872
        dateInputsRef.current.dateField.focus();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [month]);

    useEffect(() => {
      if (!formState.dirty || !date) {
        return;
      }
      const value = date.toString();
      const [firstCharacter] = value.split('');

      if (!get(formState.errors, dateFieldName) && (value.length === 2 || Number(firstCharacter) > 3)) {
        dateInputsRef.current.yearField.focus();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [date]);

    return (
      <SimpleDatePicker
        minYear={minYear}
        maxYear={maxYear}
        maxDate={maxDate}
        ref={dateInputsRef}
        dateFieldName={dateFieldName}
        yearFieldName={yearFieldName}
        monthFieldName={monthFieldName}
        disabled={disabled}
      />
    );
  },
);

SimpleDatePickerController.defaultProps = {
  fieldNamePrefix: 'date',
};
