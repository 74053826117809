import { ApplePayLineItemType, ApplePayExternalCardNetwork } from './types';
import { PAYMENT_STATUS } from '../payments.types';

export const paymentStatusMap: { [key in PAYMENT_STATUS]: ApplePayLineItemType } = {
  [PAYMENT_STATUS.FINAL]: 'final',
  [PAYMENT_STATUS.PENDING]: 'pending',
};

export const paymentNetworksMap: { [key in ApplePayExternalCardNetwork]: string } = {
  [ApplePayExternalCardNetwork.Amex]: 'amex',
  [ApplePayExternalCardNetwork.CartesBancaires]: 'cartesBancaires',
  [ApplePayExternalCardNetwork.ChinaUnionPay]: 'chinaUnionPay',
  [ApplePayExternalCardNetwork.Discover]: 'discover',
  [ApplePayExternalCardNetwork.Eftpos]: 'eftpos',
  [ApplePayExternalCardNetwork.Electron]: 'electron',
  [ApplePayExternalCardNetwork.Elo]: 'elo',
  [ApplePayExternalCardNetwork.Interac]: 'interac',
  [ApplePayExternalCardNetwork.Jcb]: 'jcb',
  [ApplePayExternalCardNetwork.Mada]: 'mada',
  [ApplePayExternalCardNetwork.Maestro]: 'maestro',
  [ApplePayExternalCardNetwork.MasterCard]: 'masterCard',
  [ApplePayExternalCardNetwork.PrivateLabel]: 'privateLabel',
  [ApplePayExternalCardNetwork.Visa]: 'visa',
  [ApplePayExternalCardNetwork.VPay]: 'vPay',
};
