import { useCallback } from 'react';

import { useCreateApplePaySessionMutation } from '@ocx/graphql';
import { ApplePaySessionObject } from '../types';
import { useConfiguration } from '../../../../modules/configuration/useConfiguration';

export const useCreateApplePaySession = (): (() => Promise<ApplePaySessionObject | null>) => {
  const { config } = useConfiguration();
  const [createApplePaySessionMutation] = useCreateApplePaySessionMutation();

  return useCallback(async (): Promise<ApplePaySessionObject | null> => {
    if (!config.applePay) {
      return null;
    }
    const response = await createApplePaySessionMutation({
      variables: {
        input: {
          merchantIdentifier: config.applePay.merchantId,
          displayName: config.applePay.merchantName || '',
          initiative: 'web',
          initiativeContext: window.location.hostname,
        },
      },
    });
    return response.data?.session.data || null;
  }, [config.applePay, createApplePaySessionMutation]);
};
