import { useCallback } from 'react';
import { Preferences } from '@capacitor/preferences';

import {
  ACCESS_TOKEN_STORAGE_KEY,
  REFRESH_TOKEN_STORAGE_KEY,
  syncReactiveAuthVariables,
} from './authReactiveVariables';

export const useInitSession = () => {
  const migrateLocalStorageSessionData = useCallback(async () => {
    const accessToken = window.localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY);
    const refreshToken = window.localStorage.getItem(REFRESH_TOKEN_STORAGE_KEY);

    if (accessToken) {
      await Preferences.set({ key: ACCESS_TOKEN_STORAGE_KEY, value: accessToken });
      window.localStorage.removeItem(ACCESS_TOKEN_STORAGE_KEY);
    }
    if (refreshToken) {
      await Preferences.set({ key: REFRESH_TOKEN_STORAGE_KEY, value: refreshToken });
      window.localStorage.removeItem(REFRESH_TOKEN_STORAGE_KEY);
    }
  }, []);

  return useCallback(async () => {
    await migrateLocalStorageSessionData();
    await syncReactiveAuthVariables();
  }, [migrateLocalStorageSessionData]);
};
