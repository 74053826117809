import { v4 as uuid } from 'uuid';

const DEFAULT_STORAGE_TTL_MS = 30000;
export class InMemoryStorage {
  private static storage: Map<string, { value: unknown; timoutId: number; createdAt: number }> = new Map();
  static setValue(key: string, value: unknown, params: { ttlMs?: number } = {}) {
    const timoutId = this.scheduleErase(key, params.ttlMs);
    this.storage.set(key, { value, timoutId, createdAt: Date.now() });
    this.listeners.onSet.get(key)?.forEach((cb) => cb(value));
  }
  static getValue<ValueType>(key: string): ValueType | null {
    return (this.storage.get(key)?.value as ValueType) || null;
  }
  static eraseValue(key: string) {
    if (this.storage.has(key)) {
      clearTimeout(this.storage.get(key)?.timoutId);
      this.storage.delete(key);
    }
  }
  private static scheduleErase(key: string, ttlMs = DEFAULT_STORAGE_TTL_MS): number {
    if (this.storage.has(key)) {
      this.eraseValue(key);
    }
    return setTimeout(() => this.eraseValue(key), ttlMs) as unknown as number;
  }

  // region Value Change Listeners
  private static listeners: { onSet: Map<string, Map<string, (value: any) => void>> } = { onSet: new Map() };
  static addOnSetListener<ValueType>(key: string, cb: (value: ValueType) => void): string {
    if (!this.listeners.onSet.has(key)) {
      this.listeners.onSet.set(key, new Map());
    }
    const listenerId = uuid();
    this.listeners.onSet.get(key)?.set(listenerId, cb);
    return listenerId;
  }
  static removeOnSetListener(key: string, listenerId: string): void {
    if (!this.listeners.onSet.has(key)) {
      return;
    }
    this.listeners.onSet.get(key)?.delete(listenerId);
  }
  // endregion
}
