import { useCallback, useEffect, useRef } from 'react';

type IntervalCallback = (...args: any[]) => void;

export const useInterval = (callback: IntervalCallback, ms: number, skip: boolean = false) => {
  // typescript has issues and trying to use NodeJS.Timeout in web
  const intervalId = useRef<any>();
  const savedCallback = useRef<IntervalCallback>();

  const checkAndClearInterval = useCallback(() => {
    if (intervalId.current) {
      clearInterval(intervalId.current);
    }
  }, []);
  const tick = useCallback(() => {
    if (!savedCallback.current) {
      return;
    }
    savedCallback.current();
  }, []);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    checkAndClearInterval();

    if (!skip) {
      intervalId.current = setInterval(tick, ms);
    }

    return checkAndClearInterval;
  }, [checkAndClearInterval, ms, skip, tick]);
};
