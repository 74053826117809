import { useCallback, useMemo, useState } from 'react';

import { logInDev } from '../../../lib/logger/logger';

export type UseRefresh = (
  refreshFunctions: (() => Promise<unknown>)[],
) => [refresh: () => Promise<void>, loading: boolean];
export const useRefresh: UseRefresh = (refreshFunctions) => {
  const [loading, setLoading] = useState(false);
  const refresh = useCallback(async () => {
    try {
      setLoading(true);
      await Promise.all(refreshFunctions.map((refreshFunction) => refreshFunction()));
    } catch (e) {
      logInDev(e);
    } finally {
      setLoading(false);
    }
  }, [refreshFunctions]);
  return useMemo(() => [refresh, loading], [loading, refresh]);
};
