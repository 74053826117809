import { CategoryRestrictions } from '@ocx/graphql';
import { ProductCategory } from '../../modules/loyalty/loyalty.types';

// Helper function for calculating "pendingVerification" on FE side
// when fuelAndConvenienceProfile is not existing
// Should be removed when tbd-ticket-number resolved
export const getPendingVerification = (params: {
  // value is undefined when fuelAndConvenienceProfile is not existing
  pendingVerification: CategoryRestrictions | null | undefined;
  membershipProductInterests: ProductCategory[];
}): CategoryRestrictions | null => {
  const { pendingVerification: _pendingVerification, membershipProductInterests } = params;

  // If value is not undefined - it means we can use it and do not calculate on FE side
  if (_pendingVerification !== undefined) {
    return _pendingVerification;
  }

  let pendingVerification: CategoryRestrictions | null = null;

  membershipProductInterests.forEach((productCategory) => {
    if (productCategory.categoryRestriction === null) {
      return;
    }

    if (productCategory.categoryRestriction === CategoryRestrictions.Eaiv) {
      pendingVerification = CategoryRestrictions.Eaiv;
      return;
    }

    if (
      productCategory.categoryRestriction === CategoryRestrictions.Age &&
      pendingVerification !== CategoryRestrictions.Eaiv
    ) {
      pendingVerification = CategoryRestrictions.Age;
    }
  });

  return pendingVerification;
};

export const getIsVerified = (params: {
  // value is undefined when fuelAndConvenienceProfile is not existing
  membershipRestrictionVerification: CategoryRestrictions | null | undefined;
  pendingVerification: CategoryRestrictions | null;
}): boolean => {
  const { membershipRestrictionVerification, pendingVerification } = params;
  // If value is null or undefined (value is undefined when fuelAndConvenienceProfile is not existing)
  // it means no verification ever passed
  if (!membershipRestrictionVerification) {
    return false;
  }

  // If any verification was passed before and no required verification to pass
  if (!pendingVerification) {
    return true;
  }

  return false;
};
