import { OfferEngagementType, OfferVisibleStatus } from '@ocx/graphql';
import { Offer } from '../../modules/offers/offer.types';

export const isOfferVisible = (offer: Offer) => {
  const { engagementType, visibleStatus } = offer;

  if (
    // Only offers with visibleStatus === VISIBLE allowed
    visibleStatus === OfferVisibleStatus.Visible
  ) {
    return true;
  }

  if (
    // Vouchered games allowed to be shown when no vouchers left
    // required for animation finalising and purchasing additional gameplay
    engagementType === OfferEngagementType.GameVoucher &&
    visibleStatus === OfferVisibleStatus.NoVoucher
  ) {
    return true;
  }

  if (
    // Limited Game's offers require animation and should stay visible.
    engagementType === OfferEngagementType.Game &&
    [OfferVisibleStatus.MemberLimit, OfferVisibleStatus.GlobalLimit].includes(visibleStatus)
  ) {
    return true;
  }

  return false;
};
