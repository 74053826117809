import { App as CapApp } from '@capacitor/app';
import { useEffect } from 'react';

import { useDeepLinkNavigate } from './deep-linking.utils';
import { InternalUrl } from './deep-linking.types';

export const useAppUrlListener = () => {
  const navigate = useDeepLinkNavigate();
  useEffect(() => {
    const listener = CapApp.addListener('appUrlOpen', (data) => {
      // Link would not open an app, if it's not a internal URL
      navigate(data.url as InternalUrl);
    });
    return () => {
      listener.then((s) => s.remove());
    };
  }, [navigate]);
};
