import React from 'react';
import { Typography, TypographyProps } from '@ocx/ui';

import { OfferCardVariant } from '../offer-card.types';

export type OfferCardTitleProps = {
  children: React.ReactNode;
  variant: OfferCardVariant;
  textAlign?: TypographyProps['textAlign'];
};

export const OfferTitle: React.FC<OfferCardTitleProps> = ({ children, variant, textAlign }) => {
  return (
    <Typography variant={variant !== 'carousel' ? 'subtitle1' : 'subtitle2'} textAlign={textAlign}>
      {children}
    </Typography>
  );
};
