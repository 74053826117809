import { ReactNode } from 'react';
import { defineMessage } from 'react-intl';

import { PoweredByOpenCommerce, NavigationBar, Page, PageContent } from '@ocx/ui';
import { HtmlTitle } from '../../components/router/HtmlTitle';
import { VendorLogo } from '../../components/Layout/NavigationBar/VendorLogo/VendorLogo';

type SignupWithLinkLayoutProps = {
  children: ReactNode;
};

export const SignupWithLinkLayout = ({ children }: SignupWithLinkLayoutProps) => {
  return (
    <Page>
      <HtmlTitle
        title={defineMessage({
          id: 'signUpWithLink:htmlTitle',
          defaultMessage: 'Sign Up',
          description: 'HTML title tag for Sign Up with link page',
        })}
      />
      <NavigationBar center={<VendorLogo />} />
      <PageContent bgcolor="background.paper">
        {children}
        <PoweredByOpenCommerce />
      </PageContent>
    </Page>
  );
};
