import { defineMessages } from 'react-intl';

export const formLabelMessages = defineMessages({
  'form-label:first-name': {
    id: 'form-label:first-name',
    defaultMessage: 'First Name',
    description: 'First Name text field label',
  },
  'form-label:last-name': {
    id: 'form-label:last-name',
    defaultMessage: 'Last Name',
    description: 'Last Name text field label',
  },
  'form-label:email': {
    id: 'form-label:email',
    defaultMessage: 'Email',
    description: 'Email text field label',
  },
  'form-label:street-address-1': {
    id: 'form-label:street-address-1',
    defaultMessage: 'Street Address',
    description: 'Street Address 1 text field label',
  },
  'form-label:city': {
    id: 'form-label:city',
    defaultMessage: 'City',
    description: 'City text field label',
  },
  'form-label:state': {
    id: 'form-label:state',
    defaultMessage: 'State',
    description: 'State text field label',
  },
  'form-label:zip-code': {
    id: 'form-label:zip-code',
    defaultMessage: 'Zip Code',
    description: 'Zip Code text field label',
  },
  'form-label:phone-number': {
    id: 'form-label:phone-number',
    defaultMessage: 'Phone Number',
    description: 'Phone Number text field label',
  },
});
