import React from 'react';
import { styled, keyframes } from '@mui/material';
import clsx from 'clsx';

import {
  CENTER,
  RADIUS,
  OUTER_DIAMETER,
  TAIL_SIZE,
  TRIGGER_THRESHOLD,
  FINAL_ROTATION_OFFSET,
  PULLING_OPACITY,
  TAIL_CIRCUMFERENCE,
  INITIAL_TAIL_LENGTH,
  ACTIVE_TAIL_LENGTH,
  START_THRESHOLD,
} from './constants';
import { linearScale } from './linearScale';

export type Phase = 'idle' | 'pulling' | 'active';

export interface IIndicatorProps {
  phase: Phase;
  pullDistance: number;
}

const scale = {
  // Complete one rotation between starting to pull and reaching the trigger point, then slightly further until the
  // maximum extent
  groupRotation: linearScale({
    domain: [START_THRESHOLD, TRIGGER_THRESHOLD, 100],
    range: [0, 360 - FINAL_ROTATION_OFFSET, 360 + FINAL_ROTATION_OFFSET],
  }),

  // Arrow tail should extend between start and trigger distance
  tailLength: linearScale({
    domain: [START_THRESHOLD, TRIGGER_THRESHOLD],
    range: [INITIAL_TAIL_LENGTH, ACTIVE_TAIL_LENGTH],
  }),

  // Tail offset should move at same rate as length to give the appearance of moving in reverse
  tailOffset: linearScale({
    domain: [START_THRESHOLD, TRIGGER_THRESHOLD],
    range: [TAIL_CIRCUMFERENCE - INITIAL_TAIL_LENGTH, TAIL_CIRCUMFERENCE - ACTIVE_TAIL_LENGTH],
  }),
};

const groupRotateAnimation = keyframes({
  '0%': {
    transform: `rotate(${FINAL_ROTATION_OFFSET}deg)`,
  },
  '100%': {
    transform: `rotate(${360 + FINAL_ROTATION_OFFSET}deg)`,
  },
});

// These keyframes are directly from MuiCircularProgress
const circleStrokeAnimation = keyframes({
  '0%': {
    strokeDasharray: `1px, ${TAIL_CIRCUMFERENCE}px`,
    strokeDashoffset: 0,
  },
  '50%': {
    strokeDasharray: `${TAIL_CIRCUMFERENCE / 2}px, ${TAIL_CIRCUMFERENCE}`,
    strokeDashoffset: '-15px',
  },
  '100%': {
    strokeDasharray: `${TAIL_CIRCUMFERENCE}`,
    strokeDashoffset: `-${TAIL_CIRCUMFERENCE}px`,
  },
});

const Container = styled('div')({
  width: `${OUTER_DIAMETER}px`,
  height: `${OUTER_DIAMETER}px`,
});

const SvgGroup = styled('g', {
  shouldForwardProp: (propName) => !['pullDistance'].includes(propName as string),
})<{ pullDistance: number }>(({ pullDistance, theme }) => ({
  transformOrigin: `${CENTER}px ${CENTER}px`,
  // Apply animation effects by default, with a slight delay before starting to allow any current transitions to
  // complete
  animation: `${groupRotateAnimation} 1.4s ${theme.transitions.duration.standard}ms linear infinite`,
  // Allow smooth changes on rotation and opacity by default.
  transition: theme.transitions.create(['transform', 'opacity']),

  // region - Handle phase
  '&.idle': {
    opacity: 0,
    transform: `rotate(0)`,
  },
  '&.active': {
    // Smooth rotation to starting point of animation before it begins
    transform: `rotate(${360 + FINAL_ROTATION_OFFSET}deg)`,
  },
  '&.pulling': {
    opacity: 0,
    // Disable animation while pulling, let style prop take over
    animation: 'none',
    // Only allow transitions on opacity during user interactions
    transition: theme.transitions.create('opacity'),
    '&.started': {
      opacity: PULLING_OPACITY,
      transform: `rotate(${scale.groupRotation(pullDistance)}deg)`,
    },
    '&.triggered': {
      opacity: 1,
    },
  },
  // endregion
}));

const SvgCircle = styled('circle', {
  shouldForwardProp: (propName) => !['pullDistance'].includes(propName as string),
})<{
  pullDistance: number;
}>(({ pullDistance, theme }) => ({
  fill: 'none',
  stroke: theme.palette.primary.main,
  strokeWidth: TAIL_SIZE,
  animation: `${circleStrokeAnimation} 1.4s ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeInOut} infinite`,
  transition: theme.transitions.create(['stroke-dasharray', 'stroke-dashoffset']),

  // region - Handle phase
  '&.idle': {
    strokeDasharray: `${scale.tailLength(5)}px, ${TAIL_CIRCUMFERENCE / 2}px`,
    strokeDashoffset: `-${scale.tailOffset(5)}px`,
  },
  '&.active': {
    // Transition stroke toward first keyframe before beginning the active animation
    strokeDasharray: `1px, 200px`,
    strokeDashoffset: `-${TAIL_CIRCUMFERENCE}px`,
  },
  '&.pulling': {
    animation: 'none',
    transition: 'none',
    strokeDasharray: `${scale.tailLength(pullDistance)}px, ${TAIL_CIRCUMFERENCE}`,
    strokeDashoffset: `-${scale.tailOffset(pullDistance)}px`,
  },
  // endregion
}));

export const Indicator: React.FC<IIndicatorProps> = ({ phase, pullDistance }) => {
  return (
    <Container>
      <svg viewBox={`0 0 ${OUTER_DIAMETER} ${OUTER_DIAMETER}`}>
        <SvgGroup
          className={clsx(phase, {
            started: pullDistance >= START_THRESHOLD,
            triggered: pullDistance >= TRIGGER_THRESHOLD,
          })}
          pullDistance={pullDistance}>
          <SvgCircle className={phase} pullDistance={pullDistance} cx={CENTER} cy={CENTER} r={RADIUS} />
        </SvgGroup>
      </svg>
    </Container>
  );
};
