import React from 'react';

export const SurveysTabBarIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  const { color = 'currentColor', ...rest } = props;

  return (
    <svg {...rest} fill={color} width="20" height="24" viewBox="0 0 20 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 23.416L6.75 20.166H2.41667C1.82083 20.166 1.31076 19.9539 0.886458 19.5296C0.462153 19.1053 0.25 18.5952 0.25 17.9993V2.83268C0.25 2.23685 0.462153 1.72678 0.886458 1.30247C1.31076 0.878168 1.82083 0.666016 2.41667 0.666016H17.5833C18.1792 0.666016 18.6892 0.878168 19.1135 1.30247C19.5378 1.72678 19.75 2.23685 19.75 2.83268V17.9993C19.75 18.5952 19.5378 19.1053 19.1135 19.5296C18.6892 19.9539 18.1792 20.166 17.5833 20.166H13.25L10 23.416ZM2.41667 17.9993H7.61667L10 20.3827L12.3833 17.9993H17.5833V2.83268H2.41667V17.9993ZM9.94583 16.6994C10.325 16.6994 10.6455 16.5684 10.9073 16.3066C11.1691 16.0448 11.3 15.7243 11.3 15.3452C11.3 14.966 11.1691 14.6455 10.9073 14.3837C10.6455 14.1219 10.325 13.991 9.94583 13.991C9.56667 13.991 9.24618 14.1219 8.98438 14.3837C8.72257 14.6455 8.59167 14.966 8.59167 15.3452C8.59167 15.7243 8.72257 16.0448 8.98438 16.3066C9.24618 16.5684 9.56667 16.6994 9.94583 16.6994ZM10.975 12.5285C10.975 11.8785 11.0472 11.4 11.1917 11.0931C11.3361 10.7862 11.6431 10.398 12.1125 9.92852C12.7444 9.29657 13.1913 8.76845 13.4531 8.34414C13.7149 7.91984 13.8458 7.43685 13.8458 6.89518C13.8458 5.93824 13.5208 5.16636 12.8708 4.57956C12.2208 3.99275 11.3451 3.69935 10.2438 3.69935C9.2507 3.69935 8.4066 3.9431 7.71146 4.4306C7.01632 4.9181 6.53333 5.59518 6.2625 6.46185L8.05 7.16602C8.17639 6.67852 8.42917 6.28581 8.80833 5.98789C9.1875 5.68997 9.62986 5.54102 10.1354 5.54102C10.6229 5.54102 11.0292 5.67192 11.3542 5.93372C11.6792 6.19553 11.8417 6.5431 11.8417 6.97643C11.8417 7.28338 11.7424 7.60838 11.5438 7.95143C11.3451 8.29449 11.0111 8.67365 10.5417 9.08893C9.94583 9.61254 9.53507 10.1136 9.30938 10.5921C9.08368 11.0705 8.97083 11.716 8.97083 12.5285H10.975Z" />
    </svg>
  );
};
