import NiceModal, { useModal } from '@ebay/nice-modal-react';
import React from 'react';

import { CarWashSelectorPopupController } from './car-wash-selector-popup.controller';
import { CarWashSelectorPopupProps } from './car-wash-selector-popup.types';

const carWashSelectorPopupModalId = 'car-wash-selector-popup';

export const CarWashSelectorPopup = NiceModal.create<CarWashSelectorPopupProps>((props) => (
  <CarWashSelectorPopupController {...props} />
));

export const useCarWashSelectorPopup = () => {
  const modal = useModal(CarWashSelectorPopup, { id: carWashSelectorPopupModalId });
  return modal;
};
