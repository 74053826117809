import React from 'react';
import { ButtonBase } from '@mui/material';
import { Typography } from '@ocx/ui';

import { SizingMultiplier } from '../../../../../../theme/constants';
import { PumpIcon } from '../../../../../../components/Icons/PumpIcon';

export interface ISelectedPumpProps {
  pumpNumber: string | number;
  onClick(): void;
}

export const SelectedPump: React.FC<ISelectedPumpProps> = (props) => {
  const { pumpNumber, onClick } = props;

  return (
    <ButtonBase
      sx={{
        backgroundColor: 'primary.main',
        color: 'primary.contrastText',
        py: SizingMultiplier.md,
        px: SizingMultiplier.lg,
        minHeight: 64,
        borderRadius: 2,
      }}
      onClick={onClick}>
      <PumpIcon sx={{ mr: SizingMultiplier.sm }} />
      <Typography variant="h4" component="span">
        {pumpNumber}
      </Typography>
    </ButtonBase>
  );
};
