import {
  OfferActivationStatus,
  OfferByLoyaltyEventFragment,
  OfferPresentationPriority,
  OfferPresentationType,
  OfferVisibleStatus,
} from '@ocx/graphql';
import { Offer } from '../../offers/offer.types';
import { DynamicCardCustomDataSchema } from '../../offers/custom-data/custom-data.schema';

export const mapSmsOptInOfferFragmentToOffer = (fragment: OfferByLoyaltyEventFragment): Offer | null => {
  const marketingContent = fragment.marketingContents?.[0];
  if (!marketingContent?.title) {
    return null;
  }

  const dynamicCardCustomDataValidation = DynamicCardCustomDataSchema.safeParse(fragment.customData);
  const customData = dynamicCardCustomDataValidation.success ? dynamicCardCustomDataValidation.data : null;

  return {
    customData,
    id: fragment.id,
    visibleStatus: OfferVisibleStatus.Visible,
    marketingContent: {
      ...marketingContent,
      title: marketingContent.title,
      termsUrl: null,
      longDescription: null,
      surgeonGeneralsWarning: null,
      externalUrl: null,
    },
    expireAt: null,
    engagementType: null,
    earningMechanism: null,
    eligible: [true],
    voucherCount: 0,
    vouchers: [],
    availableAwardCount: 0,
    activationStatus: OfferActivationStatus.DoesNotRequireActivation,
    presentationPriority: OfferPresentationPriority.Default,
    presentationType: OfferPresentationType.Default,
    displayCategories: [],
  };
};
