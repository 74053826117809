import { useCallback } from 'react';
import { useApolloClient } from '@apollo/client';

import {
  GetAtTheStoreWithPumpsDocument,
  GetAtTheStoreWithPumpsQuery,
  GetAtTheStoreWithPumpsQueryVariables,
} from '@ocx/graphql';
import { IRefreshState } from '../../lib/geolocation/useGeolocation';
import { useConfiguration } from '../configuration/useConfiguration';

export interface IIsPayAtPumpAvailable {
  isAvailable: boolean;
  notClose: boolean;
  noPumpsAvailable: boolean;
  noMobilePaymentEnabled: boolean;
  noGeolocation: boolean;
  storeId: string | null;
}

export type IUseIsPayAtPumpAvailableReturns = () => Promise<IIsPayAtPumpAvailable>;

export interface IUseIsPayAtPumpAvailableParams {
  refreshGeolocation: IRefreshState;
}

export const useIsPayAtPumpAvailable = ({
  refreshGeolocation,
}: IUseIsPayAtPumpAvailableParams): IUseIsPayAtPumpAvailableReturns => {
  const atTheStoreDistance = useConfiguration('storeLocator.atTheStoreDistance');
  const client = useApolloClient();

  return useCallback(async () => {
    const geolocation = await refreshGeolocation();

    if (!geolocation.coordinates) {
      return {
        isAvailable: false,
        notClose: false,
        noPumpsAvailable: false,
        noMobilePaymentEnabled: false,
        noGeolocation: true,
        storeId: null,
      };
    }

    const response = await client.query<GetAtTheStoreWithPumpsQuery, GetAtTheStoreWithPumpsQueryVariables>({
      query: GetAtTheStoreWithPumpsDocument,
      variables: {
        geoLocation: {
          radiusInMeters: atTheStoreDistance,
          epicenter: {
            latitude: geolocation.coordinates.lat,
            longitude: geolocation.coordinates.lng,
          },
        },
      },
      fetchPolicy: 'network-only',
    });

    if (!response?.data?.store?.edges?.[0]?.node) {
      return {
        isAvailable: false,
        notClose: true,
        noPumpsAvailable: false,
        noMobilePaymentEnabled: false,
        noGeolocation: false,
        storeId: null,
      };
    }

    const { uuid, siteSystemConfiguration } = response.data.store.edges[0].node;
    const availablePump = siteSystemConfiguration?.[0]?.details?.fuelConfigDetails.find(
      (item) => !!item.outdoorPosition,
    );

    const notClose = !uuid;
    const noMobilePaymentEnabled = !siteSystemConfiguration?.[0]?.mobileEnabled;
    const noPumpsAvailable = !availablePump?.outdoorPosition;

    const isAvailable = !notClose && !noMobilePaymentEnabled && !noPumpsAvailable;

    return { isAvailable, notClose, noMobilePaymentEnabled, noPumpsAvailable, noGeolocation: false, storeId: uuid };
  }, [atTheStoreDistance, client, refreshGeolocation]);
};
