import { useCallback } from 'react';

import { AndroidSettings, IOSSettings, NativeSettings } from 'capacitor-native-settings';
import { logInDev } from '@ocx-app/lib/logger/logger';
import { getDevicePlatform } from '@ocx-app/lib/device/utils/info';

export type NativeSettingsSection = 'notification_id' | 'location' | 'application_details';

const sectionToAndroidSettings: Record<NativeSettingsSection, AndroidSettings> = {
  application_details: AndroidSettings.ApplicationDetails,
  location: AndroidSettings.Location,
  notification_id: AndroidSettings.AppNotification,
};

export const useOpenSystemSettings = () => {
  return useCallback((section?: NativeSettingsSection) => {
    if (getDevicePlatform() === 'web') {
      logInDev('Native settings are not supported in browser');
      return;
    }
    NativeSettings.open({
      /**
       * Note that the only supported option by Apple is "App".
       * Using other options might break in future iOS versions
       * or have your app rejected from the App Store.
       */
      optionIOS: IOSSettings.App,
      optionAndroid: section ? sectionToAndroidSettings[section] : AndroidSettings.ApplicationDetails,
    }).catch((err) => {
      logInDev(`[useOpenSystemSettings]: ${err}`);
    });
  }, []);
};
