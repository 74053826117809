import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { AppRate } from '../../lib/app-rate/AppRate';
import { messages } from './app-rate.messages';
import { useRudderStack } from '../../lib/rudderStack/useRudderStack';
import { useContactSupportPage } from '../../pages/ContactSupport/use-contact-support';
import { buttonTextMessages } from '../localization/button-text.messages';
import { useAppRatePendingState } from './useAppRatePendingState';
import { useAppRateDialogController } from './useAppRateDialogController';
import { useConfirmationActionSheet } from '../../components/modals';
import { logInDev } from '../../lib/logger/logger';

export const useAppRateDialog = () => {
  const intl = useIntl();

  const { triggerEvent } = useRudderStack();
  const { open: openContactSupportPage } = useContactSupportPage();
  const [, setIsPending] = useAppRatePendingState();
  const { setLastAppRateDate } = useAppRateDialogController();
  const { getIsAppRateAvailable } = useAppRateDialogController();
  const { open: openConfirmationActionSheet } = useConfirmationActionSheet();

  const open = useCallback(async () => {
    await setIsPending(false);

    const isAppRateAvailable = await getIsAppRateAvailable();
    if (!isAppRateAvailable) {
      return;
    }

    openConfirmationActionSheet({
      title: intl.formatMessage(messages['app-rate:dialog-title']),
      description: intl.formatMessage(messages['app-rate:dialog-description']),
      confirmButtonText: intl.formatMessage(buttonTextMessages['button-text:yes']),
      cancelButtonText: intl.formatMessage(buttonTextMessages['button-text:no']),
      cancelOnClickOutsideDisabled: true,
      onConfirm: () => {
        setLastAppRateDate(new Date());
        AppRate.requestReview();
        triggerEvent('app_rate_positive');
      },
      onCancel: () => {
        setLastAppRateDate(new Date());
        openContactSupportPage();
        triggerEvent('app_rate_negative');
      },
    }).catch(logInDev);
  }, [
    getIsAppRateAvailable,
    intl,
    openConfirmationActionSheet,
    openContactSupportPage,
    setIsPending,
    setLastAppRateDate,
    triggerEvent,
  ]);

  return { open };
};
