import React, { ReactNode } from 'react';
import { darken, styled, keyframes } from '@mui/material';

import { Ticket } from './Ticket';

const ANIMATION_DELAY_MS = 300;

const moveVerticalAnimation = keyframes({
  '100%': {
    transform: 'translate(0,0) ',
  },
});

const leftTicketAnimation = keyframes({
  '100%': {
    transform: 'translate(-6%,0) rotate(-5deg)',
  },
});

const rightTicketAnimation = keyframes({
  '100%': {
    transform: 'translate(5%,0) rotate(6deg)',
  },
});

const Container = styled('div')({
  '&.disabledAnimation': {
    '& *': {
      transform: 'translate(0,0) !important',
      animation: 'unset !important',
    },
  },
});

const ContentWrapper = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  color: theme.palette.sweepsTicket.color,

  transform: 'translate(0,100vh)',
  animation: `${moveVerticalAnimation} 300ms ease-out ${ANIMATION_DELAY_MS}ms forwards`,
}));

const BackgroundTicketWrapper = styled('div')<{
  absolute?: boolean;
  dark?: boolean;
  disabled?: boolean;
}>(({ theme, absolute, dark, disabled }) => ({
  position: absolute ? 'absolute' : 'relative',
  opacity: disabled ? 0.6 : 1,
  '& svg': {
    fill: dark ? darken(theme.palette.sweepsTicket.background, 0.2) : undefined,
  },

  '&.moveVertical': {
    transform: 'translate(0,100vh)',
    animation: `${moveVerticalAnimation} 300ms ease-out ${ANIMATION_DELAY_MS}ms forwards`,
  },
  '&.rotateLeft': {
    transform: 'translate(0,100vh) rotate(-45deg)',
    animation: `${leftTicketAnimation} 200ms ease-out ${ANIMATION_DELAY_MS}ms forwards`,
  },
  '&.rotateRight': {
    transform: 'translate(0,100vh) rotate(-20deg)',
    animation: `${rightTicketAnimation} 250ms ease-out ${ANIMATION_DELAY_MS}ms forwards`,
  },
}));

export interface IAnimatedTicketBackgroundProps {
  entries: number;
  children: ReactNode;
}

export const AnimatedTicketBackground: React.FC<IAnimatedTicketBackgroundProps> = (props) => {
  const { children, entries } = props;
  const isNoEntries = entries === 0;

  return (
    <Container className={isNoEntries ? 'disabledAnimation' : undefined}>
      {entries > 1 && (
        <>
          <BackgroundTicketWrapper dark absolute className="rotateRight">
            <Ticket />
          </BackgroundTicketWrapper>
          <BackgroundTicketWrapper dark absolute className="rotateLeft">
            <Ticket />
          </BackgroundTicketWrapper>
        </>
      )}
      <BackgroundTicketWrapper disabled={isNoEntries} className="moveVertical">
        <Ticket />
      </BackgroundTicketWrapper>
      <ContentWrapper>{children}</ContentWrapper>
    </Container>
  );
};
