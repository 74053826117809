import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import {
  GetOffersByLoyaltyEventQueryVariables,
  OfferByLoyaltyEventFragment,
  OfferVisibleStatus,
  PublicationStatusType,
  useGetOffersByLoyaltyEventLazyQuery,
} from '@ocx/graphql';
import { messages } from '../messages';
import { useInfoActionSheet } from '../../../components/modals/InfoActionSheet';

const LOYALTY_EVENT_OFFERS_VARIABLES: Omit<GetOffersByLoyaltyEventQueryVariables, 'loyaltyEventEquals'> = {
  publicationStatusEquals: PublicationStatusType.Published,
  visibleStatusEquals: OfferVisibleStatus.Visible,
} as const;

export const useGetLoyaltyEventOffer = () => {
  const [getOfferByLoyaltyId, { loading }] = useGetOffersByLoyaltyEventLazyQuery();
  const getOfferByLoyaltyEvent = useCallback(
    async (event: string): Promise<OfferByLoyaltyEventFragment | null> => {
      const { data } = await getOfferByLoyaltyId({
        variables: { loyaltyEventEquals: event, ...LOYALTY_EVENT_OFFERS_VARIABLES },
      });
      return data?.currentCustomer?.membership?.offers?.edges[0]?.node || null;
    },
    [getOfferByLoyaltyId],
  );
  return useMemo(() => [getOfferByLoyaltyEvent, { loading }] as const, [getOfferByLoyaltyEvent, loading]);
};

export const useShowLoyaltyEventAward = () => {
  const intl = useIntl();
  const { open } = useInfoActionSheet();
  return useCallback(
    (offer: OfferByLoyaltyEventFragment | null): void => {
      if (!offer) {
        open({
          title: intl.formatMessage(messages.errorTitle),
          description: intl.formatMessage(messages.errorDescription),
        });
        return;
      }

      open({
        title: intl.formatMessage(messages.successTitle),
        description: intl.formatMessage(messages.successDescription),
        variant: 'congrats',
      });
    },
    [intl, open],
  );
};
