import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useCallback, useMemo } from 'react';

import { ExternalBannerDetailsPopupProps } from './external-banner-details-popup.types';
import { useRudderStack } from '../../../lib/rudderStack/useRudderStack';
import { ExternalBannerDetailsPopupController } from './external-banner-details-popup.controller';

const ExternalBannerDetailsPopupModal = NiceModal.create(ExternalBannerDetailsPopupController);

export const useExternalBannerDetailsPopup = () => {
  const { show } = useModal(ExternalBannerDetailsPopupModal);
  const { triggerEvent } = useRudderStack();

  const open = useCallback(
    (props: ExternalBannerDetailsPopupProps) => {
      triggerEvent('external_offer_details_modal_opened', { uuid: props.banner.trackingId });
      show(props);
    },
    [show, triggerEvent],
  );
  return useMemo(() => ({ open }), [open]);
};
