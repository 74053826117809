import React from 'react';
import { Box } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@ocx/ui';

import { messages } from '../../messages';
import { AnimatedTicketBackground } from './AnimatedTicketBackground';
import { SizingMultiplier } from '../../../../theme/constants';

export interface IEntriesTicketProps {
  entries: number;
}

export const EntriesTicket: React.FC<IEntriesTicketProps> = (props) => {
  const { entries } = props;

  return (
    <AnimatedTicketBackground entries={entries}>
      <Box mb={SizingMultiplier.sm}>
        <Typography variant="subtitle2" gutterBottom color="inherit" align="center">
          <FormattedMessage {...messages.ticketTitle} />
        </Typography>
        <Typography variant="h4" color="inherit" align="center">
          <FormattedMessage {...messages.entriesAmount} values={{ entries }} />
        </Typography>
      </Box>
    </AnimatedTicketBackground>
  );
};
