import React from 'react';
import clsx from 'clsx';
import { styled } from '@mui/material';

import classes from './ApplePayButton.module.css';

export type ApplePayButtonVariant = 'black' | 'white' | 'white-outline';

export interface IApplePayVariantProps {
  variant?: ApplePayButtonVariant;
}

export interface IApplePayButtonProps extends IApplePayVariantProps {
  onClick?(): void;
}

const StyledApplePayButton = styled('div', {
  shouldForwardProp: (prop) => prop !== 'variant',
})<IApplePayVariantProps>(({ variant }) => ({
  '-apple-pay-button-style': variant,
}));

export const ApplePayButton: React.FC<IApplePayButtonProps> = (props) => {
  const { variant = 'black', onClick } = props;

  return (
    <StyledApplePayButton
      className={clsx(classes.root, classes[variant])}
      aria-label="apple-pay-button"
      onClick={onClick}
      onKeyDown={onClick}
      role="button"
      tabIndex={0}
      variant={variant}
    />
  );
};
