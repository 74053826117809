import { PaymentAccountType, PaymentInstrumentType } from '@ocx/graphql';

export enum PaymentInstrumentOrder {
  Priority,
  Default,
  Disabled,
}

export type PaymentInstrument = {
  uuid: string;
  accountType: PaymentAccountType;
  paymentType: PaymentInstrumentType | null;
  accountNumber: string | null;
  address: {
    zipCode: string;
  } | null;
  last4: string | null;
  displayName: string | null;
  alias: string | null;
  providerNickName: string | null;
  balance?: number | null;
  isDefault: boolean;
  canBeUsedToPay: boolean;
  canBeDeleted: boolean;
  order: PaymentInstrumentOrder;
};
