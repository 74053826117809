import { useCallback, useEffect } from 'react';

import { useSurveyPopupController } from '../../components/modals/SurveyPopup/hooks/use-survey.controller';
import { useConfiguration } from '../../modules/configuration/useConfiguration';
import { AwardVouchersSurvey, SanitizedSurvey, SurveyRFFFormProps } from '../../modules/survey/survey.types';
import { getSurveyAward, useGetControlForQuestion } from '../../modules/survey/survey.utils';
import { SurveyPopupLayout } from '../../components/modals/SurveyPopup/survey-popup.layout';
import { SurveyPopupForm } from '../../components/modals/SurveyPopup/survey-popup.form';
import { useSurveyAwardPopup } from '../../components/modals/SurveyAwardPopup/survey-award-popup.hook';
import { AwardType } from '../../modules/offers/award.types';

type SurveyDetailsProps = {
  onClose(): void;
  sanitizedSurvey: SanitizedSurvey;
  isOpen: boolean;
  onExit(): void;
};

export const SurveyDetails = ({ onClose, sanitizedSurvey, isOpen, onExit }: SurveyDetailsProps) => {
  const { open: openSurveyAwardPopup } = useSurveyAwardPopup();
  const handleOnAwardClick = useCallback(
    (offer: AwardVouchersSurvey['offer']) => {
      openSurveyAwardPopup({ offer, isEarned: false });
    },
    [openSurveyAwardPopup],
  );
  const award = getSurveyAward(sanitizedSurvey);
  const handleOnAllQuestionsAnswered = useCallback(() => {
    onClose();

    if (award?.type === AwardType.Vouchers) {
      openSurveyAwardPopup({ offer: award.offer, isEarned: true });
    }
  }, [award, onClose, openSurveyAwardPopup]);

  const { initialValues, questions, submit, handleAnimationComplete, createSurveyView } = useSurveyPopupController({
    onAllQuestionsAnswered: handleOnAllQuestionsAnswered,
    sanitizedSurvey,
    // We don't perform dismiss on the Survey Details page, we don't need to pass onDismissError either.
    onDismissError: () => {},
  });

  const pointsStrategy = useConfiguration('loyaltyProgram.pointsStrategy');

  const handleSubmit: SurveyRFFFormProps['onSubmit'] = useCallback(
    (values) => submit({ questionUUID: questions[0].uuid, values }),
    [questions, submit],
  );

  const control = useGetControlForQuestion({ question: questions[0] });
  const hasControl = !!control;

  useEffect(() => {
    if (!hasControl) {
      return;
    }

    createSurveyView();
  }, [createSurveyView, hasControl]);

  useEffect(() => {
    if (!hasControl) {
      // If there is no control, we should close the modal
      onClose();
    }
  }, [hasControl, onClose]);

  if (!control) {
    return null;
  }

  return (
    <SurveyPopupLayout isOpen={isOpen} onClose={onClose} onExited={onExit}>
      <SurveyPopupForm
        initialValues={initialValues}
        onSubmit={handleSubmit}
        award={award}
        handleClose={onClose}
        onAnimationComplete={handleAnimationComplete}
        control={control}
        question={questions[0]}
        pointsStrategy={pointsStrategy}
        onAwardClick={handleOnAwardClick}
      />
    </SurveyPopupLayout>
  );
};
