import React, { ReactNode } from 'react';
import { defineMessage } from 'react-intl';

import { PoweredByOpenCommerce, NavigationBar, Page, PageContent } from '@ocx/ui';
import { HtmlTitle } from '../../../components/router/HtmlTitle';
import { VendorLogo } from '../../../components/Layout/NavigationBar/VendorLogo/VendorLogo';

type FuelingStatusLayoutProps = {
  children: ReactNode;
};

export const FuelingStatusLayout: React.FC<FuelingStatusLayoutProps> = ({ children }) => {
  return (
    <Page>
      <HtmlTitle
        title={defineMessage({
          id: 'fuelingStatus:htmlTitle',
          defaultMessage: 'Fueling Status',
          description:
            'HTML title tag for Fueling Status page where user see current status of fueling: Authorizing pump/Begin fueling/Fueling started',
        })}
      />
      <NavigationBar center={<VendorLogo />} />
      <PageContent bgcolor="background.paper">
        {children}
        <PoweredByOpenCommerce />
      </PageContent>
    </Page>
  );
};
