import React, { useMemo } from 'react';

import { useCreateConfigurationContext } from '../configuration/useCreateConfigurationContext';
import { AppContext } from './AppContext';

export interface IAppContextProps {
  children: React.ReactNode;
}

export const AppContextProvider = ({ children }: IAppContextProps) => {
  const configuration = useCreateConfigurationContext();

  const value = useMemo(() => ({ configuration }), [configuration]);
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};
