import React from 'react';

import { KeyboardBackspaceIcon } from '@ocx/ui-icons';
import { IconButton } from '../../../IconButton';
import { CloseIcon } from '../../../Icons/CloseIcon';

export interface IBackButtonComponentProps {
  onClick: () => void;
  type?: 'back' | 'close';
}

export const BackButtonComponent = ({ onClick, type = 'back' }: IBackButtonComponentProps) => {
  return (
    <IconButton onClick={onClick} color="inherit" aria-label={type}>
      {type === 'close' ? <CloseIcon /> : <KeyboardBackspaceIcon />}
    </IconButton>
  );
};
