import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useMemo } from 'react';

import { CompleteEmailActionSheetController } from './complete-email-action-sheet.controller';

const CompleteEmailActionSheetModal = NiceModal.create(CompleteEmailActionSheetController);

export const useCompleteEmailActionSheet = () => {
  const { show } = useModal(CompleteEmailActionSheetModal);
  return useMemo(() => ({ open: show }), [show]);
};
