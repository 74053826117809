import React from 'react';
import { BottomNavigation as MuiBottomNavigation, BottomNavigationAction, Box } from '@mui/material';

export interface BottomNavigationTab {
  key: string;
  label: string;
  Icon: React.ComponentType;
  onClick?(): void;
  routerPath: string;
}

const renderTab = (tab: BottomNavigationTab) => (
  <BottomNavigationAction
    key={tab.key}
    value={tab.key}
    label={tab.label}
    onClick={tab.onClick}
    icon={<tab.Icon />}
    sx={{
      '& .MuiBottomNavigationAction-label': {
        whiteSpace: 'nowrap',
      },
    }}
  />
);

export interface BottomNavigationProps {
  tabs: BottomNavigationTab[];
  value: string;
}

export const BottomNavigation: React.FC<BottomNavigationProps> = ({ tabs, value }) => {
  return (
    <Box pb="var(--ion-safe-area-bottom)" bgcolor="tabBar.background">
      <MuiBottomNavigation value={value} showLabels>
        {tabs.map(renderTab)}
      </MuiBottomNavigation>
    </Box>
  );
};
