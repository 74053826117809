import React from 'react';
import { Box } from '@mui/material';

import { Bar } from './components/Bar';
import { Punches } from './components/Punches';
import { OfferMemberProgress } from '../../../../modules/offers/earning-mechanism.types';
import { OfferProgressVariant } from '../../offer-card.types';

export const OFFER_PROGRESS_TEST_ID = 'offer-progress';
export interface IOfferProgressProps {
  progress: OfferMemberProgress;
  variant: OfferProgressVariant;
}

export const OfferProgress = React.memo(({ progress: { required, achieved }, variant }: IOfferProgressProps) => {
  return (
    <Box display="flex" alignSelf="stretch" data-testid={OFFER_PROGRESS_TEST_ID}>
      {variant === 'bar' ? (
        <Bar achieved={achieved} required={required} />
      ) : (
        <Punches achieved={achieved} required={required} />
      )}
    </Box>
  );
});
