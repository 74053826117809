import React from 'react';
import { useReactiveVar } from '@apollo/client';

import { isLoggedInVar } from './authReactiveVariables';
import { RedirectToRequestLink } from '../../pages/Signup/RedirectToRequestLink';

export type AuthGuardProps = {
  children: React.ReactNode;
  Unauthorized?: React.ComponentType;
};

export const AuthGuard = ({ children, Unauthorized = RedirectToRequestLink }: AuthGuardProps) => {
  const isLoggedIn = useReactiveVar(isLoggedInVar);
  if (!isLoggedIn) {
    return <Unauthorized />;
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};
