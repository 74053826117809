import React, { useMemo } from 'react';
import { Navigate } from 'react-router-dom';

import { RuntimeConfig } from './app-config.types';
import { useConfiguration } from './useConfiguration';
import { AppRoutePath } from '../../pages/AppRoutePath';

export type ConfigGuardPros = {
  children: React.ReactNode;
  _isRedirectRequired: (config: RuntimeConfig) => boolean;
  redirectToPath?: string;
};

export const ConfigGuard = ({ children, _isRedirectRequired, redirectToPath = AppRoutePath.Home }: ConfigGuardPros) => {
  const { config } = useConfiguration();
  const isRedirectRequired = useMemo(() => _isRedirectRequired(config), [_isRedirectRequired, config]);

  if (isRedirectRequired) {
    return <Navigate to={redirectToPath} />;
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};
