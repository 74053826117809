import React from 'react';
import { Box } from '@mui/material';
import FuelingProcessImage from '@active-configuration/assets/fueling-process.svg';
import FuelingCompletedImage from '@active-configuration/assets/fueling-completed.svg';
import { Typography } from '@ocx/ui';

import { SizingMultiplier } from '../../../theme/constants';
import { PrimaryInternalFuelingStatus } from '../../../modules/transaction/types';
import { FuelingStatusAction } from './FuelingStatusAction';

export interface IFuelingStatusProps {
  status: PrimaryInternalFuelingStatus;
  label: string;
  secondaryLabel: string | null;
  navigateToReceipt(): void;
  navigateToHome(): void;
}

export const FuelingStatus: React.FC<IFuelingStatusProps> = (props) => {
  const { status, label, secondaryLabel, navigateToReceipt, navigateToHome } = props;

  return (
    <Box display="flex" flexGrow={1} flexDirection="column" alignItems="center" mt={18}>
      <Box
        component="img"
        width={100}
        height={100}
        mb={SizingMultiplier.lg}
        src={
          status === PrimaryInternalFuelingStatus.CompletedSuccessfully ? FuelingCompletedImage : FuelingProcessImage
        }
        alt="fueling-logo"
      />
      <Box mb={SizingMultiplier.md}>
        <Typography variant="h5" gutterBottom align="center">
          {label}
        </Typography>
        {secondaryLabel && (
          <Typography variant="body1" color="textSecondary" align="center" maxWidth={300}>
            {secondaryLabel}
          </Typography>
        )}
      </Box>
      <FuelingStatusAction status={status} navigateToReceipt={navigateToReceipt} navigateToHome={navigateToHome} />
    </Box>
  );
};
