import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { BackButtonComponent } from './BackButtonComponent';

export const BackButton = () => {
  const navigate = useNavigate();
  const navigateBack = useCallback(() => navigate(-1), [navigate]);
  return <BackButtonComponent onClick={navigateBack} />;
};
