import { useCallback } from 'react';

import { IPaymentItem } from '../../payments.types';
import { GOOGLE_PAY_ERROR_CODE } from '../types';
import { useGetGooglePayRequest } from './useGetGooglePayRequests';
import { usePaymentProvider } from '../../../../modules/transaction/hooks/usePaymentProvider';
import { GooglePayUniversal } from '../GooglePayUniversal';

export const useGooglePayRequestPay = () => {
  const paymentProvider = usePaymentProvider();
  const { getGooglePayPaymentRequestData } = useGetGooglePayRequest();

  return useCallback(
    async (paymentItems: IPaymentItem[]) => {
      if (paymentProvider === null) {
        throw new Error('Error creating GooglePay payment request. No Payment Provider specified');
      }
      const request = getGooglePayPaymentRequestData({ paymentItems });
      if (request === null) {
        throw new Error('Error creating GooglePay payment request data');
      }
      try {
        const paymentData = await GooglePayUniversal.requestPay(request);
        if (paymentData === null) {
          throw new Error('Error creating GooglePay payment request');
        }

        const {
          paymentMethodData: { tokenizationData },
        } = paymentData;
        const nonce = paymentProvider.getGooglePayTokenNonce(paymentData);

        return {
          token: tokenizationData.token,
          nonce,
          canceled: false,
        };
      } catch (e: any) {
        if (e.code === GOOGLE_PAY_ERROR_CODE.CANCELED) {
          return {
            token: '',
            nonce: null,
            canceled: true,
          };
        }
        throw e;
      }
    },
    [getGooglePayPaymentRequestData, paymentProvider],
  );
};
