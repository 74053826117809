import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  'profile-completed-action-sheet:eaiv-promotion-button-text': {
    id: 'profile-completed-action-sheet:eaiv-promotion-button-text',
    defaultMessage: 'yes, age verify now',
    description: 'Text for "OK" button',
  },
  'profile-completed-action-sheet:complete-title': {
    id: 'profile-completed-action-sheet:complete-title',
    defaultMessage: 'Profile complete!',
    description: 'Text for complete prompt title',
  },
  'profile-completed-action-sheet:eaiv-promotion-message': {
    id: 'profile-completed-action-sheet:eaiv-promotion-message',
    defaultMessage: 'Interested in seeing tobacco and/or alcohol offers?',
    description: 'Text for complete prompt message',
  },
});
