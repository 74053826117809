import { getOfferLabelType } from './getOfferLabelType';
import { Offer } from '../../modules/offers/offer.types';
import { AwardType } from '../../modules/offers/award.types';
import { OfferLabel } from './offer-card.types';

export const getOfferLabel = (offer: Offer): OfferLabel | null => {
  const type = getOfferLabelType(offer);

  if (!type) {
    return null;
  }

  if (type === 'Reward') {
    return {
      type: 'Reward',
      voucherCount: offer.voucherCount,
    };
  }

  if (type === 'Gameplay') {
    return {
      type: 'Gameplay',
    };
  }

  if (type === 'VoucheredGameplay') {
    return {
      type: 'VoucheredGameplay',
      voucherCount: offer.voucherCount,
    };
  }

  if (type === 'Entries') {
    const entries =
      offer.earningMechanism?.award?.type === AwardType.SweepstakeEntries
        ? offer.earningMechanism.award.memberEntries
        : 0;
    return {
      type: 'Entries',
      entries,
    };
  }

  return {
    type,
  };
};
