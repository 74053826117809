import { array, literal, object, string, tuple } from 'zod';

export const FuelGradesConfigurationSchema = object({
  /**
   * Order in mapping array determines sorting order
   */
  mapping: array(tuple([string(), array(string()).min(1)])),
  default: string(),
});

export const AppStoreURLsConfigurationSchema = object({
  ios: string().url().nullable(),
  android: string().url().nullable(),
});

export const LocalRetailerConfigurationSchema = object({
  version: literal(3),
  universalLinkOrigins: array(string().url()).min(1),
  fuelGrades: FuelGradesConfigurationSchema,
  appStoreURLs: AppStoreURLsConfigurationSchema,
}).strict();
