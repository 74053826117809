import Markdown, { MarkdownToJSX } from 'markdown-to-jsx';
import { Link } from '@mui/material';
import React from 'react';
import { Typography } from '@ocx/ui';

import { SizingMultiplier } from '../../theme/constants';

const markdownOptions: MarkdownToJSX.Options = {
  wrapper: 'article',
  overrides: {
    a: {
      component: Link,
      props: {
        color: 'textSecondary',
        underline: 'always',
        target: '_blank',
        rel: 'noreferrer',
      },
    },
  },
};
export const LegalMarkdown = (props: { content: string }) => {
  const { content } = props;
  return (
    <Typography
      component={Markdown}
      options={markdownOptions}
      variant="caption"
      color="text.secondary"
      bgcolor="background.box"
      borderRadius={SizingMultiplier.md}
      p={SizingMultiplier.md}>
      {content}
    </Typography>
  );
};
