import { useMemo, useCallback } from 'react';

import { ApplePayPaymentRequest, IGetApplePayCanMakeRequestData } from '../types';
import { IPaymentItem } from '../../payments.types';
import { getCanMakeRequestData, getPaymentRequestData } from '../utils';
import { useConfiguration } from '../../../../modules/configuration/useConfiguration';

export interface IUseGetApplePayRequestReturns {
  getApplePayPaymentRequestData(params: { paymentItems: IPaymentItem[] }): ApplePayPaymentRequest | null;
  getApplePayCanMakeRequestData(): IGetApplePayCanMakeRequestData | null;
}

// TODO: rename this function. In some cases current name might be confusing
export const useGetApplePayRequest = (): IUseGetApplePayRequestReturns => {
  const { config } = useConfiguration();

  const getApplePayPaymentRequestData: IUseGetApplePayRequestReturns['getApplePayPaymentRequestData'] = useCallback(
    (params: { paymentItems: IPaymentItem[] }) => {
      if (!config.applePay) {
        return null;
      }

      return getPaymentRequestData({
        supportedNetworks: config.applePay.supportedNetworks,
        merchantName: config.applePay.merchantName || '',
        paymentItems: params.paymentItems,
      });
    },
    [config.applePay],
  );

  const getApplePayCanMakeRequestData: IUseGetApplePayRequestReturns['getApplePayCanMakeRequestData'] =
    useCallback(() => {
      if (!config.applePay) {
        return null;
      }

      return getCanMakeRequestData({
        supportedNetworks: config.applePay.supportedNetworks,
        merchantId: config.applePay.merchantId,
      });
    }, [config.applePay]);

  return useMemo(
    () => ({
      getApplePayPaymentRequestData,
      getApplePayCanMakeRequestData,
    }),
    [getApplePayPaymentRequestData, getApplePayCanMakeRequestData],
  );
};
