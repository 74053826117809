import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  'complete-email-action-sheet:title': {
    id: 'complete-email-action-sheet:title',
    defaultMessage: 'Add your email to continue',
    description: 'Popup title when email is not completed',
  },
  'complete-email-action-sheet:description': {
    id: 'complete-email-action-sheet:description',
    defaultMessage: 'An email is required to use mobile payments.',
    description: 'Popup description when email is not completed',
  },
});
