import { IPaymentItem } from '../payments.types';

// ESLint works pretty weird with .d.ts
/* eslint-disable no-undef */

export type Environment = google.payments.api.Environment;
export type PaymentsClient = google.payments.api.PaymentsClient;
export type PaymentData = google.payments.api.PaymentData;
export type CardAuthMethod = google.payments.api.CardAuthMethod;
export type DisplayItem = google.payments.api.DisplayItem;
export type DisplayItemType = google.payments.api.DisplayItemType;
export type DisplayItemStatus = google.payments.api.DisplayItemStatus;
export type CardNetwork = google.payments.api.CardNetwork;
export type ButtonOptions = google.payments.api.ButtonOptions;
export type PaymentGatewayTokenizationParameters = google.payments.api.PaymentGatewayTokenizationParameters;

export enum GooglePayEnvironment {
  // https://developers.google.com/android/reference/com/google/android/gms/wallet/WalletConstants#public-static-final-int-environment_test
  TEST = 3,
  // https://developers.google.com/android/reference/com/google/android/gms/wallet/WalletConstants#ENVIRONMENT_TEST
  PRODUCTION = 1,
}

export interface IGetIsReadyToPayRequestDataParams {
  // Native SDK field
  environment: GooglePayEnvironment;
  allowedCardNetworks: CardNetwork[];
}

export interface IIsReadyToPayRequestData {
  // Native SDK field
  environment: GooglePayEnvironment;
  isReadyToPayRequest: google.payments.api.IsReadyToPayRequest;
}

export type IsReadyToPayResponse = { success: boolean };

export interface IGetPaymentRequestDataParams {
  environment: GooglePayEnvironment;
  allowedCardNetworks: google.payments.api.CardNetwork[];
  merchantId: string;
  merchantName?: string;
  paymentItems: IPaymentItem[];
  gatewayParameters: google.payments.api.PaymentGatewayTokenizationParameters;
}

export interface IPaymentRequestData {
  // Native SDK field
  environment: GooglePayEnvironment;
  paymentRequest: google.payments.api.PaymentDataRequest;
}

export interface IGooglePayPlugin {
  isReadyToPay(request: IIsReadyToPayRequestData): Promise<IsReadyToPayResponse>;
  requestPay(request: IPaymentRequestData): Promise<PaymentData | null>;
  // For WEB only
  getPaymentsClient(): Promise<PaymentsClient | null>;
  // For WEB only
  init(environment: Environment): Promise<void>;
}

export enum GOOGLE_PAY_WEB_ERROR_CODE {
  CANCELED = 'CANCELED',
}
export enum GOOGLE_PAY_ERROR_CODE {
  CANCELED = '100',
}
