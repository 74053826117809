import React from 'react';

import { ProfileIcon } from '@ocx/ui-icons';
import { useGetNavigationActions } from '../../../../hooks/useGetNavigationActions';
import { IconButton } from '../../../IconButton';

export const ProfileButton: React.FC = () => {
  const { pushToProfile } = useGetNavigationActions();

  return (
    <IconButton onClick={pushToProfile} color="inherit">
      <ProfileIcon />
    </IconButton>
  );
};
