import React from 'react';
import { Stack } from '@mui/material';
import { Form } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { TextField } from '../../TextField';
import { InfoIcon } from '../../Icons/InfoIcon';
import { FormIconHelperText } from '../../Fields/FormIconHelperText/FormIconHelperText';
import { LoadingButton } from '../../LoadingButton';
import { CompleteEmailFormValues } from './complete-email-action-sheet.types';
import { completeEmailFormValidationSchema } from './complete-email-action-sheet.validation';
import { SizingMultiplier } from '../../../theme/constants';
import { messages as editProfileMessages } from '../../../pages/EditProfile/messages';
import { formLabelMessages } from '../../../modules/localization/form-label.messages';
import { buttonTextMessages } from '../../../modules/localization/button-text.messages';
import { ServiceLink } from '../components/ServiceLink';

type CompleteEmailActionSheetViewProps = {
  onSubmit(values: CompleteEmailFormValues): void;
};

const initialValues: CompleteEmailFormValues = { email: '' };

export const CompleteEmailActionSheetView: React.FC<CompleteEmailActionSheetViewProps> = (props) => {
  const intl = useIntl();
  const { onSubmit } = props;

  return (
    <Form<CompleteEmailFormValues>
      validate={completeEmailFormValidationSchema}
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, hasValidationErrors, dirty, submitting }) => (
        <Stack component="form" noValidate alignItems="center" spacing={SizingMultiplier.md} onSubmit={handleSubmit}>
          <Stack spacing={SizingMultiplier.sm}>
            <TextField
              variant="outlined"
              name="email"
              type="email"
              label={intl.formatMessage(formLabelMessages['form-label:email'])}
              fullWidth
            />
            <FormIconHelperText icon={InfoIcon} text={intl.formatMessage(editProfileMessages.emailFieldDescription)} />
          </Stack>
          <LoadingButton loading={submitting} disabled={hasValidationErrors || !dirty} type="submit" fullWidth>
            <FormattedMessage {...buttonTextMessages['button-text:save']} />
          </LoadingButton>
          <ServiceLink />
        </Stack>
      )}
    />
  );
};
