import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  'profile:receiptsButtonTitle': {
    id: 'profile:receiptsButtonTitle',
    defaultMessage: 'Receipts',
    description: 'Receipts button title on the Profile page',
  },
  'profile:walletButtonTitle': {
    id: 'profile:walletButtonTitle',
    defaultMessage: 'Wallet',
    description: 'Wallet button title on the Profile page',
  },
  'profile:preferredFuelGradeButtonTitle': {
    id: 'profile:preferredFuelGradeButtonTitle',
    defaultMessage: 'Default Fuel Grade',
    description: 'Preferred Fuel Grade button title on the Profile page',
  },
  'profile:about:supportAndFeedbackButtonTitle': {
    id: 'profile:about:supportAndFeedbackButtonTitle',
    defaultMessage: 'Support',
    description: 'Profile page > About section > Title of the item in the list',
  },
  'profile:about:privacyPolicyButtonTitle': {
    id: 'profile:about:privacyPolicyButtonTitle',
    defaultMessage: 'Privacy Policy',
    description: 'Profile page > About section > Title of the item in the list',
  },
  'profile:about:termsAndConditionsButtonTitle': {
    id: 'profile:about:termsAndConditionsButtonTitle',
    defaultMessage: 'Terms & Conditions',
    description: 'Profile page > About section > Title of the item in the list',
  },
  'profile:accountActions:deleteAccountButtonTitle': {
    id: 'profile:accountActions:deleteAccountButtonTitle',
    defaultMessage: 'Delete Account',
    description: 'Profile page > Account Actions section > Delete account button title of the item in the list',
  },
  'profile:accountActions:signOutButtonTitle': {
    id: 'profile:accountActions:signOutButtonTitle',
    defaultMessage: 'Sign Out',
    description: 'Profile page > Account Actions section > Sign Out button title of the item in the list',
  },
  'profile:aboutSectionTitle': {
    id: 'profile:aboutSectionTitle',
    defaultMessage: 'About',
    description: 'About card title on the Profile page',
  },
  'profile:accountSection:title': {
    id: 'profile:accountSection:title',
    defaultMessage: 'Account',
    description: 'Account section title on the Profile page',
  },
  'profile:appVersionTitle': {
    id: 'profile:appVersionTitle',
    defaultMessage: 'App version: {version}',
    description: 'Profile app version title',
  },
  'profile:profileSection:subtitle': {
    id: 'profile:profileSection:subtitle',
    defaultMessage: 'Tap to view and edit your information',
    description: 'Subtitle of the Edit Profile card on the Profile page',
  },
  'profile:communicationPreference': {
    id: 'profile:communicationPreference',
    defaultMessage: 'Communication Preferences',
    description: 'Communication Preferences list item text',
  },
  'profile:productInterests': {
    id: 'profile:productInterests',
    defaultMessage: 'Product Interests',
    description: 'Product Interests list item text',
  },
  'profile:aboutActions:howDoesItWork': {
    id: 'howDoesItWorkOffer:title',
    defaultMessage: 'How does it work?',
    description: 'How does it work offer title',
  },
});
