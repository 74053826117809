import { Box } from '@mui/material';
import { ReactNode } from 'react';

import { SizingMultiplier } from '../sizing-multiplier.constant';

type ListItemStartIconProps = {
  children?: ReactNode;
};

export const ListItemStartIcon = (props: ListItemStartIconProps) => {
  const { children } = props;
  return (
    <Box display="flex" mr={SizingMultiplier.sm} color="action.active">
      {children}
    </Box>
  );
};
