import { MockedResponse } from '@apollo/client/testing';
import merge from 'lodash/merge';
import { PartialDeep } from 'type-fest';

import {
  GetTransactionDocument,
  GetTransactionQuery,
  GetTransactionQueryVariables,
  PrimaryTransactionStatus,
  SecondaryTransactionStatus,
  TransactionFragment,
} from '@ocx/graphql';

export const defaultTransactionFragment: TransactionFragment = {
  uuid: 'test',
  fuelReceipt: {
    priceG: '0.23',
    product: '',
    fuelSale: '10.55',
    tax: '0.10',
    gallons: '10.032',
    carWashCode: 'A7H8KS',
    carWashName: 'Delux',
    carWashPrice: '15.02',
  },
  receiptLines: [
    {
      value: 'TEXT LINE 1',
      lineNumber: 1,
    },
    {
      value: 'TEXT LINE 2',
      lineNumber: 2,
    },
    {
      value: 'TEXT LINE 3',
      lineNumber: 3,
    },
    {
      value: 'TEXT LINE 4',
      lineNumber: 4,
    },
    {
      value: 'TEXT LINE 5',
      lineNumber: 5,
    },
    {
      value: 'TEXT LINE 6',
      lineNumber: 6,
    },
    {
      value: 'TEXT LINE 7',
      lineNumber: 7,
    },
  ],
  primaryStatus: PrimaryTransactionStatus.Completed,
  secondaryStatus: SecondaryTransactionStatus.Closed,
  completedAt: '2021-05-19T06:23:35.305Z',
  paymentInstrument: null,
  amount: { displayPrice: '$10.03', price: 10.03 },
  tax: { displayPrice: '$3.03', price: 3.03 },
  location: {
    address: {
      city: 'Test',
      street1: 'Test',
      street2: 'Test',
      state: 'Test',
      zipCode: 'Test',
      geoLocation: null,
    },
  },
  fuelingPosition: '1',
  // @ts-expect-error __typename is not part of this type
  __typename: 'Transaction',
};

export class TransactionMockFactory {
  static getTransactionFragment(_transactionFragment?: PartialDeep<TransactionFragment>): TransactionFragment {
    return merge<TransactionFragment, PartialDeep<TransactionFragment> | undefined>(
      { ...defaultTransactionFragment },
      _transactionFragment,
    );
  }

  static getTransactionGraphQLMock = (
    transaction: TransactionFragment,
    options: { variables?: GetTransactionQueryVariables } = {},
  ): MockedResponse<GetTransactionQuery> => ({
    request: {
      query: GetTransactionDocument,
      variables: { ...options.variables },
    },
    result: {
      data: {
        transaction,
      },
    },
  });
}
