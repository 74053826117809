import { add, IDismissedSurvey, removeBatch, getAll as storageGetAll } from './get-dismissed-surveys.storage';

export const dismiss = async (id: string, membershipId: string): Promise<void> => {
  await removeBatch([id], membershipId);
  await add({ id, dismissedAt: Date.now() }, membershipId);
};

export const buildGetIsDismissed = async (membershipId: string): Promise<(id: string) => boolean> => {
  const dismissedSurveys = await getAll(membershipId);
  return (id: string): boolean => !!dismissedSurveys.find((survey) => survey.id === id);
};

const getAll = async (membershipId: string): Promise<IDismissedSurvey[]> => {
  return storageGetAll(membershipId);
};
